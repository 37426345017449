var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main-layout",
    { staticClass: "kanban-expandido", attrs: { id: "kanban-container" } },
    [
      _c(
        "v-layout",
        [
          _c(
            "v-flex",
            { staticClass: "logo-container", attrs: { xs12: "" } },
            [
              _c("router-link", { attrs: { to: { path: "/atendimentos" } } }, [
                _c("img", {
                  staticClass: "logo kanban-expandido",
                  attrs: {
                    src: require("../../../assets/voar_logo.png"),
                    alt: ""
                  }
                })
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-layout",
        { attrs: { row: "", wrap: "" } },
        [
          _c(
            "v-flex",
            { attrs: { xs4: "" } },
            [
              _c("base-search-input", {
                attrs: { placeholder: "Procurar Por Atendimento" },
                on: {
                  update: function($event) {
                    return _vm.debounceMercuriusCaseSearch()
                  }
                },
                model: {
                  value: _vm.mercuriusCaseSearchText,
                  callback: function($$v) {
                    _vm.mercuriusCaseSearchText = $$v
                  },
                  expression: "mercuriusCaseSearchText"
                }
              })
            ],
            1
          ),
          _c(
            "v-flex",
            { attrs: { xs7: "" } },
            [
              _c(
                "v-layout",
                { staticClass: "borderless", attrs: { row: "", wrap: "" } },
                [
                  _c("v-flex", { attrs: { sm3: "" } }, [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.periodFilterSelected,
                            expression: "periodFilterSelected"
                          }
                        ],
                        staticClass: "vv-input",
                        on: {
                          change: [
                            function($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function(o) {
                                  return o.selected
                                })
                                .map(function(o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.periodFilterSelected = $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            },
                            _vm.updatePeriodFilter
                          ]
                        }
                      },
                      [
                        _c(
                          "option",
                          {
                            staticClass: "vv-disabled",
                            attrs: { selected: "", value: "" }
                          },
                          [_vm._v(_vm._s(_vm.$t("services.period")))]
                        ),
                        _vm._l(_vm.periodFilters, function(periodFilter, key) {
                          return _c(
                            "option",
                            { key: key, domProps: { value: periodFilter } },
                            [_vm._v(_vm._s(periodFilter))]
                          )
                        })
                      ],
                      2
                    )
                  ]),
                  _vm.isPeriodBetween
                    ? _c(
                        "v-flex",
                        { attrs: { sm3: "", xs4: "" } },
                        [
                          _c(
                            "v-menu",
                            {
                              attrs: {
                                lazy: "",
                                "close-on-content-click": false,
                                transition: "scale-transition",
                                "offset-y": "",
                                "full-width": "",
                                "nudge-right": 40,
                                "max-width": "290px",
                                "min-width": "290px"
                              },
                              model: {
                                value: _vm.menu,
                                callback: function($$v) {
                                  _vm.menu = $$v
                                },
                                expression: "menu"
                              }
                            },
                            [
                              _c("v-text-field", {
                                staticClass: "vv-input mt-0 px-1 pt-2",
                                attrs: {
                                  slot: "activator",
                                  readonly: "",
                                  placeholder: "Inicio"
                                },
                                slot: "activator",
                                model: {
                                  value: _vm.filteredDates.startDate,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.filteredDates,
                                      "startDate",
                                      $$v
                                    )
                                  },
                                  expression: "filteredDates.startDate"
                                }
                              }),
                              _c("v-date-picker", {
                                attrs: {
                                  "no-title": "",
                                  scrollable: "",
                                  locale: "pt-BR",
                                  actions: "",
                                  autosave: ""
                                },
                                model: {
                                  value: _vm.searchFilter.startDate,
                                  callback: function($$v) {
                                    _vm.$set(_vm.searchFilter, "startDate", $$v)
                                  },
                                  expression: "searchFilter.startDate"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isPeriodBetween
                    ? _c(
                        "v-flex",
                        { attrs: { sm3: "", xs6: "" } },
                        [
                          _c(
                            "v-menu",
                            {
                              attrs: {
                                lazy: "",
                                "close-on-content-click": false,
                                transition: "scale-transition",
                                "offset-y": "",
                                "full-width": "",
                                "nudge-right": 40,
                                "max-width": "290px",
                                "min-width": "290px"
                              }
                            },
                            [
                              _c("v-text-field", {
                                staticClass: "vv-input mt-0 px-1 pt-2",
                                attrs: {
                                  slot: "activator",
                                  readonly: "",
                                  placeholder: "Fim"
                                },
                                slot: "activator",
                                model: {
                                  value: _vm.filteredDates.endDate,
                                  callback: function($$v) {
                                    _vm.$set(_vm.filteredDates, "endDate", $$v)
                                  },
                                  expression: "filteredDates.endDate"
                                }
                              }),
                              _c("v-date-picker", {
                                attrs: {
                                  "no-title": "",
                                  scrollable: "",
                                  locale: "pt-BR",
                                  actions: "",
                                  autosave: ""
                                },
                                model: {
                                  value: _vm.searchFilter.endDate,
                                  callback: function($$v) {
                                    _vm.$set(_vm.searchFilter, "endDate", $$v)
                                  },
                                  expression: "searchFilter.endDate"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("v-flex", { attrs: { sm3: "" } })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-flex",
            { attrs: { md1: "" } },
            [
              _c(
                "v-subheader",
                {
                  staticClass: "cleanfilters",
                  on: {
                    click: function($event) {
                      return _vm.cleanFilters()
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("services.reset")))]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-layout",
        { staticClass: "card-container", attrs: { row: "", wrap: "" } },
        _vm._l(_vm.kanbanGridData, function(data) {
          return _c(
            "v-flex",
            { key: _vm.kanbanGridData.type, attrs: { xs3: "" } },
            [
              _c("v-subheader", [
                _vm._v(
                  _vm._s(data.type) +
                    " (" +
                    _vm._s(
                      _vm.sortedItemsPerStatus[data.status]
                        ? _vm.sortedItemsPerStatus[data.status].length
                        : 0
                    ) +
                    ")"
                )
              ]),
              _c("hr", { staticClass: "vv-kanban-separator" }),
              _vm._l(_vm.sortedItemsPerStatus[data.status], function(
                item,
                key
              ) {
                return _c("kanban-card", {
                  key: item.id,
                  attrs: {
                    item: item,
                    notifications: _vm.getNotificationsPerService(item)
                  },
                  on: { forceUpdate: _vm.forceUpdate }
                })
              })
            ],
            2
          )
        }),
        1
      ),
      _c(
        "v-footer",
        { staticClass: "pa-5 kanban-expandido-footer", attrs: { fixed: "" } },
        [
          _c(
            "v-flex",
            { attrs: { xs2: "" } },
            [
              _c("kanban-voltar-button", {
                on: {
                  click: function($event) {
                    return _vm.goToOriginal()
                  }
                }
              })
            ],
            1
          ),
          _c("v-spacer"),
          _c("div", [
            _c("div", { staticClass: "status-indicator" }, [
              _c("div", { staticClass: "status-badge processing" }),
              _c("div", { staticClass: "status-label " }, [
                _vm._v(_vm._s(_vm.$t("footerStatus.report")))
              ])
            ]),
            _c("div", { staticClass: "status-indicator" }, [
              _c("div", { staticClass: "status-badge departure" }),
              _c("div", { staticClass: "status-label " }, [
                _vm._v(_vm._s(_vm.$t("footerStatus.progress")))
              ])
            ]),
            _c("div", { staticClass: "status-indicator" }, [
              _c("div", { staticClass: "status-badge finished" }),
              _c("div", { staticClass: "status-label" }, [
                _vm._v(_vm._s(_vm.$t("footerStatus.end")))
              ])
            ])
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }