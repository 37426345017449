<template>
  <div id="bar-chart-container"></div>
</template>

<script>
import * as d3 from 'd3';

export default {
  name: 'DashboardBarChart',
  props: {
    chartData: {
      type: Array,
    },
    width: {
      default: 970,
      type: Number,
    },
    height: {
      default: 210,
      type: Number,
    },
  },
  data: () => ({
    svg: null,
    margin: { top: 20, right: 50, bottom: 30, left: 10 },
    dateParser: null,
    xScale: null,
    yScale: null,
    allValues: [],
    allUniqueDates: [],
    timeFormat: null,
  }),
  computed: {
    rangeX() {
      const width = this.width - (this.margin.right + 30);
      return [this.margin.left + 40, width];
    },
    rangeY() {
      const height = this.height - this.margin.bottom;
      return [height, this.margin.top];
    },
  },
  methods: {
    async parseData() {},
    createChart() {
      d3.select('svg#bar-chart').remove();
      this.svg = d3
        .select('#bar-chart-container')
        .append('svg')
        .attr('id', 'bar-chart')
        .attr('viewBox', `0 0 ${this.width} ${this.height}`);
    },
    drawChart() {
      this.setScales();
      this.setAxes();
      this.svg
        .append('g')
        .selectAll('rect')
        .data(this.chartData)
        .join('rect')
        .attr('fill', (d) => this.getBarColor(d.reason))
        .attr('x', (d, i) => this.xScale(i) + this.xScale.bandwidth() / 2 - 20)
        .attr('y', (d) => this.yScale(d.value))
        .attr('height', (d) => this.yScale(0) - this.yScale(d.value))
        .attr('width', 40);
    },
    setScales() {
      this.xScale = d3
        .scaleBand()
        .range(this.rangeX)
        .domain(d3.range(this.chartData.length));
      this.yScale = d3
        .scaleLinear()
        .range(this.rangeY)
        .domain([0, d3.max(this.chartData, (d) => d.value)]);
    },
    setAxes() {
      // x axis
      this.svg
        .append('g')
        .style('transform', `translate(0, ${this.height - this.margin.bottom}px`)
        .call(
          d3
            .axisBottom(this.xScale)
            .tickFormat((i) => `${this.chartData[i].value} (${this.getBarPercentage(this.chartData[i].value)}%)`)
        )
        .call((g) => g.select('.domain').remove())
        .call((g) =>
          g
            .selectAll('.tick text')
            .attr('class', 'bar-chart__axis-text bar-chart__axis-text--x')
            .attr('y', 11)
        )
        .call((g) =>
          g
            .selectAll('.tick line')
            .attr('stroke-width', 3)
            .attr('stroke', '#979797')
        );
      
      // y axis
      this.svg
        .append('g')
        .style('transform', `translate(${this.width - this.margin.right}px, 0`)
        .call(
          d3
            .axisRight(this.yScale)
            .tickValues(this.yScale.ticks().filter(Number.isInteger))
            .tickFormat(d3.format('d'))
            .tickSize(-(this.width - this.margin.left - this.margin.right))
            .tickSizeOuter(0)
        )
        .call((g) =>
          g
            .selectAll('.tick line')
            .attr('stroke', '#979797')
            .attr('stroke-dasharray', '5')
            .style('opacity', '0.5')
        )
        .call((g) =>
          g
            .selectAll('.tick text')
            .attr('class', 'bar-chart__axis-text')
            .attr('x', 11)
        )
        .call((g) => g.select('.domain').remove());
    },
    getBarColor(key) {
      const barColors = {
        0: '#EC4B54',
        1: '#2AB69C',
        2: '#FF95EE',
        3: '#FDD672',
        4: '#3A619D',
      };
      return barColors[key];
    },
    getBarPercentage(value) {
      const total = this.chartData.reduce((acc, { value }) => acc + value, 0);
      return total ? ((value * 100) / total).toFixed(0) : 0;
    },
  },
  mounted() {
    this.createChart();
    this.parseData();
    this.drawChart();
  },
  watch: {
    chartData: {
      handler(value) {
        this.createChart();
        this.parseData();
        this.drawChart();
      },
    },
  },
};
</script>

<style lang="scss">
.bar-chart {
  &__axis-text {
    font-size: 10px;
    color: #35343d;
    opacity: 0.5;
    text-transform: uppercase;
    &--x {
      opacity: 1;
    }
  }
}
</style>
