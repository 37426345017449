// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import router from './router'
import Vuetify from 'vuetify'
import 'material-design-icons-iconfont/dist/material-design-icons.css' // Ensure you are using css-loader
import MenuIcon from 'vue-material-design-icons/menu.vue'
import 'vuetify/dist/vuetify.min.css' // Ensure you are using css-loader
import store from './store/index.js'
import Vuex from 'vuex'
import i18n from '@/locale/index'
import io from 'socket.io-client';
import VueSocketio from 'vue-socket.io';
import messages from '@/locale/messages'
import utils from '@/libs/utils'
import VueNativeNotification from 'vue-native-notification'
import MainLayout from '@/router/layouts/Main'
import '@/components/_globals'
import es from 'vuetify/lib/locale/es'
import pt from 'vuetify/lib/locale/pt'
import en from 'vuetify/lib/locale/en'
import FlagIcon from 'vue-flag-icon'

let socket = io('https://voar-realtime.volvo.com/', {
  'secure': true,
  'reconnect': true,
  'reconnection delay': 500,
  'max reconnection attempts': 99999
});

Vue.component('MainLayout', MainLayout)
Vue.use(Vuex)
Vue.use(FlagIcon)
Vue.use(i18n)

Vue.config.i18n = i18n

Vue.use(VueSocketio, socket);

Vue.use(VueNativeNotification);

// var language = navigator.languages && navigator.languages[0] ||
//                navigator.language ||
//                navigator.userLanguage

// // se o idioma não for ingles nem português puxa direto pro português
// language = (!['en-US', 'pt-BR', 'es-AR', 'es-CL', 'es-CO'].includes(language)) ? 'pt' : language.split('-')[0]

Vue.use(Vuetify, {
  iconfont: 'md', // 'md' || 'mdi' || 'fa' || 'fa4',
  lang: {
    locales: { pt, es, en },
    current: 'pt'
  }
})
Vue.prototype.$utils = utils
/* eslint-disable no-new */
new Vue({
  i18n,
  el: '#app',
  router,
  store,
  template: '<App/>',
  components: { App }
})
