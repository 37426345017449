import Vue from 'vue';
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

import messages from './messages'
// var language = navigator.languages && navigator.languages[0] ||
//                navigator.language ||
//                navigator.userLanguage

// // se o idioma não for ingles nem português puxa direto pro português
// language = (!['en-US', 'pt-BR', 'es-AR', 'es-CL', 'es-CO'].includes(language)) ? 'pt-BR' : language

const i18n = new VueI18n({
  locale: 'pt',
  messages, // set locale messages
})

export default i18n