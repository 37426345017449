<template>
  <main-layout class='listar'>

    <v-layout
      justify-center
      row wrap
    >
      <form-alert
        :alert-model="!!alertText"
        :alert-text="alertText"
        @input="closeFormAlert()"
      />
      <v-flex xs8>
        <base-search-input
        v-model="search"
        :placeholder="$t('economicGroups.searchEconomicGroup')"
        ></base-search-input>

      </v-flex>
      <v-flex xs4>
        <base-adicionar-button
          :to="{path: '/grupos-economicos/novo'}"
          :text="$t('economicGroups.addGroup')"
        />
      </v-flex>
      <v-flex xs12>
        <v-data-table
          v-bind:headers="headers"
          :items="filteredItems"
          no-data-text="Sem dados disponíveis"
          hide-actions
          class="elevation-1 list__table"
        >
          <template slot="items" slot-scope="props">
            <router-link
              :to="{path: '/grupos-economicos/editar/' + props.item.id}"
              tag="tr"
              class='cursor-pointer'
            >
              <td class='text-xs-left' v-html="props.item.name"></td>
            </router-link>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
  </main-layout>
</template>

<style>


</style>

<script>
import utils from "@/libs/utils"
import { mapState, mapActions } from 'vuex'
import ViewMixin from '@/mixins/ViewMixin'
import config from '@/app.settings'
export default {
  name: 'GruposEconomicosListar',
  components: {},
  mixins: [ViewMixin],
  data: () => ({
    economicGroups: [],
    search: '',

  }),
  methods: {
    ...mapActions('economicGroups', [
      'loadEconomicGroups',
    ]),
    async init() {
      this.$utils.showLoadingIndicator()
      try {
        this.economicGroups = await this.loadEconomicGroups()
      } catch(e) {
        console.warn(e)
      }
      this.$utils.hideLoadingIndicator()
    },

  },
  computed: {
    ...mapState('main', [
      'pageName'
    ]),
    filteredItems() {
      let items = this.economicGroups
      let normalizeString = this.$utils.normalizeString

      if (!items.length)
        return []
      items = this.$utils.sortAlphabeticalOrder(items)

      if (!this.search)
        return items

      items = items.filter(item => item.id !== config.adminEconomicGroup)

      let tempItems = items.filter(
        item => normalizeString(item.name).indexOf(normalizeString(this.search)) !== -1
      )


      return tempItems
    },
    items () {
      let items = this.economicGroups

    },
    headers() {
      return [
        {
          text: this.$tc('economicGroups.economicGroup', 1),
          align: 'left',
          sortable: false,
          value: 'name'
        }
    ]
    }
  },
  created (){
    this.init()
    this.alertText = this.$utils.handleAlertText()
  },

}
</script>