var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "fill-height" },
    [
      _c(
        "v-content",
        { staticClass: "fill-height" },
        [
          _c(
            "v-container",
            _vm._b(
              { attrs: { fluid: "", "grid-list-md": "" } },
              "v-container",
              _vm.$attrs,
              false
            ),
            [_vm._t("default")],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }