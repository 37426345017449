var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main-layout",
    { staticClass: "listar" },
    [
      _c(
        "v-layout",
        { attrs: { "justify-center": "", row: "", wrap: "" } },
        [
          _c("form-alert", {
            attrs: {
              "alert-model": !!_vm.alertText,
              "alert-text": _vm.alertText
            },
            on: {
              input: function($event) {
                return _vm.closeFormAlert()
              }
            }
          }),
          _c(
            "v-flex",
            { attrs: { xs8: "" } },
            [
              _c("base-search-input", {
                attrs: {
                  placeholder: _vm.$t("economicGroups.searchEconomicGroup")
                },
                model: {
                  value: _vm.search,
                  callback: function($$v) {
                    _vm.search = $$v
                  },
                  expression: "search"
                }
              })
            ],
            1
          ),
          _c(
            "v-flex",
            { attrs: { xs4: "" } },
            [
              _c("base-adicionar-button", {
                attrs: {
                  to: { path: "/grupos-economicos/novo" },
                  text: _vm.$t("economicGroups.addGroup")
                }
              })
            ],
            1
          ),
          _c(
            "v-flex",
            { attrs: { xs12: "" } },
            [
              _c("v-data-table", {
                staticClass: "elevation-1 list__table",
                attrs: {
                  headers: _vm.headers,
                  items: _vm.filteredItems,
                  "no-data-text": "Sem dados disponíveis",
                  "hide-actions": ""
                },
                scopedSlots: _vm._u([
                  {
                    key: "items",
                    fn: function(props) {
                      return [
                        _c(
                          "router-link",
                          {
                            staticClass: "cursor-pointer",
                            attrs: {
                              to: {
                                path:
                                  "/grupos-economicos/editar/" + props.item.id
                              },
                              tag: "tr"
                            }
                          },
                          [
                            _c("td", {
                              staticClass: "text-xs-left",
                              domProps: { innerHTML: _vm._s(props.item.name) }
                            })
                          ]
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }