var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "input-wrapper search" }, [
    _c(
      "input",
      _vm._g(
        _vm._b(
          {
            staticClass: "vv-input search-icon",
            attrs: { type: "text" },
            on: {
              input: function($event) {
                return _vm.$emit("update", $event.target.value)
              }
            }
          },
          "input",
          _vm.$attrs,
          false
        ),
        _vm.$listeners
      )
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }