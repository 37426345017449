<template>
  <main-layout class='listar' >
    <v-layout
      justify-center
      row wrap
    >
      <form-alert
        :alert-model="!!alertText"
        :alert-text="alertText"
        @input="closeFormAlert()"
      />
      <v-flex xs8>
        <base-search-input
        v-model="search"
        :placeholder="$t('reasonsCancellations.searchReasonCancellation')"
        ></base-search-input>
      </v-flex>
      <v-flex xs4>
        <base-adicionar-button
          :to="{path: '/motivos-cancelamento/novo'}"
          :text="$t('reasonsCancellations.addReason')"
        />
      </v-flex>
      <v-flex xs12>
        <v-data-table
          v-bind:headers="headers"
          :items="filteredItems"
          no-data-text="Sem dados disponíveis"
          hide-actions
          class="elevation-1 list__table"
        >
          <template slot="items" slot-scope="props">
              <td class='text-xs-left' v-html="translateDescription(props.item)"></td>
              <td class='text-xs-left' v-html="parseStrToDate(props.item.createAt)"></td>
              <td class='text-xs-left' >
              <alterar-status-motivo  
              :item="props.item"
              />
              </td>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
  </main-layout>
</template>
<style>


</style>

<script>
import utils from '@/libs/utils';
import { mapState, mapActions } from 'vuex'
import ViewMixin from '@/mixins/ViewMixin'
import config from '@/app.settings'

export default {
  name: 'MotivosListar',
  components: {},
  mixins: [ViewMixin],
  data: () => ({
    reasonsCancellations: [],
    search: '',
    language: Boolean,
  }),
  methods: {
    ...mapActions('cancelReasons', [
      'getCancelReasons',
    ]),
    async init() {
      this.$utils.showLoadingIndicator()

      try {
        this.reasonsCancellations = await this.getCancelReasons()
      } catch(e) {
        console.warn(e)
      }
      this.$utils.hideLoadingIndicator()
    },
     parseStrToDate(value){
      return this.$utils.formatDateApi(value)
     },
    translateDescription(value){
      return this.$vuetify.lang.current == 'pt' ? value.descriptionPortugueseBr : value.descriptionSpanishEs
    }
  },
  computed: {
    ...mapState('main', [
      'pageName',
    ]),
    filteredItems() {
      let items = this.reasonsCancellations
      let normalizeString = this.$utils.normalizeString

      if (!items.length)
        return []
      items = this.$utils.sortAlphabeticalOrder(items)

      if (!this.search)
        return items

      items = items.filter(item => item.id !== config.adminEconomicGroup)

      let tempItems = items.filter(
        item => normalizeString(this.translateDescription(item)).indexOf(normalizeString(this.search)) !== -1
      )

      return tempItems
    },
    items () {
      let items = this.reasonsCancellations
    },
    headers() {
      return [
        {
          text: this.$tc('reasonsCancellations.reasonCancellation', 1),
          align: 'left',
          sortable: false,
          value: 'descriptionPortugueseBr'
        },
        {
          text: this.$tc('reasonsCancellations.reasonCancellation', 1),
          align: 'left',
          sortable: false,
          value: 'descriptionSpanishEs'
        },
        {
          text: this.$tc('reasonsCancellations.createDate', 1),
          align: 'left',
          sortable: false,
          value: 'createDate'
        },
        {
          text: this.$tc('reasonsCancellations.active', 1),
          align: 'left',
          sortable: false,
          value: 'active'
        }
    ]
    }
  },
  created (){
    this.init()
    this.alertText = this.$utils.handleAlertText()
  },
}
</script>