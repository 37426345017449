var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    { attrs: { id: "volvo-voar" } },
    [_c("router-view", { key: _vm.$route.path }), _c("the-loading-indicator")],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }