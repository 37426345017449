<template>
  <v-card>
    <v-card-title class="headline" > {{ $t('services.reasonCancelService') }} </v-card-title>
    <v-card-text>
      <button
        v-for="(reason, index) in reasonsCancel"
        :key="index"
        class="btn-primary vv-button vv-button-inverted mb-2 vv-adicionar-novo-button"
        @click="$emit('select-cancel-type', reason)"
        v-html="translateDescription(reason)"
        >
      </button>
    </v-card-text>
  </v-card>
</template>

<script>
import config from '@/app.settings'
import { mapState, mapActions } from 'vuex'

export default {
  name: 'CancelarAtendimentoModal',
  data: () => ({
    reason: [],
    reasonsCancel: []
  }),
  methods: {
    ...mapActions('cancelReasons', [
      'getCancelReasons',
    ]),

    async possibleCancelReason() {
      const reasons = await this.getCancelReasons()
      this.reasonsCancel = reasons.filter(x => x.active)
    },

    translateDescription(value){
      return this.$vuetify.lang.current == 'pt' ? value.descriptionPortugueseBr : value.descriptionSpanishEs
    }
  },
  mounted () {
    this.possibleCancelReason()
  }
}

</script>

<style lang="css" scoped>
</style>