var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main-layout",
    { staticClass: "listar", attrs: { id: "atendimentos-listar-container" } },
    [
      _c(
        "v-layout",
        { attrs: { "justify-center": "", row: "", wrap: "" } },
        [
          _c("form-alert", {
            attrs: {
              "alert-model": !!_vm.alertText,
              "alert-text": _vm.alertText
            },
            on: {
              input: function($event) {
                return _vm.closeFormAlert()
              }
            }
          }),
          _c(
            "v-flex",
            { attrs: { md12: "" } },
            [
              _c("base-search-input", {
                attrs: { placeholder: _vm.$t("services.searchService") },
                on: {
                  update: function($event) {
                    return _vm.debounceGeneralSearch()
                  }
                },
                model: {
                  value: _vm.generalSearchText,
                  callback: function($$v) {
                    _vm.generalSearchText = $$v
                  },
                  expression: "generalSearchText"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-layout",
        { attrs: { row: "", wrap: "" } },
        [
          _c(
            "v-flex",
            { attrs: { md5: "" } },
            [
              _c(
                "v-layout",
                { attrs: { row: "", wrap: "" } },
                [
                  _c(
                    "v-flex",
                    {
                      class: { md4: !_vm.oneDealership, md6: _vm.oneDealership }
                    },
                    [
                      _c("base-search-input", {
                        attrs: {
                          placeholder: _vm.$t("services.mercuriusCase")
                        },
                        on: {
                          update: function($event) {
                            return _vm.debounceMercuriusCaseSearch()
                          }
                        },
                        model: {
                          value: _vm.mercuriusCaseSearchText,
                          callback: function($$v) {
                            _vm.mercuriusCaseSearchText = $$v
                          },
                          expression: "mercuriusCaseSearchText"
                        }
                      })
                    ],
                    1
                  ),
                  !_vm.oneDealership
                    ? _c("v-flex", { attrs: { md5: "" } }, [
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.searchFilter.dealership,
                                expression: "searchFilter.dealership"
                              }
                            ],
                            staticClass: "vv-input",
                            attrs: { disabled: _vm.oneDealership },
                            on: {
                              change: function($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function(o) {
                                    return o.selected
                                  })
                                  .map(function(o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.searchFilter,
                                  "dealership",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              }
                            }
                          },
                          [
                            _c(
                              "option",
                              {
                                staticClass: "vv-disabled",
                                attrs: { selected: "", value: "" }
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.$tc("services.dealership", 1))
                                )
                              ]
                            ),
                            _vm._l(_vm.dealerships, function(dealership, key) {
                              return _c(
                                "option",
                                {
                                  key: key,
                                  domProps: { value: dealership.id }
                                },
                                [_vm._v(_vm._s(dealership.name))]
                              )
                            })
                          ],
                          2
                        )
                      ])
                    : _vm._e(),
                  _c(
                    "v-flex",
                    {
                      class: { md3: !_vm.oneDealership, md6: _vm.oneDealership }
                    },
                    [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.searchFilter.status,
                              expression: "searchFilter.status"
                            }
                          ],
                          staticClass: "vv-input",
                          on: {
                            change: function($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function(o) {
                                  return o.selected
                                })
                                .map(function(o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.searchFilter,
                                "status",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            }
                          }
                        },
                        [
                          _c(
                            "option",
                            {
                              staticClass: "vv-disabled",
                              attrs: { selected: "", value: "" }
                            },
                            [_vm._v(_vm._s(_vm.$t("services.status")))]
                          ),
                          _vm._l(_vm.status, function(state, key) {
                            return _c(
                              "option",
                              { key: key, domProps: { value: state } },
                              [_vm._v(_vm._s(_vm.getStatusName(state)))]
                            )
                          })
                        ],
                        2
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-flex",
            { attrs: { md6: "" } },
            [
              _c(
                "v-layout",
                { attrs: { row: "", wrap: "" } },
                [
                  _c("v-flex", { attrs: { sm3: "", xs12: "" } }, [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.periodFilterSelected,
                            expression: "periodFilterSelected"
                          }
                        ],
                        staticClass: "vv-input",
                        on: {
                          change: [
                            function($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function(o) {
                                  return o.selected
                                })
                                .map(function(o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.periodFilterSelected = $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            },
                            _vm.updatePeriodFilter
                          ]
                        }
                      },
                      [
                        _c(
                          "option",
                          {
                            staticClass: "vv-disabled",
                            attrs: { selected: "", value: "" }
                          },
                          [_vm._v(_vm._s(_vm.$t("services.period")))]
                        ),
                        _vm._l(_vm.periodFilters, function(periodFilter, key) {
                          return _c(
                            "option",
                            { key: key, domProps: { value: periodFilter } },
                            [_vm._v(_vm._s(periodFilter))]
                          )
                        })
                      ],
                      2
                    )
                  ]),
                  _vm.isPeriodBetween
                    ? _c(
                        "v-flex",
                        { attrs: { sm3: "", xs6: "" } },
                        [
                          _c(
                            "v-menu",
                            {
                              attrs: {
                                lazy: "",
                                "close-on-content-click": false,
                                transition: "scale-transition",
                                "offset-y": "",
                                "full-width": "",
                                "nudge-right": 40,
                                "max-width": "290px",
                                "min-width": "290px"
                              }
                            },
                            [
                              _c("v-text-field", {
                                staticClass: "vv-input mt-0 px-1 pt-2",
                                attrs: {
                                  slot: "activator",
                                  readonly: "",
                                  placeholder: _vm.$t("services.start")
                                },
                                slot: "activator",
                                model: {
                                  value: _vm.filteredDates.startDate,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.filteredDates,
                                      "startDate",
                                      $$v
                                    )
                                  },
                                  expression: "filteredDates.startDate"
                                }
                              }),
                              _c("v-date-picker", {
                                attrs: {
                                  "no-title": "",
                                  scrollable: "",
                                  locale: "pt-BR",
                                  actions: "",
                                  autosave: ""
                                },
                                model: {
                                  value: _vm.searchFilter.startDate,
                                  callback: function($$v) {
                                    _vm.$set(_vm.searchFilter, "startDate", $$v)
                                  },
                                  expression: "searchFilter.startDate"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isPeriodBetween
                    ? _c(
                        "v-flex",
                        { attrs: { sm3: "", xs6: "" } },
                        [
                          _c(
                            "v-menu",
                            {
                              attrs: {
                                lazy: "",
                                "close-on-content-click": false,
                                transition: "scale-transition",
                                "offset-y": "",
                                "full-width": "",
                                "nudge-right": 40,
                                "max-width": "290px",
                                "min-width": "290px"
                              }
                            },
                            [
                              _c("v-text-field", {
                                staticClass: "vv-input mt-0 px-1 pt-2",
                                attrs: {
                                  slot: "activator",
                                  readonly: "",
                                  placeholder: _vm.$t("services.end")
                                },
                                slot: "activator",
                                model: {
                                  value: _vm.filteredDates.endDate,
                                  callback: function($$v) {
                                    _vm.$set(_vm.filteredDates, "endDate", $$v)
                                  },
                                  expression: "filteredDates.endDate"
                                }
                              }),
                              _c("v-date-picker", {
                                attrs: {
                                  "no-title": "",
                                  locale: "pt-BR",
                                  scrollable: "",
                                  actions: "",
                                  autosave: ""
                                },
                                model: {
                                  value: _vm.searchFilter.endDate,
                                  callback: function($$v) {
                                    _vm.$set(_vm.searchFilter, "endDate", $$v)
                                  },
                                  expression: "searchFilter.endDate"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-flex",
            { attrs: { md1: "" } },
            [
              _c(
                "v-subheader",
                {
                  staticClass: "cleanfilters",
                  on: {
                    click: function($event) {
                      return _vm.cleanFilters()
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("services.reset")))]
              )
            ],
            1
          )
        ],
        1
      ),
      _c("v-data-table", {
        staticClass: "elevation-1",
        attrs: {
          headers: _vm.headers,
          items: _vm.services,
          "rows-per-page-items": [50, 100, { text: "Todas", value: -1 }],
          pagination: _vm.options,
          "total-items": _vm.totalServices,
          "no-data-text": "Sem dados disponíveis"
        },
        on: {
          "update:pagination": function($event) {
            _vm.options = $event
          }
        },
        scopedSlots: _vm._u([
          {
            key: "items",
            fn: function(props) {
              return [
                _c(
                  "router-link",
                  {
                    class: _vm.getServiceClass(props.item.status),
                    attrs: {
                      to: { path: "/atendimentos/" + props.item.id },
                      tag: "td"
                    }
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(props.item.mercuriusCase) +
                        "\n      "
                    )
                  ]
                ),
                _c("router-link", {
                  attrs: {
                    to: { path: "/atendimentos/" + props.item.id },
                    tag: "td"
                  },
                  domProps: { innerHTML: _vm._s(props.item.dealership.name) }
                }),
                _c("router-link", {
                  attrs: {
                    to: { path: "/atendimentos/" + props.item.id },
                    tag: "td"
                  },
                  domProps: {
                    innerHTML: _vm._s(
                      props.item.startDate
                        ? _vm.formatISODate(props.item.startDate)
                        : "———"
                    )
                  }
                }),
                _c("router-link", {
                  attrs: {
                    to: { path: "/atendimentos/" + props.item.id },
                    tag: "td"
                  },
                  domProps: {
                    innerHTML: _vm._s(
                      props.item.endDate
                        ? _vm.formatISODate(props.item.endDate)
                        : "———"
                    )
                  }
                }),
                _c("router-link", {
                  attrs: {
                    to: { path: "/atendimentos/" + props.item.id },
                    tag: "td"
                  },
                  domProps: {
                    innerHTML: _vm._s(_vm.getStatusName(props.item.status))
                  }
                }),
                _c("router-link", {
                  attrs: {
                    to: { path: "/atendimentos/" + props.item.id },
                    tag: "td"
                  },
                  domProps: {
                    innerHTML: _vm._s(_vm.getCancelledText(props.item))
                  }
                }),
                _c(
                  "td",
                  [
                    _c("atendimentos-pin-svg", { attrs: { id: props.item.id } })
                  ],
                  1
                )
              ]
            }
          }
        ])
      }),
      _c("kanban-footer", { attrs: { "show-cancelled": "" } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }