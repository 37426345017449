import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import router from '@/router'
import store from '@/store'
import utils from '@/libs/utils'

// still has stuff to remove from here

axios.interceptors.request.use(config => {
	config.validadeStatus = (status) => status >= 200 && status < 300
	let accessTokenObj = localStorage.accessTokenObj
	if (accessTokenObj) {
		accessTokenObj = JSON.parse(accessTokenObj)
		if (accessTokenObj.token_type == 'Bearer') {
			config.headers.Authorization = 'Bearer ' + accessTokenObj.access_token
		}
	}
	return config
})

axios.interceptors.response.use(
	config => config,
	error => {
		if(error && error.request && error.request.status === 401){
			delete axios.defaults.headers.common['Authorization'];
			localStorage.removeItem('accessTokenObj', null)
			router.push({ name: 'Login' })
			utils.hideLoadingIndicator()
	  }
	  return Promise.reject(error)
	}
)

