var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.unreadServices
    ? _c("v-badge", {
        staticClass: "unread-services-badge ",
        attrs: { color: "red" },
        scopedSlots: _vm._u(
          [
            {
              key: "badge",
              fn: function() {
                return [
                  _c("span", {
                    domProps: { innerHTML: _vm._s(_vm.unreadServices) }
                  })
                ]
              },
              proxy: true
            }
          ],
          null,
          false,
          1737921868
        )
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }