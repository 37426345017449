import axios from 'axios'
import config from '@/app.settings'
import store from '@/store'
import utils from '@/libs/utils'
import _ from "lodash";

function isObject(item) {
  return (item && typeof item === 'object' && !Array.isArray(item));
}

/**
 * Deep merge two objects.
 * @param target
 * @param ...sources
 */
function mergeDeep(target, ...sources) {
  if (!sources.length) return target;
  const source = sources.shift();

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) Object.assign(target, { [key]: {} });
        mergeDeep(target[key], source[key]);
      } else {
        Object.assign(target, { [key]: source[key] });
      }
    }
  }

  return mergeDeep(target, ...sources);
}
function userDefaultAdapter(users) {
  users.forEach(user => {
    utils.uncapitalizeAllKeys(user)
  })
  return users
}

export const state = {
  me: false,
  myPermissions: {}
}

export const getters = {}

export const mutations = {

  SET_ME (state, payload) {
    state.me = payload
  },
  SET_MY_PERMISSIONS (state, payload) {
    state.myPermissions = payload;
  }
}

export const actions = {
  async addUser ({commit}, payload) {
    let response = await axios.post(config.apiUrl + '/users/', payload)
    return response.data
  },

  async editUser ({commit}, payload) {
    let response = await axios.put(config.apiUrl + '/user/', payload)
    return response.data.data
  },
  async deleteUser ({commit}, payload) {
    let response = await axios.delete(config.apiUrl + '/user/' + payload)
    return response.data.data  || 'delete-success'
  },
  async loadUser ({commit, dispatch}, payload) {

    let response = await axios.post(config.apiUrl + '/users/search', { VolvoId: payload.volvoId} )
    let user = response.data
    user = userDefaultAdapter(user)
    localStorage.setItem('language', user[0].languageId)
    commit('SET_MY_PERMISSIONS', user[0]);
    let employeeResponse = await store.dispatch('employees/loadEmployee', {
      id: user[0].employeeId,
    })
    return _.merge(employeeResponse, user)
  },
  async updateLanguageUser({commit}, payload) {
    let response = await axios.put(config.apiUrl + '/users/language', payload)
    return response.data
  }
}