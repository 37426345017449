<template>
  <v-card>
    <v-card-title class="headline" >{{title}} </v-card-title>
    <v-card-text v-if="$slots['default']">
      <slot />
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="green darken-1"
        v-if="!noCancelButton"
        flat="flat"
        @click.native="$emit('cancel')"
        >{{ $t('misc.cancel') }}</v-btn>
      <v-btn
        color="green darken-1"
        flat="flat"
        @click.native="$emit('confirm')"
        >{{ confirmText || $t('misc.confirm')  }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'BaseModal',
  data () {
    return {
    }
  },
  props: {
    title: {
      type: String,
      default: 'Tem certeza?'
    },

    confirmText: {
      default: false
    },
    noCancelButton: {
      type: Boolean,
      default: false
    }
  },
}
</script>

<style lang="css" scoped>
</style>