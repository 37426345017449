import axios from 'axios'
import config from '@/app.settings'
import utils from '@/libs/utils'

export const state = {}
export const getters = {}
export const mutations = {}

export const actions = {
  async getServiceStatus({ commit }, payload) {
    const { data } = await axios.get(`${config.apiUrl}/services/step/${payload.token}`,
      { 
        params: {
          languageID: payload.languageID
        }
      }
    );
    return defaultAdapter(data);
  }
}

function defaultAdapter(status) {
  utils.uncapitalizeAllKeys(status);
  return status;
}

