<template>
	<div>
    <v-toolbar  fixed app>
      <v-toolbar-side-icon @click.stop="drawer = !drawer"></v-toolbar-side-icon>
      <v-toolbar-title>{{ pageName }}</v-toolbar-title>
      <p class='text-xs-right greetings'><span>{{ $t('header.greetings') + (me.username)}}!</span>
      </p>
    </v-toolbar>

    <v-navigation-drawer
      fixed
      v-model="drawer"

    >
      <router-link :to="{path: '/atendimentos'}">
        <img class='logo' src="../assets/voar_logo.png" alt="">
      </router-link>

 <!--
            <v-list>
               <v-list-group v-for="item in items" :value="item.active" v-bind:key="item.title" v-if="checkPermission(item)">
                  <v-list-tile slot="item"  v-bind="clickBehaviour(item)">
                    <v-list-tile-action>
                      <v-icon>{{ item.action }}</v-icon>
                    </v-list-tile-action>
                    <v-list-tile-content>
                      <v-list-tile-title >{{ $t('header.' + item.templateName) }}</v-list-tile-title>
                    </v-list-tile-content>
                    <v-list-tile-action v-if="item.subItems">
                      <v-icon>keyboard_arrow_down</v-icon>
                    </v-list-tile-action>
                  </v-list-tile>
                  <v-list-tile :class="($route.path === subItem.path) ? 'active' : ''" v-for="subItem in item.subItems" v-bind:key="subItem.title" v-bind="clickBehaviour(subItem)"  >
                    <v-list-tile-content >
                      <v-list-tile-title>{{ $t('header.' + subItem.templateName) }}</v-list-tile-title>
                    </v-list-tile-content>
                    <v-list-tile-action>
                      <v-icon>{{ subItem.action }}</v-icon>
                    </v-list-tile-action>
                  </v-list-tile>
                </v-list-group>
            </v-list>
          -->
      <v-list>
        <v-list-group
          v-for="item in menuSidebar"
          :key="item.title"
          v-model="item.active"
          :prepend-icon="item.action"
          no-action
        >
          <template v-slot:activator>
            <v-list-tile
              @click="$router.push({path: item.path})"
            >
              <v-list-tile-content>
                <v-list-tile-title >{{ item.title }}</v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
          </template>

          <v-list-tile
            v-for="subItem in item.subItems"
            :key="subItem.title"
            @click="$router.push({path: subItem.path})"
          >
            <v-list-tile-content>
              <v-list-tile-title  > {{ subItem.title }}</v-list-tile-title>
            </v-list-tile-content>

            <v-list-tile-action>
              <v-icon>{{ subItem.action }}</v-icon>
            </v-list-tile-action>
          </v-list-tile>
        </v-list-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>
<script>
  import { mapState, mapActions } from 'vuex'
  export default {
    name: 'VvWrapper',
    data() {
      return {
          drawer: null
      }
  	},
  	computed: {
      ...mapState('main', [
        'menuSidebar',
        'pageName'
      ]),
      ...mapState('users', [
        'me',
      ]),
      ...mapState('employees', [
        'employee',
        'employees',
      ]),
      route () {
          return this.$route.name
      }

  	},
  	methods: {
      redirect (item) {
        let t = this
        if (!item.subItems) {
            t.$router.go(item.path)
        }
      },
      parseJwt  (token) {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace('-', '+').replace('_', '/');
        return JSON.parse(window.atob(base64));
      },
      checkPermission (item) {
        let employee = this.$store.state.employee
        if (!item.permissions) {
          return true
        }

        return item.permissions.includes(employee.level)
      },

    },
    mounted () {
        document.title = this.$route.name + ' - Volvo Voar '
        this.$store.commit('main/SET_PAGENAME', this.$route.name)
        this.$nextTick(() => this.$forceUpdate())

        if (!this.me) {

          this.$store.commit('users/SET_ME', this.parseJwt(JSON.parse(localStorage.accessTokenObj).access_token))
        }
    },
    watch: {
      route (newRoute, oldRoute) {
        document.title = this.$route.name + ' - Volvo Voar '
        this.$store.commit('main/SET_PAGENAME', newRoute)
        /* for (var i = 1; i < 99999; i++) window.clearInterval(i); */
      }
    },
  }
</script>
<style lang="scss" >

</style>
