var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c("v-card-title", { staticClass: "headline" }, [
        _vm._v(" " + _vm._s(_vm.$t("services.whichStatus")))
      ]),
      _c(
        "v-card-text",
        _vm._l(_vm.possibleStatus, function(option, index) {
          return _c("button", {
            key: index,
            staticClass:
              "btn-primary vv-button vv-button-inverted mb-2 vv-adicionar-novo-button",
            domProps: { innerHTML: _vm._s(option.name) },
            on: {
              click: function($event) {
                return _vm.$emit("change-status", { status: option.status })
              }
            }
          })
        }),
        0
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }