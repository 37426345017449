var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c("v-card-title", { staticClass: "headline" }),
      _c(
        "v-card-text",
        { staticClass: "confirm-cancel-modal__text" },
        [
          _c("v-icon", { attrs: { "x-large": "", color: "red" } }, [
            _vm._v("warning")
          ]),
          _c("p", { staticClass: "description" }, [
            _vm._v("Confirme abaixo o motivo do cancelamento do atendimento")
          ]),
          _c("span", { staticClass: "reason" }, [
            _vm._v(_vm._s(_vm.nameReason))
          ])
        ],
        1
      ),
      _c(
        "v-card-actions",
        { staticClass: "confirm-cancel-modal__footer" },
        [
          _c(
            "v-btn",
            {
              staticClass: "confirm-cancel-modal__button btn-cancel",
              attrs: { color: "#35343d", flat: "" },
              nativeOn: {
                click: function($event) {
                  return _vm.$emit("back")
                }
              }
            },
            [_vm._v(_vm._s(_vm.$t("misc.back")))]
          ),
          _c(
            "v-btn",
            {
              staticClass:
                "confirm-cancel-modal__button vv-button vv-button-inverted btn-primary btn-confirm",
              nativeOn: {
                click: function($event) {
                  return _vm.$emit("confirm", _vm.reason)
                }
              }
            },
            [_vm._v(_vm._s("Confirmar ação" || _vm.$t("misc.confirm")))]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }