var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c("v-card-text", { staticClass: "select-group-modal__text" }, [
        _c(
          "div",
          { staticClass: "select-group-modal__search" },
          [
            _c("v-text-field", {
              attrs: { label: _vm.$t("misc.search") },
              model: {
                value: _vm.searchString,
                callback: function($$v) {
                  _vm.searchString = $$v
                },
                expression: "searchString"
              }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "select-group-modal__list" },
          _vm._l(_vm.filteredOptions, function(group, index) {
            return _c(
              "div",
              { key: index, staticClass: "select-group-modal__option" },
              [
                _c("label", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.selectedGroup,
                        expression: "selectedGroup"
                      }
                    ],
                    attrs: { type: "radio", name: "economic-group" },
                    domProps: {
                      value: group.id,
                      checked: _vm._q(_vm.selectedGroup, group.id)
                    },
                    on: {
                      change: function($event) {
                        _vm.selectedGroup = group.id
                      }
                    }
                  }),
                  _c("span", { staticClass: "radio--override" }),
                  _vm._v("\n          " + _vm._s(group.name) + "\n        ")
                ])
              ]
            )
          }),
          0
        )
      ]),
      _c(
        "v-card-actions",
        { staticClass: "select-group-modal__footer pt-4 pb-3" },
        [
          _c("v-btn", {
            staticClass: "select-group-modal__button btn-cancel",
            attrs: { color: "#35343d", flat: "" },
            domProps: { innerHTML: _vm._s(_vm.$t("misc.cancel")) },
            on: {
              click: function($event) {
                return _vm.$emit("cancel")
              }
            }
          }),
          _c("v-btn", {
            staticClass:
              "select-group-modal__button vv-button vv-button-inverted btn-primary btn-confirm",
            domProps: { innerHTML: _vm._s(_vm.$t("misc.select")) },
            on: { click: _vm.selectGroup }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }