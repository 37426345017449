import axios from 'axios'
import config from '@/app.settings'
import utils from '@/libs/utils'

export const state = {
  reasons: [],
}
function cancelReasonsDefaultAdapter(reasons) {
  reasons.forEach(reason => {
    utils.uncapitalizeAllKeys(reason)
  })
  return reasons
}

export const getters = {}

export const mutations = {
  SET_CANCELREASONS (state, payload) {
    state.reasons = payload
  },
}

export const actions = {
  async addReason ({commit}, payload) {
    utils.capitalizeAllKeys(payload)
    let response = await axios.post(config.apiUrl + '/cancelreasons/', payload)
    return response.data
  },
  async editReason ({commit}, payload) {
    utils.capitalizeAllKeys(payload)
    console.log(payload)
    let response = await axios.put(config.apiUrl + `/cancelreasons/${payload.Id}/active`, {Active: payload.Active})
    return response.data
  },
  async getCancelReasons ({commit}, payload) {
    let response = await axios.get(config.apiUrl + '/cancelreasons/search', {})
    return cancelReasonsDefaultAdapter(response.data)
  },
}