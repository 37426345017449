var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "fragment",
    [
      _c("v-toolbar-side-icon", {
        on: {
          click: function($event) {
            $event.stopPropagation()
            return _vm.$emit("click")
          }
        }
      }),
      _c("v-toolbar-title", [_vm._v(_vm._s(_vm.pageName))]),
      _c("v-spacer"),
      _c(
        "v-flex",
        { staticClass: "pr-2", attrs: { sm3: "", md2: "" } },
        [
          _c("v-select", {
            attrs: {
              items: _vm.optionsLanguage,
              "hide-details": "",
              "return-object": "",
              color: "rgba(0,0,0,.87)"
            },
            scopedSlots: _vm._u([
              {
                key: "selection",
                fn: function(data) {
                  return [
                    _vm._v("\n        " + _vm._s(data.item.name) + "\n      ")
                  ]
                }
              },
              {
                key: "item",
                fn: function(data) {
                  return [
                    _vm._v("\n        " + _vm._s(data.item.name) + "\n      ")
                  ]
                }
              }
            ]),
            model: {
              value: _vm.currentLanguage,
              callback: function($$v) {
                _vm.currentLanguage = $$v
              },
              expression: "currentLanguage"
            }
          })
        ],
        1
      ),
      _c("p", { staticClass: "text-xs-right greetings" }, [
        _c("span", [
          _vm._v(_vm._s(_vm.$t("header.greetings") + _vm.me.username) + "!")
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }