var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main-layout",
    [
      _c(
        "v-layout",
        { attrs: { "justify-center": "", row: "", wrap: "" } },
        [
          _c("form-alert", {
            attrs: {
              "alert-model": !!_vm.alertText,
              "alert-text": _vm.alertText
            },
            on: {
              input: function($event) {
                return _vm.closeFormAlert()
              }
            }
          }),
          _c(
            "v-flex",
            { attrs: { xs8: "" } },
            [
              _c("base-search-input", {
                attrs: { placeholder: _vm.$t("dealerships.searchDealership") },
                model: {
                  value: _vm.searchFilter.generalSearch,
                  callback: function($$v) {
                    _vm.$set(_vm.searchFilter, "generalSearch", $$v)
                  },
                  expression: "searchFilter.generalSearch"
                }
              })
            ],
            1
          ),
          _c(
            "v-flex",
            { attrs: { xs4: "" } },
            [
              _c("base-adicionar-button", {
                attrs: {
                  to: { path: "concessionarias/novo" },
                  text: _vm.$t("dealerships.addNew")
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm.filteredItems.length
        ? _c("v-data-table", {
            staticClass: "elevation-1 list__table",
            attrs: {
              headers: _vm.headers,
              items: _vm.filteredItems,
              "no-data-text": "Sem dados disponíveis",
              "hide-actions": ""
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "items",
                  fn: function(props) {
                    return [
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: {
                              path: "/concessionarias/editar/" + props.item.id
                            },
                            tag: "tr"
                          }
                        },
                        [
                          _c("td", {
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.economicGroups.length
                                  ? _vm.economicGroups.find(function(item) {
                                      return (
                                        item.id == props.item.economicGroupId
                                      )
                                    }).name
                                  : ""
                              )
                            }
                          }),
                          _c("td", {
                            domProps: { innerHTML: _vm._s(props.item.name) }
                          }),
                          _c("td", {
                            domProps: { innerHTML: _vm._s(props.item.address) }
                          })
                        ]
                      )
                    ]
                  }
                }
              ],
              null,
              false,
              2617660152
            )
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }