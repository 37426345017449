<template>
  <v-flex xs12>
    <v-alert
    :color="alertText.type"
    outline
    icon="check_circle"
    dismissible
    v-model="alertModel"
    @input="$emit('input')"
    transition="scale-transition">
    {{ alertText.message }}
  </v-alert>
</v-flex>
</template>

<script>
export default {

  name: 'FormAlert',
  props: ['alertModel', 'alertText'],
  data () {
    return {

    }
  }
}
</script>

<style lang="css" scoped>
</style>