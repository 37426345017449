import axios from 'axios'
import utils from '@/libs/utils'
import config from '@/app.settings'
import _ from 'lodash'
const PAUSED_STATUS = 8
const RESUMED_STATUS = 9

function serviceDefaultAdapter (services) {
  services.forEach((service, index) => {
    utils.uncapitalizeAllKeys(service)
    service.departureDate = utils.fixDateWithoutGMT(service.departureDate)
    service.startDate = utils.fixDateWithoutGMT(service.startDate)
    service.endDate = utils.fixDateWithoutGMT(service.endDate)
    service.gpsDistance = (service.hasOwnProperty('gpsDistance')) ? service.gpsDistance : null
    if (service.distance) {
      service.distance = service.distance.trim()
    }
    if (service.gpsDistance) {
      service.gpsDistance = service.gpsDistance.trim()
    }
    service.unread = (service.hasOwnProperty('unread')) ? service.unread : null
  })
  return services
}

function serviceNotificationsDefaultAdapter (serviceNotifications) {
  serviceNotifications.forEach((serviceNotification, index) => {
    utils.uncapitalizeAllKeys(serviceNotification)
    serviceNotification.date = utils.fixDateWithoutGMT(serviceNotification.date)
  })

  serviceNotifications = serviceNotifications.filter(removeDeletedServiceNotificationsAdapter)
  return serviceNotifications
}

function serviceRemoveCancelledAdapter (services) {
  return services.filter(item => item.status != 10)
}

function serviceTrackingDefaultAdapter (serviceTrackings) {
  serviceTrackings.forEach((serviceTracking, index) => {
    utils.uncapitalizeAllKeys(serviceTracking)
    serviceTracking.time = utils.fixDateWithoutGMT(serviceTracking.time)
  })
  return serviceTrackings
}
function removeDeletedServiceNotificationsAdapter(item) {
  return !item.hasOwnProperty('deleted') || item.deleted == false
}

export const state = {
  services: [],
  totalServices: 0,
  unreadServices: 0,
  trackings: [],
}
export const getters = {}


export const mutations = {
  SET_SERVICES (state, payload) {
    state.services = payload
  },

  SET_TOTAL_SERVICES(state, payload) {
    state.totalServices = payload
  },

  SET_TRACKINGS (state, payload) {
    state.trackings = payload
  },

  SET_UNREAD_SERVICES (state, payload) {
    state.unreadServices = payload
  },

  REDUCE_SERVICE_COUNTER (state, payload) {
    state.unreadServices = --state.unreadServices
  },

  SET_SERVICES_NOTIFICATIONS (state, payload) {
    state.serviceNotifications = payload
  },
}

export const actions = {
  loadServices ({ commit }, { payload, queryParams }) {
    return axios.post(`${config.newApiUrl}/services/search`, payload, { params: queryParams })
    .then(response => {
      let data = response.data.Items;
      data = serviceDefaultAdapter(data || []) // quando nao tem resultado, nao vem Items no request
      commit('SET_SERVICES', data)
      commit('SET_TOTAL_SERVICES', response.data.TotalCount)
      return data
    })
    .catch(error => {
      console.log(error)
    })
  },

  async cancelService ({commit}, payload) {
    utils.capitalizeAllKeys(payload)
    let serviceId = payload.ServiceId
    delete payload.ServiceId
    let response = await axios.put(config.apiUrl + `/services/${serviceId}/cancel`, 
      payload
    )
    return response.data
  },
  async changeStatus ({commit}, payload) {
    utils.capitalizeAllKeys(payload)
    let serviceId = payload.ServiceId
    let { Status, ServiceId: Id } = payload
    let response = await axios.put(config.apiUrl + `/services/${serviceId}/status`, {
      Status, Id
    })
    return response.data
  },

  async checkForUnreadServices ({commit}, payload) {
    let response = await axios.get(config.apiUrl + `/services/unreads`)
    commit('SET_UNREAD_SERVICES', response.data.Quantity)
    return response.data
  },

  async readService ({state, commit}, payload) {
    let response = await axios.put(config.apiUrl + `/services/${payload.id}/reset`)
    commit('SET_UNREAD_SERVICES', response.data.Quantity)
    return response.data
  },
  checkNewServices ({commit}, onlyStatusChanges = false) {
    return axios.get(`${config.apiUrl}/services/kanban`)
    .then(response => {
      let data = response.data
      data = serviceDefaultAdapter(data)
      const oldServices = state.services
      const hasServiceChanged = (oldService, newService, onlyStatus) => {
        return onlyStatus
          ? oldService.status !== newService.status
          : !_.isEqual(oldService, newService); 
      };
      const newAndChangedServices = data.filter(newService => {
        const existingService = oldServices.find(oldService => newService.id === oldService.id)
        return !existingService || hasServiceChanged(existingService, newService, onlyStatusChanges)
      });
      commit('SET_SERVICES', data)
      return newAndChangedServices
    })
    .catch(error => {
      console.log(error)
    })
  },
  loadService ({commit}, payload) {
    return axios.post(`${config.apiUrl}/services/search`, payload)
    .then(response => {
      let data = response.data
      data = serviceDefaultAdapter(data)
      return data[0]
    })
    .catch(error => {
      console.log(error)
    })
  },

  loadServicesNotifications ({commit}, payload) {
    return axios.post(config.apiUrl + '/servicesnotifications/search', payload)
    .then(response => {
      //console.log(response.data)
      let data = serviceNotificationsDefaultAdapter(response.data)
      maybeGenerateFakeNewStatusTracking(data)

      commit('SET_SERVICES_NOTIFICATIONS', data)
      return data
    })
    .catch(error => {
      console.log(error)
    })
  },

  loadServiceNotifications ({commit}, payload) {
    return axios.post(config.newApiUrl + '/servicesnotifications/search', {
      ServiceIds: [payload.id]
    })

    .then(response => {
      let data = serviceNotificationsDefaultAdapter(response.data)

      return data
    })
    .catch(error => {
      console.log(error)
    })
  },
  loadServiceListNotifications ({commit}, payload) {
    delete payload.disableLoadingIndicator
    return axios.post(config.apiUrl + '/servicesnotifications/search/', {
      ServiceIds: payload.serviceIds
    })
    .then(response => {
      let data = response.data
      data = serviceNotificationsDefaultAdapter(response.data)
      maybeGenerateFakeNewStatusTracking(data)
      return data
    })
    .catch(error => {
      console.log(error)
    })
  },
  loadTrackings ({commit}, payload) {
    return axios.post(config.apiUrl + '/servicestrackings/search', payload)
    .then(response => {
      let data = response.data
      data = serviceTrackingDefaultAdapter(data)
      commit('SET_TRACKINGS', data)
      return data
    })
    .catch(error => {
      console.log(error)
    })
  },
  loadTracking ({commit}, payload) {

    return axios.post(config.apiUrl + '/servicestrackings/search', {
      ServiceId: payload.id
    })
    .then(response => {
      // commit('SET_TRACKING', response.data)
      let data = response.data
      data = serviceTrackingDefaultAdapter(data)


      return data
    })
    .catch(error => {
      console.log(error)
    })
  },
  getServicesKanban({ commit }, payload) {
    return axios.get(`${config.apiUrl}/services/kanban`, { params: payload })
      .then(response => {
        let data = serviceDefaultAdapter(response.data)
        commit('SET_SERVICES', data)
        return data
      })
      .catch(error => {
        console.log(error)
      })
  },
  async archiveService (context, payload) {
    let response = await axios.put(`${config.newApiUrl}/services/${payload}/archive`)
    return response.data
  },
}
function maybeGenerateFakeNewStatusTracking(data) {
  if (config.mockPausedStatusFlag) {

    let initiated = data.filter(item => item.serviceId == 3)
    let paused = Object.assign({}, initiated[0])
    paused.type = config.PAUSED_STATUS
    data.push(paused)

    let paused2 = Object.assign({}, initiated[0])
    paused2.type = config.PAUSED_STATUS
    data.push(paused2)

    let resumed = Object.assign({}, initiated[0])
    resumed.type = config.RESUMED_STATUS
    data.push(resumed)
  }

}

