<template>
  <div class="input-wrapper search">
   <input
      type='text'
      class='vv-input search-icon'
      v-bind="
        $attrs
      "
      @input="$emit('update', $event.target.value)"
       v-on="
        $listeners
      "
   ></input>
 </div>
</template>

<script>
export default {

  name: 'BaseSearchInput',
  data () {
    return {

    }
  },
  model: {
    event: 'update',
  },
  inheritAttrs: false,
}
</script>

<style lang="css" scoped>
</style>