<template>
  <button v-on="$listeners" class="btn-expandir" tag='button'>
    <svg viewBox="0 0 24 24">
          <path fill="#0f3299" d="M20,10V14H11L14.5,17.5L12.08,19.92L4.16,12L12.08,4.08L14.5,6.5L11,10H20Z" />
        </svg><span>{{$t('misc.back')}}</span>
  </button >
</template>

<script>
export default {

  name: 'KanbanVoltarButton',

  data () {
    return {

    }
  }
}
</script>

<style lang="css" scoped>
</style>