var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main-layout",
    { staticClass: "listar" },
    [
      _vm.loaded
        ? _c(
            "v-layout",
            { attrs: { "justify-center": "", row: "", wrap: "" } },
            [
              _c("form-alert", {
                attrs: {
                  "alert-model": !!_vm.alertText,
                  "alert-text": _vm.alertText
                },
                on: {
                  input: function($event) {
                    return _vm.closeFormAlert()
                  }
                }
              }),
              _c(
                "v-flex",
                { attrs: { xs8: "" } },
                [
                  _c("base-search-input", {
                    attrs: { placeholder: _vm.$t("employees.searchEmployee") },
                    model: {
                      value: _vm.searchFilter.generalSearch,
                      callback: function($$v) {
                        _vm.$set(_vm.searchFilter, "generalSearch", $$v)
                      },
                      expression: "searchFilter.generalSearch"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-flex",
                { attrs: { xs4: "" } },
                [
                  _c("base-adicionar-button", {
                    attrs: {
                      text: _vm.$t("employees.addNew"),
                      to: { path: "funcionarios/novo" }
                    }
                  })
                ],
                1
              ),
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _vm.headers.length && _vm.loaded
                    ? _c("v-data-table", {
                        staticClass: "elevation-1 list__table",
                        attrs: {
                          headers: _vm.headers,
                          items: _vm.filteredItems,
                          "pagination.sync": "pagination",
                          "rows-per-page-items": [
                            50,
                            100,
                            { text: "Todas", value: -1 }
                          ],
                          "no-data-text": "Sem dados disponíveis"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "items",
                              fn: function(props) {
                                return [
                                  _c(
                                    "router-link",
                                    {
                                      attrs: {
                                        to: {
                                          path:
                                            "/funcionarios/editar/" +
                                            props.item.id
                                        },
                                        tag: "tr"
                                      }
                                    },
                                    [
                                      _c("td", {
                                        domProps: {
                                          innerHTML: _vm._s(
                                            props.item.user.name
                                          )
                                        }
                                      }),
                                      _c("td", {
                                        domProps: {
                                          innerHTML: _vm._s(
                                            props.item.user.volvoId
                                          )
                                        }
                                      }),
                                      _c("td", {
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.getDealershipOrGroupName(
                                              props.item
                                            )
                                          )
                                        }
                                      }),
                                      _c("td", {
                                        domProps: {
                                          innerHTML: _vm._s(
                                            props.item.user.levelLabel
                                          )
                                        }
                                      }),
                                      _c("td", {
                                        domProps: {
                                          innerHTML: _vm._s(
                                            props.item.user.deleted
                                              ? "Bloqueado"
                                              : "Ativo"
                                          )
                                        }
                                      })
                                    ]
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          614811824
                        )
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }