<template>
  <div>
    <div class="status__header">
      <img :src="imageCountry" alt="Volvo VOAR" height="28" width="60" />
    </div>
    <div class="status__wrapper pa-4" v-if="serviceStatus" :class="{ transparent: avisoLgpdDialog }">
      <div class="status__timeline">
        <h1>
          {{ $t('pageFollowService.greetings') }} {{ serviceStatus.customer.name }}! {{ $t('pageFollowService.followService') }}
          <span class="emoji">&#128071;</span>
        </h1>
        <div class="status__steps mt-3 mb-2">
          <div class="step" v-for="(step, index) in statusStepsToShow" :key="index">
            <div class="step-point mr-3" :class="{ current: step.active }"></div>
            <div class="step-text mr-3" :class="{ current: step.active }">
              {{ step.statusText }}
              <v-chip v-if="showPauseChip(step)" disabled small label color="red" text-color="white">{{ $t('footerStatus.paused') }}</v-chip>
            </div>
            <div class="step-date">{{ step.date }}</div>
          </div>
        </div>
      </div>
      <div class="status__divider"></div>
      <div class="status__wrapper--right">
        <div class="status__text">
          {{ $t('pageFollowService.numberFollow') }}
          <br />
          <span class="emoji">&#9999;&#65039; </span> <span class="highlight">{{ serviceStatus.mercuriusCase }}</span
          ><br />
          <br />
          <div v-if="!isTowed">
            {{ $t('pageFollowService.employeeService') }}
            <span class="highlight">{{ serviceStatus.technicalName }}</span> {{ $t('pageFollowService.dealership') }}
            <span class="highlight">{{ serviceStatus.dealership.name }}</span> {{ $t('pageFollowService.distance') }}
            <span class="highlight">{{ serviceStatus.distance }}km</span> {{ $t('pageFollowService.localization') }}
            <span v-if="serviceStatus.technicalContact">
              {{ $t('pageFollowService.contact') }}
              <span class="emoji">&#128242; </span> <span class="highlight">{{ serviceStatus.technicalContact }}</span
              >{{ $t('pageFollowService.voarNumber') }}:
              <div>
                <br />
                <b> {{ $t('misc.countryBR') }} </b>
                <div>
                  VOAR Caminhões: <span class="highlight">0800 041 61 61</span>
                </div>
                <div>
                  VOAR Ônibus: <span class="highlight">0800 647 4747</span>
                </div>
              </div>
              <br />
              <div>
                <b> {{ $t('misc.countryAR') }} </b>
                <div>
                  VAS: <span class="highlight">0800 666 4639</span>
                </div>
              </div>
              <br />
              <div>
                <b> {{ $t('misc.countryCL') }} </b>
                <div>
                  VAS: <span class="highlight">800 646 815</span>
                </div>
              </div>
              <br />
              <div>
                <b> {{ $t('misc.countryPE') }} </b>
                <div>
                  VOLAR: <span class="highlight">0800 533 86</span>
                </div>
              </div>
              <br />
            </span>
            <span v-else>
              {{ $t('pageFollowService.voar') }}:
              <div>
                <br />
                <b> {{ $t('misc.countryBR') }} </b>
                <div>
                  VOAR Caminhões: <span class="highlight">0800 041 61 61</span>
                </div>
                <div>
                  VOAR Ônibus: <span class="highlight">0800 647 4747</span>
                </div>
              </div>
              <br />
              <div>
                <b> {{ $t('misc.countryAR') }} </b>
                <div>
                  VAS: <span class="highlight">0800 666 4639</span>
                </div>
              </div>
              <br />
              <div>
                <b> {{ $t('misc.countryCL') }} </b>
                <div>
                  VAS: <span class="highlight">800 646 815</span>
                </div>
              </div>
              <br />
              <div>
                <b> {{ $t('misc.countryPE') }} </b>
                <div>
                  VOLAR: <span class="highlight">0800 533 86</span>
                </div>
              </div>
              <br />
            </span>
          </div>
          <div v-else>
            {{ $t('pageFollowService.towCar') }}:
            <div>
              <br />
                <b> {{ $t('misc.countryBR') }} </b>
                <div>
                  VOAR Caminhões: <span class="highlight">0800 041 61 61</span>
                </div>
                <div>
                  VOAR Ônibus: <span class="highlight">0800 647 4747</span>
                </div>
              </div>
              <br />
              <div>
                <b> {{ $t('misc.countryAR') }} </b>
                <div>
                  VAS: <span class="highlight">0800 666 4639</span>
                </div>
              </div>
              <br />
              <div>
                <b> {{ $t('misc.countryCL') }} </b>
                <div>
                  VAS: <span class="highlight">800 646 815</span>
                </div>
              </div>
              <br />
              <div>
                <b> {{ $t('misc.countryPE') }} </b>
                <div>
                  VOLAR: <span class="highlight">0800 533 86</span>
                </div>
              </div>
              <br />
          </div>
        </div>
        <div class="status__vehicle mt-4">
          <v-layout row wrap class="px-3">
            <v-flex xs12>
              <v-text-field
                :label="$t('services.timeArrive')"
                :value="valueOrBlank(serviceStatus.estimatedTimeArrival)"
                readonly
              ></v-text-field>
            </v-flex>
            <v-flex xs12>
              <v-text-field
                :label="$t('services.vehicle')"
                :value="valueOrBlank(serviceStatus.customer.vehicle.model)"
                readonly
              ></v-text-field>
            </v-flex>
            <v-flex xs6>
              <v-text-field
                :label="$t('services.plate')"
                :value="valueOrBlank(serviceStatus.customer.vehicle.plate)"
                readonly
                class="mr-2"
              ></v-text-field>
            </v-flex>
            <v-flex xs6>
              <v-text-field
                :label="$tc('services.chassi', 2)"
                :value="valueOrBlank(serviceStatus.customer.vehicle.chassisNumber)"
                readonly
              ></v-text-field>
            </v-flex>
          </v-layout>
        </div>
      </div>
    </div>
    <div class="status__wrapper pa-4" v-if="!serviceStatus && !busy">
      <div class="status__text no-info">
        {{ $t('pageFollowService.withoutService') }}<br />
        {{ $t('pageFollowService.voar') }}:
        <div>
          <br />
          <b> {{ $t('misc.countryBR') }} </b>
          <div>
            VOAR Caminhões: <span class="highlight">0800 041 61 61</span>
          </div>
          <div>
            VOAR Ônibus: <span class="highlight">0800 647 4747</span>
          </div>
        </div>
        <br />
        <div>
          <b> {{ $t('misc.countryAR') }} </b>
          <div>
            VAS: <span class="highlight">0800 666 4639</span>
          </div>
        </div>
        <br />
        <div>
          <b> {{ $t('misc.countryCL') }} </b>
          <div>
            VAS: <span class="highlight">800 646 815</span>
          </div>
        </div>
        <br />
        <div>
          <b> {{ $t('misc.countryPE') }} </b>
          <div>
            VOLAR: <span class="highlight">0800 533 86</span>
          </div>
        </div>
        <br />
      </div>
    </div>
    <v-dialog v-model="avisoLgpdDialog" max-width="450">
      <AvisoLgpdModal @confirm="avisoLgpdDialog = false" @policy="goToPolicy()"> </AvisoLgpdModal>
    </v-dialog>
  </div>
</template>

<script>
import AvisoLgpdModal from '@/components/AvisoLgpdModal';
import { mapActions } from 'vuex';
import utils from '@/libs/utils';
import { format, parseISO } from 'date-fns';
import config from '@/app.settings';

export default {
  name: 'Status',
  components: { AvisoLgpdModal },
  data: () => ({
    avisoLgpdDialog: true,
    statusSteps: [],
    serviceStatus: null,
    utils,
    config,
    busy: false,
    languageItems: [
      {
        id: 1,
        lang: 'pt'
      },
      {
        id: 2,
        lang: 'es'
      }
    ]
  }),
  computed: {
    token() {
      return this.$route.params.token;
    },
    isTowed() {
      return this.serviceStatus && this.serviceStatus.isTowed;
    },
    isCanceled() {
      return this.serviceStatus && this.serviceStatus.cancelDate;
    },
    statusStepsToShow() {
      return this.statusSteps.filter((step) => step.show);
    },
    imageCountry() {
      const country = this.serviceStatus ? this.serviceStatus.dealership.country : "brazil";
      return this.utils.getImageAccordingCountry({ name: country, type: "status" });
    },
  },
  mounted() {
    this.updateLangague();
    this.fetchData();
  },
  methods: {
    ...mapActions('serviceStatus', ['getServiceStatus']),
    async fetchData() {
      this.busy = true;
      this.utils.showLoadingIndicator();
      try {
        const payload = {
          token: this.token,
          languageId: this.languageItems.filter(x => x.lang === this.$root.$i18n.locale).id
        }
        this.serviceStatus = await this.getServiceStatus(payload);
        if (!this.serviceStatus) throw this.$t('pageFollowService.withoutService');
        this.fillStatusSteps();
        const currentStep = this.statusSteps.find((step) => {
          if ([config.PAUSED_STATUS, config.RESUMED_STATUS].includes(this.serviceStatus.status)) {
            return step.status === config.REPAIRING_STATUS;
          } else return step.status === this.serviceStatus.status;
        });
        if (currentStep) {
          currentStep.active = true;
        }
        this.fillDates();
      } catch (error) {
        console.warn(error);
      }
      this.busy = false;
      this.utils.hideLoadingIndicator();
    },
    goToPolicy() {
      window.open('https://www.volvogroup.com/en/tools/privacy/privacy-pt.html', '_blank');
    },
    fillDates() {
      this.serviceStatus.reportDate = utils.fixDateWithoutGMT(this.serviceStatus.reportDate);
      this.statusSteps.forEach((step) => {
        const key = this.getStatusDateKey(step.status);
        if (this.serviceStatus[key]) {
          step.date = format(parseISO(this.serviceStatus[key]), 'HH:mm - dd/MM/y');
        }
      });
    },
    fillStatusSteps() {
      this.statusSteps = [
        {
          status: 0,
          statusText: this.$t('pageFollowService.serviceOpen'),
          date: '',
          active: false,
          show: true,
        },
        {
          status: config.SENT_STAUTS,
          statusText: this.$t('pageFollowService.preparing'),
          date: '',
          active: false,
          show: true,
        },
        {
          status: config.ONCOURSE_STATUS,
          statusText: this.$t('pageFollowService.employee'),
          date: '',
          active: false,
          show: !this.isTowed,
        },
        {
          status: config.CANCELED_STATUS,
          statusText: this.$t('services.towedCar'),
          date: '',
          active: false,
          show: this.isTowed,
        },
        {
          status: config.REPAIRING_STATUS,
          statusText: this.$t('footerStatus.repairing'),
          date: '',
          active: false,
          show: !this.isTowed,
        },
        {
          status: config.FINISHED_STATUS,
          statusText: this.$t('pageFollowService.finishService'),
          date: '',
          active: false,
          show: !this.isTowed && !this.isCanceled,
        },
        {
          status: config.CANCELED_STATUS,
          statusText: this.$t('pageFollowService.cancelService'),
          date: '',
          active: false,
          show: !this.isTowed && this.isCanceled,
        },
      ];
    },
    getStatusDateKey(status) {
      const dateKeys = {
        0: 'reportDate',
        [config.SENT_STAUTS]: 'reportDate',
        [config.ONCOURSE_STATUS]: 'departureDate',
        [config.REPAIRING_STATUS]: 'startDate',
        [config.FINISHED_STATUS]: 'endDate',
        [config.CANCELED_STATUS]: 'cancelDate',
      };
      return dateKeys[status];
    },
    showPauseChip(step) {
      return step.status === config.REPAIRING_STATUS && this.serviceStatus.status === config.PAUSED_STATUS;
    },
    valueOrBlank(value) {
      return value || ' ';
    },
    updateLangague() {
      var language = navigator.languages && navigator.languages[0] ||
        navigator.language ||
        navigator.userLanguage;
      language = (!['pt', 'es'].includes(language.split('-')[0])) ? 'pt' : language.split('-')[0];
      this.$root.$i18n.locale = language;
      this.$vuetify.lang.current = language;

    }
  },
};
</script>

<style lang="scss">
.status {
  &__header {
    height: 68px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #1e3673;
  }
  &__wrapper {
    max-width: 768px;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media screen and (min-width: 768px) {
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
    }
    &.transparent {
      opacity: 0.25;
    }
  }
  &__timeline {
    h1 {
      font-size: 20px;
    }
    max-width: 330px;
    .v-chip {
      padding: 0 !important;
      height: 18px !important;
      .v-chip__content {
        padding: 0 5px !important;
        height: auto !important;
      }
    }
  }
  &__steps {
    display: flex;
    flex-direction: column;
    .step {
      display: flex;
      align-items: center;
      height: 48px;
      &:last-child {
        .step-point::before {
          display: none;
        }
      }
      .step-point {
        position: relative;
        height: 12px;
        width: 12px;
        min-width: 12px;
        border-radius: 50%;
        background-color: #a9a9a9;
        z-index: 2;
        &.current {
          background-color: #286aa6;
        }
        &::before {
          content: '';
          position: absolute;
          width: 1px;
          height: 15px;
          border-right: 1px solid #a9a9a9;
          left: 47%;
          top: 12px;
          height: 38px;
          z-index: 1;
        }
      }
      .step-text {
        font-size: 16px;
        width: 175px;
        &.current {
          color: #286aa6;
          font-weight: bold;
        }
      }
      .step-date {
        color: #a9a9a9;
        font-size: 12px;
      }
    }
  }
  &__vehicle {
    max-width: 330px;
    .v-input__slot::before {
      border-image: none !important;
    }
    .v-label {
      color: #2a343b !important;
    }
  }
  &__text {
    font-size: 16px;
    color: #2a343b;
    max-width: 330px;
    margin-top: 10px;
    @media screen and (min-width: 768px) {
      margin-top: 0;
    }
    .highlight {
      color: #286aa6;
      font-weight: bold;
    }
    .emoji {
      font-size: 18px;
    }
    &.no-info {
      max-width: 100%;
    }
  }
  &__divider {
    display: none;
    @media screen and (min-width: 768px) {
      display: block;
      height: 470px;
      width: 1px;
      border-right: 1px solid rgba(0, 0, 0, 0.12);
    }
  }
}
</style>
