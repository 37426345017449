<template>

</template>

<style lang="scss" scoped>
</style>

<script>

export default {
  name: 'Home',
  components: {

  },
  data: () => ({
    model: {},
    dialog: false
  }),
  methods: {
    auth(){
      let t = this
      t.$router.push('/dashboard')
    },
    parseJwt (token) {
      var base64Url = token.split('.')[1];
      var base64 = base64Url.replace('-', '+').replace('_', '/');
      return JSON.parse(window.atob(base64));
    }
  },
  mounted () {
    let t = this
    if (localStorage.accessTokenObj) {
      t.$router.push('/dashboard')
    }
    else{
      window.location.replace("https://federate.volvo.com/as/authorization.oauth2?client_id=VOARWeb&response_type=token&redirect_uri=https://voar.volvo.com");
    }

  }
}
</script>