<template>
  <v-card>
    <v-card-title class="headline" > {{ $t('services.whichStatus') }}</v-card-title>
    <v-card-text>
      <button
        v-for="(option, index) in possibleStatus "
        :key="index"
        class="btn-primary vv-button vv-button-inverted mb-2 vv-adicionar-novo-button"
        @click="$emit('change-status', {status: option.status})"
        v-html="option.name">
      </button>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  name: 'AlterarStatusModal',
  data () {
    return {
    }
  },
  props: {
    status: {
      default: null
    }
  },
  computed: {
    possibleStatus () {
      if(!this.status && !this.mergedNonReactiveData)
        return []
      if(this.finishedStatus.includes(this.status)) {
        return this.possibleStatusForFinishedStatus
      }
      if(this.ongoingStatus.includes(this.status)) {
        return this.possibleStatusForOngoingStatus
      }
      if(this.repairingStatus.includes(this.status)) {
        return this.possibleStatusForRepairingStatus
      }
    },
    nonReactiveData() {
      return {
        possibleStatusForFinishedStatus: [
          { status: 'ServiceReport', name: this.$t('footerStatus.report') },
          { status: 'ServiceDeparture', name: this.$t('trackings.departure') },
          { status: 'ServicePaused', name: this.$t('trackings.stopRepair') }
        ],
        possibleStatusForRepairingStatus: [
          { status: 'ServiceReport', name: this.$t('footerStatus.report') },
          { status: 'ServiceDeparture', name: this.$t('trackings.departure') },
        ],
        possibleStatusForOngoingStatus: [
          { status: 'ServiceReport', name: this.$t('footerStatus.report') },
        ],
        finishedStatus: [4],
        repairingStatus: [3, 8, 9],
        ongoingStatus: [2, 5],
      }
    }
  },
  mounted () {
    Object.assign(this, this.nonReactiveData, {mergedNonReactiveData: true})
  }
}
</script>
