var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main-layout",
    [
      _c(
        "v-layout",
        {
          staticClass: "vv-form",
          attrs: { "justify-center": "", row: "", wrap: "" }
        },
        [
          _c("form-alert", {
            attrs: {
              "alert-model": !!_vm.alertText,
              "alert-text": _vm.alertText
            },
            on: {
              input: function($event) {
                return _vm.closeFormAlert()
              }
            }
          }),
          _c(
            "v-flex",
            { attrs: { xs12: "" } },
            [
              _c("div", { staticClass: "vv-form-header" }, [
                _c(
                  "div",
                  { staticClass: "vv-voltar-button" },
                  [
                    _c(
                      "router-link",
                      { attrs: { to: { path: "/motivos-cancelamento" } } },
                      [
                        _c("svg", { attrs: { viewBox: "0 0 24 24" } }, [
                          _vm._v("0\n                "),
                          _c("path", {
                            attrs: {
                              fill: "#0f3299",
                              d:
                                "M20,10V14H11L14.5,17.5L12.08,19.92L4.16,12L12.08,4.08L14.5,6.5L11,10H20Z"
                            }
                          })
                        ]),
                        _vm._v(_vm._s(_vm.$t("misc.back")) + "\n            ")
                      ]
                    )
                  ],
                  1
                )
              ]),
              _c(
                "v-layout",
                {
                  staticClass: "white-background",
                  attrs: { row: "", wrap: "" }
                },
                [
                  _c("span", { staticClass: "span-reason" }, [
                    _vm._v(
                      _vm._s(_vm.$tc("reasonsCancellations.addDescription", 0))
                    )
                  ]),
                  _c(
                    "v-form",
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs6: "" } },
                        [
                          _c("v-text-field", {
                            ref: "inputName",
                            attrs: {
                              label: _vm.$tc(
                                "Motivo de Cancelamento (Português)"
                              ),
                              required: "",
                              rules: [
                                function(value) {
                                  return !!value || _vm.$t("misc.validateInput")
                                }
                              ]
                            },
                            model: {
                              value: _vm.model.descriptionPortugueseBr,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.model,
                                  "descriptionPortugueseBr",
                                  $$v
                                )
                              },
                              expression: "model.descriptionPortugueseBr"
                            }
                          }),
                          _c("v-text-field", {
                            ref: "inputName",
                            attrs: {
                              label: _vm.$tc("Motivo Cancelacione (Espanhol)"),
                              required: "",
                              rules: [
                                function(value) {
                                  return !!value || _vm.$t("misc.validateInput")
                                }
                              ]
                            },
                            model: {
                              value: _vm.model.descriptionSpanishEs,
                              callback: function($$v) {
                                _vm.$set(_vm.model, "descriptionSpanishEs", $$v)
                              },
                              expression: "model.descriptionSpanishEs"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-layout",
        { staticClass: "vv-form-button", attrs: { row: "", wrap: "" } },
        [
          _c("v-spacer"),
          _c("v-flex", { attrs: { xs4: "" } }, [
            _c(
              "button",
              {
                ref: "buttonSave",
                staticClass:
                  "btn-primary vv-button vv-button-inverted vv-adicionar-novo-button",
                on: {
                  click: function($event) {
                    return _vm.save()
                  }
                }
              },
              [
                _c("span", [
                  _vm._v(
                    _vm._s(
                      !_vm.$route.params.id
                        ? _vm.$t("misc.add")
                        : _vm.$t("misc.edit")
                    ) +
                      " " +
                      _vm._s(
                        _vm.$tc("reasonsCancellations.reasonCancellation", 0)
                      )
                  )
                ])
              ]
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }