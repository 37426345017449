var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "router-link",
    _vm._b(
      { staticClass: "btn-primary vv-button vv-adicionar-novo-button" },
      "router-link",
      _vm.$attrs,
      false
    ),
    [
      _c(
        "svg",
        {
          staticClass: "vv-adicionar-novo-plus",
          staticStyle: { width: "22px", height: "22px" },
          attrs: { viewBox: "0 0 24 24" }
        },
        [
          _c("path", {
            attrs: {
              fill: "#0f3299",
              d:
                "M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M13,7H11V11H7V13H11V17H13V13H17V11H13V7Z"
            }
          })
        ]
      ),
      _c("span", { domProps: { innerHTML: _vm._s(_vm.text) } })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }