<template>
  <main-layout>
    <v-layout justify-center row wrap class='vv-form'>
      <form-alert
      :alert-model="!!alertText"
      :alert-text="alertText"
      @input="closeFormAlert()"/>

      <v-flex xs12>
        <div class="vv-form-header">
          <div class="vv-voltar-button">
            <router-link :to="{ path:  '/grupos-economicos'}">
              <svg viewBox="0 0 24 24">0
                <path fill="#0f3299" d="M20,10V14H11L14.5,17.5L12.08,19.92L4.16,12L12.08,4.08L14.5,6.5L11,10H20Z" />
              </svg>{{ $t('misc.back') }}
            </router-link>
          </div>
        </div>
        <v-layout row wrap class='white-background'>
          <v-form >
            <v-flex xs6>
             <v-text-field
             :label="$tc('economicGroups.economicGroup', 1)"
             required
             ref='inputName'
             v-model="model.name"
             :rules="[(value) => !!value || $t('misc.validateInput')]"
             ></v-text-field>
           </v-flex>
         </v-form>
       </v-layout>
     </v-flex>
   </v-layout>
   <v-layout row wrap class='vv-form-button'>
    <v-spacer></v-spacer>
    <v-flex xs4 v-if="$route.params.id">
      <button @click.stop="dialog = true" class='btn-primary vv-button vv-adicionar-novo-button'>
        <span>{{$t('misc.delete')}}</span>
      </button>
    </v-flex>
    <v-flex xs4>
      <button ref='buttonSave' @click="save()" class='btn-primary vv-button vv-button-inverted vv-adicionar-novo-button'>
        <span>{{ (!$route.params.id ) ? $t('misc.add') : $t('misc.edit')}} {{ $tc('economicGroups.economicGroup', 0) }}</span>
      </button>
    </v-flex>
  </v-layout>
    <v-dialog
    v-model="dialog"
    max-width="290">
    <base-modal
      :title="$t('misc.areYouSure')"
      @cancel="dialog = false"
      @confirm="dialog = false; remove()"
      >
    {{ $t('economicGroups.deleteGroup') }}
  </base-modal>
  </v-dialog>
</main-layout>

</template>

<style lang="scss" scoped>
</style>

<script>
  import { mapState, mapActions } from 'vuex'
  import ViewMixin from '@/mixins/ViewMixin'
  import utils from '@/libs/utils'
  export default {
    name: 'Home',
    components: {},
    mixins: [ViewMixin],
    data: () => ({
      model: {},
      alertText: false
    }),

    computed: {
      ...mapState('main', [
        'pageName'
        ])
    },
    methods: {
      ...mapActions('economicGroups', [
        'editEconomicGroup',
        'addEconomicGroup',
        'deleteEconomicGroup',
        'loadEconomicGroup',
        'loadEconomicGroupS',
        ]),

      save() {
        this.$refs.inputName.focus()
        this.$nextTick(() => {
          this.$refs.buttonSave.focus()
        })
        if (!this.model.name || !this.model.name.trim()) return
          this.$utils.showLoadingIndicator()
          if (this.$route.params.id) {
            let id = this.$route.params.id
            let obj = Object.assign({}, this.model)
            obj.id = id
            this.$utils.showLoadingIndicator()
            this.editEconomicGroup(obj)
            .then(response => this.successHandler(this.$tc('economicGroups.economicGroup', 1) + this.$t('misc.alertMessageEditMale'), response))
            .catch(this.errorHandler)


          } else {

            let obj = Object.assign({}, this.model)
            this.$utils.showLoadingIndicator()
            this.addEconomicGroup(this.model)
            .then(response => this.successHandler(this.$tc('economicGroups.economicGroup', 1) + this.$t('misc.alertMessageInsertMale'), response))
            .catch(this.errorHandler)
          }
        },
        async remove() {
          let response
          try {
            this.$utils.showLoadingIndicator()
            await this.deleteEconomicGroup({Id: this.$route.params.id})
            this.successHandler(this.$tc('economicGroups.economicGroup', 1) + this.$t('misc.alertMessageDeleteMale'), response)
          } catch(e) {
            this.errorHandler(e)
          }
        },

        errorHandler (error) {
          let t = this
          error = error.response
          this.$utils.hideLoadingIndicator()
          this.$store.commit('main/SET_ALERT_TEXT', {
            type: 'error', message: error.data.message
          })
          this.alertText = this.$utils.handleAlertText()
        },
        successHandler (message, response) {
          let t = this
          this.$store.commit('main/SET_ALERT_TEXT', {
            type: 'success', message
          })
          this.$router.push('/grupos-economicos')
          return
        },
    async init () {
      let response
      if (!this.$route.params.id) return
        this.$utils.showLoadingIndicator()
      try {
        this.model = await this.loadEconomicGroup({id: this.$route.params.id})
        this.model = this.model[0]
        this.$store.commit('main/SET_PAGENAME', this.pageName + ' - ' + this.model.name)
        document.title += " - " + this.model.name
      } catch(e) {
        console.warn(e)
      }
      this.$utils.hideLoadingIndicator()
    }
  },
  mounted () {
    this.init()
  }
}
</script>