import axios from 'axios'
import config from '@/app.settings'
import utils from '@/libs/utils'

export const state = {}
export const getters = {}
export const mutations = {}

export const actions = {
  async getDashboardStatus({ commit }, payload) {
    const { data } = await axios.get(`${config.apiUrl}/dashboards/status`, {
      params: payload
    });
    return defaultAdapter(data);
  },
  async getDashboardCanceled({ commit }, payload) {
    const { data } = await axios.get(`${config.apiUrl}/dashboards/canceled`, {
      params: payload
    });
    return defaultAdapter(data);
  },
  async getDashboardSheet({ commit }, payload) {
    const { data } = await axios.get(`${config.apiUrl}/dashboards/export`, {
      params: payload
    });
    return defaultAdapter(data);
  },
  async downloadDashboardSheet({ commit, dispatch }, payload) {
    const { fileDownloadName, fileContents, contentType } = await dispatch('getDashboardSheet', payload);
    const url = `data:${contentType};base64,${fileContents}`;
    const blob = await (await fetch(url)).blob();
    return { filename: fileDownloadName, blob };
  },
};

function defaultAdapter(data) {
  utils.uncapitalizeAllKeys(data);
  return data;
}
