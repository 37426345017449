var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "router-link",
    { attrs: { to: { path: "/atendimentos/mapa/" + _vm.id } } },
    [
      _c(
        "svg",
        {
          staticStyle: { width: "24px", height: "24px" },
          attrs: { viewBox: "0 0 24 24" }
        },
        [
          _c("path", {
            attrs: {
              fill: "#ababac",
              d:
                "M12,11.5A2.5,2.5 0 0,1 9.5,9A2.5,2.5 0 0,1 12,6.5A2.5,2.5 0 0,1 14.5,9A2.5,2.5 0 0,1 12,11.5M12,2A7,7 0 0,0 5,9C5,14.25 12,22 12,22C12,22 19,14.25 19,9A7,7 0 0,0 12,2Z"
            }
          })
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }