
import Vue from 'vue'

import routes from './routes'
import VueRouter from 'vue-router'
import store  from '../store'
import utils from '@/libs/utils'

Vue.use(VueRouter)
const router = new VueRouter({
  mode: 'hash',
  routes,
  // Simulate native-like scroll behavior when navigating to a new
  // route and using back/forward buttons.
  /* scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }, */

})

router.beforeEach((to, from, next) => {
  if (to.path.match(/acompanhar/)) {
    next()
    return
  }
  if (to.path.match(/access_token/) && to.path != '/atendimentos' && to.path != '/login') {
    // parseia tudo
    let params = to.path.substr(1).split('&')
    let accessTokenObj = {}
    params.forEach(item => {
      let splitted = item.split('=')
      accessTokenObj[splitted[0]] = splitted[1]
    })

    // guarda em JSON stringify tudo
    let parsedToken = utils.parseJwt(accessTokenObj.access_token)
    localStorage.setItem('accessTokenObj', JSON.stringify(accessTokenObj))
    let user = store.dispatch('users/loadUser', {
      volvoId: parsedToken.username,
    })

    store.commit('users/SET_ME', parsedToken)

    next({path: '/dashboard'})
    return
  }
  if (!localStorage.getItem('accessTokenObj') && to.path != '/login' ) {
    next({path: '/login'})
    return
  }
  if (to.path != '/login' && !localStorage.getItem('accessTokenObj') ) {
    store.commit('users/SET_ME', utils.parseJwt(JSON.parse(localStorage.accessTokenObj).access_token))
  }
  if (to.path == "/employee-logout") {
    next()
    return
  }
  if (to.path != '/login') {
    let parsedToken = utils.parseJwt(JSON.parse(localStorage.getItem('accessTokenObj')).access_token)
    store.dispatch('users/loadUser', {
      volvoId: parsedToken.username
    })
    .then(response => {
      store.commit('employees/SET_EMPLOYEE', response[0])
      if (response[0].user.level == 'DealerEmployee') {
        next({path: '/employee-logout'})
        return
      }
      next()
      return

    })
  }
  if (to.path == '/login' ) {
     next()
  }
  if (to.matched.length == 0) {
    next("/404");
  }
})


export default router
