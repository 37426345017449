var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c("v-card-title", { staticClass: "headline" }),
      _c(
        "v-card-text",
        { staticClass: "aviso-lgpd-modal__text" },
        [
          _c("v-icon", { attrs: { "x-large": "", color: "#286AA6" } }, [
            _vm._v("security")
          ]),
          _c("p", { staticClass: "description mt-4 mb-0" }, [
            _vm._v(_vm._s(_vm.$t("pageFollowService.textTerms")))
          ])
        ],
        1
      ),
      _c(
        "v-card-actions",
        { staticClass: "aviso-lgpd-modal__footer pb-3" },
        [
          _c(
            "v-btn",
            {
              staticClass:
                "aviso-lgpd-modal__button vv-button btn-primary btn-confirm mb-2",
              attrs: { flat: "" },
              nativeOn: {
                click: function($event) {
                  return _vm.$emit("policy")
                }
              }
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("pageFollowService.buttonTerms")) +
                  "\n    "
              )
            ]
          ),
          _c(
            "v-btn",
            {
              staticClass:
                "aviso-lgpd-modal__button vv-button vv-button-inverted btn-primary btn-confirm mb-2",
              nativeOn: {
                click: function($event) {
                  return _vm.$emit("confirm")
                }
              }
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("pageFollowService.buttonConfirm")) +
                  "\n    "
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }