<template>
  <div class="vv-loading-indicator" v-if="loadingIndicatorFlag">
    <v-progress-circular indeterminate v-bind:size="50" color="primary"></v-progress-circular>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
export default {

  name: 'TheLoadingIndicator',

  data () {
    return {

    }
  },
  computed: {
    ...mapState('main', [
      'loadingIndicatorFlag'
    ])
  }

}
</script>

<style lang="css" scoped>
</style>