import Login from '@/router/views/Login/Index'
import Logout from '@/router/views/Login/Logout'
import Mapa from '@/router/views/Mapa/Index'
import AtendimentosListar from '@/router/views/Atendimentos/Listar'
import AtendimentosMapa from '@/router/views/Atendimentos/Mapa'
import AtendimentosKanban from '@/router/views/Atendimentos/Kanban'
import AtendimentosKanbanExpandido from '@/router/views/Atendimentos/KanbanExpandido'
import AtendimentosVisualizar from '@/router/views/Atendimentos/Visualizar'
import ConcessionariasEditar from '@/router/views/Concessionarias/Editar'
import ConcessionariasListar from '@/router/views/Concessionarias/Listar'
import GruposEconomicosEditar from '@/router/views/GruposEconomicos/Editar'
import GruposEconomicosListar from '@/router/views/GruposEconomicos/Listar'
import FuncionariosEditar from '@/router/views/Funcionarios/Editar'
import FuncionariosListar from '@/router/views/Funcionarios/Listar'
import MotivosEditar from '@/router/views/MotivosCancelamento/Editar'
import MotivosListar from '@/router/views/MotivosCancelamento/Listar'
import EmployeeLogout from '@/router/views/Logout/EmployeeLogout'
import TestePing from '@/router/views/TestePing'
import Status from '@/router/views/Acompanhamento/Status'
import Dashboard from '@/router/views/Dashboard/Dashboard'
import PageNotFound from '@/router/views/PageNotFound'

export default [
  { path: '/', redirect: {name: 'Login'} },
  { path: '/login', name: 'Login',  component: Login  },
  { path: '/acompanhar/:token', name: 'Acompanhar Status', component: Status },
  { path: '/dashboard', name: 'Dashboard',  component: Dashboard  },
  { path: '/atendimentos', name: 'Atendimentos',  component: AtendimentosListar  },
  { path: '/atendimentos/kanban', name: 'Kanban', component: AtendimentosKanban },
  { path: '/atendimentos/kanban/expandido', name: 'KanbanExpandido', component: AtendimentosKanbanExpandido },
  { path: '/atendimentos/mapa/:id', name: 'Localização', component: AtendimentosMapa },
  { path: '/atendimentos/:id', name: 'Visualizar Atendimento',  component: AtendimentosVisualizar  },
  { path: '/mapa', name: 'Mapa', component: Mapa  },
  { path: '/concessionarias', name: 'Concessionárias', component: ConcessionariasListar },
  { path: '/concessionarias/novo', name: 'Adicionar Concessionária', component: ConcessionariasEditar },
  { path: '/concessionarias/editar/:id', name: 'Editar Concessionária',	component: ConcessionariasEditar },
  { path: '/grupos-economicos', name: 'Grupos Econômicos', component: GruposEconomicosListar },
  { path: '/grupos-economicos/novo', name: 'Adicionar Grupo Econômico', component: GruposEconomicosEditar },
  { path: '/grupos-economicos/editar/:id', name: 'Editar Grupo Econômico',	component: GruposEconomicosEditar },
  { path: '/motivos-cancelamento', name: 'Motivos de cancelamento', component: MotivosListar },
  { path: '/motivos-cancelamento/novo', name: 'Adicionar Motivo', component: MotivosEditar },
  { path: '/motivos-cancelamento/editar/:id', name: 'EditarMotivos de cancelamento',	component: MotivosEditar },
  { path: '/funcionarios', name: 'Funcionários', component: FuncionariosListar },
  { path: '/funcionarios/novo', name: 'Adicionar Funcionário', component: FuncionariosEditar },
  { path: '/funcionarios/editar/:id', name: 'Editar Funcionário',	component: FuncionariosEditar },
  { path: '/logout',  name: 'Logout', component: Logout },
  { path: '/employee-logout',  name: 'EmployeeLogout', component: EmployeeLogout },
  { path: '/testeping',	name: 'Teste Ping', component: TestePing },
  { path: '/404',	name: 'Página não Encontrada', component: PageNotFound }
]
