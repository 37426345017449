<template>
  <center>
    Você não tem acesso ao sistema. Deslogando em 5 segundos.
  </center>
</template>

<script>
import axios from 'axios'
export default {
  name: 'Home',
  methods: {
	  parseJwt (token) {
		  var base64Url = token.split('.')[1];
	  	var base64 = base64Url.replace('-', '+').replace('_', '/');
	  	return JSON.parse(window.atob(base64));
		}
  },
  mounted (){
  	let accessToken = ''
  	let t = this
    setTimeout(() => {
  		localStorage.removeItem('accessTokenObj', null)
  		window.location = "https://federate.volvo.com/idp/startSLO.ping"
    }, 5000)
  },
}
</script>