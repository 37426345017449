<template>
  <main-layout>
    <v-layout justify-center row wrap class='vv-form'>
      <form-alert
      :alert-model="!!alertText"
      :alert-text="alertText"
      @input="closeFormAlert()"/>
      <v-flex xs12>
        <div class="vv-form-header">
          <div class="vv-voltar-button">
            <router-link :to="{ path:  '/motivos-cancelamento'}">
              <svg viewBox="0 0 24 24">0
                <path fill="#0f3299" d="M20,10V14H11L14.5,17.5L12.08,19.92L4.16,12L12.08,4.08L14.5,6.5L11,10H20Z" />
              </svg>{{ $t('misc.back') }}
            </router-link>
          </div>
        </div>
        <v-layout row wrap class='white-background'>
          <span class="span-reason">{{ $tc('reasonsCancellations.addDescription', 0) }}</span>
          <v-form >
            <v-flex xs6>
             <v-text-field
             :label="$tc('Motivo de Cancelamento (Português)')"
             required
             ref='inputName'
             v-model="model.descriptionPortugueseBr"
             :rules="[(value) => !!value || $t('misc.validateInput')]"
             ></v-text-field>
             <v-text-field
             :label="$tc('Motivo Cancelacione (Espanhol)')"
             required
             ref='inputName'
             v-model="model.descriptionSpanishEs"
             :rules="[(value) => !!value || $t('misc.validateInput')]"
             ></v-text-field>
           </v-flex>
         </v-form>
       </v-layout>
     </v-flex>
   </v-layout>
   <v-layout row wrap class='vv-form-button'>
    <v-spacer></v-spacer>
    <v-flex xs4>
      <button ref='buttonSave' @click="save()" class='btn-primary vv-button vv-button-inverted vv-adicionar-novo-button'>
        <span>{{ (!$route.params.id ) ? $t('misc.add') : $t('misc.edit')}} {{ $tc('reasonsCancellations.reasonCancellation', 0) }}</span>
      </button>
    </v-flex>
  </v-layout>
</main-layout>
</template>

<style lang="scss" scoped>
	.span-reason {
		font-size: 14px; 
		height: 24px;
		font-family: 'Avenir';
		font-style: normal;
		font-weight: 900;
		font-size: 14px;
		line-height: 24px;
	}
</style>

<script>
  import { mapState, mapActions } from 'vuex'
  import ViewMixin from '@/mixins/ViewMixin'
  import utils from '@/libs/utils'
  export default {
    name: 'Home',
    components: {},
    mixins: [ViewMixin],
    data: () => ({
      model: {},
      alertText: false
    }),

    computed: {
      ...mapState('main', [
        'pageName'
        ])
    },
    methods: {
      ...mapActions('cancelReasons', [
        'editReason',
        'addReason',
        'getCancelReasons',
        ]),

      save() {
        this.$refs.inputName.focus()
        this.$nextTick(() => {
          this.$refs.buttonSave.focus()
        })
        if (!this.model.descriptionPortugueseBr || !this.model.descriptionPortugueseBr.trim() &&
            !this.model.descriptionSpanishEs || !this.model.descriptionSpanishEs.trim()) return
          this.$utils.showLoadingIndicator()
            let obj = Object.assign({}, this.model)
            obj.createDate = new Date()
            obj.deleted = true
            this.$utils.showLoadingIndicator()
            this.addReason(obj)
            .then(response => this.successHandler(this.$tc('reasonsCancellations.reasonCancellation', 1) + this.$t('misc.alertMessageInsertMale'), response))
            .catch(this.errorHandler)
        },
        errorHandler (error) {
          let t = this
          error = error.response
          this.$utils.hideLoadingIndicator()
          this.$store.commit('main/SET_ALERT_TEXT', {
            type: 'error', message: error.data.message
          })
          this.alertText = this.$utils.handleAlertText()
        },
        successHandler (message, response) {
          let t = this
          this.$store.commit('main/SET_ALERT_TEXT', {
            type: 'success', message
          })
          this.$router.push('/motivos-cancelamento')
          return
        },
  },
}
</script>