<template>
  <v-footer class="pa-3">
    <v-spacer></v-spacer>
    <div>
      <div class="status-indicator">
        <div class="status-badge processing"></div>
        <div class="status-label ">{{ $t('footerStatus.report') }}</div>
      </div>
      <div class="status-indicator">
        <div class="status-badge departure"></div>
        <div class="status-label ">{{ $t('footerStatus.start') }}</div>
      </div>
      <div class="status-indicator">
        <div class="status-badge repairing"></div>
        <div class="status-label">{{ $t('footerStatus.repairing') }}</div>
      </div>
      <div class="status-indicator">
        <div class="status-badge finished"></div>
        <div class="status-label">{{ $t('footerStatus.end') }}</div>
      </div>
      <div class="status-indicator" v-if="showCancelled">
        <div class="status-badge cancelled"></div>
        <div class="status-label">{{ $t('footerStatus.cancel') }}</div>
      </div>
    </div>
  </v-footer>
</template>

<script>
export default {
  name: 'KanbanFooter',
  data () {
    return {
    }
  },
  props: {
    showCancelled: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="css" scoped>
</style>