<template>
  <main-layout class='listar'>
    <v-layout
      justify-center
      row wrap
      v-if="loaded"
    >
      <form-alert
        :alert-model="!!alertText"
        :alert-text="alertText"
        @input="closeFormAlert()"
      />

      <v-flex xs8>
        <base-search-input
          v-model="searchFilter.generalSearch"
          :placeholder="$t('employees.searchEmployee')"
        />
      </v-flex>
      <v-flex xs4>
        <base-adicionar-button
        :text="$t('employees.addNew')"
        :to="{path: 'funcionarios/novo'}" >

        </base-adicionar-button>
      </v-flex>
      <v-flex xs12>
        <v-data-table
          v-bind:headers="headers"
          :items="filteredItems"
          pagination.sync="pagination"
          :rows-per-page-items="[50, 100, {text:'Todas', value: -1}]"
          no-data-text="Sem dados disponíveis"
          v-if="headers.length && loaded"
          class="elevation-1 list__table"
        >
          <template slot="items" slot-scope="props">
            <router-link
              :to="{path: '/funcionarios/editar/' + props.item.id}"
              tag="tr">
              <td v-html="props.item.user.name"></td>
              <td v-html="props.item.user.volvoId"></td>
              <td v-html="getDealershipOrGroupName(props.item)"></td>
              <td v-html="props.item.user.levelLabel"></td>
              <td v-html="(props.item.user.deleted) ? 'Bloqueado' : 'Ativo'"></td>
            </router-link>
          </template>
        </v-data-table>
      </v-flex>

    </v-layout>
  </main-layout>

</template>

<style>

</style>

<script>
import utils from "@/libs/utils"
import { mapState, mapActions } from 'vuex'
import ViewMixin from '@/mixins/ViewMixin'
import config from '@/app.settings'
export default {
  name: 'FuncionariosListar',
  components: {

  },
  mixins: [ViewMixin],
  data: () => ({
    searchFilter: {
      generalSearch: '',
    },
    alertText: false,
    loaded: false,
    employees: [],
    dealerships: [],
    economicGroups: []
  }),
  computed: {
    ...mapState('employees', ['employee']),

    filteredItems() {
      let filters = this.searchFilter
      let items = this.employees

      if (items.length == 0) {
        return []
      }

      items.sort((a, b) => a.user.deleted - b.user.deleted)
      if (!filters.generalSearch )
        return items

      let normalizeString = this.$utils.normalizeString
      let tempItems = items.filter(item =>
        (
          normalizeString(item.user.volvoId).indexOf(normalizeString(filters.generalSearch)) !== -1 ||
          normalizeString(item.user.name).indexOf(normalizeString(filters.generalSearch)) !== -1 ||
          normalizeString(this.getDealershipOrGroupName(item)).indexOf(normalizeString(filters.generalSearch)) !== -1
        )
      )
      return tempItems
    },
    headers() {
      return [
        { text: this.$t('employees.name'), align: 'left',  value: 'name', sortable: false },
        { text: this.$t('employees.volvoId'), align: 'left',  value: 'name', sortable: false },
        { text: this.$tc('employees.dealershipOrEconomicGroup'), align: 'left', sortable: false, value: 'economicGroup' },
        { text: this.$t('employees.level'), align: 'left', sortable: false, value: 'localization' },
        { text: this.$t('employees.status'), align: 'left', sortable: false, value: 'localization' },
      ]
    }
  },
  methods: {
    ...mapActions('employees', ['loadEmployees']),
    ...mapActions('dealerships', ['loadDealerships']),
    ...mapActions('economicGroups', ['loadEconomicGroups']),
    async init () {
      this.$utils.showLoadingIndicator()
      try {
        this.employees = await this.loadEmployees()
        this.dealerships = await this.loadDealerships()
        this.economicGroups = await this.loadEconomicGroups()
        this.loaded = true
      } catch(e) {
        console.warn(e)
      }
      this.$utils.hideLoadingIndicator()

    },
    getDealershipOrGroupName(item) {
      if (item.level !== 'EconomicGroupManager') {
        return item.dealershipEmployee ? item.dealershipEmployee.reduce((result, dealer, index) => {
          if (index === item.dealershipEmployee.length - 1) return (result += dealer.dealership.name);
          if (dealer.dealership) return (result += dealer.dealership.name + ", ");
        }, "") : "";
      } else {
        const { economicGroupId } = this.dealerships.find(dealership => dealership.id == item.dealershipEmployee[0].dealershipId)
        return this.economicGroups.find(group => group.id === economicGroupId).name
      }
    }


  },

  mounted() {
    this.init()
    this.alertText = this.$utils.handleAlertText()

  }

}
</script>