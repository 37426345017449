<template>
  <div class="d-flex page-not-found__container pa-5">
    <div class="page-not-found__row mt-5">
      <img class="page-not-found__logo logo" src="@/assets/voar_logo.png" alt="">
      <p class="pt-10 mb-6 page-not-found__title">
        {{ $t("pageNotFound.title") }}
      </p>
      <p class="mb-3 page-not-found__text">
        {{ $t("pageNotFound.text") }}
      </p>
      <button @click="goToLogin()" class='page-not-found__container page-not-found__button btn-primary vv-button vv-button-inverted vv-adicionar-novo-button'>
        <span>{{ $t("pageNotFound.button") }}</span>
      </button>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      optionsLanguage: [
        {
          id: 1,
          avatar: 'br',
          lang: 'pt'
        },
        {
          id: 2,
          avatar:'es',
          lang: 'es'
        }
      ]
    }
  },
  created() {
    let currentLanguage = this.optionsLanguage.find( x => x.id === JSON.parse(localStorage.getItem('language')));
    this.$root.$i18n.locale = currentLanguage.lang;
    this.$vuetify.lang.current = currentLanguage.lang;
  },
  methods: {
    goToLogin() {
      this.$router.push('/login');
    }
  }
}
</script>
<style lang="scss">
.page-not-found__text {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: center;
}
.page-not-found__title {
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: center;
}
.page-not-found__logo {
  height: 100px;
}
.page-not-found__row {
  top: 50%;
  left: 50%;
}
.page-not-found__container {
  margin: 0 auto;
}
.page-not-found__button {
  max-width: 200px !important;
}
</style>