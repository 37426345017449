<template>
  <main-layout>
      <v-layout justify-center row wrap class='vv-form' v-if="loaded" id="form-funcionarios">

        <form-alert
          :alert-model="!!alertText"
          :alert-text="alertText"
          @input="closeFormAlert()"
        />

        <v-flex xs12>
          <div class="vv-form-header">
            <base-voltar-button
            :to="{ path:  '/funcionarios'}" />
          </div>
          <v-layout row wrap class='white-background'>
            <v-form ref='form'>
              <v-flex xs6>
                <v-layout row wrap>
                  <v-flex xs12 v-if="['EconomicGroupManager', 'VASManager', 'CountryVasManager'].includes(employee.user.level)">
                    <select
                      :label="$t('employees.level')"
                      class="vv-input form-select"
                      v-model="selectedEmployeeLevel"
                    >
                      <option selected value="" class='vv-disabled'>{{$tc('employees.level')}}</option>
                      <option
                        v-for="(employeeLevel, key) in employeeLevels"
                        :value="employeeLevel.id"
                        :key="key"
                        >{{employeeLevel.value}}</option>
                    </select>
                  </v-flex>
                  <v-flex xs12 v-if="['VASManager', 'CountryVasManager'].includes(employee.user.level) && selectedEmployeeLevel == 'CountryVasManager'">
                    <select
                      :label="$t('misc.country')"
                      class="vv-input form-select"
                      v-model="selectedEmployeeCountry"
                    >
                      <option selected value="" class='vv-disabled'>{{$tc('misc.country')}}</option>
                      <option
                        v-for="(country, key) in countries"
                        :value="country.id"
                        :key="key"
                        >{{country.value}}</option>
                    </select>
                  </v-flex>
                  <v-flex xs12 v-if="
                      ['EconomicGroupManager'].includes(employee.user.level) ||
                      (['VASManager'].includes(employee.user.level)
                      && selectedEmployeeLevel
                      && selectedEmployeeLevel != 'EconomicGroupManager'
                      && selectedEmployeeLevel != 'VASManager'
                      && selectedEmployeeLevel != 'CountryVasManager'
                      && selectedEmployeeLevel != 'DealerManager')">
                     <v-select
                      :label="$tc('employees.dealership', 1)"
                      v-model="selectedDealershipMultiple"
                      multiple
                      :items="dealerships"
                      item-text="name"
                      item-value="id"
                      :rules="[(value) => value.length > 0 || $t('misc.validateInput')]"
                    />
                   </v-flex>
                    <v-flex xs12 v-if="
                      ['EconomicGroupManager'].includes(employee.user.level) ||
                      (['VASManager'].includes(employee.user.level)
                      && selectedEmployeeLevel
                      && selectedEmployeeLevel != 'EconomicGroupManager' 
                      && selectedEmployeeLevel != 'VASManager'
                      && selectedEmployeeLevel != 'CountryVasManager'
                      && selectedEmployeeLevel != 'DealerEmployee')
                    ">
                     <select
                      :label="$tc('employees.dealership', 1)"
                      class='vv-input form-select'
                      v-model="selectedDealership"
                    >
                      <option selected value="" class='vv-disabled'>{{$tc('services.dealership', 1)}}</option>
                      <option
                        v-for="(dealership, key) in dealerships"
                        :value="dealership.id"
                        :key="key"
                        >{{ dealership.name }}</option>
                    </select>

                   </v-flex>
                   <v-flex xs12 v-if="['VASManager', 'CountryVasManager'].includes(employee.user.level) && selectedEmployeeLevel &&  selectedEmployeeLevel == 'EconomicGroupManager' ">
                     <select

                      :label="$tc('services.economicGroup', 1)"
                      class='vv-input form-select'
                      v-model="selectedEconomicGroup"
                    >
                      <option selected value="" class='vv-disabled'>{{$tc('services.economicGroup', 1)}}</option>
                      <option
                        v-for="(economicGroup, key) in economicGroups"
                        :value="economicGroup.id"
                        :key="key"
                        >{{economicGroup.name}}</option>
                    </select>

                   </v-flex>
                   <v-flex xs12>
                     <v-text-field
                      :label="$t('employees.volvoId')"
                      v-model="model.user.volvoId"
                      required
                      :rules="[(value) => !!value || $t('misc.validateInput')]"
                    ></v-text-field>
                   </v-flex>
                   <v-flex xs12>
                     <v-text-field
                      :label="$t('employees.name')"
                      v-model="model.user.name"
                      required
                      :rules="[(value) => !!value || $t('misc.validateInput')]"
                    ></v-text-field>
                   </v-flex>
                </v-layout>
              </v-flex>
            </v-form>
          </v-layout>
        </v-flex>
      </v-layout>
      <v-layout row wrap class='vv-form-button'>
        <v-spacer></v-spacer>
        <v-flex xs4></v-flex>
        <v-flex xs4 v-if="$route.params.id && !model.user.deleted">
          <button @click.stop="dialog = true" class='btn-primary vv-button vv-button vv-adicionar-novo-button'>
            <span>{{$t('misc.block')}}</span>
          </button>
        </v-flex>
        <v-flex xs4>
          <button @click="save()" class='btn-primary vv-button vv-button-inverted vv-adicionar-novo-button'>
            <span>{{ (!$route.params.id ) ? $t('misc.add') : $t('misc.edit')}} {{ $tc('employees.employee', 1) }}</span>
          </button>
        </v-flex>
      </v-layout>
      <v-dialog
        v-model="dialog"
        max-width="290">
        <base-modal
          :title="$t('misc.areYouSure')"
          @cancel="dialog = false"
          @confirm="dialog = false; remove()"
          >
          {{ $t('misc.askForBlock', {theEntity: $tc('employees.employee', 1) }) }}
        </base-modal>
      </v-dialog>

  </main-layout>

</template>

<style lang="scss" scoped>
  .input-group input, .input-group select, .input-group--select__autocomplete{
    background: none !important;
  }
</style>

<script>
const DEFAULT_USERNAME = 'Usuário Volvo'


import utils from "@/libs/utils"
import { mapState, mapActions } from 'vuex'
import ViewMixin from '@/mixins/ViewMixin'
import config from '@/app.settings'
export default {
  name: 'FuncionariosEditar',
  mixins: [ViewMixin],
  components: {

  },
  data: () => ({
    model: {
      user: {
        volvoId: ''
      }
    },
    economicGroups: [],
    dealerships: [],

    selectedDealership: '',
    selectedDealershipMultiple: [],
    selectedEmployeeLevel: '',
    selectedEconomicGroup: '',
    selectedEmployeeCountry: '',
    dialog: false,
    loaded: false,
    alert: true,
    alertText: false,

  }),
  computed: {
    ...mapState('employees', [
      'employee',
    ]),
    countries () {
      return [
        { id: 'brazil', value: this.$t('misc.countryBR') },
        { id: 'argentina', value: this.$t('misc.countryAR') },
        { id: 'chile', value: this.$t('misc.countryCL') },
        { id: 'peru', value: this.$t('misc.countryPE') },
      ]
    },
    employeeLevels () {
      if (!this.employee) return []

      let options = [
        { id: 'DealerEmployee', value: this.$t('employeeLevels.DealerEmployee') },
      ]
      if (this.employee.user.level == 'EconomicGroupManager') {
        options.push({ id: 'DealerManager', value: 'Dealer Manager' })
      }
      if (this.employee.user.level == 'VASManager' || this.employee.user.level == 'CountryVasManager') {
        options.push(
          { id: 'VASManager', value: this.$t('employeeLevels.VASManager') },
          { id: 'EconomicGroupManager', value: this.$t('employeeLevels.EconomicGroupManager') },
          { id: 'DealerManager', value: this.$t('employeeLevels.DealerManager') },
          { id: 'CountryVasManager', value: this.$t('employeeLevels.CountryVasManager') },
        )
      }
      return options
    }
  },
  methods: {
    ...mapActions('users', [
      'addUser',
      'editUser',
      'deleteUser'
    ]),
    ...mapActions('employees', [
      'deleteEmployee',
      'loadEmployee',
      'editEmployee',
      'addEmployee',
    ]),
    ...mapActions('dealerships', [
      'loadDealerships'
    ]),
    ...mapActions('economicGroups', [
      'loadEconomicGroups'
    ]),
    save() {
      if(!this.$refs.form.validate()) return
      if (this.employee.user.level == 'DealerManager') {
        this.selectedEmployeeLevel = 'DealerEmployee'
        this.selectedDealership = this.employee.dealershipId
      }
      if (this.selectedEmployeeLevel == 'VASManager' || this.selectedEmployeeLevel == 'CountryVasManager') {
        this.selectedDealership = 'c4a8b17f-bcde-406f-b464-864b5979435c'
      }
      if (this.selectedEmployeeLevel != 'CountryVasManager') {
        this.selectedEmployeeCountry = null
      }
      if (this.selectedEmployeeLevel != 'EconomicGroupManager') {
        this.selectedEconomicGroup = null
      }
      if (this.selectedEmployeeLevel == 'EconomicGroupManager') {
        this.selectedDealership = this.dealerships.filter(dealership => dealership.economicGroupId == this.selectedEconomicGroup)[0].id
      }
      if (this.$route.params.id) {
        if (!this.model.user.volvoId.length) {
          return this.throwErrorOnScreen('O campo volvoId não pode estar vazio!')
        }
        let id = this.$route.params.id
        let obj = this.model
        obj.id = id

        let userObj = {
          user: {
            employeeId: this.model.user.employeeId,
            id: this.model.userId,
            level: this.selectedEmployeeLevel,
            volvoId: this.model.user.volvoId,
            name: this.model.user.name,
            country: this.selectedEmployeeCountry,
          },
          dealershipEmployee: this.payloadDealership(),
        }
        if(!userObj.user.country) {
          delete userObj.user.country
        }
        if(!userObj.dealershipEmployee.dealershipId) {
          delete userObj.dealershipEmployee.dealershipId
        }
        if(!userObj.dealershipEmployee.economicGroupId) {
          delete userObj.dealershipEmployee.economicGroupId
        }
        this.$utils.showLoadingIndicator()
        this.editEmployee(userObj).then(response => {
          if (!this.selectedDealership && userObj.dealershipId && userObj.dealership) {
            delete userObj.dealershipId
            delete userObj.dealership.id
          }
          this.successHandler(this.$tc('employees.employee', 1) + this.$t('misc.alertMessageEditMale'), response)
        })
        .catch(this.errorHandler)
      } else {
        if (!this.model.user.volvoId.length) {
          return this.throwErrorOnScreen('O campo volvoId não pode estar vazio!')
        }

        this.model.dealershipId = this.selectedDealership
        this.model.user.level =  this.selectedEmployeeLevel
        let payload = {
          user: {
            level: this.selectedEmployeeLevel,
            volvoId: this.model.user.volvoId,
            name: this.model.user.name,
            employeeId: this.model.user.employeeId,
            country: this.selectedEmployeeCountry,
          },
          dealershipEmployee: this.payloadDealership(),
        }
        if(!payload.user.country) {
          delete payload.user.country
        }
        if(!payload.dealershipEmployee.dealershipId) {
          delete payload.dealershipEmployee.dealershipId
        }
        if(!payload.dealershipEmployee.economicGroupId) {
          delete payload.dealershipEmployee.economicGroupId
        }
        this.$utils.showLoadingIndicator()

        this.addEmployee(payload)
        .then(response => this.successHandler(this.$tc('employees.employee', 1) + this.$t('misc.alertMessageInsertMale'), response))
        .catch(this.errorHandler)

      }
    },
    payloadDealership() {
      if (this.employee.user.level == 'DealerManager' || this.selectedEmployeeLevel != 'DealerEmployee')
        return [
          {
            dealershipId: this.selectedDealership,
            economicGroupId: this.selectedEconomicGroup
          }
        ]
      let payload = []
      this.selectedDealershipMultiple.forEach(item => {
        payload.push({
          dealershipId: item,
          economicGroupId: this.selectedEconomicGroup
        })
      })
      return payload
    },
    throwErrorOnScreen(message) {
      this.$store.commit('main/SET_ALERT_TEXT', {type: 'error', message})
      this.alert = true
      this.alertText = this.$store.state.alertText
      this.$forceUpdate()
      this.$store.commit('main/SET_ALERT_TEXT', false)
      this.$store.commit('SET_LOADING_INDICATOR_FLAG', false)
      this.$forceUpdate()
    },

    errorHandler (error) {
      //TODO: Buscar do back-end o erro quando estiver ajustado
      let message = this.$t('misc.commonError')

      this.$store.commit('main/SET_ALERT_TEXT', {type: 'error', message})
      this.alertText = this.$utils.handleAlertText()
      this.$utils.hideLoadingIndicator()
      return

    },
    successHandler (message, response) {

      this.$store.commit('main/SET_ALERT_TEXT', {type: 'success', message})
      this.$router.push('/funcionarios')
    },
    async remove() {
      let response
      try {
        this.$utils.showLoadingIndicator()
        response = await this.deleteEmployee(this.model.id)
         // await this.deleteUser(this.model.userId)
        this.successHandler(
          this.$tc('employees.employee', 1) + this.$t('misc.alertMessageBlockMale'),
          response
        )
      } catch(e) {
        this.errorHandler(e)
        console.warn(e)
      }
      this.$utils.hideLoadingIndicator()
    },
    async init() {
      try {
        this.$utils.showLoadingIndicator()
        this.dealerships = await this.loadDealerships()
        this.dealerships = this.$utils.sortAlphabeticalOrder(this.dealerships)
        if (this.employee.user.level == 'VASManager'|| this.employee.user.level == 'CountryVasManager') {
          this.economicGroups = await this.loadEconomicGroups()
          this.economicGroups = this.$utils.sortAlphabeticalOrder(this.economicGroups)
        }
      } catch(e) {
        console.warn(e)
      }
      this.$utils.hideLoadingIndicator()


    },
    async loadModel(modelId) {
      try {
        let _dealership
        this.model = await this.loadEmployee({id: modelId})
        this.model = this.model[0]
        this.selectedEmployeeLevel = this.model.user.level
        if (this.model.dealershipEmployee) {
          this.selectedDealership = this.model.dealershipEmployee[0].dealershipId
          this.selectedDealershipMultiple = this.model.dealershipEmployee.reduce((array, item) => {
              if (item.dealershipId)
                array.push(item.dealershipId);
              return array;
            }, [])
          this.selectedEmployeeLevel = this.model.user.level
          _dealership = this.dealerships.find(item => item.id == this.model.dealershipEmployee[0].dealershipId)
          this.selectedEconomicGroup = _dealership.economicGroupId
        }
        this.selectedEmployeeCountry = this.model.user.country
        this.loaded = true
      } catch(e) {
        console.warn(e)
      }
      this.$utils.hideLoadingIndicator()
    }
  },
  mounted () {
    this.init().then(() => {
      if (this.$route.params.id) {
        this.loadModel(this.$route.params.id)
        document.title += " - " + this.model.name
      }
      else {
        this.loaded = true
      }

    })
  },
}
</script>
