var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-card",
        {
          key: _vm.item.id,
          staticClass: "kanban-card",
          class: _vm.getKanbanCardClasses(),
          attrs: { id: "card-" + _vm.item.id }
        },
        [
          _c(
            "v-card-title",
            { staticClass: "mb-0 pb-0 pt-1 mt-0 pl-2" },
            [
              _c(
                "h3",
                { staticClass: "mb-0" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "mb-0",
                      attrs: { to: { path: "/atendimentos/" + _vm.item.id } }
                    },
                    [
                      _vm._v(
                        "\n      \t\t" +
                          _vm._s(_vm.item.mercuriusCase) +
                          "\n      \t"
                      )
                    ]
                  )
                ],
                1
              ),
              _c("v-spacer"),
              _vm.isStatusEnd
                ? _c(
                    "button",
                    {
                      staticClass:
                        "justify-end d-flex align-end archive-service__button",
                      attrs: { id: "btn--" + _vm.item.id },
                      on: { click: _vm.updateCardArchiveService }
                    },
                    [
                      _vm.permissionArchive
                        ? _c("v-icon", { attrs: { color: "#35343D" } }, [
                            _vm._v(" more_horiz ")
                          ])
                        : _vm._e(),
                      _vm.showArchiveService
                        ? _c(
                            "v-card",
                            {
                              staticClass: "archive-service__card",
                              attrs: { id: "item-" + _vm.item.id }
                            },
                            [
                              _c(
                                "button",
                                {
                                  staticClass: "px-2 pt-3",
                                  staticStyle: { color: "#1e3673" },
                                  on: { click: _vm.archiveSelectedService }
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: require("@/assets/ic_archive_file.png"),
                                      width: "20%",
                                      id: "img--" + _vm.item.id
                                    }
                                  }),
                                  _vm._v(" Arquivar\n\t\t  ")
                                ]
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "v-card-title",
            { staticClass: "mb-0 pb-0 pt-1 mt-0 px-0" },
            [
              _vm.needsPausedChip()
                ? _c(
                    "v-chip",
                    {
                      attrs: {
                        small: "",
                        label: "",
                        disabled: "",
                        color: "red",
                        "text-color": "white"
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("footerStatus.paused")))]
                  )
                : _vm._e(),
              _vm.isAvailableETA
                ? _c(
                    "v-chip",
                    {
                      attrs: { small: "", label: "", disabled: "", outline: "" }
                    },
                    [
                      _vm._v(
                        "ETA: " + _vm._s(_vm.item.estimatedTimeArrival) + " "
                      )
                    ]
                  )
                : _vm._e()
            ],
            1
          ),
          _c("v-card-text", [
            _c(
              "div",
              [
                _c("div", { staticClass: "vv-kanban-address" }, [
                  _vm._v(
                    "\n        \t" +
                      _vm._s(_vm.item.placeDescription.replace(/BRAZIL/g, "")) +
                      "\n        "
                  )
                ]),
                _vm._l(_vm.sortedNotifications, function(notification, index) {
                  return notification.type !== 7
                    ? _c("div", { key: index }, [
                        _vm._v(
                          "\n        \t" +
                            _vm._s(_vm.getNotificationByStatus(notification)) +
                            ":\n        \t\t"
                        ),
                        notification.type != 1
                          ? _c("span", [
                              _vm._v(
                                _vm._s(
                                  notification.date
                                    ? _vm.getDateAndTime(notification.date)
                                    : ""
                                ) + "h"
                              )
                            ])
                          : _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.item.reportDate
                                    ? _vm.getDateAndTime(
                                        _vm.utils.fixDateWithoutGMT(
                                          _vm.item.reportDate
                                        )
                                      )
                                    : ""
                                ) + "h"
                              )
                            ])
                      ])
                    : _vm._e()
                })
              ],
              2
            )
          ]),
          _c(
            "v-card-actions",
            [
              _c("span", {
                staticClass: "left",
                domProps: {
                  innerHTML: _vm._s(
                    _vm.item.gpsDistance
                      ? _vm.item.gpsDistance + "km"
                      : _vm.item.distance + "km"
                  )
                }
              }),
              _c("v-spacer"),
              _c("span", {
                staticClass: "right",
                domProps: { innerHTML: _vm._s(_vm.item.dealership.name) }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "450" },
          model: {
            value: _vm.requestDialog,
            callback: function($$v) {
              _vm.requestDialog = $$v
            },
            expression: "requestDialog"
          }
        },
        [
          _c("base-modal", {
            attrs: {
              title: _vm.message,
              "confirm-text": "OK",
              "no-cancel-button": ""
            },
            on: {
              confirm: function($event) {
                _vm.requestDialog = false
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }