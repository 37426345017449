<template>

  <main-layout>

      <v-layout justify-center row wrap class='vv-form atendimento-visualizar' id='#print-form' v-if="loaded">
        <v-flex xs12>
          <v-layout>
            <v-flex xs6>
              <base-voltar-button @click="$router.go(-1)" />
            </v-flex>
            <v-spacer></v-spacer>
            <v-flex xs6 >
              <button v-if='false' @click="printDiv('#print-form')"  class='btn-primary vv-button vv-button-inverted vv-adicionar-novo-button'>
                <span>{{ $t('misc.print') }}</span>
              </button>
              <v-layout justify-end row wrap>
                <v-flex xs6 v-if="shouldShowCancelServiceButton()">
                  <button
                    class="btn-primary vv-button vv-button-inverted vv-adicionar-novo-button "

                    @click="openModalCancelService">
                    {{$t('services.cancelService')}}
                  </button>
                </v-flex>
                <v-flex xs6 v-if="shouldShowUpdateStatusButton()">
                  <button
                    class="btn-primary vv-button vv-button-inverted vv-adicionar-novo-button "

                    @click="openModalChangeStatus">
                    {{$t('services.changeStatus')}}
                  </button>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
          <v-layout row wrap mt-2 >
            <v-flex xs12>
              <v-card >
                <v-card-text class='py-4 px-4'>
                  <v-layout row wrap>
                    <v-flex xs6>
                      <v-text-field
                        :label="$t('services.technicianInCharge')"
                        :value="model.technicalName || ' '"
                        readonly
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs4>
                    <v-text-field
                      :label="$t('services.contact')"
                      :value="model.technicalContact || ' '"
                      readonly
                    ></v-text-field>
                    </v-flex>
                    <v-flex xs2>
                    <v-text-field
                      label="Volvo ID"
                      v-if="model.employee"
                      :value="model.employee.user.volvoId || ' '"
                      readonly
                    ></v-text-field>
                    </v-flex>
                    <v-flex xs6>
                      <v-text-field
                        :label="$t('services.economicGroup', 1)"
                        :value="economicGroupName || ' '"
                        readonly
                      ></v-text-field>
                     </v-flex>
                     <v-flex xs6>
                       <v-text-field
                        :label="$t('services.dealership', 1)"
                        :value="model.dealership.name || ' '"
                        readonly
                      ></v-text-field>
                     </v-flex>

                     <v-flex xs6>
                      <v-text-field
                        :label="$t('services.mercuriusCase')"
                        readonly
                        :value="model.mercuriusCase || ' '"
                      ></v-text-field>
                     </v-flex>
                     <v-flex xs6>

                     </v-flex>
                     <v-flex xs6>
                      <v-text-field
                        :label="$t('services.contractGold')"
                        readonly
                        :value="model.goldPlane || ' '"

                      ></v-text-field>
                     </v-flex>
                     <v-flex xs6>
                      <v-text-field
                        :label="$t('services.firstContact')"
                        readonly
                        :value="firstContactDate || ' '"
                      ></v-text-field>
                     </v-flex>
                     <v-flex xs12>
                       <v-text-field
                          :label="$t('services.customer')"
                          readonly
                          :value="model.customer.name || ' '"
                        ></v-text-field>
                     </v-flex>
                     <v-flex xs6>
                       <v-text-field
                          label="Veículo"
                          :value="model.customer.vehicle.model || ' '"
                          readonly
                        ></v-text-field>
                     </v-flex>
                     <v-flex xs3>
                      <v-text-field
                        :label="$t('services.plate')"
                        :value="model.customer.vehicle.plate || ' '"
                        readonly
                      ></v-text-field>
                     </v-flex>
                     <v-flex xs3>
                      <v-text-field
                        :label="$tc('services.chassi', 2)"
                        :value="model.customer.vehicle.chassisNumber || ' '"
                        readonly
                      ></v-text-field>
                     </v-flex>
                     <v-flex xs9>
                      <v-text-field
                        :label="$t('services.localization')"
                        readonly
                        :value="model.placeDescription || ' '"
                      ></v-text-field>
                     </v-flex>
                     <v-flex xs3>
                      <router-link :to="{path: '/atendimentos/mapa/' + $route.params.id}" class="btn-primary vv-button vv-button-inverted vv-adicionar-novo-button">{{$t('services.seeOnMap')}}</router-link>
                     </v-flex>
                     <v-flex xs6>
                      <v-text-field
                        :label="$t('services.estimatedDistance')"
                        readonly
                        :value="estimatedDistance || ' '"
                      ></v-text-field>
                     </v-flex>
                     <v-flex xs6>
                      <v-text-field
                        :label="$t('services.travelledDistance')"
                        readonly
                        :value="travelledDistance || ' '"
                      ></v-text-field>
                     </v-flex>


                     <v-flex xs12>
                      <v-text-field
                        :label="$t('services.observation')"
                        readonly
                        :value="model.serviceDescription || ' '"
                      ></v-text-field>
                   </v-flex>
                  </v-layout>
                </v-card-text>
              </v-card>
            </v-flex>
          </v-layout>
          
          <v-layout row wrap class="mt-4">
            <v-flex xs12>
              <v-card >
                <v-card-text class='py-4 px-4'>
                  <v-layout row wrap>
                    <v-flex xs6>
                      <v-text-field
                        :label="$t('services.finalTravelledDistance')"
                        :value="model.displacement || ' '"
                        readonly
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs6>
                    <v-text-field
                      :label="$t('services.vehicleModel')"
                      :value="model.vehicleModel || ' '"
                      readonly
                    ></v-text-field>
                    </v-flex>
                     <v-flex xs6>
                      <v-text-field
                        :label="$t('services.serviceReason')"
                        :value="model.causeService || ' '"
                        readonly
                      ></v-text-field>
                     </v-flex>
                     <v-flex xs6>
                      <v-text-field
                        :label="$t('services.serviceConclusion')"
                        :value="model.completionService || ' '"
                        readonly
                      ></v-text-field>
                     </v-flex>
                     <v-flex xs12>
                      <v-text-field
                        :label="$t('services.serviceNotes')"
                        :value="model.notesService || ' '"
                        readonly
                      ></v-text-field>
                   </v-flex>
                  </v-layout>
                </v-card-text>
              </v-card>
            </v-flex>
          </v-layout>

          <v-layout row wrap class='mt-4'>
             <v-flex xs12>
               <v-data-table
                  v-bind:headers="table1Headers"
                  no-data-text="Sem dados disponíveis"
                  :items="table1Items"
                  hide-actions
                  class="elevation-1"
                >
                <template slot="items" slot-scope="props">
                  <td v-html="props.item.mercuriusCase"></td>
                  <td v-html="(props.item.dealership && props.item.dealership.name) ? props.item.dealership.name : ''"> </td>
                  <td v-html="(props.item.startDate) ? getFullDate(props.item.startDate) : '———'"></td>
                  <td v-html="(props.item.endDate) ? getFullDate(props.item.endDate) : '———'"></td>
                  <td v-html="getStatusName(props.item.status)"></td>
                </template>
               </v-data-table>
             </v-flex>
          </v-layout>

           <v-layout
            row
            wrap
            class='mt-4'>
            <v-flex xs12>
              <v-data-table
                v-bind:headers="table2Headers"
                :items="table2Items"
                hide-actions
                class="elevation-1"
                :no-data-text="$t('misc.noData')"
              >
            <template slot="items" slot-scope="props">
              <td v-html="getNotificationByStatus(props.item)"></td>
              <td v-html="getTableItemDate(props.item)"></td>
              <td v-html="getTableItemTime(props.item)"></td>
              <td v-html="getTableReason(props.item)"></td>
            </template>
          </v-data-table>

            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
      <v-dialog
        v-model="cancelServiceDialog"
        max-width="450">
          <cancelar-atendimento-modal
            @select-cancel-type="openModalConfirmCancelService"
          />
      </v-dialog>
      <v-dialog
        v-model="confirmCancelServiceDialog"
        max-width="450"
      >
        <confirmar-cancelamento-modal
          :reason="cancelReasonSelected"
          @back="openModalCancelService"
          @confirm="cancelService"
        />
      </v-dialog>
      <v-dialog
        v-model="changeStatusDialog"
        max-width="450">
          <alterar-status-modal
            @change-status="changeStatus"
            :status="model.status"
          />
      </v-dialog>
      <v-dialog
        v-model="cancelServiceReturnDialog"
        max-width="450">
          <base-modal
          :title="$t('services.canceledService')"
          :confirm-text="'OK'"
          @confirm="closeCancelServiceReturnDialog"
          no-cancel-button>

          </base-modal>
      </v-dialog>
      <v-dialog
        v-model="changeStatusReturnDialog"
        max-width="450">
          <base-modal
          :title="$t('services.editedStatus')"
          :confirm-text="'OK'"
          @confirm="closeChangeStatusReturnDialog"
          no-cancel-button>

          </base-modal>
      </v-dialog>
      <v-dialog
        v-model="requestErrorDialog"
        max-width="450">
          <base-modal
          :title="$t('services.problemRequest')"
          :confirm-text="'OK'"
          @confirm="requestErrorDialog = false"
          no-cancel-button>

          </base-modal>
      </v-dialog>
  </main-layout>

</template>

<style lang="scss" scoped>
.elevation-1 {
  border-radius: 4px;
}
</style>

<script>
const possibleServiceStatusToCancelService = [1, 2]
const possibleServiceStatusUpdateStatus = [3, 4, 8, 9]

import utils from "@/libs/utils"
import { mapState, mapActions } from 'vuex'
import ViewMixin from '@/mixins/ViewMixin'
import config from '@/app.settings'
import { format, parseISO } from 'date-fns'

export default {
  name: 'AtendimentosVisualizar',
  components: {},
  mixins: [ViewMixin],
  data: () => ({
    // prevent problems with accessing properties which doenst exists
    model: {
      customer: {
        vehicle: {}
      },

    },
    typeOfMap: false,
    economicGroups: [],
    selectedEconomicGroup: false,
    dealerships: [],
    selectedDealership: false,
    table1Headers: [],
    table1Items: [],
    table2Headers: [],
    loaded: false,
    table2Items: [],
    cancelServiceDialog: false,
    confirmCancelServiceDialog: false,
    cancelServiceReturnDialog: false,
    changeStatusDialog: false,
    changeStatusReturnDialog: false,
    requestErrorDialog: false,
    config,
    cancelReasonSelected: {}
  }),
  computed: {
    estimatedDistance () {
      return this.model.distance + 'km'
    },
    travelledDistance () {
      if(!this.model.gpsDistance) return 'NI'
      return this.model.gpsDistance * 2 + 'km'
    },
    firstContactDate() {
      return this.model.reportDate 
        ? format(parseISO(utils.fixDateWithoutGMT(this.model.reportDate)), 'dd/MM/y HH:mm:ss')
        : ''
    },
    economicGroupName() {
      const economicGroup = this.economicGroups.find(group => group.id === this.model.dealership.economicGroupId)
      return economicGroup.name || '';
    },
    ...mapState({
      pageName: 'main/pageName'
    }),
  },
  methods: {

    ...mapActions({
      loadDealerships: 'dealerships/loadDealerships',
      loadEconomicGroups: 'economicGroups/loadEconomicGroups',
      loadServiceNotifications: 'services/loadServiceNotifications',
      loadService: 'services/loadService',
      actionCancelService: 'services/cancelService',
      actionChangeStatus: 'services/changeStatus',
    }),
    getFullDate(date) {
      return format(parseISO(date.replace('Z', '')), 'dd/MM/y - HH:mm:ss')
    },
    getDate(date) {
      return format(parseISO(date.replace('Z', '')), 'dd/MM/y')
    },
    getTime(date) {
      return format(parseISO(date.replace('Z', '')), 'HH:mm:ss')
    },
    printDiv(divName) {
      var printContents = document.getElementById(divName).innerHTML;
      var originalContents = document.body.innerHTML;
      document.body.innerHTML = printContents;
      window.print();
      document.body.innerHTML = originalContents;
    },
    getStatusName (status) {
      switch (status) {
        case 1: return this.$t('footerStatus.report')
        case 2: return this.$t('footerStatus.start')
        case 3: return this.$t('footerStatus.repairing')
        case 4: return this.$t('footerStatus.end')
        case 5: return this.$t('footerStatus.start')
        case config.PAUSED_STATUS: return this.$t('footerStatus.paused')
        case config.RESUMED_STATUS: return this.$t('footerStatus.resumed')
        case config.CANCELLED_STATUS: return this.$t('footerStatus.cancel')
      }
    },
    getCancelReasonText(reason) {
      const messages = {
        [config.serviceCancelReasons.cancelledByCustomer]: this.$t('services.cancelClientSide'),
        [config.serviceCancelReasons.vehicleTowed]: this.$t('services.towedCar'),
        [config.serviceCancelReasons.inAppIssues]: this.$t('services.problemsInApp'),
        [config.serviceCancelReasons.appNotUsedInTheService]: this.$t('services.noAppInService'),
        [config.serviceCancelReasons.others]: this.$t('services.others')
      }
      return messages[reason] || '';
    },
    getTableReason(item) {
      if (item.reason) return item.reason
      return (item.type === config.CANCELLED_STATUS) 
          ? this.getCancelReasonText(this.model.reason)
          : '———'
    },
    getTableItemDate(item) {
      if (item.type === 1) {
        return (this.model.reportDate) ? this.getDate(utils.fixDateWithoutGMT(this.model.reportDate)) : '———';
      } else {
        return (item.date) ? this.getDate(item.date) : '———';
      }
    },
    getTableItemTime(item) {
      if (item.type === 1) {
        return (this.model.reportDate) ? this.getTime(utils.fixDateWithoutGMT(this.model.reportDate)) : '———';
      } else {
        return (item.date) ? this.getTime(item.date) : '———';
      }
    },
    shouldShowCancelServiceButton () {
      const userLevel = this.$store.state.employees.employee.user.level
      const should =
        userLevel == (this.config.levels.VASManager || this.config.levels.CountryVasManager) &&
        possibleServiceStatusToCancelService.includes(this.model.status)

      return should
    },
    shouldShowUpdateStatusButton () {
      const userLevel = this.$store.state.employees.employee.user.level
      const should =
        userLevel == (this.config.levels.VASManager || this.config.levels.CountryVasManager) &&
        possibleServiceStatusUpdateStatus.includes(this.model.status)

      return should
    },
    openModalCancelService() {
      this.confirmCancelServiceDialog = false
      this.cancelServiceDialog = true
    },
    openModalConfirmCancelService(data) {
      this.cancelReasonSelected = data
      this.cancelServiceDialog = false
      this.confirmCancelServiceDialog = true
    },
    closeCancelServiceReturnDialog() {
      this.cancelServiceReturnDialog = false
      this.$router.push({ path: '/atendimentos/kanban'})
    },
    closeChangeStatusReturnDialog() {
      this.changeStatusReturnDialog = false
      this.$router.push({ path: '/atendimentos/kanban'})
    },
    openModalChangeStatus() {
      this.changeStatusDialog = true
    },
    async changeStatus(data) {
      this.changeStatusDialog = false
      let payload = {
        status: data.status,
        serviceId: this.$route.params.id
      }
      this.$utils.showLoadingIndicator()
      try {
        await this.actionChangeStatus(payload)
        this.changeStatusReturnDialog = true
      } catch(e) {
        this.requestErrorDialog = true
        console.warn(e)
      }
      this.$utils.hideLoadingIndicator()
    },
    async cancelService(data) {
      this.confirmCancelServiceDialog = false
      let response
      let payload = {
        cancelReasonId: data.id,
        serviceId: this.$route.params.id
      }
      console.log(data)
      this.$utils.showLoadingIndicator()
      try {
        response = await this.actionCancelService(payload)
        this.cancelServiceReturnDialog = true
      } catch(e) {
        this.requestErrorDialog = true
        console.warn(e)
      }
      this.$utils.hideLoadingIndicator()
    },
    async init() {
      try {
        this.$utils.showLoadingIndicator()
        this.dealerships = await this.loadDealerships()
        this.economicGroups = await this.loadEconomicGroups()
        this.model = await this.loadService(this.getFiltersPayload())
        this.model.notifications = await this.loadServiceNotifications({id: this.$route.params.id})
        if (!this.model.customer) {
          this.model.customer = {}
          this.model.customer.vehicle = {}
        }
        this.table1Items = [ this.model ]
        this.table2Items = this.model.notifications
          // .sort((a, b) => (a.date < b.date) ? -1 : ((a.date > b.date) ? 1  : 0))
        this.selectedDealership = this.model.dealershipId
        this.model.entireDistance = this.model.distance * 2 + 'km'
        this.$store.commit('main/SET_PAGENAME', this.$store.state.main.pageName + ' - ' + this.model.mercuriusCase)
        document.title += " - " + this.model.mercuriusCase
        this.loaded = true
      } catch(e) {
        console.warn(e)
      }
      this.$utils.hideLoadingIndicator()
    },
    getFiltersPayload() {
      return {
        Id: this.$route.params.id,
        IncludeCustomer: true,
        IncludeEmployee: true,
      }
    },
    getNotificationByStatus(notification) {
		  return this.$utils.getNotificationTypeName(this, notification.type)
		}
  },
  mounted () {
    this.init()
    this.table1Headers = [
      { text: this.$t('services.mercuriusCase'), align: 'left',  value: 'mercurius_case'  , sortable: false},
      { text: this.$tc('services.dealership', 1), align: 'left', value: 'dealership' , sortable: false},
      { text: this.$t('services.start'), align: 'left', value: 'startDate' , sortable: false},
      { text: this.$t('services.end'), align: 'left', value: 'endDate' , sortable: false},
      { text: this.$t('services.status'), align: 'left', value: 'status' , sortable: false},
    ]
    this.table2Headers = [
      { text: this.$t('services.history'), align: 'left',  value: 'mercurius_case'  , sortable: false},
      { text: this.$t('misc.date'), align: 'left', value: 'dealership' , sortable: false},
      { text: this.$t('misc.time'), align: 'left', value: 'startDate' , sortable: false},
      { text: this.$t('services.reason'), align: 'left', value: 'reason', sortable: false }
    ]
  }
}
</script>