const DEV_API_URL = 'https://ctvas-backend-dev.azurewebsites.net/api/v1'
const PROD_API_URL = 'https://voar-api.volvo.com/api/v1'
const STAGE_API_URL = 'https://ctvas-backend-staging.azurewebsites.net/api/v1'
const NEW_DEV_API_URL = 'https://ctvas-backend-dev.azurewebsites.net/api/v1.1'
const NEW_PROD_API_URL = 'https://voar-api.volvo.com/api/v1.1'
const NEW_STAGE_API_URL = 'https://ctvas-backend-staging.azurewebsites.net/api/v1.1'

const DEV_TRACKING_API_URL = 'https://voar-tracking-api-dev.azurewebsites.net/api/v1'
const PROD_TRACKING_API_URL = 'https://voar-tracking-api.azurewebsites.net/api/v1'
const STAGE_TRACKING_API_URL = 'https://voar-tracking-api-stage.azurewebsites.net/api/v1'


const PROD_FEDERATE_REDIRECT_URL = 'https://federate.volvo.com/as/authorization.oauth2?client_id=VOARWeb&response_type=token&redirect_uri=https://voar.volvo.com'
const QA_FEDERATE_REDIRECT_URL = 'https://federate.volvo.com/as/authorization.oauth2?client_id=VOARWeb&response_type=token&redirect_uri=https://ctvas-front-staging.azurewebsites.net'
const serverURLs = {
  local: 'localhost',
  localHomolog: 'staging.localhost',
  staging: 'ctvas-front-staging.azurewebsites.net',
  dev: 'ctvas-front-dev.azurewebsites.net',
  prod: 'voar.volvo.com',
  prod_test: 'ctvas-front.azurewebsites.net',
}
const { apiUrl, trackingApiUrl, pingFederateLoginURL, newApiUrl } =
  (window.location.hostname == serverURLs.local) ? {
    apiUrl: DEV_API_URL,
    newApiUrl: NEW_DEV_API_URL,
    trackingApiUrl: PROD_TRACKING_API_URL,
    pingFederateLoginURL: PROD_FEDERATE_REDIRECT_URL
  }
  : (window.location.hostname == serverURLs.localHomolog) ? {
    apiUrl: DEV_API_URL,
    newApiUrl: NEW_DEV_API_URL,
    trackingApiUrl: DEV_TRACKING_API_URL,
    pingFederateLoginURL: PROD_FEDERATE_REDIRECT_URL
  }

  : (window.location.hostname == serverURLs.dev) ? {
    apiUrl: DEV_API_URL,
    newApiUrl: NEW_DEV_API_URL,
    trackingApiUrl: DEV_TRACKING_API_URL,
    pingFederateLoginURL: PROD_FEDERATE_REDIRECT_URL
  }

  : (window.location.hostname == serverURLs.staging) ? {
    apiUrl: STAGE_API_URL,
    newApiUrl: NEW_STAGE_API_URL,
    trackingApiUrl: STAGE_TRACKING_API_URL,
    pingFederateLoginURL: QA_FEDERATE_REDIRECT_URL
  }

  :  (window.location.hostname == serverURLs.prod || window.location.hostname == serverURLs.prod_test) ? {
    apiUrl: PROD_API_URL,
    newApiUrl: NEW_PROD_API_URL,
    trackingApiUrl: PROD_TRACKING_API_URL,
    pingFederateLoginURL: PROD_FEDERATE_REDIRECT_URL
  } : false

module.exports = {
  apiUrl,
  newApiUrl,
   trackingApiUrl,
   pingFederateLoginURL,
   adminEconomicGroup: 'a4bac509-064e-45f0-b78f-734b8b2826a6',
   adminDealership: 'c4a8b17f-bcde-406f-b464-864b5979435c',
   SENT_STAUTS: 1,
   ONCOURSE_STATUS: 2,
   REPAIRING_STATUS: 3,
   FINISHED_STATUS: 4,
   PAUSED_STATUS: 8,
   RESUMED_STATUS: 9,
  CANCELLED_STATUS: 10,
  mockPausedStatusFlag: false,
  levels: {
    VASManager: 'VASManager',
    dealerEmployee: 'DealerEmployee',
    dealerManager: 'DealerManager',
    economicGroupManager: 'EconomicGroupManager',
    CountryVasManager: 'CountryVasManager',
  },
  serviceCancelReasons: {
    cancelledByCustomer: 0,
    vehicleTowed: 1,
    inAppIssues: 2,
    appNotUsedInTheService: 3,
    others: 4
  }
 }