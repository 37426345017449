
export default {
	'pt': {
		'header': {
			'greetings': 'Bem vindo, ',
			'services': 'Atendimentos',
			'list': 'Lista',
			'dashboard': 'Dashboard',
			'kanban': 'Kanban',
			'employees': 'Funcionários',
			'dealerships': 'Concessionárias',
			'economicGroups': 'Grupos Econômicos',
			'reasonsCancellations': 'Motivos de Cancelamento',
			'logout': 'Sair',
			'map': 'Mapa',
			'checkStatus': 'Acompanhar Status',
			'currentService': 'Visualizar Atendimento',
			'br': 'Português',
			'es': 'Espanhol'
		},
		'services': {
			'service': 'Atendimento | Atendimentos',
			'mercuriusCase': 'Caso Mercurius',
			'status': 'Status',
			'substatus': 'Substatus',
			'period': 'Período',
			'last24h': 'Últimas 24 horas',
			'last48h': 'Últimas 48 horas',
			'last72h': 'Últimas 72 horas',
			'lastMonth': 'Último Mês',
			'periodBetween': 'Período entre',
			'dealership': 'Concessionária | Concessionárias',
			'searchService': 'Procurar por atendimentos, Caso Mercurius ou nome da concessionária',
			'customer': 'Cliente',
			'addNew': 'Adicionar Novo',
			'start': 'Início',
			'end': 'Fim',
			'reset': 'Limpar',
			'economicGroup': 'Grupo Econômico',
			'reasonsCancellations': 'Motivos de Cancelamento',
			'firstContact': '1º Contato',
			'plate': 'Placa',
			'chassi': 'Chassi | Chassis',
			'localization': 'Localização',
			'observation': 'Observação',
			'contract': 'Contrato',
			'contractGold': 'Contrato Plano Ouro?',
			'distanceToClient': 'Distancia até o cliente',
			'estimatedDistance': 'Deslocamento estimado',
			'travelledDistance': 'Distância Percorrida',
			'seeOnMap': 'Ver no Mapa',
			'history': 'Histórico',
			'mapProblem': 'Houve um problema para renderizar o mapa desse atendimento: por favor, cheque se todos os endereços correspondem à localizações válidas no google maps.',
			'cancelService': 'Cancelar Atendimento',
			'changeStatus': 'Alterar Status',
			'technicianInCharge': 'Técnico responsável pelo atendimento',
			'contact': 'Contato',
			'finalTravelledDistance': 'Deslocamento percorrido',
			'vehicleModel': 'Modelo do veículo utilizado',
			'serviceReason': 'Causa do atendimento',
			'serviceConclusion': 'Conclusão do atendimento',
			'serviceNotes': 'Observação do atendimento',
			'reason': 'Motivo',
			'newServicesNotificationTitle': 'Novos atendimentos!',
			'newServicesNotificationBody': 'Há novos atendimentos ou atualizações de status.',
			'numberServices': 'Número total de casos',
			'numberCancelServices': 'Número total de cancelados',
			'cases': 'casos',
			'percentageCancelServices': '% do total de casos',
			'cancelByClient': 'Cancelado pelo cliente',
			'towedCar': 'Veículo será guinchado',
			'problemsInApp': 'Problemas no aplicativo',
			'noAppInService': 'Aplicativo não utilizado no atendimento',
			'others': 'Outro(s)',
			'waiver': 'Desistência',
			'towCar': 'Guincho',
			'failedApp': 'Falha no app',
			'notUsedApp': 'App não usado',
			'canceledService': 'Atendimento cancelado com sucesso!',
			'editedStatus': 'Status alterado com sucesso!',
			'problemRequest': 'Houve um erro ao processar essa requisição!',
			'cancelClientSide': 'Cancelamento pelo cliente',
			'filterService': 'Procurar Por Atendimento',
			'reasonCancelService': 'Qual o motivo do cancelamento do atendimento?',
			'whichStatus': 'Qual o status correto?',
			'archiveService': 'O Atendimento foi arquivado com sucesso!',
			'timeArrive': 'Tempo previsto de chegada',
			'vehicle': 'Veículo'
		},
		'economicGroups' : {
			'economicGroup': 'Grupo Econômico | Grupos Econômicos',
			'searchEconomicGroup': 'Procurar por Grupo Econômico',
			'addGroup': 'Adicionar Grupo',
			'addEconomicGroup': 'Adicionar Grupo Econômico',
			'name': 'Nome',
			'deleteGroup': 'Deletar o grupo econômico é uma operação irreversível.',
			'editEconomicGroup': 'Editar Grupo Econômico'
		},
		'reasonsCancellations' : {
			'reasonCancellation': 'Motivo de Cancelamento | Motivos de Cancelamento',
			'searchReasonCancellation': 'Procurar por Motivos',
			'addReason': 'Adicionar Novo',
			'addReasonsCancellation': 'Adicionar Motivo de Cancelamento',
			'name': 'Nome',
			'createDate': 'Data da Criação',
			'active': 'Status',
			'localization' : 'Localização',
			'addDescription': 'Adicione os motivos para o cancelamento tanto em português quanto em espanhol',
		},
		'dealerships' : {
			'theDealership': 'a concessionária',
			'dealership': 'Concessionária | Concessionárias',
			'searchDealership': 'Procurar por Concessionária',
			'addNew': 'Adicionar Nova',
			'name': 'Nome',
			'localization' : 'Localização',
			'dealershipName': 'Nome da Concessionária',
			'economicGroup': 'Grupo Econômico | Grupos Econômicos',
			'addDealership': 'Adicionar Concessionária',
			'editDealership': 'Editar Concessionária'
		},
		'employees' : {
			'employee': 'Funcionário | Funcionários',
			'searchEmployee': 'Procurar por Funcionário',
			'volvoId': 'Volvo ID',
			'dealership': 'Concessionária | Concessionárias',
			'dealershipOrEconomicGroup': 'Concessionária ou Grupo Econômico',
			'level': 'Nível',
			'addNew': 'Adicionar Novo',
			'name': 'Nome',
			'status': 'Status',
			'addEmployee': 'Adicionar Funcionário',
			'editEmployee': 'Editar Funcionário'
		},
		'trackings' : {
			'firstContact': 'Primeiro Contato com o Cliente',
			'departure': 'Saiu para Atendimento',
			'finished': 'Finalizado',
			'sentService': 'Atendimentos Enviados',
			'startRepair': 'Reparos Iniciados',
			'stopRepair': 'Reparo Pausado',
			'firstContactClient': 'Primeiro Contato do Cliente',
			'undefinedStatus': 'Status Indefinido: ',
			'delayService': 'Houve um atraso',
			'quitService': 'Desistiu do atendimento',
			'closer': 'Está próximo'
		},

		'misc': {
			'noData': 'Sem dados disponíveis',
			'back': 'Voltar',
			'add' : 'Adicionar',
			'edit' : 'Editar',
			'cancel' : 'Cancelar',
			'ok' : 'ok',
			'latitude': "Latitude",
			'longitude': "Longitude",
			'confirm' : 'Confirmar',
			'select': 'Selecionar',
			'date' : 'Data',
			'delete' : 'Excluir',
			'block' : 'Bloquear',
			'time' : 'Hora',
			'print' : 'Imprimir',
			'search': 'Pesquisar',
			'areYouSure' : 'Tem certeza?',
			'askForDelete' : 'Deletar {theEntity} é uma operação irreversível.',
			'askForBlock' : 'Bloquear {theEntity} é uma operação irreversível.',
			'alertMessageEditMale' : ' editado com sucesso!',
			'alertMessageInsertMale' : ' inserido com sucesso!',
			'alertMessageDeleteMale' : ' deletado com sucesso!',
			'alertMessageBlockMale' : ' bloqueado com sucesso!',
			'alertMessageEditFemale' : ' editada com sucesso!',
			'alertMessageInsertFemale' : ' inserida com sucesso!',
			'alertMessageDeleteFemale' : ' deletada com sucesso!',
			'update': 'Atualizar',
			'export': 'Exportar',
			'expand': 'Expandir',
			'validateInput': 'Esse campo é obrigatório!',
			'latlonError': 'Deve ser uma lat/lng válida (ex: 99.999999)',
			'commonError': 'Houve um erro para realizar essa operação!',
			'country': 'País',
			'countryBR': 'Brasil',
			'countryAR': 'Argentina',
			'countryCL': 'Chile',
			'countryPE': 'Peru'
		},
		'footerStatus' : {
			'report': 'Enviado',
			'start': 'A caminho',
			'end': 'Finalizado',
			'mechanic': 'Mecânico',
			'departure': 'Partida',
			'repairing': 'Em reparo',
			'cancel': 'Cancelado',
			'paused': 'Pausado',
			'resumed': 'Retomado',
			'progress': 'Em Andamento',
			'started': 'Iniciado'
		},
		employeeLevels: {
			'VASManager': 'Gestor do VOAR / VAS / VOLAR',
			'DealerEmployee': 'Mecânico',
			'DealerManager': 'Gestor do Concessionário ',
			'EconomicGroupManager': 'Gestor do Grupo Econômico ',
			'CountryVasManager': 'Gestor do Mercado'
		},
		pageNotFound: {
			'title': 'Página não encontrada',
			'text': 'A página que voce está tentando acessar não existe ou você não possui acesso.',
			'button': 'Voltar'
		},
		pageFollowService: {
			'greetings': 'Olá,',
			'followService': 'Acompanhe aqui o andamento do seu atendimento',
			'numberFollow': 'Anote o número do seu atendimento:',
			'employeeService': 'Você será atendido pelo técnico',
			'dealership': 'da concessionária',
			'distance': 'que está a',
			'localization': 'da sua localização.',
			'contact': 'Se precisar entrar em contato com o técnico da concessionária, ligue para',
			'voarNumber': ', ou se quiser falar com a Volvo, ligue',
			'voar': 'Se quiser falar com a Volvo, ligue',
			'towCar': 'O veículo será guinchado. Caso precise falar com a Volvo, ligue',
			'withoutService': 'Nenhum atendimento encontrado.',
			'serviceOpen': 'Atendimento aberto',
			'preparing': 'Em preparação',
			'employee': 'Técnico a caminho',
			'finishService': 'Atendimento finalizado',
			'cancelService': 'Atendimento cancelado',
			'textTerms': 'Caso prossiga, você está concordando com a Política Geral de Privacidade da Volvo.',
			'buttonTerms': 'Política de Privacidade',
			'buttonConfirm': 'Prosseguir'
		}

	},
	'en': {
		'header': {
			'greetings': 'Welcome, ',
			'services': 'Services',
			'list': 'List',
			'dashboard': 'Dashboard',
			'kanban': 'Kanban',
			'employees': 'Employees',
			'dealerships': 'Dealerships',
			'economicGroups': 'Economic Groups',
			'reasonsCancellations': 'Reasons for Cancellation',
			'logout': 'Logout',
			'map': 'Map',
			'checkStatus': 'Check Status',
			'currentService': 'View Service',
			'br': 'Portuguese',
			'es': 'Spanish'
		},
		'services': {
			'service': 'Service | Services',
			'mercuriusCase': 'Mercurius Case',
			'status': 'Status',
			'substatus': 'Substatus',
			'period': 'Period',
			'last24h': 'Last 24 hours',
			'last48h': 'Last 48 hours',
			'last72h': 'Last 72 hours',
			'lastMonth': 'Last month',
			'periodBetween': 'Period between',
			'dealership': 'Dealership | Dealerships',
			'searchService': 'Search for Services',
			'customer': 'Customer',
			'addNew': 'Add New',
			'start': 'Start',
			'end': 'End',
			'reset': 'Reset',
			'economicGroup': 'Economic Group',
			'firstContact': '1º Contact',
			'plate': 'Plate',
			'chassi': 'Chassi | Chassis',
			'localization': 'Localization',
			'observation': 'Observation',
			'contract': 'Contract',
			'contractGold': 'Gold Plan Contract?',
			'distanceToClient': 'Distance to Client',
			'estimatedDistance': 'Estimated Distance',
			'travelledDistance': 'Travelled Distance',
			'seeOnMap': 'See on Map',
			'history': 'History',
			'mapProblem': 'There were a problem on map renderizing for this service: please check if all the addresses are valid localizations on google maps.',
			'cancelService': 'Cancel Service',
			'changeStatus': 'Change Status',
			'technicianInCharge': 'Technician in charge of this service',
			'contact': 'Contact',
			'finalTravelledDistance': 'Travelled distance',
			'vehicleModel': 'Model of vehicle used',
			'serviceReason': 'Service reason',
			'serviceConclusion': 'Service conclusion',
			'serviceNotes': 'Service notes',
			'reason': 'Reason',
			'newServicesNotificationTitle': 'New services!',
			'newServicesNotificationBody': 'There are new services or status updates.',
			'numberServices': 'Total number of cases',
			'numberCancelServices': 'Total number of canceled',
			'cases': 'cases',
			'percentageCancelServices': '% of total cases',
			'cancelByClient': 'Canceled by customer',
			'towedCar': 'Vehicle will be towed',
			'problemsInApp': 'Application Problems',
			'noAppInService': 'Application not used in service',
			'others': 'Others',
			'waiver': 'Waiver',
			'towCar': 'Tow Car',
			'failedApp': 'Failed App',
			'notUsedApp': 'Not Used App',
			'canceledService': 'Service canceled successfully!',
			'editedStatus': 'Status changed successfully!',
			'problemRequest': 'There was an error processing this request!',
			'cancelClientSide': 'Cancellation by customer',
			'filterService': 'Search for Services',
			'reasonCancelService': 'What is the reason for canceling the service?',
			'whichStatus': 'What is the correct status?',
			'archiveService': 'Service was archived successfully!',
			'timeArrive': 'Estimated time of arrival',
			'vehicle': 'Vehicle'
		},
		'economicGroups' : {
			'economicGroup': 'Economic Group | Economic Groups',
			'searchEconomicGroup': 'Search for Economic Group',
			'addGroup': 'Add Group',
			'addEconomicGroup': 'Add Economic Group',
			'name': 'Name',
			'deleteGroup': 'Delete the economic group is an irreversible operation.',
			'editEconomicGroup': 'Edit Economic Group'
		},
		'reasonsCancellations' : {
			'reasonCancellation': 'Cancellation Reason | Reasons for Cancellation',
			'searchReasonCancellation': 'Search for Reasons',
			'addReason': 'Add New',
			'addReasonsCancellation': 'Add Cancellation Reason',
			'name': 'Name',
			'createDate': 'Creation Date',
			'active': 'Status',
			'localization' : 'Location',
			'editReasonsCancellation': 'Edit Cancellation Reason',
			'addDescription': 'Add reasons for cancellation in both Portuguese and Spanish',
		},
		'dealerships' : {
			'theDealership': 'the dealership',
			'dealership': 'Dealership | Dealerships',
			'searchDealership': 'Search for Dealership',
			'addNew': 'Add New',
			'name': 'Name',
			'localization' : 'Localization',
			'dealershipName': 'Dealership\'s name',
			'economicGroup': 'Economic Group | Economic Groups',
			'addDealership': 'Add Dealership',
			'editDealership': 'Edit Dealership'
		},
		'employees' : {
			'employee': 'Employee | Employees',
			'searchEmployee': 'Search for Employee',
			'volvoId': 'Volvo ID',
			'dealership': 'Dealership | Dealerships',
			'dealershipOrEconomicGroup': 'Dealership or Economic Group',
			'level': 'Level',
			'addNew': 'Add New',
			'name': 'Name',
			'status': 'Status',
			'addEmployee': 'Add Employee',
			'editEmployee': 'Edit Employee'
		},
		'trackings' : {
			'firstContact': 'First Contact with Customer',
			'departure': 'Departured',
			'finished': 'Finished',
			'sentService': 'Calls Sent',
			'startRepair': 'Repairs Initiated',
			'stopRepair': 'Repair Paused',
			'firstContactClient': "Customer's First Contact",
			'undefinedStatus': 'Undefined Status: ',
			'delayService': 'There was a delay',
			'quitService': 'Withdrew from the service',
			'closer': "It's close"
		},

		'misc': {
			'noData': 'No data available',
			'back': 'Back',
			'add' : 'Add',
			'edit' : 'Edit',
			'cancel' : 'Cancel',
			'ok' : 'ok',
			'latitude': "Latitude",
			'longitude': "Longitude",
			'confirm' : 'Confirm',
			'select': 'Select',
			'date' : 'Date',
			'delete' : 'Delete',
			'block' : 'Block',
			'time' : 'Time',
			'print' : 'Print',
			'search': 'Search',
			'areYouSure' : 'Are you sure?',
			'askForDelete' : 'Delete {theEntity} is an irreversible operation.',
			'askForBlock' : 'Block {theEntity} is an irreversible operation.',
			'alertMessageEditMale' : ' edited successfully!',
			'alertMessageInsertMale' : ' inserted successfully!',
			'alertMessageDeleteMale' : ' deleted successfully!',
			'alertMessageBlockMale' : ' blocked successfully!',
			'alertMessageEditFemale' : ' edited successfully!',
			'alertMessageInsertFemale' : ' inserted successfully!',
			'alertMessageDeleteFemale' : ' deleted successfully!',
			'update': 'Update',
			'export': 'Export',
			'expand': 'Expand',
			'validateInput': 'This input is mandatory!',
			'latlonError': 'Must be a valid lat/lng (ex: 99.999999)',
			'commonError': 'There was an error performing this operation!',
			'country': 'Country',
			'countryBR': 'Brazil',
			'countryAR': 'Argentina',
			'countryCL': 'Chile',
			'countryPE': 'Peru'
		},
		'footerStatus' : {
			'report': 'Departured',
			'start': 'On going',
			'end': 'Finished',
			'repairing': 'Repairing',
			'mechanic': 'Mechanic',
			'departure': 'Departure',
			'cancel': 'Canceled',
			'paused': 'Paused',
			'resumed': 'Resumed',
			'progress': 'In progress',
			'started': 'Started'
		},
		employeeLevels: {
			'VASManager': 'VOAR / VAS / VOLAR Manager',
			'DealerEmployee': 'Dealer Employee',
			'DealerManager': 'Dealer Manager',
			'EconomicGroupManager': 'Economic Group Manager',
			'CountryVasManager': 'Market Manager'
		},
		pageNotFound: {
			'title': 'Page not found',
			'text': 'The page you are trying to access does not exist or you do not have access.',
			'button': 'Back'
		},
		pageFollowService: {
			'greetings': 'Hello,',
			'followService': 'Follow the progress of your service here',
			'numberFollow': 'Write down your service number:',
			'employeeService': 'You will be attended by the technician',
			'dealership': 'of the dealership',
			'distance': 'which is the',
			'localization': 'of your location.',
			'contact': 'If you need to contact the dealership technician, call',
			'voarNumber': ', or if you want to talk to Volvo, call',
			'voar': 'If you want to talk to Volvo, call',
			'towCar': 'The vehicle will be towed away. If you need to talk to Volvo, call',
			'withoutService': 'No services found.',
			'serviceOpen': 'Open service',
			'preparing': 'In preparation',
			'employee': 'Technician on the way',
			'finishService': 'Service completed',
			'cancelService': 'Service canceled',
			'textTerms': "If you proceed, you are agreeing to Volvo's General Privacy Policy.",
			'buttonTerms': 'Privacy Policy',
			'buttonConfirm': 'Proceed'
		}
	},
	'es': {
		'header': {
			'greetings': 'Bienvenido, ',
			'services': 'Asistencias',
			'list': 'Lista',
			'dashboard': 'Dashboard',
			'kanban': 'Kanban',
			'employees': 'Empleados',
			'dealerships': 'Concesionarios',
			'economicGroups': 'Grupos económicos',
			'reasonsCancellations': 'Motivos de cancelacion',
			'logout': 'Salir',
			'map': 'Mapa',
			'checkStatus': 'Acompañar Status',
			'currentService': 'Ver Servicio',
			'br': 'Portugués',
			'es': 'Español'
		},
		'services': {
			'service': 'Atención al cliente | Asistencias',
			'mercuriusCase': 'Numero Asistencia',
			'status': 'Status',
			'substatus': 'Substatus',
			'period': 'Período',
			'last24h': 'Últimas 24 horas',
			'last48h': 'Últimas 48 horas',
			'last72h': 'Últimas 72 horas',
			'lastMonth': 'El mes pasado',
			'periodBetween': 'Periodo entre',
			'dealership': 'Concesionario | Concesionarios',
			'searchService': 'Búsqueda de asistencias',
			'customer': 'Cliente',
			'addNew': 'Agregar Nuevo',
			'start': 'Inicio',
			'end': 'Fin',
			'reset': 'Borrar',
			'economicGroup': 'Grupo económico',
			'reasonsCancellations': 'Motivos de Cancelacion',
			'firstContact': '1º Contacto',
			'plate': 'Plato',
			'chassi': 'Chassi | Chassis',
			'localization': 'Localización',
			'observation': 'Observación',
			'contract': 'Contrato',
			'contractGold': '¿Contrato del Plan Gold?',
			'distanceToClient': 'Distancia al cliente',
			'estimatedDistance': 'Distancia Estimada',
			'travelledDistance': 'Distancia Recorrida',
			'seeOnMap': 'Ver en el mapa',
			'history': 'Histórico',
			'mapProblem': 'Hubo un problema en la representación del mapa para este servicio: verifique si todas las direcciones son localizaciones válidas en Google Maps.',
			'cancelService': 'Cancelar Servicio',
			'changeStatus': 'Cambiar Estado',
			'technicianInCharge': 'Técnico responsable del servicio',
			'contact': 'Contacto',
			'finalTravelledDistance': 'Distancia recorrida',
			'vehicleModel': 'Modelo de vehículo utilizado',
			'serviceReason': 'Causa del servicio',
			'serviceConclusion': 'Conclusión del servicio',
			'serviceNotes': 'Notas de servicio',
			'reason': 'Razón',
			'newServicesNotificationTitle': '¡Nuevos servicios!',
			'newServicesNotificationBody': 'Hay nuevos servicios o actualizaciones de estado.',
			'numberServices': 'Número total de casos',
			'numberCancelServices': 'Número total de canceladas',
			'cases': 'casos',
			'percentageCancelServices': '% del total de casos',
			'cancelByClient': 'Cancelado por el cliente',
			'towedCar': 'El vehículo será remolcado',
			'problemsInApp': 'Problemas de aplicación',
			'noAppInService': 'Aplicación no utilizada en servicio',
			'others': 'Otros',
			'waiver': 'Exención',
			'towCar': 'Cabrestante',
			'failedApp': 'Bloqueo de el App',
			'notUsedApp': 'App no utilizado',
			'canceledService': '¡Servicio cancelado con éxito!',
			'editedStatus': '¡El status cambió con éxito!',
			'problemRequest': 'Se produjo un error al procesar esta solicitud!',
			'cancelClientSide': 'Cancelación por parte del cliente',
			'filterService': 'Búsqueda de asistencias',
			'reasonCancelService': '¿Cuál es el motivo de la cancelación del servicio?',
			'whichStatus': '¿Cuál es el status correcto?',
			'archiveService': '¡El servicio se presentó con éxito!',
			'timeArrive': 'Tiempo estimado de llegada',
			'vehicle': 'Vehículo'
		},
		'economicGroups' : {
			'economicGroup': 'Grupo Económico | Grupos Económicos',
			'searchEconomicGroup': 'Búsqueda por Grupo Económico',
			'addGroup': 'Agregar Grupo',
			'addEconomicGroup': 'Agregar Grupo Económico',
			'name': 'Nombre',
			'deleteGroup': 'Eliminar el grupo económico es una operación irreversible.',
			'editEconomicGroup': 'Editar Grupo Económico'
		},
		'reasonsCancellations' : {
			'reasonCancellation': 'Motivo Cancelacione | Motivo Cancelaciones',
			'searchReasonCancellation': 'Búsqueda por Razones',
			'addReason': 'Agregar Nuevo',
			'addReasonsCancellation': 'Agregar Motivo Cancelacione',
			'name': 'Nombre',
			'createDate': 'Fecha de creación',
			'active': 'Estado',
			'localization' : 'Ubicación',
			'editReasonsCancellation': 'Editar motivo de cancelación',
			'addDescription': 'Agregar motivos de cancelación en portugués y español'
		},
		'dealerships' : {
			'theDealership': 'el concesionario',
			'dealership': 'Concesionario | Concesionarios',
			'searchDealership': 'Buscar por Concesionario',
			'addNew': 'Agregar Nuevo',
			'name': 'Agregar Grupo',
			'localization' : 'Localización',
			'dealershipName': 'Nombre del concesionario',
			'economicGroup': 'Grupo Económico | Grupos Económicos',
			'addDealership': 'Agregar Concesionario',
			'editDealership': 'Editar Concesionario'
		},
		'employees' : {
			'employee': 'Empleado | Empleados',
			'searchEmployee': 'Buscar Empleado',
			'volvoId': 'Volvo ID',
			'dealership': 'Concesionario | Concesionarios',
			'dealershipOrEconomicGroup': 'Concesionario o Grupo Económico',
			'level': 'Nivel',
			'addNew': 'Agregar Nuevo',
			'name': 'Nombre',
			'status': 'Status',
			'addEmployee': 'Agregar Empleado',
			'editEmployee': 'Editar Empleado'
		},
		'trackings' : {
			'firstContact': 'Primer Contacto con el Cliente',
			'departure': 'Salida para el servicio',
			'finished': 'Terminado',
			'sentService': 'Asistencias enviadas',
			'startRepair': 'Reparaciones Iniciadas',
			'stopRepair': 'Reparación en Pausa',
			'firstContactClient': 'Primer contacto del cliente',
			'undefinedStatus': 'Status Indefinido: ',
			'delayService': 'Hubo una demora',
			'quitService': 'Se retiró del servicio',
			'closer': 'Está cerca'
		},

		'misc': {
			'noData': 'No hay datos disponibles',
			'back': 'Volver',
			'add' : 'Agregar',
			'edit' : 'Editar',
			'cancel' : 'Cancelar',
			'ok' : 'ok',
			'latitude': "Latitud",
			'longitude': "Longitud",
			'confirm' : 'Confirmar',
			'select': 'Seleccionar',
			'date' : 'Fecha',
			'delete' : 'Eliminar',
			'block' : 'Bloque',
			'time' : 'Tiempo',
			'print' : 'Imprimir',
			'search': 'Buscar',
			'areYouSure' : '¿Estás seguro?',
			'askForDelete' : 'Eliminar {theEntity} es una operación irreversible.',
			'askForBlock' : 'Bloquear {theEntity} es una operación irreversible.',
			'alertMessageEditMale' : ' ¡editado correctamente!',
			'alertMessageInsertMale' : ' ¡insertado correctamente!',
			'alertMessageDeleteMale' : ' ¡eliminado correctamente!',
			'alertMessageBlockMale' : ' ¡bloqueado correctamente!',
			'alertMessageEditFemale' : ' ¡editada correctamente!',
			'alertMessageInsertFemale' : ' ¡insertada correctamente!',
			'alertMessageDeleteFemale' : ' ¡eliminada correctamente!',
			'update': 'Actualizar',
			'export': 'Exportar',
			'expand': 'Expandir',
			'validateInput': '¡Este campo es obligatorio!',
			'latlonError': 'Debe ser una lat / lng válida (por ejemplo: 99.999999)',
			'commonError': '¡Hubo un error al realizar esta operación!',
			'country': 'País',
			'countryBR': 'Brasil',
			'countryAR': 'Argentina',
			'countryCL': 'Chile',
			'countryPE': 'Perú'
		},
		'footerStatus' : {
			'report': 'Enviado',
			'start': 'En Camino',
			'end': 'Finalizado',
			'repairing': 'En reparación',
			'mechanic': 'Mecánico',
			'departure': 'Salida',
			'cancel': 'Cancelado',
			'paused': 'En pausa',
			'resumed': 'Reanudado',
			'progress': 'En proceso',
			'started': 'Empezado'
		},
		employeeLevels: {
			'VASManager': 'Gerente del VOAR / VAS / VOLAR',
			'DealerEmployee': 'Mecánico',
			'DealerManager': 'Gerente del Concesionario',
			'EconomicGroupManager': 'Gerente del Grupo Económico',
			'CountryVasManager': 'Gerente del Mercado'
		},
		pageNotFound: {
			'title': 'Página no encontrada',
			'text': 'La página a la que está intentando acceder no existe o no tiene acceso.',
			'button': 'Volver'
		},
		pageFollowService: {
			'greetings': 'Hola,',
			'followService': 'Siga el progreso de su servicio aquí',
			'numberFollow': 'Anote su número de servicio:',
			'employeeService': 'Serás atendido por el técnico',
			'dealership': 'del concesionario',
			'distance': 'cuál es el',
			'localization': 'de su ubicación.',
			'contact': 'Si necesita comunicarse con el técnico del concesionario, llame',
			'voarNumber': ', o si quieres hablar con Volvo, llama',
			'voar': 'Si quieres hablar con Volvo, llama',
			'towCar': 'El vehículo será remolcado. Si necesita hablar con Volvo, llame',
			'withoutService': 'No se encontraron llamadas.',
			'serviceOpen': 'Servicio abierto',
			'preparing': 'En la preparación de',
			'employee': 'Técnico en camino',
			'finishService': 'Servicio completado',
			'cancelService': 'Servicio cancelado',
			'textTerms': 'Si continúa, acepta la Política de privacidad general de Volvo.',
			'buttonTerms': 'Política de privacidad',
			'buttonConfirm': 'Continuar'
		}
	}
}