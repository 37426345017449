<template>
  <v-card>
    <v-card-title class="headline" ></v-card-title>
    <v-card-text class="confirm-cancel-modal__text">
      <v-icon x-large color="red">warning</v-icon>
      <p class="description">Confirme abaixo o motivo do cancelamento do atendimento</p>
      <span class="reason">{{ nameReason }}</span>
    </v-card-text>
    <v-card-actions class="confirm-cancel-modal__footer">
      <v-btn
        color="#35343d"
        class="confirm-cancel-modal__button btn-cancel"
        flat
        @click.native="$emit('back')"
        >{{ $t('misc.back') }}</v-btn>
      <v-btn
        class="confirm-cancel-modal__button vv-button vv-button-inverted btn-primary btn-confirm"
        @click.native="$emit('confirm', reason)"
        >{{ 'Confirmar ação' || $t('misc.confirm')  }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import config from '@/app.settings'
const CHARACTER_LIMIT = 140

export default {
  name: 'ConfirmarCancelamentoModal',
  data () {
    return {
      otherReason: '',
      cancelReasons: config.serviceCancelReasons,
    }
  },
  props: {
    reason: {
      type: Object
    }
  },
  computed: {
    nameReason(){
      return this.$vuetify.lang.current == 'pt' ? this.reason.descriptionPortugueseBr : this.reason.descriptionSpanishEs
    }
  },
}
</script>

<style lang="scss">
.confirm-cancel-modal__button {
  text-transform: none;
  letter-spacing: normal;
  &.btn-confirm {
    max-width: 300px !important;
  }
  &.btn-cancel {
    background-color: transparent;
    &:before {
      background-color: transparent;
    }
  }
  &.v-btn--disabled {
    border: none !important;
  }
}
.confirm-cancel-modal__footer {
  justify-content: center;
  padding-bottom: 25px;
}
.confirm-cancel-modal__text {
  text-align: center;
  .description {
    font-size: 16px;
    color: #35343D;
    margin-top: 15px;
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
  }
  .reason {
    font-size: 18px;
    color: #0F3299;
    font-weight: bold;
  }
  .v-textarea.v-text-field--enclosed textarea {
    margin-top: 12px;
  }
  .v-text-field--outline > .v-input__control > .v-input__slot {
    border: 1px solid #90A0D0;
    border-radius: 8px;
    &:hover {
      border: 1px solid #90A0D0 !important;
    }
  }
}
</style>