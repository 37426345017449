var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main-layout",
    [
      _vm.loaded
        ? _c(
            "v-layout",
            {
              staticClass: "vv-form",
              attrs: {
                "justify-center": "",
                row: "",
                wrap: "",
                id: "form-funcionarios"
              }
            },
            [
              _c("form-alert", {
                attrs: {
                  "alert-model": !!_vm.alertText,
                  "alert-text": _vm.alertText
                },
                on: {
                  input: function($event) {
                    return _vm.closeFormAlert()
                  }
                }
              }),
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c(
                    "div",
                    { staticClass: "vv-form-header" },
                    [
                      _c("base-voltar-button", {
                        attrs: { to: { path: "/funcionarios" } }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-layout",
                    {
                      staticClass: "white-background",
                      attrs: { row: "", wrap: "" }
                    },
                    [
                      _c(
                        "v-form",
                        { ref: "form" },
                        [
                          _c(
                            "v-flex",
                            { attrs: { xs6: "" } },
                            [
                              _c(
                                "v-layout",
                                { attrs: { row: "", wrap: "" } },
                                [
                                  [
                                    "EconomicGroupManager",
                                    "VASManager",
                                    "CountryVasManager"
                                  ].includes(_vm.employee.user.level)
                                    ? _c("v-flex", { attrs: { xs12: "" } }, [
                                        _c(
                                          "select",
                                          {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.selectedEmployeeLevel,
                                                expression:
                                                  "selectedEmployeeLevel"
                                              }
                                            ],
                                            staticClass: "vv-input form-select",
                                            attrs: {
                                              label: _vm.$t("employees.level")
                                            },
                                            on: {
                                              change: function($event) {
                                                var $$selectedVal = Array.prototype.filter
                                                  .call(
                                                    $event.target.options,
                                                    function(o) {
                                                      return o.selected
                                                    }
                                                  )
                                                  .map(function(o) {
                                                    var val =
                                                      "_value" in o
                                                        ? o._value
                                                        : o.value
                                                    return val
                                                  })
                                                _vm.selectedEmployeeLevel = $event
                                                  .target.multiple
                                                  ? $$selectedVal
                                                  : $$selectedVal[0]
                                              }
                                            }
                                          },
                                          [
                                            _c(
                                              "option",
                                              {
                                                staticClass: "vv-disabled",
                                                attrs: {
                                                  selected: "",
                                                  value: ""
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$tc("employees.level")
                                                  )
                                                )
                                              ]
                                            ),
                                            _vm._l(_vm.employeeLevels, function(
                                              employeeLevel,
                                              key
                                            ) {
                                              return _c(
                                                "option",
                                                {
                                                  key: key,
                                                  domProps: {
                                                    value: employeeLevel.id
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(employeeLevel.value)
                                                  )
                                                ]
                                              )
                                            })
                                          ],
                                          2
                                        )
                                      ])
                                    : _vm._e(),
                                  ["VASManager", "CountryVasManager"].includes(
                                    _vm.employee.user.level
                                  ) &&
                                  _vm.selectedEmployeeLevel ==
                                    "CountryVasManager"
                                    ? _c("v-flex", { attrs: { xs12: "" } }, [
                                        _c(
                                          "select",
                                          {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.selectedEmployeeCountry,
                                                expression:
                                                  "selectedEmployeeCountry"
                                              }
                                            ],
                                            staticClass: "vv-input form-select",
                                            attrs: {
                                              label: _vm.$t("misc.country")
                                            },
                                            on: {
                                              change: function($event) {
                                                var $$selectedVal = Array.prototype.filter
                                                  .call(
                                                    $event.target.options,
                                                    function(o) {
                                                      return o.selected
                                                    }
                                                  )
                                                  .map(function(o) {
                                                    var val =
                                                      "_value" in o
                                                        ? o._value
                                                        : o.value
                                                    return val
                                                  })
                                                _vm.selectedEmployeeCountry = $event
                                                  .target.multiple
                                                  ? $$selectedVal
                                                  : $$selectedVal[0]
                                              }
                                            }
                                          },
                                          [
                                            _c(
                                              "option",
                                              {
                                                staticClass: "vv-disabled",
                                                attrs: {
                                                  selected: "",
                                                  value: ""
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$tc("misc.country")
                                                  )
                                                )
                                              ]
                                            ),
                                            _vm._l(_vm.countries, function(
                                              country,
                                              key
                                            ) {
                                              return _c(
                                                "option",
                                                {
                                                  key: key,
                                                  domProps: {
                                                    value: country.id
                                                  }
                                                },
                                                [_vm._v(_vm._s(country.value))]
                                              )
                                            })
                                          ],
                                          2
                                        )
                                      ])
                                    : _vm._e(),
                                  ["EconomicGroupManager"].includes(
                                    _vm.employee.user.level
                                  ) ||
                                  (["VASManager"].includes(
                                    _vm.employee.user.level
                                  ) &&
                                    _vm.selectedEmployeeLevel &&
                                    _vm.selectedEmployeeLevel !=
                                      "EconomicGroupManager" &&
                                    _vm.selectedEmployeeLevel != "VASManager" &&
                                    _vm.selectedEmployeeLevel !=
                                      "CountryVasManager" &&
                                    _vm.selectedEmployeeLevel !=
                                      "DealerManager")
                                    ? _c(
                                        "v-flex",
                                        { attrs: { xs12: "" } },
                                        [
                                          _c("v-select", {
                                            attrs: {
                                              label: _vm.$tc(
                                                "employees.dealership",
                                                1
                                              ),
                                              multiple: "",
                                              items: _vm.dealerships,
                                              "item-text": "name",
                                              "item-value": "id",
                                              rules: [
                                                function(value) {
                                                  return (
                                                    value.length > 0 ||
                                                    _vm.$t("misc.validateInput")
                                                  )
                                                }
                                              ]
                                            },
                                            model: {
                                              value:
                                                _vm.selectedDealershipMultiple,
                                              callback: function($$v) {
                                                _vm.selectedDealershipMultiple = $$v
                                              },
                                              expression:
                                                "selectedDealershipMultiple"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  ["EconomicGroupManager"].includes(
                                    _vm.employee.user.level
                                  ) ||
                                  (["VASManager"].includes(
                                    _vm.employee.user.level
                                  ) &&
                                    _vm.selectedEmployeeLevel &&
                                    _vm.selectedEmployeeLevel !=
                                      "EconomicGroupManager" &&
                                    _vm.selectedEmployeeLevel != "VASManager" &&
                                    _vm.selectedEmployeeLevel !=
                                      "CountryVasManager" &&
                                    _vm.selectedEmployeeLevel !=
                                      "DealerEmployee")
                                    ? _c("v-flex", { attrs: { xs12: "" } }, [
                                        _c(
                                          "select",
                                          {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.selectedDealership,
                                                expression: "selectedDealership"
                                              }
                                            ],
                                            staticClass: "vv-input form-select",
                                            attrs: {
                                              label: _vm.$tc(
                                                "employees.dealership",
                                                1
                                              )
                                            },
                                            on: {
                                              change: function($event) {
                                                var $$selectedVal = Array.prototype.filter
                                                  .call(
                                                    $event.target.options,
                                                    function(o) {
                                                      return o.selected
                                                    }
                                                  )
                                                  .map(function(o) {
                                                    var val =
                                                      "_value" in o
                                                        ? o._value
                                                        : o.value
                                                    return val
                                                  })
                                                _vm.selectedDealership = $event
                                                  .target.multiple
                                                  ? $$selectedVal
                                                  : $$selectedVal[0]
                                              }
                                            }
                                          },
                                          [
                                            _c(
                                              "option",
                                              {
                                                staticClass: "vv-disabled",
                                                attrs: {
                                                  selected: "",
                                                  value: ""
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$tc(
                                                      "services.dealership",
                                                      1
                                                    )
                                                  )
                                                )
                                              ]
                                            ),
                                            _vm._l(_vm.dealerships, function(
                                              dealership,
                                              key
                                            ) {
                                              return _c(
                                                "option",
                                                {
                                                  key: key,
                                                  domProps: {
                                                    value: dealership.id
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(dealership.name)
                                                  )
                                                ]
                                              )
                                            })
                                          ],
                                          2
                                        )
                                      ])
                                    : _vm._e(),
                                  ["VASManager", "CountryVasManager"].includes(
                                    _vm.employee.user.level
                                  ) &&
                                  _vm.selectedEmployeeLevel &&
                                  _vm.selectedEmployeeLevel ==
                                    "EconomicGroupManager"
                                    ? _c("v-flex", { attrs: { xs12: "" } }, [
                                        _c(
                                          "select",
                                          {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.selectedEconomicGroup,
                                                expression:
                                                  "selectedEconomicGroup"
                                              }
                                            ],
                                            staticClass: "vv-input form-select",
                                            attrs: {
                                              label: _vm.$tc(
                                                "services.economicGroup",
                                                1
                                              )
                                            },
                                            on: {
                                              change: function($event) {
                                                var $$selectedVal = Array.prototype.filter
                                                  .call(
                                                    $event.target.options,
                                                    function(o) {
                                                      return o.selected
                                                    }
                                                  )
                                                  .map(function(o) {
                                                    var val =
                                                      "_value" in o
                                                        ? o._value
                                                        : o.value
                                                    return val
                                                  })
                                                _vm.selectedEconomicGroup = $event
                                                  .target.multiple
                                                  ? $$selectedVal
                                                  : $$selectedVal[0]
                                              }
                                            }
                                          },
                                          [
                                            _c(
                                              "option",
                                              {
                                                staticClass: "vv-disabled",
                                                attrs: {
                                                  selected: "",
                                                  value: ""
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$tc(
                                                      "services.economicGroup",
                                                      1
                                                    )
                                                  )
                                                )
                                              ]
                                            ),
                                            _vm._l(_vm.economicGroups, function(
                                              economicGroup,
                                              key
                                            ) {
                                              return _c(
                                                "option",
                                                {
                                                  key: key,
                                                  domProps: {
                                                    value: economicGroup.id
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(economicGroup.name)
                                                  )
                                                ]
                                              )
                                            })
                                          ],
                                          2
                                        )
                                      ])
                                    : _vm._e(),
                                  _c(
                                    "v-flex",
                                    { attrs: { xs12: "" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          label: _vm.$t("employees.volvoId"),
                                          required: "",
                                          rules: [
                                            function(value) {
                                              return (
                                                !!value ||
                                                _vm.$t("misc.validateInput")
                                              )
                                            }
                                          ]
                                        },
                                        model: {
                                          value: _vm.model.user.volvoId,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.model.user,
                                              "volvoId",
                                              $$v
                                            )
                                          },
                                          expression: "model.user.volvoId"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    { attrs: { xs12: "" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          label: _vm.$t("employees.name"),
                                          required: "",
                                          rules: [
                                            function(value) {
                                              return (
                                                !!value ||
                                                _vm.$t("misc.validateInput")
                                              )
                                            }
                                          ]
                                        },
                                        model: {
                                          value: _vm.model.user.name,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.model.user,
                                              "name",
                                              $$v
                                            )
                                          },
                                          expression: "model.user.name"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-layout",
        { staticClass: "vv-form-button", attrs: { row: "", wrap: "" } },
        [
          _c("v-spacer"),
          _c("v-flex", { attrs: { xs4: "" } }),
          _vm.$route.params.id && !_vm.model.user.deleted
            ? _c("v-flex", { attrs: { xs4: "" } }, [
                _c(
                  "button",
                  {
                    staticClass:
                      "btn-primary vv-button vv-button vv-adicionar-novo-button",
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        _vm.dialog = true
                      }
                    }
                  },
                  [_c("span", [_vm._v(_vm._s(_vm.$t("misc.block")))])]
                )
              ])
            : _vm._e(),
          _c("v-flex", { attrs: { xs4: "" } }, [
            _c(
              "button",
              {
                staticClass:
                  "btn-primary vv-button vv-button-inverted vv-adicionar-novo-button",
                on: {
                  click: function($event) {
                    return _vm.save()
                  }
                }
              },
              [
                _c("span", [
                  _vm._v(
                    _vm._s(
                      !_vm.$route.params.id
                        ? _vm.$t("misc.add")
                        : _vm.$t("misc.edit")
                    ) +
                      " " +
                      _vm._s(_vm.$tc("employees.employee", 1))
                  )
                ])
              ]
            )
          ])
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "290" },
          model: {
            value: _vm.dialog,
            callback: function($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog"
          }
        },
        [
          _c(
            "base-modal",
            {
              attrs: { title: _vm.$t("misc.areYouSure") },
              on: {
                cancel: function($event) {
                  _vm.dialog = false
                },
                confirm: function($event) {
                  _vm.dialog = false
                  _vm.remove()
                }
              }
            },
            [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.$t("misc.askForBlock", {
                      theEntity: _vm.$tc("employees.employee", 1)
                    })
                  ) +
                  "\n      "
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }