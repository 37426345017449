var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main-layout",
    [
      _c(
        "v-layout",
        {
          staticClass: "vv-form",
          attrs: { "justify-center": "", row: "", wrap: "" }
        },
        [
          _c("form-alert", {
            attrs: {
              "alert-model": !!_vm.alertText,
              "alert-text": _vm.alertText
            },
            on: {
              input: function($event) {
                return _vm.closeFormAlert()
              }
            }
          }),
          _c(
            "v-flex",
            { attrs: { xs12: "" } },
            [
              _c("div", { staticClass: "vv-form-header" }, [
                _c(
                  "div",
                  { staticClass: "vv-voltar-button" },
                  [
                    _c(
                      "router-link",
                      { attrs: { to: { path: "/grupos-economicos" } } },
                      [
                        _c("svg", { attrs: { viewBox: "0 0 24 24" } }, [
                          _vm._v("0\n                "),
                          _c("path", {
                            attrs: {
                              fill: "#0f3299",
                              d:
                                "M20,10V14H11L14.5,17.5L12.08,19.92L4.16,12L12.08,4.08L14.5,6.5L11,10H20Z"
                            }
                          })
                        ]),
                        _vm._v(_vm._s(_vm.$t("misc.back")) + "\n            ")
                      ]
                    )
                  ],
                  1
                )
              ]),
              _c(
                "v-layout",
                {
                  staticClass: "white-background",
                  attrs: { row: "", wrap: "" }
                },
                [
                  _c(
                    "v-form",
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs6: "" } },
                        [
                          _c("v-text-field", {
                            ref: "inputName",
                            attrs: {
                              label: _vm.$tc("economicGroups.economicGroup", 1),
                              required: "",
                              rules: [
                                function(value) {
                                  return !!value || _vm.$t("misc.validateInput")
                                }
                              ]
                            },
                            model: {
                              value: _vm.model.name,
                              callback: function($$v) {
                                _vm.$set(_vm.model, "name", $$v)
                              },
                              expression: "model.name"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-layout",
        { staticClass: "vv-form-button", attrs: { row: "", wrap: "" } },
        [
          _c("v-spacer"),
          _vm.$route.params.id
            ? _c("v-flex", { attrs: { xs4: "" } }, [
                _c(
                  "button",
                  {
                    staticClass:
                      "btn-primary vv-button vv-adicionar-novo-button",
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        _vm.dialog = true
                      }
                    }
                  },
                  [_c("span", [_vm._v(_vm._s(_vm.$t("misc.delete")))])]
                )
              ])
            : _vm._e(),
          _c("v-flex", { attrs: { xs4: "" } }, [
            _c(
              "button",
              {
                ref: "buttonSave",
                staticClass:
                  "btn-primary vv-button vv-button-inverted vv-adicionar-novo-button",
                on: {
                  click: function($event) {
                    return _vm.save()
                  }
                }
              },
              [
                _c("span", [
                  _vm._v(
                    _vm._s(
                      !_vm.$route.params.id
                        ? _vm.$t("misc.add")
                        : _vm.$t("misc.edit")
                    ) +
                      " " +
                      _vm._s(_vm.$tc("economicGroups.economicGroup", 0))
                  )
                ])
              ]
            )
          ])
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "290" },
          model: {
            value: _vm.dialog,
            callback: function($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog"
          }
        },
        [
          _c(
            "base-modal",
            {
              attrs: { title: _vm.$t("misc.areYouSure") },
              on: {
                cancel: function($event) {
                  _vm.dialog = false
                },
                confirm: function($event) {
                  _vm.dialog = false
                  _vm.remove()
                }
              }
            },
            [
              _vm._v(
                "\n    " + _vm._s(_vm.$t("economicGroups.deleteGroup")) + "\n  "
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }