<template>
  <v-app  id='volvo-voar'>
    <router-view :key="$route.path" />
    <the-loading-indicator></the-loading-indicator>
  </v-app>
</template>
<script>
import { mapState, mapActions } from 'vuex'
export default {
  name: 'app',
}
</script>

<style lang="scss">
  @import "assets/main.scss";

</style>
