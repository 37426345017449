<template></template>
<script>
import axios from 'axios'
export default {
  name: 'Logout',
  mounted (){
  	let accessToken = ''
		localStorage.removeItem('accessTokenObj', null)
		window.location = "https://federate.volvo.com/idp/startSLO.ping"
  }
}
</script>