var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.loadingIndicatorFlag
    ? _c(
        "div",
        { staticClass: "vv-loading-indicator" },
        [
          _c("v-progress-circular", {
            attrs: { indeterminate: "", size: 50, color: "primary" }
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }