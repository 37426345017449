var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main-layout",
    [
      _vm.loaded
        ? _c(
            "v-layout",
            {
              staticClass: "vv-form atendimento-visualizar",
              attrs: {
                "justify-center": "",
                row: "",
                wrap: "",
                id: "#print-form"
              }
            },
            [
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c(
                    "v-layout",
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs6: "" } },
                        [
                          _c("base-voltar-button", {
                            on: {
                              click: function($event) {
                                return _vm.$router.go(-1)
                              }
                            }
                          })
                        ],
                        1
                      ),
                      _c("v-spacer"),
                      _c(
                        "v-flex",
                        { attrs: { xs6: "" } },
                        [
                          false
                            ? _c(
                                "button",
                                {
                                  staticClass:
                                    "btn-primary vv-button vv-button-inverted vv-adicionar-novo-button",
                                  on: {
                                    click: function($event) {
                                      return _vm.printDiv("#print-form")
                                    }
                                  }
                                },
                                [
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.$t("misc.print")))
                                  ])
                                ]
                              )
                            : _vm._e(),
                          _c(
                            "v-layout",
                            { attrs: { "justify-end": "", row: "", wrap: "" } },
                            [
                              _vm.shouldShowCancelServiceButton()
                                ? _c("v-flex", { attrs: { xs6: "" } }, [
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "btn-primary vv-button vv-button-inverted vv-adicionar-novo-button ",
                                        on: {
                                          click: _vm.openModalCancelService
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(
                                              _vm.$t("services.cancelService")
                                            ) +
                                            "\n                "
                                        )
                                      ]
                                    )
                                  ])
                                : _vm._e(),
                              _vm.shouldShowUpdateStatusButton()
                                ? _c("v-flex", { attrs: { xs6: "" } }, [
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "btn-primary vv-button vv-button-inverted vv-adicionar-novo-button ",
                                        on: { click: _vm.openModalChangeStatus }
                                      },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(
                                              _vm.$t("services.changeStatus")
                                            ) +
                                            "\n                "
                                        )
                                      ]
                                    )
                                  ])
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-layout",
                    { attrs: { row: "", wrap: "", "mt-2": "" } },
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs12: "" } },
                        [
                          _c(
                            "v-card",
                            [
                              _c(
                                "v-card-text",
                                { staticClass: "py-4 px-4" },
                                [
                                  _c(
                                    "v-layout",
                                    { attrs: { row: "", wrap: "" } },
                                    [
                                      _c(
                                        "v-flex",
                                        { attrs: { xs6: "" } },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              label: _vm.$t(
                                                "services.technicianInCharge"
                                              ),
                                              value:
                                                _vm.model.technicalName || " ",
                                              readonly: ""
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-flex",
                                        { attrs: { xs4: "" } },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              label: _vm.$t("services.contact"),
                                              value:
                                                _vm.model.technicalContact ||
                                                " ",
                                              readonly: ""
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-flex",
                                        { attrs: { xs2: "" } },
                                        [
                                          _vm.model.employee
                                            ? _c("v-text-field", {
                                                attrs: {
                                                  label: "Volvo ID",
                                                  value:
                                                    _vm.model.employee.user
                                                      .volvoId || " ",
                                                  readonly: ""
                                                }
                                              })
                                            : _vm._e()
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-flex",
                                        { attrs: { xs6: "" } },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              label: _vm.$t(
                                                "services.economicGroup",
                                                1
                                              ),
                                              value:
                                                _vm.economicGroupName || " ",
                                              readonly: ""
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-flex",
                                        { attrs: { xs6: "" } },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              label: _vm.$t(
                                                "services.dealership",
                                                1
                                              ),
                                              value:
                                                _vm.model.dealership.name ||
                                                " ",
                                              readonly: ""
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-flex",
                                        { attrs: { xs6: "" } },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              label: _vm.$t(
                                                "services.mercuriusCase"
                                              ),
                                              readonly: "",
                                              value:
                                                _vm.model.mercuriusCase || " "
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c("v-flex", { attrs: { xs6: "" } }),
                                      _c(
                                        "v-flex",
                                        { attrs: { xs6: "" } },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              label: _vm.$t(
                                                "services.contractGold"
                                              ),
                                              readonly: "",
                                              value: _vm.model.goldPlane || " "
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-flex",
                                        { attrs: { xs6: "" } },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              label: _vm.$t(
                                                "services.firstContact"
                                              ),
                                              readonly: "",
                                              value: _vm.firstContactDate || " "
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-flex",
                                        { attrs: { xs12: "" } },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              label: _vm.$t(
                                                "services.customer"
                                              ),
                                              readonly: "",
                                              value:
                                                _vm.model.customer.name || " "
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-flex",
                                        { attrs: { xs6: "" } },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              label: "Veículo",
                                              value:
                                                _vm.model.customer.vehicle
                                                  .model || " ",
                                              readonly: ""
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-flex",
                                        { attrs: { xs3: "" } },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              label: _vm.$t("services.plate"),
                                              value:
                                                _vm.model.customer.vehicle
                                                  .plate || " ",
                                              readonly: ""
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-flex",
                                        { attrs: { xs3: "" } },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              label: _vm.$tc(
                                                "services.chassi",
                                                2
                                              ),
                                              value:
                                                _vm.model.customer.vehicle
                                                  .chassisNumber || " ",
                                              readonly: ""
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-flex",
                                        { attrs: { xs9: "" } },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              label: _vm.$t(
                                                "services.localization"
                                              ),
                                              readonly: "",
                                              value:
                                                _vm.model.placeDescription ||
                                                " "
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-flex",
                                        { attrs: { xs3: "" } },
                                        [
                                          _c(
                                            "router-link",
                                            {
                                              staticClass:
                                                "btn-primary vv-button vv-button-inverted vv-adicionar-novo-button",
                                              attrs: {
                                                to: {
                                                  path:
                                                    "/atendimentos/mapa/" +
                                                    _vm.$route.params.id
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("services.seeOnMap")
                                                )
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-flex",
                                        { attrs: { xs6: "" } },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              label: _vm.$t(
                                                "services.estimatedDistance"
                                              ),
                                              readonly: "",
                                              value:
                                                _vm.estimatedDistance || " "
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-flex",
                                        { attrs: { xs6: "" } },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              label: _vm.$t(
                                                "services.travelledDistance"
                                              ),
                                              readonly: "",
                                              value:
                                                _vm.travelledDistance || " "
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-flex",
                                        { attrs: { xs12: "" } },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              label: _vm.$t(
                                                "services.observation"
                                              ),
                                              readonly: "",
                                              value:
                                                _vm.model.serviceDescription ||
                                                " "
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-layout",
                    { staticClass: "mt-4", attrs: { row: "", wrap: "" } },
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs12: "" } },
                        [
                          _c(
                            "v-card",
                            [
                              _c(
                                "v-card-text",
                                { staticClass: "py-4 px-4" },
                                [
                                  _c(
                                    "v-layout",
                                    { attrs: { row: "", wrap: "" } },
                                    [
                                      _c(
                                        "v-flex",
                                        { attrs: { xs6: "" } },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              label: _vm.$t(
                                                "services.finalTravelledDistance"
                                              ),
                                              value:
                                                _vm.model.displacement || " ",
                                              readonly: ""
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-flex",
                                        { attrs: { xs6: "" } },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              label: _vm.$t(
                                                "services.vehicleModel"
                                              ),
                                              value:
                                                _vm.model.vehicleModel || " ",
                                              readonly: ""
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-flex",
                                        { attrs: { xs6: "" } },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              label: _vm.$t(
                                                "services.serviceReason"
                                              ),
                                              value:
                                                _vm.model.causeService || " ",
                                              readonly: ""
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-flex",
                                        { attrs: { xs6: "" } },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              label: _vm.$t(
                                                "services.serviceConclusion"
                                              ),
                                              value:
                                                _vm.model.completionService ||
                                                " ",
                                              readonly: ""
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-flex",
                                        { attrs: { xs12: "" } },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              label: _vm.$t(
                                                "services.serviceNotes"
                                              ),
                                              value:
                                                _vm.model.notesService || " ",
                                              readonly: ""
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-layout",
                    { staticClass: "mt-4", attrs: { row: "", wrap: "" } },
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs12: "" } },
                        [
                          _c("v-data-table", {
                            staticClass: "elevation-1",
                            attrs: {
                              headers: _vm.table1Headers,
                              "no-data-text": "Sem dados disponíveis",
                              items: _vm.table1Items,
                              "hide-actions": ""
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "items",
                                  fn: function(props) {
                                    return [
                                      _c("td", {
                                        domProps: {
                                          innerHTML: _vm._s(
                                            props.item.mercuriusCase
                                          )
                                        }
                                      }),
                                      _c("td", {
                                        domProps: {
                                          innerHTML: _vm._s(
                                            props.item.dealership &&
                                              props.item.dealership.name
                                              ? props.item.dealership.name
                                              : ""
                                          )
                                        }
                                      }),
                                      _c("td", {
                                        domProps: {
                                          innerHTML: _vm._s(
                                            props.item.startDate
                                              ? _vm.getFullDate(
                                                  props.item.startDate
                                                )
                                              : "———"
                                          )
                                        }
                                      }),
                                      _c("td", {
                                        domProps: {
                                          innerHTML: _vm._s(
                                            props.item.endDate
                                              ? _vm.getFullDate(
                                                  props.item.endDate
                                                )
                                              : "———"
                                          )
                                        }
                                      }),
                                      _c("td", {
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.getStatusName(props.item.status)
                                          )
                                        }
                                      })
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              363987877
                            )
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-layout",
                    { staticClass: "mt-4", attrs: { row: "", wrap: "" } },
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs12: "" } },
                        [
                          _c("v-data-table", {
                            staticClass: "elevation-1",
                            attrs: {
                              headers: _vm.table2Headers,
                              items: _vm.table2Items,
                              "hide-actions": "",
                              "no-data-text": _vm.$t("misc.noData")
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "items",
                                  fn: function(props) {
                                    return [
                                      _c("td", {
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.getNotificationByStatus(
                                              props.item
                                            )
                                          )
                                        }
                                      }),
                                      _c("td", {
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.getTableItemDate(props.item)
                                          )
                                        }
                                      }),
                                      _c("td", {
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.getTableItemTime(props.item)
                                          )
                                        }
                                      }),
                                      _c("td", {
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.getTableReason(props.item)
                                          )
                                        }
                                      })
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              2713399012
                            )
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "450" },
          model: {
            value: _vm.cancelServiceDialog,
            callback: function($$v) {
              _vm.cancelServiceDialog = $$v
            },
            expression: "cancelServiceDialog"
          }
        },
        [
          _c("cancelar-atendimento-modal", {
            on: { "select-cancel-type": _vm.openModalConfirmCancelService }
          })
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "450" },
          model: {
            value: _vm.confirmCancelServiceDialog,
            callback: function($$v) {
              _vm.confirmCancelServiceDialog = $$v
            },
            expression: "confirmCancelServiceDialog"
          }
        },
        [
          _c("confirmar-cancelamento-modal", {
            attrs: { reason: _vm.cancelReasonSelected },
            on: { back: _vm.openModalCancelService, confirm: _vm.cancelService }
          })
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "450" },
          model: {
            value: _vm.changeStatusDialog,
            callback: function($$v) {
              _vm.changeStatusDialog = $$v
            },
            expression: "changeStatusDialog"
          }
        },
        [
          _c("alterar-status-modal", {
            attrs: { status: _vm.model.status },
            on: { "change-status": _vm.changeStatus }
          })
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "450" },
          model: {
            value: _vm.cancelServiceReturnDialog,
            callback: function($$v) {
              _vm.cancelServiceReturnDialog = $$v
            },
            expression: "cancelServiceReturnDialog"
          }
        },
        [
          _c("base-modal", {
            attrs: {
              title: _vm.$t("services.canceledService"),
              "confirm-text": "OK",
              "no-cancel-button": ""
            },
            on: { confirm: _vm.closeCancelServiceReturnDialog }
          })
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "450" },
          model: {
            value: _vm.changeStatusReturnDialog,
            callback: function($$v) {
              _vm.changeStatusReturnDialog = $$v
            },
            expression: "changeStatusReturnDialog"
          }
        },
        [
          _c("base-modal", {
            attrs: {
              title: _vm.$t("services.editedStatus"),
              "confirm-text": "OK",
              "no-cancel-button": ""
            },
            on: { confirm: _vm.closeChangeStatusReturnDialog }
          })
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "450" },
          model: {
            value: _vm.requestErrorDialog,
            callback: function($$v) {
              _vm.requestErrorDialog = $$v
            },
            expression: "requestErrorDialog"
          }
        },
        [
          _c("base-modal", {
            attrs: {
              title: _vm.$t("services.problemRequest"),
              "confirm-text": "OK",
              "no-cancel-button": ""
            },
            on: {
              confirm: function($event) {
                _vm.requestErrorDialog = false
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }