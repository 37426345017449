var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    _vm.getLayoutComponentName(),
    {
      tag: "component",
      class: { "dashboard-fullscreen": _vm.isFullScreenMode },
      attrs: { id: "dashboard-container" }
    },
    [
      _c(
        "v-layout",
        { attrs: { row: "", wrap: "" } },
        [
          !_vm.oneDealership && !_vm.isFullScreenMode
            ? _c("v-flex", { attrs: { md3: "" } }, [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchFilter.dealership,
                        expression: "searchFilter.dealership"
                      }
                    ],
                    staticClass: "vv-input",
                    attrs: { disabled: _vm.dealerships.length == 1 },
                    on: {
                      change: [
                        function($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function(o) {
                              return o.selected
                            })
                            .map(function(o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.searchFilter,
                            "dealership",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                        function($event) {
                          return _vm.mountMap(true)
                        }
                      ]
                    }
                  },
                  [
                    _c(
                      "option",
                      {
                        staticClass: "vv-disabled",
                        attrs: { selected: "", value: "" }
                      },
                      [_vm._v(_vm._s(_vm.$tc("services.dealership", 1)))]
                    ),
                    _vm._l(_vm.dealerships, function(dealership, key) {
                      return _c(
                        "option",
                        { domProps: { value: dealership.id } },
                        [_vm._v(_vm._s(dealership.name))]
                      )
                    })
                  ],
                  2
                )
              ])
            : _vm._e(),
          !_vm.isFullScreenMode
            ? _c("v-flex", { attrs: { md2: "" } }, [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchFilter.status,
                        expression: "searchFilter.status"
                      }
                    ],
                    staticClass: "vv-input",
                    on: {
                      change: [
                        function($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function(o) {
                              return o.selected
                            })
                            .map(function(o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.searchFilter,
                            "status",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                        function($event) {
                          return _vm.mountMap()
                        }
                      ]
                    }
                  },
                  [
                    _c(
                      "option",
                      {
                        staticClass: "vv-disabled",
                        attrs: { selected: "", value: "" }
                      },
                      [_vm._v(_vm._s(_vm.$t("services.status")))]
                    ),
                    _vm._l(_vm.status, function(state, key) {
                      return _c("option", { domProps: { value: state } }, [
                        _vm._v(_vm._s(_vm.getStatusName(state)))
                      ])
                    })
                  ],
                  2
                )
              ])
            : _vm._e(),
          !_vm.isFullScreenMode
            ? _c("v-flex", { attrs: { md2: "", xs12: "" } }, [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.periodFilterSelected,
                        expression: "periodFilterSelected"
                      }
                    ],
                    staticClass: "vv-input",
                    on: {
                      change: [
                        function($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function(o) {
                              return o.selected
                            })
                            .map(function(o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.periodFilterSelected = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        },
                        function($event) {
                          return _vm.mountMap()
                        }
                      ]
                    }
                  },
                  [
                    _c(
                      "option",
                      {
                        staticClass: "vv-disabled",
                        attrs: { selected: "", value: "" }
                      },
                      [_vm._v(_vm._s(_vm.$t("services.period")))]
                    ),
                    _vm._l(_vm.periodFilters, function(periodFilter, key) {
                      return _c(
                        "option",
                        { domProps: { value: periodFilter } },
                        [_vm._v(" " + _vm._s(periodFilter) + " ")]
                      )
                    })
                  ],
                  2
                )
              ])
            : _vm._e(),
          _vm.isPeriodBetween
            ? _c(
                "v-flex",
                { attrs: { sm2: "", xs6: "" } },
                [
                  _c(
                    "v-menu",
                    {
                      attrs: {
                        lazy: "",
                        "close-on-content-click": false,
                        transition: "scale-transition",
                        "offset-y": "",
                        "full-width": "",
                        "nudge-right": 40,
                        "max-width": "290px",
                        "min-width": "290px"
                      }
                    },
                    [
                      _c("v-text-field", {
                        staticClass: "vv-input",
                        attrs: {
                          slot: "activator",
                          readonly: "",
                          placeholder: _vm.$t("services.start")
                        },
                        slot: "activator",
                        model: {
                          value: _vm.filteredDates.startDate,
                          callback: function($$v) {
                            _vm.$set(_vm.filteredDates, "startDate", $$v)
                          },
                          expression: "filteredDates.startDate"
                        }
                      }),
                      _c("v-date-picker", {
                        attrs: {
                          "no-title": "",
                          scrollable: "",
                          locale: "pt-BR",
                          actions: "",
                          autosave: ""
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(ref) {
                                var save = ref.save
                                var cancel = ref.cancel
                                return [
                                  _c(
                                    "v-card-actions",
                                    [
                                      _c("v-spacer"),
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: { flat: "", color: "primary" },
                                          on: { click: save }
                                        },
                                        [_vm._v(_vm._s(_vm.$t("misc.ok")))]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          3964242408
                        ),
                        model: {
                          value: _vm.searchFilter.startDate,
                          callback: function($$v) {
                            _vm.$set(_vm.searchFilter, "startDate", $$v)
                          },
                          expression: "searchFilter.startDate"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.isPeriodBetween
            ? _c(
                "v-flex",
                { attrs: { sm2: "", xs6: "" } },
                [
                  _c(
                    "v-menu",
                    {
                      attrs: {
                        lazy: "",
                        "close-on-content-click": false,
                        transition: "scale-transition",
                        "offset-y": "",
                        "full-width": "",
                        "nudge-right": 40,
                        "max-width": "290px",
                        "min-width": "290px"
                      }
                    },
                    [
                      _c("v-text-field", {
                        staticClass: "vv-input",
                        attrs: {
                          slot: "activator",
                          readonly: "",
                          placeholder: _vm.$t("services.end")
                        },
                        slot: "activator",
                        model: {
                          value: _vm.filteredDates.endDate,
                          callback: function($$v) {
                            _vm.$set(_vm.filteredDates, "endDate", $$v)
                          },
                          expression: "filteredDates.endDate"
                        }
                      }),
                      _c("v-date-picker", {
                        attrs: {
                          "no-title": "",
                          locale: "pt-BR",
                          scrollable: "",
                          actions: "",
                          autosave: ""
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(ref) {
                                var save = ref.save
                                var cancel = ref.cancel
                                return [
                                  _c(
                                    "v-card-actions",
                                    [
                                      _c("v-spacer"),
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: { flat: "", color: "primary" },
                                          on: { click: save }
                                        },
                                        [_vm._v(_vm._s(_vm.$t("misc.ok")))]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          3964242408
                        ),
                        model: {
                          value: _vm.searchFilter.endDate,
                          callback: function($$v) {
                            _vm.$set(_vm.searchFilter, "endDate", $$v)
                          },
                          expression: "searchFilter.endDate"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-flex",
            {
              class: {
                "pt-0": _vm.isFullScreenMode,
                "pb-0": _vm.isFullScreenMode
              },
              attrs: { sm2: "", "offset-xs3": "" }
            },
            [
              _c(
                "button",
                {
                  staticClass: "btn btn-expandir dashboard-btn-expandir",
                  attrs: { tag: "button" },
                  on: {
                    click: function($event) {
                      return _vm.setFullScreenMode()
                    }
                  }
                },
                [
                  _c("kanban-expandir-icon"),
                  _c("span", {
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.isFullScreenMode
                          ? _vm.$t("misc.back")
                          : _vm.$t("misc.expand")
                      )
                    }
                  })
                ],
                1
              )
            ]
          )
        ],
        1
      ),
      _c(
        "v-layout",
        {
          attrs: { "justify-center": "", row: "", wrap: "", "fill-height": "" }
        },
        [
          _vm.showMap
            ? _c(
                "v-flex",
                {
                  class: !_vm.showMap ? "dn" : "",
                  attrs: {
                    xs12: "",
                    id: "one",
                    "pt-0": "",
                    "pb-0": "",
                    "fill-height": ""
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "fill-height",
                      class: !_vm.showMap ? "dn" : ""
                    },
                    [
                      _c("div", {
                        staticClass: "vv-map full dashboard",
                        class: !_vm.showMap ? "dn" : "",
                        attrs: { id: "location-map" }
                      })
                    ]
                  )
                ]
              )
            : _vm._e(),
          !_vm.showMap
            ? _c("v-flex", { attrs: { xs12: "", id: "two" } }, [
                _c(
                  "div",
                  [
                    _c(
                      "v-layout",
                      {
                        staticClass: "white-background",
                        attrs: { row: "", wrap: "" }
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("services.mapProblem")) +
                            "\n        "
                        )
                      ]
                    )
                  ],
                  1
                )
              ])
            : _vm._e(),
          !_vm.isFullScreenMode
            ? _c("v-flex", { attrs: { xs12: "" } }, [
                _c("div", [
                  _c("div", { staticClass: "status-indicator" }, [
                    _c("div", { staticClass: "status-badge processing" }),
                    _c("div", { staticClass: "status-label " }, [
                      _vm._v(_vm._s(_vm.$t("footerStatus.report")))
                    ])
                  ]),
                  _c("div", { staticClass: "status-indicator" }, [
                    _c("div", { staticClass: "status-badge departure" }),
                    _c("div", { staticClass: "status-label " }, [
                      _vm._v(_vm._s(_vm.$t("footerStatus.start")))
                    ])
                  ]),
                  _c("div", { staticClass: "status-indicator" }, [
                    _c("div", { staticClass: "status-badge repairing" }),
                    _c("div", { staticClass: "status-label" }, [
                      _vm._v(_vm._s(_vm.$t("footerStatus.repairing")))
                    ])
                  ]),
                  _c("div", { staticClass: "status-indicator" }, [
                    _c("div", { staticClass: "status-badge finished" }),
                    _c("div", { staticClass: "status-label" }, [
                      _vm._v(_vm._s(_vm.$t("footerStatus.end")))
                    ])
                  ])
                ])
              ])
            : _vm._e()
        ],
        1
      ),
      _vm._l(_vm.filteredServices, function(service) {
        return _vm.model && _vm.model.services && _vm.refreshKanban
          ? _c(
              "div",
              {
                key: service.id,
                staticStyle: { display: "none" },
                attrs: { id: "kanban-map-" + service.id }
              },
              [
                _c("kanban-card", {
                  attrs: {
                    item: service,
                    "dashboard-card": "",
                    notifications: _vm.model.notifications.filter(function(
                      item
                    ) {
                      return item.serviceId == service.id
                    })
                  }
                })
              ],
              1
            )
          : _vm._e()
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }