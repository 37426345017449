var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "fragment",
    [
      _c("router-link", { attrs: { to: { path: "/atendimentos" } } }, [
        _c("img", {
          staticClass: "logo",
          attrs: { src: _vm.imageCountry, alt: "", height: "44", width: "82" }
        })
      ]),
      !_vm.busy
        ? _c(
            "v-list",
            _vm._l(_vm.menuSidebar, function(item) {
              return _vm.checkPermission(item)
                ? _c(
                    "v-list-group",
                    {
                      key: item.title,
                      attrs: {
                        "no-action": "",
                        "append-icon": item.subItems
                          ? "$vuetify.icons.expand"
                          : ""
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function() {
                              return [
                                _c(
                                  "v-list-tile",
                                  _vm._b(
                                    {},
                                    "v-list-tile",
                                    _vm.clickBehaviour(item),
                                    false
                                  ),
                                  [
                                    _c("v-list-tile-action", [_c("v-icon")], 1),
                                    _c(
                                      "v-list-tile-content",
                                      [
                                        _c("v-list-tile-title", [
                                          _vm._v(_vm._s(item.title))
                                        ])
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ]
                            },
                            proxy: true
                          }
                        ],
                        null,
                        true
                      ),
                      model: {
                        value: item.active,
                        callback: function($$v) {
                          _vm.$set(item, "active", $$v)
                        },
                        expression: "item.active"
                      }
                    },
                    _vm._l(item.subItems, function(subItem) {
                      return _c(
                        "v-list-tile",
                        _vm._b(
                          { key: subItem.title },
                          "v-list-tile",
                          _vm.clickBehaviour(subItem),
                          false
                        ),
                        [
                          _c(
                            "v-list-tile-content",
                            [
                              _c(
                                "v-list-tile-title",
                                [
                                  _vm._v(
                                    _vm._s(subItem.title) + "\n            "
                                  ),
                                  subItem.title == "Kanban"
                                    ? _c("kanban-sidebar-unread-badge")
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    }),
                    1
                  )
                : _vm._e()
            }),
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }