var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vv-voltar-button" },
    [
      _vm.$attrs.to
        ? _c("router-link", _vm._b({}, "router-link", _vm.$attrs, false), [
            _c("svg", { attrs: { viewBox: "0 0 24 24" } }, [
              _c("path", {
                attrs: {
                  fill: "#0f3299",
                  d:
                    "M20,10V14H11L14.5,17.5L12.08,19.92L4.16,12L12.08,4.08L14.5,6.5L11,10H20Z"
                }
              })
            ]),
            _vm._v(_vm._s(_vm.$t("misc.back")) + "\n  ")
          ])
        : _vm._e(),
      _vm.$attrs.href || _vm.$listeners.click
        ? _c("a", _vm._g({}, _vm.$listeners), [
            _c("svg", { attrs: { viewBox: "0 0 24 24" } }, [
              _c("path", {
                attrs: {
                  fill: "#0f3299",
                  d:
                    "M20,10V14H11L14.5,17.5L12.08,19.92L4.16,12L12.08,4.08L14.5,6.5L11,10H20Z"
                }
              })
            ]),
            _vm._v("\n  " + _vm._s(_vm.$t("misc.back")) + "\n  ")
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }