<template>
  <fragment>
    <router-link :to="{path: '/atendimentos'}">
      <img class='logo' :src="imageCountry" alt="" height="44" width="82">
    </router-link>
    <v-list v-if="!busy">
      <v-list-group
        v-for="item in menuSidebar"
        :key="item.title"
        v-model="item.active"
        no-action
        v-if="checkPermission(item)"
        :append-icon="(item.subItems) ? '$vuetify.icons.expand' : ''  "
      >

        <template v-slot:activator >
          <v-list-tile
            v-bind="clickBehaviour(item)">
            <v-list-tile-action>
                  <v-icon></v-icon>
                </v-list-tile-action>
            <v-list-tile-content>
              <v-list-tile-title>{{ item.title }}</v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
        </template>
        <v-list-tile
          v-for="subItem in item.subItems"
          :key="subItem.title"
          v-bind="clickBehaviour(subItem)"
        >
          <v-list-tile-content>
            <v-list-tile-title>{{ subItem.title }}
              <kanban-sidebar-unread-badge
              v-if="subItem.title == 'Kanban'"
               />
            </v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
      </v-list-group>
    </v-list>
  </fragment>
</template>

<script>
import { Fragment } from 'vue-fragment'
import { mapState, mapActions } from 'vuex'
import utils from '@/libs/utils';
export default {

  name: 'TheSidebar',

  data () {
    return {
      busy: false
    }
  },
  components: {
    Fragment
  },
  computed: {
    ...mapState('main', [
      'menuSidebar',
      'pageName'
    ]),
    ...mapState('employees', [
      'employee'
    ]),
    ...mapState('users', [
      'myPermissions'
    ]),
    imageCountry() {
      const country = this.myPermissions.country || "brazil";
      return utils.getImageAccordingCountry({ name: country, type: "sidebar" });
    },
  },
  methods: {
    checkPermission (item) {
      if (!item.permissions) {
        return true
      }
      return item.permissions.includes(this.employee.user.level)
    },
    clickBehaviour(item) {
      if (!item.subItems) {
        return { href: '/#' + item.path }
      }
    },
    updateSelected() {
      const selected = this.menuSidebar.find(item => this.$route.path.includes(item.path));
      if (selected) {
        selected.active = true;
      }
    }
  },
  mounted() {
    this.busy = true;
    this.updateSelected();
    this.busy = false;
  },
  watch: {
    menuSidebar() {
      this.updateSelected();
    }
  }
}
</script>

<style lang="css" scoped>
</style>