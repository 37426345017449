var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "status__header" }, [
        _c("img", {
          attrs: {
            src: _vm.imageCountry,
            alt: "Volvo VOAR",
            height: "28",
            width: "60"
          }
        })
      ]),
      _vm.serviceStatus
        ? _c(
            "div",
            {
              staticClass: "status__wrapper pa-4",
              class: { transparent: _vm.avisoLgpdDialog }
            },
            [
              _c("div", { staticClass: "status__timeline" }, [
                _c("h1", [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("pageFollowService.greetings")) +
                      " " +
                      _vm._s(_vm.serviceStatus.customer.name) +
                      "! " +
                      _vm._s(_vm.$t("pageFollowService.followService")) +
                      "\n        "
                  ),
                  _c("span", { staticClass: "emoji" }, [_vm._v("👇")])
                ]),
                _c(
                  "div",
                  { staticClass: "status__steps mt-3 mb-2" },
                  _vm._l(_vm.statusStepsToShow, function(step, index) {
                    return _c("div", { key: index, staticClass: "step" }, [
                      _c("div", {
                        staticClass: "step-point mr-3",
                        class: { current: step.active }
                      }),
                      _c(
                        "div",
                        {
                          staticClass: "step-text mr-3",
                          class: { current: step.active }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(step.statusText) +
                              "\n            "
                          ),
                          _vm.showPauseChip(step)
                            ? _c(
                                "v-chip",
                                {
                                  attrs: {
                                    disabled: "",
                                    small: "",
                                    label: "",
                                    color: "red",
                                    "text-color": "white"
                                  }
                                },
                                [_vm._v(_vm._s(_vm.$t("footerStatus.paused")))]
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _c("div", { staticClass: "step-date" }, [
                        _vm._v(_vm._s(step.date))
                      ])
                    ])
                  }),
                  0
                )
              ]),
              _c("div", { staticClass: "status__divider" }),
              _c("div", { staticClass: "status__wrapper--right" }, [
                _c("div", { staticClass: "status__text" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("pageFollowService.numberFollow")) +
                      "\n        "
                  ),
                  _c("br"),
                  _c("span", { staticClass: "emoji" }, [_vm._v("✏️ ")]),
                  _c("span", { staticClass: "highlight" }, [
                    _vm._v(_vm._s(_vm.serviceStatus.mercuriusCase))
                  ]),
                  _c("br"),
                  _c("br"),
                  !_vm.isTowed
                    ? _c("div", [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.$t("pageFollowService.employeeService")
                            ) +
                            "\n          "
                        ),
                        _c("span", { staticClass: "highlight" }, [
                          _vm._v(_vm._s(_vm.serviceStatus.technicalName))
                        ]),
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("pageFollowService.dealership")) +
                            "\n          "
                        ),
                        _c("span", { staticClass: "highlight" }, [
                          _vm._v(_vm._s(_vm.serviceStatus.dealership.name))
                        ]),
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("pageFollowService.distance")) +
                            "\n          "
                        ),
                        _c("span", { staticClass: "highlight" }, [
                          _vm._v(_vm._s(_vm.serviceStatus.distance) + "km")
                        ]),
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("pageFollowService.localization")) +
                            "\n          "
                        ),
                        _vm.serviceStatus.technicalContact
                          ? _c("span", [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.$t("pageFollowService.contact")) +
                                  "\n            "
                              ),
                              _c("span", { staticClass: "emoji" }, [
                                _vm._v("📲 ")
                              ]),
                              _c("span", { staticClass: "highlight" }, [
                                _vm._v(
                                  _vm._s(_vm.serviceStatus.technicalContact)
                                )
                              ]),
                              _vm._v(
                                _vm._s(_vm.$t("pageFollowService.voarNumber")) +
                                  ":\n            "
                              ),
                              _c("div", [
                                _c("br"),
                                _c("b", [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("misc.countryBR")) + " "
                                  )
                                ]),
                                _vm._m(0),
                                _vm._m(1)
                              ]),
                              _c("br"),
                              _c("div", [
                                _c("b", [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("misc.countryAR")) + " "
                                  )
                                ]),
                                _vm._m(2)
                              ]),
                              _c("br"),
                              _c("div", [
                                _c("b", [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("misc.countryCL")) + " "
                                  )
                                ]),
                                _vm._m(3)
                              ]),
                              _c("br"),
                              _c("div", [
                                _c("b", [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("misc.countryPE")) + " "
                                  )
                                ]),
                                _vm._m(4)
                              ]),
                              _c("br")
                            ])
                          : _c("span", [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.$t("pageFollowService.voar")) +
                                  ":\n            "
                              ),
                              _c("div", [
                                _c("br"),
                                _c("b", [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("misc.countryBR")) + " "
                                  )
                                ]),
                                _vm._m(5),
                                _vm._m(6)
                              ]),
                              _c("br"),
                              _c("div", [
                                _c("b", [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("misc.countryAR")) + " "
                                  )
                                ]),
                                _vm._m(7)
                              ]),
                              _c("br"),
                              _c("div", [
                                _c("b", [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("misc.countryCL")) + " "
                                  )
                                ]),
                                _vm._m(8)
                              ]),
                              _c("br"),
                              _c("div", [
                                _c("b", [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("misc.countryPE")) + " "
                                  )
                                ]),
                                _vm._m(9)
                              ]),
                              _c("br")
                            ])
                      ])
                    : _c("div", [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("pageFollowService.towCar")) +
                            ":\n          "
                        ),
                        _c("div", [
                          _c("br"),
                          _c("b", [
                            _vm._v(" " + _vm._s(_vm.$t("misc.countryBR")) + " ")
                          ]),
                          _vm._m(10),
                          _vm._m(11)
                        ]),
                        _c("br"),
                        _c("div", [
                          _c("b", [
                            _vm._v(" " + _vm._s(_vm.$t("misc.countryAR")) + " ")
                          ]),
                          _vm._m(12)
                        ]),
                        _c("br"),
                        _c("div", [
                          _c("b", [
                            _vm._v(" " + _vm._s(_vm.$t("misc.countryCL")) + " ")
                          ]),
                          _vm._m(13)
                        ]),
                        _c("br"),
                        _c("div", [
                          _c("b", [
                            _vm._v(" " + _vm._s(_vm.$t("misc.countryPE")) + " ")
                          ]),
                          _vm._m(14)
                        ]),
                        _c("br")
                      ])
                ]),
                _c(
                  "div",
                  { staticClass: "status__vehicle mt-4" },
                  [
                    _c(
                      "v-layout",
                      { staticClass: "px-3", attrs: { row: "", wrap: "" } },
                      [
                        _c(
                          "v-flex",
                          { attrs: { xs12: "" } },
                          [
                            _c("v-text-field", {
                              attrs: {
                                label: _vm.$t("services.timeArrive"),
                                value: _vm.valueOrBlank(
                                  _vm.serviceStatus.estimatedTimeArrival
                                ),
                                readonly: ""
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "v-flex",
                          { attrs: { xs12: "" } },
                          [
                            _c("v-text-field", {
                              attrs: {
                                label: _vm.$t("services.vehicle"),
                                value: _vm.valueOrBlank(
                                  _vm.serviceStatus.customer.vehicle.model
                                ),
                                readonly: ""
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "v-flex",
                          { attrs: { xs6: "" } },
                          [
                            _c("v-text-field", {
                              staticClass: "mr-2",
                              attrs: {
                                label: _vm.$t("services.plate"),
                                value: _vm.valueOrBlank(
                                  _vm.serviceStatus.customer.vehicle.plate
                                ),
                                readonly: ""
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "v-flex",
                          { attrs: { xs6: "" } },
                          [
                            _c("v-text-field", {
                              attrs: {
                                label: _vm.$tc("services.chassi", 2),
                                value: _vm.valueOrBlank(
                                  _vm.serviceStatus.customer.vehicle
                                    .chassisNumber
                                ),
                                readonly: ""
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ])
            ]
          )
        : _vm._e(),
      !_vm.serviceStatus && !_vm.busy
        ? _c("div", { staticClass: "status__wrapper pa-4" }, [
            _c("div", { staticClass: "status__text no-info" }, [
              _vm._v(
                "\n      " + _vm._s(_vm.$t("pageFollowService.withoutService"))
              ),
              _c("br"),
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("pageFollowService.voar")) +
                  ":\n      "
              ),
              _c("div", [
                _c("br"),
                _c("b", [_vm._v(" " + _vm._s(_vm.$t("misc.countryBR")) + " ")]),
                _vm._m(15),
                _vm._m(16)
              ]),
              _c("br"),
              _c("div", [
                _c("b", [_vm._v(" " + _vm._s(_vm.$t("misc.countryAR")) + " ")]),
                _vm._m(17)
              ]),
              _c("br"),
              _c("div", [
                _c("b", [_vm._v(" " + _vm._s(_vm.$t("misc.countryCL")) + " ")]),
                _vm._m(18)
              ]),
              _c("br"),
              _c("div", [
                _c("b", [_vm._v(" " + _vm._s(_vm.$t("misc.countryPE")) + " ")]),
                _vm._m(19)
              ]),
              _c("br")
            ])
          ])
        : _vm._e(),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "450" },
          model: {
            value: _vm.avisoLgpdDialog,
            callback: function($$v) {
              _vm.avisoLgpdDialog = $$v
            },
            expression: "avisoLgpdDialog"
          }
        },
        [
          _c("AvisoLgpdModal", {
            on: {
              confirm: function($event) {
                _vm.avisoLgpdDialog = false
              },
              policy: function($event) {
                return _vm.goToPolicy()
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _vm._v("\n                VOAR Caminhões: "),
      _c("span", { staticClass: "highlight" }, [_vm._v("0800 041 61 61")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _vm._v("\n                VOAR Ônibus: "),
      _c("span", { staticClass: "highlight" }, [_vm._v("0800 647 4747")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _vm._v("\n                VAS: "),
      _c("span", { staticClass: "highlight" }, [_vm._v("0800 666 4639")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _vm._v("\n                VAS: "),
      _c("span", { staticClass: "highlight" }, [_vm._v("800 646 815")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _vm._v("\n                VOLAR: "),
      _c("span", { staticClass: "highlight" }, [_vm._v("0800 533 86")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _vm._v("\n                VOAR Caminhões: "),
      _c("span", { staticClass: "highlight" }, [_vm._v("0800 041 61 61")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _vm._v("\n                VOAR Ônibus: "),
      _c("span", { staticClass: "highlight" }, [_vm._v("0800 647 4747")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _vm._v("\n                VAS: "),
      _c("span", { staticClass: "highlight" }, [_vm._v("0800 666 4639")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _vm._v("\n                VAS: "),
      _c("span", { staticClass: "highlight" }, [_vm._v("800 646 815")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _vm._v("\n                VOLAR: "),
      _c("span", { staticClass: "highlight" }, [_vm._v("0800 533 86")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _vm._v("\n                VOAR Caminhões: "),
      _c("span", { staticClass: "highlight" }, [_vm._v("0800 041 61 61")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _vm._v("\n                VOAR Ônibus: "),
      _c("span", { staticClass: "highlight" }, [_vm._v("0800 647 4747")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _vm._v("\n                VAS: "),
      _c("span", { staticClass: "highlight" }, [_vm._v("0800 666 4639")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _vm._v("\n                VAS: "),
      _c("span", { staticClass: "highlight" }, [_vm._v("800 646 815")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _vm._v("\n                VOLAR: "),
      _c("span", { staticClass: "highlight" }, [_vm._v("0800 533 86")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _vm._v("\n          VOAR Caminhões: "),
      _c("span", { staticClass: "highlight" }, [_vm._v("0800 041 61 61")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _vm._v("\n          VOAR Ônibus: "),
      _c("span", { staticClass: "highlight" }, [_vm._v("0800 647 4747")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _vm._v("\n          VAS: "),
      _c("span", { staticClass: "highlight" }, [_vm._v("0800 666 4639")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _vm._v("\n          VAS: "),
      _c("span", { staticClass: "highlight" }, [_vm._v("800 646 815")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _vm._v("\n          VOLAR: "),
      _c("span", { staticClass: "highlight" }, [_vm._v("0800 533 86")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }