<template>
  <fragment>
    <v-toolbar-side-icon @click.stop="$emit('click')"></v-toolbar-side-icon>
    <v-toolbar-title>{{ pageName }}</v-toolbar-title>
    <v-spacer />
    <v-flex sm3 md2 class="pr-2">
      <v-select
        v-model="currentLanguage"
        :items="optionsLanguage"
        hide-details
        return-object
        color='rgba(0,0,0,.87)'
      >
        <template slot="selection" slot-scope="data">
          {{ data.item.name }}
        </template>
        <template slot="item" slot-scope="data">
          {{ data.item.name }}
        </template>
      </v-select>
    </v-flex>
    <p class='text-xs-right greetings'>
      <span>{{ $t('header.greetings') + (me.username)}}!</span>
    </p>
  </fragment>
</template>

<script>
import { Fragment } from 'vue-fragment'
import { mapState, mapActions } from 'vuex'
export default {

  name: 'TheToolbar',
  components: {
    Fragment
  },
  data () {
    return {
      currentLanguage: null
    }
  },
  computed: {
    ...mapState('main', [
      'menuSidebar',
      'pageName'
    ]),
    ...mapState('users', [
      'me',
      'myPermissions'
    ]),
    optionsLanguage() {
      return [
        {
          id: 1,
          name: this.$t('header.br'),
          avatar: 'br',
          lang: 'pt'
        },
        {
          id: 2,
          name: this.$t('header.es'),
          avatar: null,
          lang: 'es'
        }
      ];
    },
    currentId() {
      return this.myPermissions.languageId;
    }
  },
  mounted() {
    this.currentLanguage = this.optionsLanguage.find( x => x.id === this.currentId);
    this.$emit('changeLanguage', this.currentLanguage.lang);
  },
  methods: {
    ...mapActions('users', ['updateLanguageUser']),
    async updateLanguage(value) {
      try {
        this.$utils.showLoadingIndicator();
        const payload = { languageId: value.id };
        let response = await this.updateLanguageUser(payload);
        this.$emit('changeLanguage', value.lang);
        this.currentLanguage = this.optionsLanguage.find( x => x.id === value.id);
      } catch(error) {
        console.warn(error);
      } finally {
        this.$utils.hideLoadingIndicator();
      }
    }
  },
  watch:{
    currentLanguage(value){
      this.updateLanguage(value);
    }
  }
}
</script>

<style lang="css" scoped>
</style>