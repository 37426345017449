var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-toolbar",
        { attrs: { fixed: "", app: "" } },
        [
          _c("v-toolbar-side-icon", {
            on: {
              click: function($event) {
                $event.stopPropagation()
                _vm.drawer = !_vm.drawer
              }
            }
          }),
          _c("v-toolbar-title", [_vm._v(_vm._s(_vm.pageName))]),
          _c("p", { staticClass: "text-xs-right greetings" }, [
            _c("span", [
              _vm._v(_vm._s(_vm.$t("header.greetings") + _vm.me.username) + "!")
            ])
          ])
        ],
        1
      ),
      _c(
        "v-navigation-drawer",
        {
          attrs: { fixed: "" },
          model: {
            value: _vm.drawer,
            callback: function($$v) {
              _vm.drawer = $$v
            },
            expression: "drawer"
          }
        },
        [
          _c("router-link", { attrs: { to: { path: "/atendimentos" } } }, [
            _c("img", {
              staticClass: "logo",
              attrs: { src: require("../assets/voar_logo.png"), alt: "" }
            })
          ]),
          _c(
            "v-list",
            _vm._l(_vm.menuSidebar, function(item) {
              return _c(
                "v-list-group",
                {
                  key: item.title,
                  attrs: { "prepend-icon": item.action, "no-action": "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function() {
                          return [
                            _c(
                              "v-list-tile",
                              {
                                on: {
                                  click: function($event) {
                                    return _vm.$router.push({ path: item.path })
                                  }
                                }
                              },
                              [
                                _c(
                                  "v-list-tile-content",
                                  [
                                    _c("v-list-tile-title", [
                                      _vm._v(_vm._s(item.title))
                                    ])
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]
                        },
                        proxy: true
                      }
                    ],
                    null,
                    true
                  ),
                  model: {
                    value: item.active,
                    callback: function($$v) {
                      _vm.$set(item, "active", $$v)
                    },
                    expression: "item.active"
                  }
                },
                _vm._l(item.subItems, function(subItem) {
                  return _c(
                    "v-list-tile",
                    {
                      key: subItem.title,
                      on: {
                        click: function($event) {
                          return _vm.$router.push({ path: subItem.path })
                        }
                      }
                    },
                    [
                      _c(
                        "v-list-tile-content",
                        [
                          _c("v-list-tile-title", [
                            _vm._v(" " + _vm._s(subItem.title))
                          ])
                        ],
                        1
                      ),
                      _c(
                        "v-list-tile-action",
                        [_c("v-icon", [_vm._v(_vm._s(subItem.action))])],
                        1
                      )
                    ],
                    1
                  )
                }),
                1
              )
            }),
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }