<template>
  <v-card>
    <v-card-text class="select-group-modal__text">
      <div class="select-group-modal__search">
        <v-text-field :label="$t('misc.search')" v-model="searchString"></v-text-field>
      </div>
      <div class="select-group-modal__list">
        <div v-for="(group, index) in filteredOptions" :key="index" class="select-group-modal__option">
          <label>
            <input type="radio" name="economic-group" :value="group.id" v-model="selectedGroup" />
            <span class="radio--override"></span>
            {{ group.name }}
          </label>
        </div>
      </div>
    </v-card-text>
    <v-card-actions class="select-group-modal__footer pt-4 pb-3">
      <v-btn
        color="#35343d"
        class="select-group-modal__button btn-cancel"
        flat
        @click="$emit('cancel')"
        v-html="$t('misc.cancel')"
      ></v-btn>
      <v-btn
        class="select-group-modal__button vv-button vv-button-inverted btn-primary btn-confirm"
        @click="selectGroup"
        v-html="$t('misc.select')"
      ></v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import config from '@/app.settings';

export default {
  name: 'SelecionarGrupoEconomicoModal',
  data() {
    return {
      selectedGroup: '',
      searchString: '',
    };
  },
  computed: {
    filteredOptions() {
      if (this.searchString) {
        return this.options.filter((group) =>
          group.name
            .toLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .includes(this.searchString.toLowerCase())
        );
      } else return this.options;
    },
  },
  props: {
    options: {
      type: Array,
    },
  },
  methods: {
    selectGroup() {
      this.$emit('select', this.selectedGroup);
    },
  },
};
</script>

<style lang="scss">
.select-group-modal {
  &__button {
    text-transform: none !important;
    font-size: 18px !important;
    letter-spacing: normal;
    font-weight: normal !important;
    &.btn-confirm {
      max-width: 350px !important;
    }
    &.btn-cancel {
      width: 175px;
      background-color: transparent;
      &:before {
        background-color: transparent;
      }
    }
  }
  &__footer {
    justify-content: center;
    padding-bottom: 25px;
  }
  &__list {
    height: 400px;
    max-height: 400px;
    overflow-y: scroll;
  }
  &__text {
    padding: 20px 20px 20px 20px !important;
  }
  &__option {
    font-size: 18px;
    color: #35343d;
    padding: 20px 0;
    border-bottom: 1px solid rgba(151, 151, 151, 0.2);
    display: flex;
    align-items: center;
    position: relative;
    label {
      cursor: pointer;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      display: flex;
      align-items: center;
    }
    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
      &:checked + .radio--override::after {
        display: block;
      }
    }
    .radio--override {
      display: inline-block;
      position: relative;
      height: 24px;
      width: 24px;
      min-width: 24px;
      margin-right: 8px;
      border-radius: 50%;
      border: 1px solid #35343d;
      &::after {
        content: '';
        position: absolute;
        display: none;
        top: 3px;
        left: 3px;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background: #1e3673;
      }
    }
  }
}
</style>
