<template>
	<main-layout>
			<v-layout justify-center row wrap class='vv-form'>
				<v-flex xs12>
					<v-flex xs12>
						<form-alert
							:alert-model="!!alertText"
							:alert-text="alertText"
							@input="closeFormAlert()"
						/>
				</v-flex>
					<div class="vv-form-header">
						<base-voltar-button
						:to="{ path:  '/concessionarias'}"
						/>
					</div>
					<v-layout row wrap class='white-background'>
						<v-form ref='form'>
							<v-flex xs6>
								<v-layout row wrap>
									<v-flex xs12>
										 <v-text-field
											:label="$t('dealerships.dealershipName')"
											v-model="model.name"
											ref='inputName'
											:rules="[(value) => !!value || $t('misc.validateInput')]"
											required
										></v-text-field>
									 </v-flex>
									 <v-flex xs12 v-if="['VASManager', 'CountryVasManager'].includes(employee.level)">
										<select
											:label="$tc('dealerships.economicGroup', 1)"
											class='vv-input form-select'
											v-model="selectedEconomicGroup"
										>
											<option selected value="" class='vv-disabled'>{{$tc('dealerships.economicGroup', 1)}}</option>
											<option
												v-for="(economicGroup, key) in economicGroups"
												:value="economicGroup.id"
												:key="key"
												>{{economicGroup.name}}</option>

										</select>
									 </v-flex>

									 <v-flex xs12>
										<vuetify-google-autocomplete
											id="map"
											ref="address"
											:required="true"
											v-model="model.address"
											classname="input-group"
											:label="$t('dealerships.localization')"
											@placechanged="getAddressData"
											:country="searchCountries"
											:load-google-api="false"
											google-api-key=""
											:types="'address'"
											:language="$root.$i18n.locale"
										>
										</vuetify-google-autocomplete>
									</v-flex>
									<v-flex xs12>
										<v-select
											v-model="model.country"
											:items="countryList"
											return-object
											color='rgba(0,0,0,.87)'
											:label="$t('misc.country')"
											:rules="[(value) => !!value || $t('misc.validateInput')]"
											required
										>
											<template slot="selection" slot-scope="data">
												{{ data.item.name }}
											</template>
											<template slot="item" slot-scope="data">
												{{ data.item.name }}
											</template>
										</v-select>
									</v-flex>
									<v-flex xs6>
									 <v-text-field
										:label="$t('misc.latitude')"
										ref="latitude"
										v-model="model.latitude"
										@keyup="checkIfLatLng($event.target.value)"
										:rules="[testLatLng]"
									></v-text-field>
									</v-flex>
									<v-flex xs6>
											<v-text-field
												:label="$t('misc.longitude')"
												v-model="model.longitude"
												ref="longitude"
												@keyup="checkIfLatLng($event.target.value)"
												:rules="[testLatLng]"
											></v-text-field>
									</v-flex>
								</v-layout>
							</v-flex>
						</v-form>
					</v-layout>
				</v-flex>
			</v-layout>
			<v-layout row wrap class='vv-form-button'>
				<v-spacer></v-spacer>
				<v-flex xs4 v-if="$route.params.id">
					<button @click.stop="dialog = true" class='btn-primary vv-button vv-button vv-adicionar-novo-button'>
						<span>{{ $t('misc.delete') }}</span>
					</button>
				</v-flex>
				<v-flex xs4>

					<button @click="save()" ref="buttonSave" class='btn-primary vv-button vv-button-inverted vv-adicionar-novo-button'>
						<span>{{ (!$route.params.id ) ? $t('misc.add') : $t('misc.edit')}} {{$tc('dealerships.dealership', 1)}} </span>
					</button>
				</v-flex>
			</v-layout>
		<v-dialog v-model="dialog" max-width="290">
			<v-card>
				<v-card-title class="headline">{{$t('misc.areYouSure')}}</v-card-title>
				<v-card-text>{{$t('misc.askForDelete', {theEntity: $t('dealerships.theDealership')})}}</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="green darken-1" flat="flat" @click.native="dialog = false">{{ $t('misc.cancel') }}</v-btn>
					<v-btn color="green darken-1" flat="flat" @click.native="dialog = false; remove()">{{ $t('misc.confirm') }}</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</main-layout>

</template>

<style lang="scss" scoped>
</style>

<script>

import VuetifyGoogleAutocomplete from 'vuetify-google-autocomplete'

import utils from "@/libs/utils"
import { mapState, mapActions } from 'vuex'
import ViewMixin from '@/mixins/ViewMixin'
import config from '@/app.settings'
import _ from "lodash"
export default {
	name: 'ConcessionariaEditar',
	components: {
		VuetifyGoogleAutocomplete
	},
	mixins: [ViewMixin],
	data: () => ({
		model: {},
		economicGroups: [],
		selectedEconomicGroup: '',
		addressValue: '',
		searchCountries: ['br', 'cl', 'ar', 'pe']
	}),
	computed: {
		...mapState('employees', ['employee']),
		...mapState('main', ['pageName']),
		latLngMessage() {
		  return this.$t('misc.latlonError')
		},
		countryList() {
		  	return [
				{
					id: 'brazil',
					name: this.$t('misc.countryBR'),
					google: 'BR'
				},
				{
					id: 'argentina',
					name: this.$t('misc.countryAR'),
					google: 'AR'
				},
				{
					id: 'chile',
					name: this.$t('misc.countryCL'),
					google: 'CL'
				},
				{
					id: 'peru',
					name: this.$t('misc.countryPE'),
					google: 'PE'
				}
			]
		}
	},
	methods: {
		...mapActions('dealerships', [
			'addDealership',
			'editDealership',
			'deleteDealership',
			'loadDealership'
		]),
		...mapActions('economicGroups', [
			'loadEconomicGroups'
		]),
		save() {
			let t = this
			if(!this.$refs.form.validate()) return
			if (this.employee.level == 'EconomicGroupManager') {
				this.selectedEconomicGroup = this.employee.economicGroupId
			}
			this.$utils.showLoadingIndicator()
			if (t.$route.params.id) {
				let id = t.$route.params.id
				let obj = _.cloneDeep(t.model)
				obj.country = obj.country.id
				obj.economicGroupId = t.selectedEconomicGroup
				obj.id = id
				this.editDealership(obj)
				.then(response => t.successHandler(t.$tc('dealerships.dealership', 1) + t.$t('misc.alertMessageEditFemale'), response))
				.catch(t.errorHandler)
			} else {
				t.model.economicGroupId =  t.selectedEconomicGroup
				let payload = _.cloneDeep(t.model)
				payload.country = payload.country.id
				this.addDealership(payload)
				.then(response => t.successHandler(t.$tc('dealerships.dealership', 1) + t.$t('misc.alertMessageInsertFemale'), response))
				.catch(error => t.errorHandler(error))
			}
		},
		remove() {
			let t = this
			this.$utils.showLoadingIndicator()
			t.deleteDealership(this.$route.params.id)
			.then(response => t.successHandler(t.$tc('dealerships.dealership', 1) + t.$t('misc.alertMessageDeleteFemale'), response))
			.catch(t.errorHandler)
		},
		testLatLng(value){
			let t = this
			if (!!value && /^(\-?\d+(\.\d+)?)$/.test(value.trim())) {
				return true
			}
			else {
				return t.latLngMessage
			}
		},
		testObj(obj){
			return (
				this.testLatLng(obj.latitude) == this.latLngMessage  ||
				this.testLatLng(obj.longitude) == this.latLngMessage
			)
		},
		errorHandler (error) {
			let t = this
			this.$utils.hideLoadingIndicator()
			//TODO: Buscar do back-end o erro quando estiver ajustado
			let message = this.$t('misc.commonError')
			this.$store.commit('main/SET_ALERT_TEXT', {type: 'error', message: message})
			this.alertText = this.$utils.handleAlertText()
			return
		},
		successHandler (message, response) {
			this.$store.commit('main/SET_ALERT_TEXT', {type: 'success', message})
			this.$router.push('/concessionarias')
		},
		getAddressData(addressData, placeResultData, id){
			if(!addressData) return	
			let isCountryInAddress = placeResultData.address_components.find(x => ["BR", "CL", "AR", "PE"].includes(x.short_name))
			if (isCountryInAddress)
			  this.model.country = this.countryList.find(x => x.google === isCountryInAddress.short_name)
			this.model.address = placeResultData.formatted_address
			this.model.latitude = String(addressData.latitude)
			this.model.longitude = String(addressData.longitude)
		},
		checkIfLatLng(value) {
			let t = this
			let latLngRegex = /^(\-?\d+(\.\d+)?),\s*(\-?\d+(\.\d+)?)$/
			if (latLngRegex.test(value)) {
				let coordinates = value.split(',')
				t.model.latitude = coordinates[0]
				t.model.longitude = coordinates[1]
			}
		},
		async init() {
			try {
				this.$utils.showLoadingIndicator()
				this.economicGroups = await this.loadEconomicGroups()
				if (this.$route.params.id) {
					this.model = await this.loadDealership({id: this.$route.params.id})
					this.model = this.model[0]
					this.model.country = this.countryList.find(x => x.id === this.model.country)
					this.$refs.address.update(this.model.address)
					this.selectedEconomicGroup = this.model.economicGroupId
					this.$store.commit('main/SET_PAGENAME', this.pageName + ' - ' + this.model.name)
				}
			} catch(e) {
				console.warn(e)
			}
			this.$utils.hideLoadingIndicator()
		}

	},
	mounted () {
		this.init()
		document.title += " - " + this.model.name
	},
}
</script>