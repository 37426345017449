import volvoTotem  from "@/assets/map_totem_volvo.png"
import yellowTruck  from "@/assets/ic_map_truck_yellow.png"
import redTruck  from "@/assets/ic_map_truck_red.png"
import greenTruck  from "@/assets/ic_map_truck_green.png"
import blueTruck  from "@/assets/ic_map_truck_blue.png"
import purpleTruck  from "@/assets/ic_map_truck_purple.png"
import blueCar  from "@/assets/ic_map_car.png"
import blueDot  from "@/assets/blue-dot.png"
export default {
	png: {
		volvoTotem,
		yellowTruck,
		redTruck,
    greenTruck,
		blueTruck,
    purpleTruck,
    blueCar,
		blueDot,
	},
	createInfoWindow() {
		let infoWindow = ''
		infoWindow = `
  		<v-card class='finished'>
              </v-card-media>
              <v-card-text >
                <div>
                  <h3 class=" mb-0">2017246194</h3>
                  <div>DICAVE ICARA</div>
                  <div>Enviado: </div>
                  <div>Saiu para Atendimento: </div>
                  <div>Finalizado: </div>

                </div>
              </v-card-text>
              <v-card-actions>
                <span class="left">68km</span>
                <v-spacer></v-spacer>
                <span class="right">Fulano de Tal</span>
              </v-card-actions>
            </v-card>
       `
       return infoWindow

	}
}