var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-toolbar",
        { attrs: { fixed: "", app: "" } },
        [
          _c("the-toolbar", {
            on: {
              click: function($event) {
                _vm.drawer = !_vm.drawer
              },
              changeLanguage: _vm.updateLanguageSystem
            }
          })
        ],
        1
      ),
      _c(
        "v-navigation-drawer",
        {
          attrs: { fixed: "", app: "" },
          model: {
            value: _vm.drawer,
            callback: function($$v) {
              _vm.drawer = $$v
            },
            expression: "drawer"
          }
        },
        [_c("the-sidebar")],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }