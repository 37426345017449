<template>
      <v-switch
        v-model="active"
        color="indigo"
        :label="`${status(active)}`"
        @change="validate(active)"
      ></v-switch>
  </template>
  
  <script>
  import { mapState, mapActions } from 'vuex'
  import utils from '@/libs/utils'

  export default {
    name: 'AlterarStatusMotivo',
    data () {
      return {
        active: this.item.active,
      }
    },
    props: [ 'item'],
    methods: {
      ...mapActions('cancelReasons', [
        'editReason',
     ]),
     status(value){
      return value ? 'Ativo' : 'Inativo'
     },
     validate(value){
      let id = this.item.id
      let obj = Object.assign({}, this.model)
      obj.id = id
      obj.Active = value
      this.$utils.showLoadingIndicator()
      this.editReason(obj).then(response => {
        this.$utils.hideLoadingIndicator()
      })
     },
  },
  }
  </script>
  <style lang="css" scoped>
  </style>