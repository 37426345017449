<template>
  <div>
    <v-toolbar fixed app>
      <the-toolbar @click="drawer = !drawer" @changeLanguage="updateLanguageSystem"/>
    </v-toolbar>

    <v-navigation-drawer fixed v-model="drawer" app>
      <the-sidebar />
    </v-navigation-drawer>
  </div>
</template>
<script>
  const MOCK_LIB_NAME_ROUTE = {
    'Login': 'header.logout',
    'Acompanhar Status': 'header.checkStatus',
    'Dashboard': 'header.dashboard',
    'Atendimentos': 'header.services',
    'Kanban': 'header.kanban',
    'KanbanExpandido': 'header.kanban',
    'Localização': 'services.localization',
    'Visualizar Atendimento': 'header.currentService',
    'Mapa': 'header.map',
    'Concessionárias': 'header.dealerships',
    'Adicionar Concessionária': 'dealerships.addDealership',
    'Editar Concessionária': 'dealerships.editDealership',
    'Grupos Econômicos': 'header.economicGroups',
    'Adicionar Grupo Econômico': 'economicGroups.addEconomicGroup',
    'Editar Grupo Econômico': 'economicGroups.editEconomicGroup',
    'Motivos de cancelamento': 'header.reasonsCancellations',
    'Adicionar Motivo': 'reasonsCancellations.addReasonsCancellation',
    'Editar Motivo': 'reasonsCancellations.editReasonsCancellation',
    'Funcionários': 'header.employees',
    'Adicionar Funcionário': 'employees.addEmployee',
    'Editar Funcionário': 'employees.editEmployee',
    'Logout': 'header.logout',
    'EmployeeLogout': 'header.logout',
    'Teste Ping': 'header.logout'
  };
  import { mapState, mapActions } from 'vuex'
  export default {
    name: 'TheWrapper',
    data() {
      return {
        drawer: null,
        unreadServicesPolling: false,
        MOCK_LIB_NAME_ROUTE
      }
    },
    computed: {
      ...mapState('main', [
        'menuSidebar',
        'pageName'
      ]),
      ...mapState('users', [
        'me',
      ]),
      route () {
        return this.$route.name
      }

    },
    methods: {
      ...mapActions({
        checkForUnreadServices: 'services/checkForUnreadServices'
      }),
    ...mapActions('main', [
      'TRANSLATED_SIDEBAR'
    ]),
      async pollUnreadServices() {
        let response
        try {
          response = await this.checkForUnreadServices()

        } catch(e) {
          console.warn(e)
        }
        this.unreadServicesPolling = setTimeout(this.pollUnreadServices, 10000)
      },
      updateRoute(route) {
        document.title = this.$t(MOCK_LIB_NAME_ROUTE[this.$route.name]) + ' - Volvo Voar '
        this.$store.commit('main/SET_PAGENAME', this.$t(MOCK_LIB_NAME_ROUTE[route]))
      },
      updateLanguageSystem(value) {
        this.$root.$i18n.locale = value;
        this.$vuetify.lang.current = value;
        this.updateRoute(this.$route.name);
        this.TRANSLATED_SIDEBAR(this);
      }
    },
    mounted () {
      this.TRANSLATED_SIDEBAR(this)
      this.updateRoute(this.$route.name);
      this.$nextTick(() => this.$forceUpdate())

      if (!this.me) {
        this.$store.commit('users/SET_ME', this.$utils.parseJwt(JSON.parse(localStorage.accessTokenObj).access_token))
      }
      this.pollUnreadServices()
    },

    beforeDestroy () {
      clearInterval(this.unreadServicesPolling)
    },
    watch: {
      route (newRoute, oldRoute) {
        this.updateRoute(newRoute)
      }
    },
  }
</script>
<style lang="scss" >

</style>
