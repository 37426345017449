<template>
  <component
    :is="getLayoutComponentName()"
    id='dashboard-container'
    :class="{'dashboard-fullscreen': isFullScreenMode}">
    <v-layout row wrap  >
      <v-flex md3 v-if="!oneDealership && !isFullScreenMode"  >
        <select  class='vv-input' v-model="searchFilter.dealership" @change="mountMap(true)"  :disabled="dealerships.length == 1">
          <option selected value=""  class='vv-disabled' >{{$tc('services.dealership', 1)}}</option>
          <option v-for="(dealership, key) in dealerships" :value="dealership.id">{{dealership.name}}</option>
        </select>
      </v-flex>

      <v-flex md2 v-if="!isFullScreenMode">
         <select class='vv-input' v-model="searchFilter.status" @change="mountMap()">
          <option selected value="" class='vv-disabled'>{{$t('services.status')}}</option>
          <option v-for="(state, key) in status" :value="state">{{ getStatusName(state) }}</option>
        </select>
      </v-flex>

      <v-flex md2 xs12 v-if="!isFullScreenMode">
        <select  class='vv-input' v-model="periodFilterSelected" @change="mountMap()">
          <option selected value="" class='vv-disabled'>{{$t('services.period')}}</option>
          <option v-for="(periodFilter, key) in periodFilters" :value="periodFilter"> {{periodFilter}} </option>
        </select>
      </v-flex>
      <v-flex sm2 xs6 v-if='isPeriodBetween' >
        <v-menu
          lazy
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          full-width
          :nudge-right="40"
          max-width="290px"
          min-width="290px"
        >
         <v-text-field class='vv-input'
            slot="activator"
            readonly
            :placeholder="$t('services.start')"
            v-model="filteredDates.startDate"
          ></v-text-field>
          <v-date-picker no-title v-model="searchFilter.startDate" scrollable locale="pt-BR"  actions autosave>
            <template slot-scope="{ save, cancel }">
              <v-card-actions>
                <v-spacer></v-spacer>
                <!---<v-btn flat color="primary" @click="cancel">{{$t('misc.cancel')}}</v-btn>-->
                <v-btn flat color="primary" @click="save">{{$t('misc.ok')}}</v-btn>
              </v-card-actions>
            </template>
          </v-date-picker>
        </v-menu>
      </v-flex>
      <v-flex sm2 xs6 v-if='isPeriodBetween' >
        <v-menu
          lazy
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          full-width
          :nudge-right="40"
          max-width="290px"
          min-width="290px"
        >
         <v-text-field class='vv-input'
            slot="activator"
            readonly
            v-model="filteredDates.endDate"
            :placeholder="$t('services.end')"
          ></v-text-field>
          <v-date-picker no-title v-model="searchFilter.endDate" locale="pt-BR" scrollable actions autosave>
            <template slot-scope="{ save, cancel }">
              <v-card-actions>
                <v-spacer></v-spacer>
                <!---<v-btn flat color="primary" @click="cancel">{{$t('misc.cancel')}}</v-btn>-->
                <v-btn flat color="primary" @click="save">{{$t('misc.ok')}}</v-btn>
              </v-card-actions>
            </template>
          </v-date-picker>
        </v-menu>

      </v-flex>
      <v-flex
      sm2
      offset-xs3
      :class="{'pt-0': isFullScreenMode, 'pb-0': isFullScreenMode}">
        <button @click="setFullScreenMode()" class='btn btn-expandir dashboard-btn-expandir' tag="button">
          <kanban-expandir-icon></kanban-expandir-icon>
          <span v-html="(isFullScreenMode) ? $t('misc.back') : $t('misc.expand')"></span>
        </button>
      </v-flex>
    </v-layout>

    <v-layout justify-center row wrap fill-height>
      <v-flex xs12 v-if='showMap' id='one' pt-0 pb-0 fill-height :class="(!showMap) ? 'dn' : ''">
        <div :class="(!showMap) ? 'dn' : ''" class='fill-height'>
          <div id="location-map" class='vv-map full dashboard'  :class="(!showMap) ? 'dn' : ''" ></div>
        </div>
      </v-flex>
      <v-flex xs12 v-if="!showMap" id='two'>
        <div>
          <v-layout row wrap class='white-background' >
            {{ $t('services.mapProblem') }}
          </v-layout>
        </div>
      </v-flex>
      <v-flex xs12 v-if="!isFullScreenMode">
        <div>
          <div class="status-indicator">
            <div class="status-badge processing"></div>
            <div class="status-label ">{{$t('footerStatus.report')}}</div>
          </div>
          <div class="status-indicator">
            <div class="status-badge departure"></div>
            <div class="status-label ">{{$t('footerStatus.start')}}</div>
          </div>
          <div class="status-indicator">
            <div class="status-badge repairing"></div>
            <div class="status-label">{{$t('footerStatus.repairing')}}</div>
          </div>
          <div class="status-indicator">
            <div class="status-badge finished"></div>
            <div class="status-label">{{$t('footerStatus.end')}}</div>
          </div>
          <!-- <div class="status-indicator">
            <div class="status-badge mechanic"></div>
            <div class="status-label">{{$t('footerStatus.mechanic')}}</div>
          </div>
          <div class="status-indicator">
            <div class="status-badge start"></div>
            <div class="status-label">{{$t('footerStatus.departure')}}</div>
          </div> -->
        </div>
      </v-flex>

    </v-layout>
    <div
      v-for="service in filteredServices"
      :key="service.id"
      :id="'kanban-map-' + service.id"
      style='display: none'
      v-if="model && model.services && refreshKanban">
      <kanban-card
        :item="service"
        dashboard-card
        :notifications="model.notifications.filter(item => item.serviceId == service.id)"
      ></kanban-card>
    </div>
  </component>

</template>

<style lang="scss" scoped>
  .dashboard-fullscreen {
    .gm-style {
      height: 100%;
      width: 100%;
    }
  }
</style>

<script>

import FullscreenLayout from '@/router/layouts/FullscreenLayout'
import KanbanCard from "@/components/KanbanCard"
import utils from "@/libs/utils"
import mapAssets from "@/libs/map-assets"
import { mapState, mapActions } from 'vuex'
import ViewMixin from '@/mixins/ViewMixin'
import config from '@/app.settings'
import _ from 'lodash'

export default {
  name: 'Mapa',
  mixins: [ViewMixin],
  components: {
    KanbanCard,
    FullscreenLayout
  },
  data: () => ({
    utils,
    drawer: null,
    headers: [],
    items: [],
    markers: [],
    frozen: false,
    map: false,
    noneOnMap: false,
    refreshKanban: true,
    model: [],
    mapName: 'location-map',
    routeCalculateInterval: 1000,
    typeOfMap: 0,
    generatedRoutes: 0,
    dontSearchForRoute: [],
    pageName: '',
    markerCluster: false,
    boundMarkers: [],
    employeeMarkers: [],
    searchFilter: {
      generalSearch: '',
      dealership: '',
      status: '',
      mercuriusCase: '',
      startDate: '',
      endDate: '',
      startTime: '',
      endTime: ''
    },
    directionsDisplays: [],
    routes: [],
    periodFilterSelected: '',
    dealerships: [],
    status: [],
    quantityOfRoutesToBePlotted: 0,
    showMap: true,
    wasDisconnected: false,
    isFullScreenMode: true,
    refreshInterval: false,
    singleDealershipMarker: true,
    debounceCheckNewServices: _.debounce(function() { this.checkForNewServices() }, 60000),
  }),
  computed: {
    oneDealership () {
      return this.dealerships && this.dealerships.length  === 1
    },
    periodFilters() {
      return [
        this.$t('services.last24h'), 
        this.$t('services.last48h'), 
        this.$t('services.last72h'),
        this.$t('services.lastMonth'),
        this.$t('services.periodBetween')
      ];
    },
    filteredServices() {


      let t = this
      let filters = t.searchFilter
      let items = this.model.services

      if(!items || items.length == 0) return []

      // sort by property defined right above
      let generalDealerships = []
      items = items.filter(item => {
        if(!generalDealerships.includes(item.dealershipId)) {
          generalDealerships.push(item.dealershipId)
          return true
        }
        return (item.latitude && item.longitude && item.latitude != null)
      })
      if (
        !filters.generalSearch &&
        !filters.startDate &&
        !filters.endDate &&
        !filters.status &&
        !filters.dealership &&
        !t.periodFilterSelected
        ) {
        return items
      }

      if (!t.periodFilterSelected) {
        t.searchFilter.startDate = ''
        t.searchFilter.endDate = ''
      }


      let tempItems = items
      .filter(item =>
        (
          item.dealership.name.toLowerCase().indexOf(filters.generalSearch.toLowerCase()) !== -1
        )
      )


      if (filters.dealership) {
        tempItems = tempItems.filter(item => item.dealershipId.indexOf(filters.dealership) !== -1)
      }
      if (filters.status) {
        tempItems = tempItems.filter(item => {
          // tem que tratar casos com status de 800 metros como caso em andamento
          if (item.status == 5) { // 800 metros
            item.status = 2 // em andamneto
          }
          if (item.status == 6) { // desistiu do atendimento
            item.status = 1 // enviado
          }
          return item.status === filters.status
        })

      }

      let tomorrowInMiliseconds = 1000 * 60 * 60 * 24
      let timezoneOffsetMiliseconds = utils.getTimezoneOffsetMiliseconds()
      if (filters.startDate && filters.endDate) {
        tempItems = tempItems.filter(item => {
          let isDateValid =
            ((
              Date.parse(filters.startDate) < (Date.parse(item.startDate) + timezoneOffsetMiliseconds) &&
              Date.parse(filters.endDate) + tomorrowInMiliseconds > (Date.parse(item.startDate) + timezoneOffsetMiliseconds)
            ) || (
              Date.parse(filters.startDate) < (Date.parse(item.departureDate) + timezoneOffsetMiliseconds) &&
              Date.parse(filters.endDate) + tomorrowInMiliseconds > (Date.parse(item.departureDate) + timezoneOffsetMiliseconds)
            ) || (
              Date.parse(filters.startDate) < (Date.parse(utils.fixDateWithoutGMT(item.reportDate)) + timezoneOffsetMiliseconds) &&
              Date.parse(filters.endDate) + tomorrowInMiliseconds > (Date.parse(utils.fixDateWithoutGMT(item.reportDate)) + timezoneOffsetMiliseconds)
            ))
          return isDateValid
        })
      }
      else{
        if (filters.startDate) {
          tempItems = tempItems.filter(item => {
            let isDateValid =
              Date.parse(filters.startDate) < (Date.parse(item.startDate) + timezoneOffsetMiliseconds) ||
              Date.parse(filters.startDate) < (Date.parse(item.departureDate) + timezoneOffsetMiliseconds) ||
              Date.parse(filters.startDate) < (Date.parse(utils.fixDateWithoutGMT(item.reportDate)) + timezoneOffsetMiliseconds)
            return isDateValid

          })
        }
        if (filters.endDate) {
          tempItems = tempItems.filter(item => {
            let isDateValid =
              Date.parse(filters.endDate) + tomorrowInMiliseconds > (Date.parse(item.startDate) + timezoneOffsetMiliseconds) ||
              Date.parse(filters.endDate) + tomorrowInMiliseconds > (Date.parse(utils.fixDateWithoutGMT(item.reportDate)) + timezoneOffsetMiliseconds) ||
              Date.parse(filters.endDate) + tomorrowInMiliseconds > (Date.parse(item.departureDate) + timezoneOffsetMiliseconds)
            return isDateValid
          })
        }
      }
      if (t.periodFilters.includes(t.periodFilterSelected) && !t.isPeriodBetween) {
        let timeToSubtract = 1000 * 3600 * 24
        switch (t.periodFilterSelected) {
          case t.$t('services.last24h'):
            break;
          case t.$t('services.last48h'):
            timeToSubtract *= 2
            break
          case t.$t('services.last72h'):
            timeToSubtract *= 3
            break
          case t.$t('services.lastMonth'):
            timeToSubtract *= 30
            break
        }
        tempItems = tempItems.filter(item => {
          let isDateValid =
            (Date.now() - timeToSubtract) < (Date.parse(item.startDate  ) + timezoneOffsetMiliseconds) ||
            (Date.now() - timeToSubtract) < (Date.parse(item.departureDate  ) + timezoneOffsetMiliseconds) ||
            (Date.now() - timeToSubtract) < (Date.parse(utils.fixDateWithoutGMT(item.reportDate)  ) + timezoneOffsetMiliseconds)
          return isDateValid
        })

      }
      if (filters.startTime) {
        let timeToCompare = filters.startTime.split(':')
        let timeStampToCompare = (timeToCompare[0] * 60) + timeToCompare[1]
        tempItems = tempItems.filter(item => {
          let itemTime = Date.parse(item.start)
          return timeStampToCompare < Date.parse()
        })
      }


      return tempItems
    },
    filteredDates() {
      let t = this
      let startDate = (t.searchFilter.startDate ) ? t.searchFilter.startDate.split('-').reverse().join('/') : ''
      let endDate = (t.searchFilter.endDate ) ? t.searchFilter.endDate.split('-').reverse().join('/') : ''

      return {
        startDate, endDate
      }

    },
    isPeriodBetween() {
      return this.periodFilterSelected == _.last(this.periodFilters);
    }
  },
  methods: {
      ...mapActions('services', [
        'getServicesKanban',
        'loadServicesNotifications',
        'loadTrackings',
        'loadTracking',
        'loadServiceNotifications',
        'checkNewServices'
      ]),
      async setFullScreenMode() {
        this.isFullScreenMode = !this.isFullScreenMode
        sessionStorage.isFullScreen = this.isFullScreenMode
        await this.$nextTick()
        this.mountMap()
      },
      getLayoutComponentName() {
        let layout =
          (this.isFullScreenMode) ? 'fullscreen-layout' : 'main-layout'

        return layout
      },
      checkIfDisableLoadingIndicator(){
        let t = this
        t.generatedRoutes++
        if (t.generatedRoutes >= (t.quantityOfRoutesToBePlotted - 1)) {
          // this.$utils.hideLoadingIndicator
        }
      },
      calculateAndDisplayRoute(directionsService, directionsDisplay, pointA, pointB, serviceId) {
        let t = this
        // cache se e só se a rota do ponto A ao ponto B existirem
        if (t.routes[pointA + pointB]) {
          //
          t.checkIfDisableLoadingIndicator()
          return directionsDisplay.setDirections(t.routes[pointA + pointB]);
        }

        /*
        if(t.frozen && t.frozen !== pointA + pointB ) {
            console.log(t.frozen)
            setTimeout(() => {
              t.calculateAndDisplayRoute(directionsService, directionsDisplay, pointA, pointB, serviceId)
            }, t.routeCalculateInterval)
            return
        }
        */

        directionsService.route({
          origin: pointA,
          destination: pointB,
          travelMode: google.maps.TravelMode.DRIVING
        }, function(response, status) {
          if (status == google.maps.DirectionsStatus.OK) {
            directionsDisplay.setDirections(response);
            t.routes[pointA + pointB] = response
            t.frozen = false
            t.checkIfDisableLoadingIndicator()
            // console.log(t.generatedRoutes)

          } else { // se deu erro
            //
            if (status == 'OVER_QUERY_LIMIT') {
              t.frozen = pointA + pointB
              setTimeout(() => {
                t.calculateAndDisplayRoute(directionsService, directionsDisplay, pointA, pointB)
              }, 2000)
            }
            if (status == 'ZERO_RESULTS') {
              //
              t.checkIfDisableLoadingIndicator()
            }
          }
        });
      },
      setBounds() {
        let t = this
        const bounds = new google.maps.LatLngBounds();
        t.boundMarkers.forEach(item => {
          bounds.extend( new google.maps.LatLng(item.latitude, item.longitude))
        })
        t.map.fitBounds(bounds)

      },
      setOnlyDealershipBounds() {

        const bounds = new google.maps.LatLngBounds()
        bounds.extend( new google.maps.LatLng(
          this.singleDealershipMarker.latitude,
          this.singleDealershipMarker.longitude
        ))
        this.map.fitBounds(bounds)
        this.map.setZoom(13)
      },
      plotServiceMarker(coord, map, serviceId) {

        let t = this
        if (t.noneOnMap) return
        const position = new google.maps.LatLng(coord.latitude, coord.longitude);
        let marker = new google.maps.Marker({
          position,
          map,
          icon: coord.icon
        })

        t.markers.push(Object.assign(marker, {serviceId}))

        let contentInfoWindow = document.querySelector('#kanban-map-' + serviceId)
        if (contentInfoWindow) {
          let contentInnerHTML = contentInfoWindow.innerHTML
          var infowindow = new google.maps.InfoWindow({
            content: contentInnerHTML
          });
          google.maps.event.addListener(infowindow, 'domready', t.stylizeInfoWindow)

          marker.addListener('mouseover', function() {
            infowindow.open(map, marker);
          });
          marker.addListener('mouseout', function() {
            infowindow.close();
          });
        }
        t.boundMarkers.push(coord)
      },

       plotMarkers(markerCoordinates, map, serviceId) {
        let t = this
        const bounds = new google.maps.LatLngBounds();

        markerCoordinates.forEach((coord, index) => {
          if (!coord.latitude || !coord.longitude || coord.latitude == "null" ) return false
          if (t.noneOnMap && index == 0) return false
          const position = new google.maps.LatLng(coord.latitude, coord.longitude);
          let marker = new google.maps.Marker({
            position,
            map,
            icon: coord.icon
          })
          if (index == 0) {
            t.markers.push(Object.assign(marker, {serviceId}))
          }
          if (coord.description == 'serviceMarker') {
            let contentInfoWindow = document.querySelector('#kanban-map-' + serviceId).innerHTML
            var infowindow = new google.maps.InfoWindow({
              content: contentInfoWindow
            });
            google.maps.event.addListener(infowindow, 'domready', t.stylizeInfoWindow)

            marker.addListener('mouseover', function() {
              infowindow.open(map, marker);
            });
            marker.addListener('mouseout', function() {
              infowindow.close();
            });
          }
          if (coord.description == 'dealershipMarker') {
            this.singleDealershipMarker = coord
          }
        });
        if (t.noneOnMap) {
          t.boundMarkers.push(markerCoordinates[1])
        }


        else{
          let coord = markerCoordinates[0]
          if (coord.latitude && coord.longitude && coord.latitude != "null") {
            t.boundMarkers.push(markerCoordinates[0])
            t.boundMarkers.push(markerCoordinates[1])
          }


        }
        return markerCoordinates.find(item => item.description == 'serviceMarker').latitude != null
      },

      plotRoute(dealershipMarker, serviceMarker, strokeColor, map, serviceId) {
        let t = this
        if (t.noneOnMap) return
        let pointA = new google.maps.LatLng(dealershipMarker.latitude, dealershipMarker.longitude)
        let pointB = new google.maps.LatLng(serviceMarker.latitude, serviceMarker.longitude)

        let directionsService = new google.maps.DirectionsService
        let directionsDisplay = new google.maps.DirectionsRenderer({
          map,
          polylineOptions: { strokeColor },
          suppressMarkers: true,
          preserveViewport: true
        })
        t.directionsDisplays[serviceId] = directionsDisplay
        t.calculateAndDisplayRoute(directionsService, directionsDisplay, pointA, pointB, serviceId);
      },

      plotEmployee(trackings, map, serviceId, plotFromStatusChange = false) {
        let t = this
        if (t.noneOnMap) return
        const bounds = new google.maps.LatLngBounds();
        let employeePosition = null
        if (plotFromStatusChange) {
          employeePosition = new google.maps.LatLng(trackings.latitude, trackings.longitude)
        } else {
          employeePosition = new google.maps.LatLng(trackings[trackings.length - 1].latitude, trackings[trackings.length - 1].longitude)
        }

        let employeeMarker = new google.maps.Marker({
          position: employeePosition,
          map: map,
          icon: mapAssets.png.blueCar
        });
        let contentInfoWindow = document.querySelector('#kanban-map-' + serviceId).innerHTML
        var infowindow = new google.maps.InfoWindow({
          content: contentInfoWindow
        });
        google.maps.event.addListener(infowindow, 'domready', t.stylizeInfoWindow)

        employeeMarker.addListener('mouseover', function() {
          infowindow.open(map, employeeMarker);
        });
        employeeMarker.addListener('mouseout', function() {
          infowindow.close();
        });
        employeeMarker.setMap( map );
        t.employeeMarkers[serviceId] = employeeMarker
        // map.fitBounds(bounds.extend(employeePosition))
      },
      stylizeInfoWindow() {
        // Reference to the DIV which receives the contents of the infowindow using jQuery
         var iwOuter = document.querySelector('.gm-style-iw');
         if (iwOuter) {
           if (iwOuter.nextSibling) iwOuter.nextSibling.style.display = 'none'
  
           /* The DIV we want to change is above the .gm-style-iw DIV.
            * So, we use jQuery and create a iwBackground variable,
            * and took advantage of the existing reference to .gm-style-iw for the previous DIV with .prev().
            */
           var iwBackground = iwOuter.previousSibling;
  
           // Remove the background shadow DIV
           if (iwBackground) {
             iwBackground.querySelector(':nth-child(2)').style.display = 'none'
             iwBackground.querySelector(':nth-child(1)').style.display = 'none'
             iwBackground.querySelector(':nth-child(3)').style.display = 'none'
             // Remove the white background DIV
             iwBackground.querySelector(':nth-child(4)').style.display = 'none'
             // iwOuter.style.display = 'block'
           }
         }
      },
      generateFilters (items) {
        let t = this
        t.dealerships = []
        t.status = []
        for(var a in items) {
          let item = items[a]
          if (!t.dealerships.find(dealership => dealership.id == item.dealershipId)) t.dealerships.push({id: item.dealershipId, name: item.dealership.name})

        }
        t.dealerships = t.dealerships.sort((a, b) => {
          if (a.name < b.name) return -1
          if (a.name > b.name) return 1
          return 0
        })
        if (t.dealerships.length == 1) {
          t.searchFilter.dealership = t.dealerships[0].id
        }
        t.status = [1,2,3,4, 8, 9]
      },
      getStatusName (status) {
        switch (status) {
          case 1:
            return this.$t('footerStatus.report')
            break
          case 2:
            return this.$t('footerStatus.progress')
            break
          case 3:
            return this.$t('footerStatus.started')
            break
          case 4:
            return this.$t('footerStatus.end')
            break
          case 8:
            return this.$t('footerStatus.paused')
            break
          case 9:
            return this.$t('footerStatus.resumed')
            break
        }
      },
      getServiceAssets(status) {
        let serviceAssets = {
          strokeColor: '',
          truckIcon: ''
        }
        switch(status) {
          case 1:
          case 6:
            serviceAssets.strokeColor = '#d8222b'
            serviceAssets.truckIcon = mapAssets.png.redTruck
            break;
          case 2:
          case 5:
            serviceAssets.strokeColor = '#253a71'
            serviceAssets.truckIcon = mapAssets.png.blueTruck
            break;
          case 3:
          case 8:
          case 9:
            serviceAssets.strokeColor = '#632289'
            serviceAssets.truckIcon = mapAssets.png.purpleTruck
            break;

          case 4:
            serviceAssets.strokeColor = '#009949'
            serviceAssets.truckIcon = mapAssets.png.greenTruck
            break;
        }
        return serviceAssets
      },

      plotOnMap(service) {
        return new Promise((resolve, reject) => {
          let t = this
          let trackings = t.model.trackings.filter(item => item.serviceId == service.id)

          let dealershipAddress = false
          let markerCoordinates = []
          let geocodePromises = []

          let serviceAssets = t.getServiceAssets(service.status)
          let serviceAddress = service.placeDescription
          let bounds = new google.maps.LatLngBounds();
          let element = document.getElementById(this.mapName)

          let serviceMarker = {
            latitude: service.latitude,
            longitude: service.longitude,
            icon: serviceAssets.truckIcon,
            description: 'serviceMarker',
          }

          let dealershipMarker = {
            latitude: service.dealership.latitude,
            longitude: service.dealership.longitude,
            icon: mapAssets.png.volvoTotem,
            description: "dealershipMarker",
          }

          markerCoordinates.push(serviceMarker)
          markerCoordinates.push(dealershipMarker)
          // console.log(service.mercuriusCase, service.id, trackings)
          t.$nextTick(() => {
          if(t.plotMarkers(markerCoordinates, t.map, service.id)){
            t.plotRoute(dealershipMarker, serviceMarker, serviceAssets.strokeColor, t.map, service.id)
          }
          if (
            ((
             service.status > 1 &&
             service.status < 4
             ) || service.status == 5) &&
            ( trackings[0] &&
              trackings[trackings.length-1].latitude != "null" &&
              trackings[trackings.length-1].longitude != "null"
            )
            ) {
            t.plotEmployee(trackings, t.map, service.id)
          }
          resolve()
        })
        })
      },

      moveEmployeeMarkerByServiceId(latitude, longitude, serviceId){
        if (!latitude  || !longitude || latitude == 'null') return

        let t = this
        // t.employeeMarker.setMap(t.map)
        //
        let employeeMarker = t.employeeMarkers[serviceId]
        //
        //

        // check setposition = false
        if (!t.filteredServices.find(item => item.id == serviceId)) return false
        if (!employeeMarker) {
          let plotFromStatusChange = true
          // // console.log({latitude, longitude}, t.map, serviceId, plotFromStatusChange)
          t.plotEmployee({latitude, longitude}, t.map, serviceId, plotFromStatusChange)
        }
        t.$nextTick(() => {
          employeeMarker = t.employeeMarkers[serviceId]
          //
          if (!employeeMarker) return
          //if (!t.map.getBounds().contains(employeeMarker.getPosition())) {
            //
            new google.maps.Marker(employeeMarker)
          // }
          //
          employeeMarker.setPosition( new google.maps.LatLng( latitude, longitude ) );
        })
        // t.map.panTo( new google.maps.LatLng( latitude, longitude ) );
      },
      grabOneServiceFromEachDealership(){
        let t = this
        let generalDealerships = []
        let items = t.model.services
        /* if (!t.model.services) {
          return []
        } */
        if (t.model.services) {
          return []
        }
        items = items.filter(item => {
          if(!generalDealerships.includes(item.dealershipId)) {
            generalDealerships.push(item.dealershipId)
            return true
          }
          return (item.latitude && item.longitude && item.latitude != null)
        })
        return items
      },
      mountMap(fromDealershipChange = false){
        return new Promise((resolve, reject) => {
          let t = this
          t.map = null
          t.generatedRoutes = 0
          t.markers = []
          t.boundMarkers = []
          t.noneOnMap = false
          t.employeeMarkers = []
          t.markerCluster = null
          t.directionsDisplays = []


          let options = {
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            zoom: 5,
            fullscreenControl: false,
            center: {lat: -16.29145602413925, lng: -50.04985315424403},
          }
          if(
            sessionStorage.mapLat != undefined &&
            sessionStorage.mapLng != undefined &&
            sessionStorage.mapZoom != undefined
          ) {
            Object.assign(options, {
              center: new google.maps.LatLng(sessionStorage.mapLat, sessionStorage.mapLng),
              zoom: parseInt(sessionStorage.mapZoom),
              scaleControl: true
            })
          }

          let mapPromises = []
          let services = t.filteredServices
          //
          if (!t.filteredServices.length) {
            services = t.grabOneServiceFromEachDealership()
            t.noneOnMap = true

            // t.searchFilter.status = ''
          }

          t.quantityOfRoutesToBePlotted = services.filter(item => {
            return item.latitude && item.longitude && item.latitude != 'null' && item.longitude != 'null'
          }).length


          let element = document.getElementById('location-map')
          this.map = new google.maps.Map(element, options);


          t.updateKanbanCard().then(() => {
            for (var a in services) {
              let service = services[a]
              mapPromises.push(
                t.plotOnMap(service)
              )
            }


            google.maps.event.addListener(this.map, "center_changed", () => {
                let mapCentre = this.map.getCenter();
                sessionStorage.mapLat = mapCentre.lat();
                sessionStorage.mapLng = mapCentre.lng();
                sessionStorage.mapZoom = this.map.getZoom();
            })

            google.maps.event.addListener(this.map, "zoom_changed", () => {
                let mapCentre = this.map.getCenter();
                sessionStorage.mapLat = mapCentre.lat();
                sessionStorage.mapLng = mapCentre.lng();
                sessionStorage.mapZoom = this.map.getZoom();
            })
            Promise.all(mapPromises)
            .then((mapResponses) => {
              resolve()

            })
            .catch(error => {})
            .then(() => {
            })
            setTimeout(function(){
              if(fromDealershipChange) {
                if (t.markers.length == 0) t.setOnlyDealershipBounds()
                else t.setBounds()
              }

              t.debounceCheckNewServices()
            }, 2000)
          })
        })
      },
      async prepareEverything() {
        this.$utils.showLoadingIndicator()
        this.$store.commit('main/SET_PAGENAME', this.$store.state.pageName)
        try {
          const services = await this.getServicesKanban()
          const serviceIds = services.map(service => service.id)
          const trackings = await this.loadTrackings({ ServiceIds: serviceIds })
          const notifications = await this.loadServicesNotifications({ ServiceIds: serviceIds })
          this.model = { services, trackings, notifications }
          this.generateFilters(this.model.services)
          this.mountMap()
          this.$socket.emit('join', '123456')
        } catch(error) {
          console.warn(error)
        }
        this.$utils.hideLoadingIndicator()
      },
      updateKanbanCard() {
        let t = this
        return new Promise((resolve, reject) => {
          t.refreshKanban = false
          t.$nextTick(() => {
            t.refreshKanban = true
            t.$nextTick(() => {
              resolve()
            })
          })
        })
      },
      changeRouteColor(latitude, longitude, serviceId, status) {
        //plotRoute(dealershipMarker, serviceMarker, strokeColor, map, serviceId) {
        let t = this
        let serviceAssets = t.getServiceAssets(status)
        let service = t.model.services.find(item => item.id == serviceId)
        if (!service) return
        let dealership = service.dealership

        let dealershipMarker = {
          latitude: dealership.latitude,
          longitude: dealership.longitude,
          icon: mapAssets.png.volvoTotem,
          description: "dealershipMarker",
        }
        let serviceMarker = {
          latitude: service.latitude,
          longitude: service.longitude,
          icon: serviceAssets.truckIcon,
          description: 'serviceMarker'
        }

        // debugger

        // console.log(serviceId)

        let oldServiceMarker = t.markers.find(item => {

          if (item && item.hasOwnProperty('serviceId')) {

            // console.log(item.serviceId)
            return item.serviceId == serviceId
          }
          return false
        })


        if (oldServiceMarker) {
          oldServiceMarker.setMap(null)

          let indexOfOldService = t.markers.indexOf(t.markers.find(item => {
            if (item && item.hasOwnProperty('serviceId')) {
              return item.serviceId == serviceId
            }
            return false
          }))
          t.markers.splice(indexOfOldService, 1)
        }
        t.$forceUpdate()
        t.plotServiceMarker(serviceMarker, t.map, serviceId)

        let pointA = new google.maps.LatLng(dealershipMarker.latitude, dealershipMarker.longitude)
        let pointB = new google.maps.LatLng(service.latitude, service.longitude)
        if (t.directionsDisplays[serviceId]) {
          t.directionsDisplays[serviceId].setMap(null)
          t.directionsDisplays[serviceId] = null
          delete t.routes[pointA + pointB]

}
        t.plotRoute(dealershipMarker, {latitude: service.latitude, longitude: service.longitude}, serviceAssets.strokeColor, t.map, serviceId)
      },
      grabStatusByServiceNotification(serviceNotificationStatus, serviceStatus){
        if (serviceNotificationStatus == 5 || serviceNotificationStatus == 7) {
          return serviceStatus
        }
        if (serviceNotificationStatus == 6) {
          return 1
        }
        return serviceNotificationStatus
      },
      removeEmployee(serviceId) {
        let t = this
        let employeeMarker = t.employeeMarkers[serviceId]
        if (employeeMarker) {
          delete t.employeeMarkers[serviceId]
          employeeMarker.setMap(null)
        }
      },
      removeService(serviceId){

        let t = this
        let service = t.model.services.find(item => item.id == serviceId)
        if (!service) return
        let dealership = service.dealership

        let dealershipMarker = {
          latitude: dealership.latitude,
          longitude: dealership.longitude,
        }
        let serviceMarker = {
          latitude: service.latitude,
          longitude: service.longitude,
        }
        let pointA = new google.maps.LatLng(dealershipMarker.latitude, dealershipMarker.longitude)
        let pointB = new google.maps.LatLng(serviceMarker.latitude, serviceMarker.longitude)

        if (t.directionsDisplays[serviceId]) {
          t.directionsDisplays[serviceId].setMap(null)
          t.directionsDisplays[serviceId] = null
          delete t.routes[pointA + pointB]
        }
        let oldServiceMarker = t.markers.find(item => {
          if (item && item.hasOwnProperty('serviceId')) {
            return item.serviceId == serviceId
          }
          return false
        })

        if (oldServiceMarker) {

          oldServiceMarker.setMap(null)
          let indexOfOldService = t.markers.indexOf(t.markers.find(item => {
            if (item && item.hasOwnProperty('serviceId')) {
              return item.serviceId == serviceId
            }
            return false
          }))
          t.markers.splice(indexOfOldService, 1)
        }
      },
      async checkForNewServices() {
        try {
          const services = await this.checkNewServices()
          if (services.length === 0) {
            this.debounceCheckNewServices()
            return
          }
          services.forEach(newService => {
            const serviceToUpdate = this.model.services.find(oldService => oldService.id === newService.id)
            if (serviceToUpdate) {
              this.model.services = this.model.services.filter(item => item.id !== serviceToUpdate.id);
              this.model.notifications = this.model.notifications.filter(item => item.serviceId !== serviceToUpdate.id);
              this.model.trackings = this.model.trackings.filter(item => item.serviceId !== serviceToUpdate.id);
            }
          })
          this.model.services.push(...services)
          const serviceIds = services.map(service => service.id)
          const trackings = await this.loadTrackings({ ServiceIds: serviceIds })
          const notifications = await this.loadServicesNotifications({ ServiceIds: serviceIds })
          this.model.trackings.push(...trackings)
          this.model.notifications.push(...notifications)
          services.forEach(service => {
            this.updateKanbanCard().then(() => {
              this.changeRouteColor(service.latitude, service.longitude, service.id, service.status)
            })
          })
          this.debounceCheckNewServices()
        } catch(error) {
          console.warn(error)
        }
      },
      prepareToRefresh(refreshTime) {
        // console.log('[LOG] Prepare autorefresh')
        return setTimeout(() => {
          window.location.reload()
        }, refreshTime)
      }
    },

  sockets: {
    connect: function(){
      let t = this
      // console.log('[SOCKET] Connected to server')
      if (t.wasDisconnected == true) {
        t.$socket.emit('join', '123456')
      }
    },
    update(message) {
      let t = this
      // console.log('[SOCKET] Connected to server')
      t.wasDisconnected = false
    },

    disconnect() {
      let t = this
      // console.log( 'disconnected to server' );
      t.wasDisconnected = true
    },
    message(message) {
      let t = this

      // console.log('[SOCKET] Message', message)
      let data = JSON.parse(message)

      // if (data.status == 4)  debugger
      if (
        !data.hasOwnProperty('serviceId') ||
        !data.hasOwnProperty('latitude') ||
        !data.hasOwnProperty('longitude') ||
        !data.hasOwnProperty('status')

      ) return false

      //  RETORNAR SE NÃO TIVER SERFICES AINDA
      if (!t.model.services) return false
      let service = t.model.services.find(service => service.id == data.serviceId)
      //
      if (!service) {
        return t.checkForNewServices()
      }
      if (data.status == 3 && service.hasOwnProperty('hasAlreadyReceivedStatus3') && service.hasAlreadyReceivedStatus3) {

        return
      }

      if (data.status == 3) {
        service.hasAlreadyReceivedStatus3 = true
      }

      if (
        (
          data.hasOwnProperty('serviceLatitude') && service.latitude !== data.serviceLatitude &&
          data.hasOwnProperty('serviceLongitude') && service.longitude !== data.serviceLongitude &&
          data.hasOwnProperty('placeDescription') && service.placeDescription !== data.placeDescription
        )
        ||
        data.hasOwnProperty('distance') &&  data.distance !== null && service.distance !== data.distance
      ) {
        service.latitude = data.serviceLatitude
        service.longitude = data.serviceLongitude
        service.placeDescription = data.placeDescription
        //if (data.hasOwnProperty('distance') &&  data.distance !== null && service.distance !== data.distance) {
          let distance = data.distance / 1000
          distance = distance.toString().slice(0, -1)
          service.distance = distance
        // }

        t.removeService(data.serviceId)
        t.updateKanbanCard().then(() => {
          t.changeRouteColor(service.latitude, service.longitude, service.id, service.status)
        })

        t.$forceUpdate()
      }

      if (data.hasOwnProperty('status') && service.status !== data.status) {

        t.$forceUpdate()
        if (t.searchFilter.status && t.searchFilter.status == data.status) {
          t.noneOnMap = false
        }
        service.status = t.grabStatusByServiceNotification(data.status, service.status)
        let newNotificationFromWebSocket = {
          serviceId: data.serviceId,
          type: data.status,
          date: utils.fixDateWithoutGMT(data.time)
        }
        t.model.notifications.push(newNotificationFromWebSocket)

        t.updateKanbanCard().then(response => {
           if (data.status == 6) {
            data.status = 1
          }
          if (data.status == 5) {
            data.status = 2
          }

          // if (t.searchFilter.status && t.searchFilter.status !== data.status ) {
          if (!t.filteredServices.find(item => item.id == data.serviceId)) {
            t.removeEmployee(service.id)
            t.removeService(data.serviceId)
          } else {

            t.removeService(data.serviceId)
            t.changeRouteColor(data.latitude, data.longitude, service.id, service.status)
            t.removeEmployee(service.id)

            let presentStatusDontNeedEmployeeMarker = service.status == 1 || service.status == 4
            if(presentStatusDontNeedEmployeeMarker) {
            } else {
              t.moveEmployeeMarkerByServiceId(data.latitude, data.longitude, service.id)
            }
          }
        })
      }
      else {
        //
        t.removeEmployee(service.id)
        t.moveEmployeeMarkerByServiceId(data.latitude, data.longitude, service.id)

      }

    },
  },
  mounted () {
    let t = this

    this.isFullScreenMode = JSON.parse(sessionStorage.getItem('isFullScreen'))
    t.pageName = t.$store.state.pageName
    let localStorageFilters = localStorage.getItem('dashboardFilters')
    let localStoragePeriodFilter = localStorage.getItem('dashboardPeriodFilter')
    if (localStorageFilters) {
      t.searchFilter = JSON.parse(localStorageFilters)
    }
    if (localStoragePeriodFilter) {
      t.periodFilterSelected = JSON.parse(localStoragePeriodFilter)
    }
    t.prepareEverything()

    // 3 horas
    let refreshTime = 1000 * 60 * 60 * 3
    // let refreshTime = 1000 * 20
    this.refreshInterval = this.prepareToRefresh(refreshTime)
  },
  beforeDestroy() {
    this.debounceCheckNewServices.cancel()
    clearInterval(this.refreshInterval)
    this.refreshInterval = false
  },

  watch: {
    'searchFilter.startDate': function (val) {
      this.mountMap()
    },
    'searchFilter.endDate': function (val) {
      this.mountMap()
    },
    searchFilter: {
      handler(val) {
        let t = this
        t.searchFilter = val
        localStorage.setItem('dashboardFilters', JSON.stringify(t.searchFilter))
      },
      deep: true
    },
    periodFilterSelected(val) {
      let t = this
      t.periodFilterSelected = val
      localStorage.setItem('dashboardPeriodFilter', JSON.stringify(t.periodFilterSelected))
    }

  },
}
</script>