var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c("v-card-title", { staticClass: "headline" }, [
        _vm._v(_vm._s(_vm.title) + " ")
      ]),
      _vm.$slots["default"]
        ? _c("v-card-text", [_vm._t("default")], 2)
        : _vm._e(),
      _c(
        "v-card-actions",
        [
          _c("v-spacer"),
          !_vm.noCancelButton
            ? _c(
                "v-btn",
                {
                  attrs: { color: "green darken-1", flat: "flat" },
                  nativeOn: {
                    click: function($event) {
                      return _vm.$emit("cancel")
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("misc.cancel")))]
              )
            : _vm._e(),
          _c(
            "v-btn",
            {
              attrs: { color: "green darken-1", flat: "flat" },
              nativeOn: {
                click: function($event) {
                  return _vm.$emit("confirm")
                }
              }
            },
            [_vm._v(_vm._s(_vm.confirmText || _vm.$t("misc.confirm")))]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }