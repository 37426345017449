import axios from 'axios'
import config from '@/app.settings'
import utils from '@/libs/utils'

export const state = {
  dealerships: [],
}
function dealershipDefaultAdapter(dealerships) {
  dealerships.forEach(dealership => {
    utils.uncapitalizeAllKeys(dealership)
  })
  return dealerships
}

export const getters = {}

export const mutations = {
  SET_DEALERSHIPS (state, payload) {
    state.dealerships = payload
  },
}

export const actions = {
  async addDealership ({commit}, payload) {
    utils.capitalizeAllKeys(payload)
    let response = await axios.post(config.apiUrl + '/dealerships/', payload)
    return response.data
  },
  async editDealership ({commit}, payload) {

    utils.capitalizeAllKeys(payload)
    let response = await axios.put(config.apiUrl + `/dealerships/${payload.Id}`, payload)
    return response.data
  },
  async loadDealerships ({commit}, payload) {

    let response = await axios.post(config.apiUrl + '/dealerships/search', {})
    return dealershipDefaultAdapter(response.data)
  },
  async loadDealership ({commit}, payload) {
    utils.capitalizeAllKeys(payload)
    let response = await axios.post(config.apiUrl + '/dealerships/search', {
      Id: payload.Id
    })
    return dealershipDefaultAdapter(response.data)
  },
  async deleteDealership ({commit}, payload) {
    let response =  axios.delete(config.apiUrl + '/dealerships/' + payload)
    return response.data
  },
}