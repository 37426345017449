var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-footer",
    { staticClass: "pa-3" },
    [
      _c("v-spacer"),
      _c("div", [
        _c("div", { staticClass: "status-indicator" }, [
          _c("div", { staticClass: "status-badge processing" }),
          _c("div", { staticClass: "status-label " }, [
            _vm._v(_vm._s(_vm.$t("footerStatus.report")))
          ])
        ]),
        _c("div", { staticClass: "status-indicator" }, [
          _c("div", { staticClass: "status-badge departure" }),
          _c("div", { staticClass: "status-label " }, [
            _vm._v(_vm._s(_vm.$t("footerStatus.start")))
          ])
        ]),
        _c("div", { staticClass: "status-indicator" }, [
          _c("div", { staticClass: "status-badge repairing" }),
          _c("div", { staticClass: "status-label" }, [
            _vm._v(_vm._s(_vm.$t("footerStatus.repairing")))
          ])
        ]),
        _c("div", { staticClass: "status-indicator" }, [
          _c("div", { staticClass: "status-badge finished" }),
          _c("div", { staticClass: "status-label" }, [
            _vm._v(_vm._s(_vm.$t("footerStatus.end")))
          ])
        ]),
        _vm.showCancelled
          ? _c("div", { staticClass: "status-indicator" }, [
              _c("div", { staticClass: "status-badge cancelled" }),
              _c("div", { staticClass: "status-label" }, [
                _vm._v(_vm._s(_vm.$t("footerStatus.cancel")))
              ])
            ])
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }