<template>
  <v-card>
    <v-card-title class="headline"></v-card-title>
    <v-card-text class="aviso-lgpd-modal__text">
      <v-icon x-large color="#286AA6">security</v-icon>
      <p class="description mt-4 mb-0">{{ $t('pageFollowService.textTerms') }}</p>
    </v-card-text>
    <v-card-actions class="aviso-lgpd-modal__footer pb-3">
      <v-btn
        class="aviso-lgpd-modal__button vv-button btn-primary btn-confirm mb-2"
        flat
        @click.native="$emit('policy')"
      >
        {{ $t('pageFollowService.buttonTerms') }}
      </v-btn>
      <v-btn
        class="aviso-lgpd-modal__button vv-button vv-button-inverted btn-primary btn-confirm mb-2"
        @click.native="$emit('confirm')"
      >
        {{ $t('pageFollowService.buttonConfirm') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>

export default {
  name: 'AvisoLgpdModal',
}
</script>

<style lang="scss">
.aviso-lgpd-modal__button {
  text-transform: none !important;
  letter-spacing: normal;
  font-weight: bold;
  margin-left: 0 !important;
  font-size: 16px !important;
  &::before {
    opacity: 0 !important;
  }
  &.btn-confirm {
    max-width: 300px !important;
  }
  &.vv-button {
    border-color: #286AA6 !important;
    color: #286AA6 !important;
    &:hover {
      background-color: #286AA6 !important;
      color: #ffffff !important;
    }
    &.vv-button-inverted {
      background-color: #286AA6 !important;
      color: #ffffff !important;
      &:hover {
        background-color: #fff !important;
        color:  #286AA6 !important;
      }
    }
  }
}
.aviso-lgpd-modal__footer {
  flex-direction: column;
  justify-content: center;
}
.aviso-lgpd-modal__text {
  text-align: center;
  .description {
    font-size: 16px;
    color: #35343D;
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
  }
}
</style>