<template>
  <div id="line-chart-container" class="line-chart__container"></div>
</template>

<script>
import * as d3 from 'd3';

export default {
  name: 'DashboardLineChart',
  props: {
    chartData: {
      type: Object,
    },
    width: {
      default: 970,
      type: Number,
    },
    height: {
      default: 210,
      type: Number,
    },
  },
  data: () => ({
    svg: null,
    margin: { top: 20, right: 50, bottom: 30, left: 10 },
    dateParser: null,
    line: null,
    xScale: null,
    yScale: null,
    allValues: [],
    allUniqueDates: [],
    timeFormat: null,
    tooltip: null,
    tooltipBox: null,
    tooltipLine: null,
  }),
  computed: {
    rangeX() {
      const width = this.width - (this.margin.right + 30);
      return [this.margin.left + 40, width];
    },
    rangeY() {
      const height = this.height - this.margin.bottom;
      return [height, this.margin.top];
    },
    svgBBox() {
      return this.svg.node().getBBox();
    },
  },
  mounted() {
    this.createChart();
    this.parseData();
    this.drawChart();
    this.createTooltip();
  },
  methods: {
    async parseData() {
      this.allValues = [];
      this.allUniqueDates = [];
      this.dateParser = d3.timeParse('%Y-%m-%d');
      Object.keys(this.chartData).forEach((key) => {
        this.chartData[key].forEach((d) => {
          this.allValues.push(d.value);
          d.date = this.dateParser(d.date);
          if (!this.allUniqueDates.some((unique) => unique.date && unique.date.getTime() === d.date.getTime())) {
            this.allUniqueDates.push(d);
          }
        });
      });
      this.fillZeroValues();
      const locale = await d3.json('https://cdn.jsdelivr.net/npm/d3-time-format@2/locale/pt-BR.json');
      d3.timeFormatDefaultLocale(locale);
    },
    fillZeroValues() {
      Object.keys(this.chartData).forEach((key) => {
        this.allUniqueDates.forEach((unique) => {
          if (!this.chartData[key].some((d) => d.date.getTime() === unique.date.getTime())) {
            this.chartData[key].push({ value: 0, date: unique.date });
          }
        });
        this.chartData[key].sort((a, b) => a.date - b.date);
      });
      this.allUniqueDates.sort((a, b) => a.date - b.date);
    },
    createChart() {
      d3.select('svg#line-chart').remove();
      this.svg = d3
        .select('#line-chart-container')
        .append('svg')
        .attr('id', 'line-chart')
        .attr('viewBox', `0 0 ${this.width} ${this.height}`);
    },
    drawChart() {
      this.setScales();
      this.setAxes();
      this.line = d3
        .line()
        .x((d) => this.xScale(d.date))
        .y((d) => this.yScale(d.value));
      Object.keys(this.chartData).forEach((key) => {
        this.svg
          .append('path')
          .datum(this.chartData[key])
          .attr('fill', 'none')
          .attr('stroke', this.getLineColor(key))
          .attr('stroke-width', 3)
          .attr('stroke-opacity', 0.8)
          .attr('d', this.line);
      });
    },
    setScales() {
      this.xScale = d3
        .scaleTime()
        .range(this.rangeX)
        .domain(d3.extent(this.allUniqueDates, (d) => d.date));
      this.yScale = d3
        .scaleLinear()
        .range(this.rangeY)
        .domain([0, d3.max(this.allValues)]);
    },
    setAxes() {
      // x axis
      this.timeFormat = d3.timeFormat('%e/%m');
      this.svg
        .append('g')
        .style('transform', `translate(0, ${this.height - this.margin.bottom}px`)
        .call(
          d3
            .axisBottom(this.xScale)
            .tickValues(this.allUniqueDates.map((item) => item.date))
            .tickFormat((d) => this.timeFormat(d))
        )
        .call((g) => g.select('.domain').remove())
        .call((g) =>
          g
            .selectAll('.tick text')
            .attr('class', 'line-chart__axis-text line-chart__axis-text--x')
            .attr('y', 11)
        )
        .call((g) =>
          g
            .selectAll('.tick line')
            .attr('stroke-width', 3)
            .attr('stroke', '#979797')
        );

      // y axis
      this.svg
        .append('g')
        .style('transform', `translate(${this.width - this.margin.right}px, 0`)
        .call(
          d3
            .axisRight(this.yScale)
            .tickValues(this.yScale.ticks().filter(Number.isInteger))
            .tickFormat(d3.format('d'))
            .tickSize(-(this.width - this.margin.left - this.margin.right))
            .tickSizeOuter(0)
        )
        .call((g) =>
          g
            .selectAll('.tick line')
            .attr('stroke', '#979797')
            .attr('stroke-dasharray', '5')
            .style('opacity', '0.5')
        )
        .call((g) =>
          g
            .selectAll('.tick text')
            .attr('class', 'line-chart__axis-text')
            .attr('x', 11)
        )
        .call((g) => g.select('.domain').remove());
    },
    createTooltip() {
      this.tooltip = d3
        .select('#line-chart-container')
        .append('div')
        .attr('class', 'line-chart__tooltip')
        .style('opacity', 0)
        .style('pointer-events', 'none');
      this.tooltipBox = this.svg
        .append('g')
        .append('rect')
        .attr('x', this.svgBBox.x)
        .attr('y', this.svgBBox.y)
        .attr('width', this.svgBBox.width)
        .attr('height', this.svgBBox.height)
        .attr('opacity', 0)
        .on('touchmove mousemove', (event) => {
          this.showTooltip(event);
        })
        .on('touchend mouseleave', this.hideTooltip);
      this.tooltipLine = this.svg.append('g').append('line');
    },
    hideTooltip() {
      if (this.tooltipLine) {
        this.tooltipLine.attr('stroke', 'none');
      }
      if (this.tooltip) {
        this.tooltip.style('opacity', 0);
      }
    },
    showTooltip(event) {
      const { date, value } = this.bisect(d3.pointer(event, this.tooltipBox.node())[0]);
      this.tooltipLine
        .style('pointer-events', 'none')
        .attr('stroke', '#35343D')
        .attr('stroke-width', 15)
        .attr('opacity', 0.1)
        .attr('x1', this.xScale(date))
        .attr('x2', this.xScale(date))
        .attr('y1', 0)
        .attr('y2', this.height - this.margin.bottom);
      this.tooltip
        .style('opacity', 1)
        .style('left', `${event.x - this.width / 3}px`)
        .style('top', `${event.y - this.height - this.margin.bottom}px`)
        .html(this.getTooltipHtml(date));
    },
    bisect(mouseX) {
      const bisector = d3.bisector((d) => d.date).left;
      const date = this.xScale.invert(mouseX);
      const index = bisector(this.allUniqueDates, date, 1);
      const a = this.allUniqueDates[index - 1];
      const b = this.allUniqueDates[index];
      return b && date - a.date > b.date - date ? b : a;
    },
    getLineColor(key) {
      const lineColors = {
        reports: '#EC4B54',
        ended: '#2AB69C',
        canceled: '#ABABAC',
      };
      return lineColors[key];
    },
    getTooltipHtml(date) {
      let valuesHtml = '';
      Object.keys(this.chartData).forEach((key) => {
        const element = this.chartData[key].find((d) => d.date.getTime() === date.getTime());
        const value = element ? element.value : '——';
        const color = this.getLineColor(key);
        valuesHtml += `
          <div class="item mb-2">
            <div class="circle mr-2" style="background-color: ${color}"></div>
            <span>${value}</span>
          </div>
        `;
      });
      return `
      <div class="line-chart__tooltip--content">
        ${valuesHtml}
      </div>
      `;
    },
  },
  watch: {
    chartData: {
      handler(value) {
        this.createChart();
        this.parseData();
        this.drawChart();
        this.createTooltip();
      },
    },
  },
};
</script>

<style lang="scss">
.line-chart {
  &__axis-text {
    font-size: 10px;
    color: #35343d;
    opacity: 0.5;
    text-transform: uppercase;
    &--x {
      font-size: 8px;
      letter-spacing: -0.5px;
    }
  }
  &__tooltip {
    padding: 15px 15px 5px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    background-color: white;
    border-radius: 4px;
    position: absolute;
    z-index: 10;
    &--content {
      .item {
        display: flex;
        align-items: center;
      }
      .circle {
        width: 12px;
        height: 12px;
        border-radius: 50%;
      }
    }
  }
}
</style>
