<template>
  <div class="vv-voltar-button">

    <!--- se for passado um :to, se comporta como  <router-link> -->
    <router-link v-bind="$attrs" v-if="$attrs.to" >
      <svg viewBox="0 0 24 24">
        <path fill="#0f3299" d="M20,10V14H11L14.5,17.5L12.08,19.92L4.16,12L12.08,4.08L14.5,6.5L11,10H20Z" />
      </svg>{{$t('misc.back')}}
    </router-link>

    <!--- se for passado um @click ou href, se comporta como <a> -->
    <a v-on="$listeners" v-if="$attrs.href || $listeners.click">
      <svg viewBox="0 0 24 24">
        <path fill="#0f3299" d="M20,10V14H11L14.5,17.5L12.08,19.92L4.16,12L12.08,4.08L14.5,6.5L11,10H20Z" />
      </svg>
    {{ $t('misc.back') }}
    </a>

  </div>
</template>

<script>
export default {
  name: 'BaseVoltarButton',
}
</script>

<style lang="css" scoped>
</style></vuec>