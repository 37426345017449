<template>
  <main-layout id='atendimentos-listar-container' class='listar'>
      <v-layout
        justify-center
        row wrap
      >

      <form-alert
        :alert-model="!!alertText"
        :alert-text="alertText"
        @input="closeFormAlert()"
      />

      <v-flex md12>
        <base-search-input
          v-model="generalSearchText"
          :placeholder="$t('services.searchService')"
          @update="debounceGeneralSearch()"
        ></base-search-input>
      </v-flex>
      </v-layout>


      <v-layout row wrap>
        <v-flex md5 >
            <v-layout row wrap>
              <v-flex :class='{ md4: !oneDealership, md6: oneDealership }'>
                <base-search-input
                  v-model="mercuriusCaseSearchText"
                  :placeholder="$t('services.mercuriusCase')"
                  @update="debounceMercuriusCaseSearch()"
                ></base-search-input>
              </v-flex>
              <v-flex md5 v-if="!oneDealership" >
                <select
                  class='vv-input'
                  v-model="searchFilter.dealership"
                  :disabled="oneDealership"
                >
                  <option selected value="" class='vv-disabled'>{{$tc('services.dealership', 1)}}</option>
                  <option v-for="(dealership, key) in dealerships" :key="key" :value="dealership.id" >{{dealership.name}}</option>
                </select>
              </v-flex>
              <v-flex :class='{md3: !oneDealership, md6: oneDealership}'>
                 <select class='vv-input' v-model="searchFilter.status">
                  <option selected value="" class='vv-disabled'>{{$t('services.status')}}</option>
                  <option v-for="(state, key) in status" :key="key" :value="state">{{getStatusName(state)}}</option>
                </select>
              </v-flex>
          </v-layout>
        </v-flex>
        <v-flex md6 >
          <v-layout row wrap>
            <v-flex sm3 xs12>

              <select  class='vv-input' v-model="periodFilterSelected" @change="updatePeriodFilter">
                <option selected value="" class='vv-disabled'>{{$t('services.period')}}</option>
                <option v-for="(periodFilter, key) in periodFilters" :key="key" :value="periodFilter">{{periodFilter}}</option>
              </select>
            </v-flex>

              <v-flex sm3 xs6 v-if="isPeriodBetween" >
                <v-menu
                  lazy
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  full-width
                  :nudge-right="40"
                  max-width="290px"
                  min-width="290px"
                >
                 <v-text-field class='vv-input mt-0 px-1 pt-2'
                    slot="activator"
                    readonly
                    :placeholder="$t('services.start')"
                    v-model="filteredDates.startDate"
                  ></v-text-field>
                  <v-date-picker no-title v-model="searchFilter.startDate" scrollable locale="pt-BR"  actions autosave>
                    <!-- <template slot-scope="{ save, cancel }">
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn flat color="primary" @click="cancel">{{$t('misc.cancel')}}</v-btn>
                        <v-btn flat color="primary" @click="save">{{$t('misc.ok')}}</v-btn>
                      </v-card-actions>
                    </template> -->
                  </v-date-picker>
                </v-menu>
              </v-flex>
              <v-flex sm3 xs6 v-if='isPeriodBetween' >
                <v-menu
                  lazy
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  full-width

                  :nudge-right="40"
                  max-width="290px"
                  min-width="290px"
                >
                 <v-text-field class='vv-input mt-0 px-1 pt-2'
                    slot="activator"
                    readonly
                    v-model="filteredDates.endDate"
                    :placeholder="$t('services.end')"
                  ></v-text-field>
                  <v-date-picker no-title v-model="searchFilter.endDate" locale="pt-BR" scrollable actions autosave>
                    <!-- <template slot-scope="{ save, cancel }">
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn flat color="primary" @click="cancel">{{$t('misc.cancel')}}</v-btn>
                        <v-btn flat color="primary" @click="save">{{$t('misc.ok')}}</v-btn>
                      </v-card-actions>
                    </template> -->
                  </v-date-picker>
                </v-menu>
              </v-flex>

          </v-layout>

        </v-flex>
        <v-flex md1>
          <v-subheader class='cleanfilters' @click='cleanFilters()'>{{$t('services.reset')}}</v-subheader>
        </v-flex>
      </v-layout>
      <v-data-table
        :headers="headers"
        :items="services"
        :rows-per-page-items="[50, 100, {text:'Todas', value: -1}]"
        :pagination.sync="options"
        :total-items="totalServices"
        class="elevation-1"
        no-data-text="Sem dados disponíveis"
      >

      <template slot="items" slot-scope="props">
        <router-link :to="{path: '/atendimentos/' + props.item.id}" tag="td" :class="getServiceClass(props.item.status)">
          {{ props.item.mercuriusCase }}
        </router-link>
        <router-link :to="{path: '/atendimentos/' + props.item.id}" tag="td" class=""
          v-html="props.item.dealership.name">
        </router-link>
        <router-link :to="{path: '/atendimentos/' + props.item.id}" tag="td"
          v-html="(props.item.startDate) ? formatISODate(props.item.startDate) : '———'">
        </router-link>
        <router-link :to="{path: '/atendimentos/' + props.item.id}" tag="td"
          v-html="(props.item.endDate) ? formatISODate(props.item.endDate) : '———'">
        </router-link>
        <router-link :to="{path: '/atendimentos/' + props.item.id}" tag="td" class=""
          v-html="getStatusName(props.item.status)">
        </router-link>
        <router-link :to="{path: '/atendimentos/' + props.item.id}" tag="td" class=""
          v-html="getCancelledText(props.item)">
        </router-link>
        <td >
          <atendimentos-pin-svg
          :id="props.item.id" />
        </td>
      </template>

    </v-data-table>

    <kanban-footer show-cancelled />
  </main-layout>

</template>

<script>
import { mapState, mapActions } from 'vuex'
import ViewMixin from '@/mixins/ViewMixin'
import config from '@/app.settings'
import utils from "@/libs/utils"
import _ from "lodash"

const defaultDataTableOptions = {
  rowsPerPage: 50,
  page: 1,
  sortBy: 'mercurius_case',
  descending: true
}

export default {
  name: 'Home',
  components: {

  },
  data: () => ({
    drawer: null,
    mercuriusCaseSearchText: '',
    generalSearchText: '',
    searchFilter: {
      generalSearch: '',
      dealership: '',
      status: '',
      mercuriusCase: '',
      startDate: '',
      endDate: '',
      startTime: '',
      endTime: ''
    },
    options: defaultDataTableOptions,
    periodFilterSelected: '',
    dealerships: [],
    status: [],
    menu: null,
    alert: true,
    alertText: false,
    firstLoadServiceRequest: true,
    loadServicesByFilterChange: false,
    busy: false,
    refreshData: _.debounce(function() { this.fetchData() }, 30000),
    debounceGeneralSearch: _.debounce(function() { this.updateGeneralSearch() }, 600),
    debounceMercuriusCaseSearch: _.debounce(function() { this.updateMercuriusCaseSearch() }, 600),
  }),
  mixins: [ViewMixin],
  computed: {
    ...mapState('services', ['services', 'totalServices']),

    filteredDates() {
      let t = this
      let startDate = (this.searchFilter.startDate )
        ? this.searchFilter.startDate.split('-').reverse().join('/')
        : ''

      let endDate = (this.searchFilter.endDate )
        ? this.searchFilter.endDate.split('-').reverse().join('/')
        : ''

      return { startDate, endDate }

    },
    oneDealership () {
      return this.dealerships && this.dealerships.length  === 1
    },
    periodFilters() {
      return [
        this.$t('services.last24h'), 
        this.$t('services.last48h'), 
        this.$t('services.last72h'),
        this.$t('services.lastMonth'),
        this.$t('services.periodBetween')
      ];
    },
    isPeriodBetween() {
      return this.periodFilterSelected == _.last(this.periodFilters);
    },
    headers() {
      return [
        { text: this.$t('services.mercuriusCase'), align: 'left',  value: 'mercurius_case'  , sortable: true },
        { text: this.$tc('services.dealership', 1), align: 'left', value: 'dealership' , sortable: true },
        { text: this.$t('services.start'), align: 'left', value: 'startDate' , sortable: true },
        { text: this.$t('services.end'), align: 'left', value: 'endDate' , sortable: true },
        { text: this.$t('services.status'), align: 'left', value: 'status' , sortable: true },
        { text: this.$t('services.substatus'), align: 'left', value: 'substatus' , sortable: false },
        { text: '', align: 'left', value: 'acao', sortable: false },
      ]
    }

  },
  methods: {
    ...mapActions('services', [
      'loadServices',
    ]),
    ...mapActions('dealerships', ['loadDealerships']),
    getCancelledText({ reason, status }) {
      if (status !== config.CANCELLED_STATUS) {
        return 'OK';
      }
      let text =
        (reason == 0) ? this.$t('services.waiver') :
        (reason == 1) ? this.$t('services.towCar') :
        (reason == 2) ? this.$t('services.failedApp') : 
        (reason == 3) ? this.$t('services.notUsedApp') : 
        (reason == 4) ? this.$t('services.others') : 'OK'
      return text
    },
    formatISODate (date) {
      let dateAndTimeSeparated = date.split('T')
      dateAndTimeSeparated[0] = dateAndTimeSeparated[0].split('-').reverse().join('/')
      dateAndTimeSeparated[1] = dateAndTimeSeparated[1].substr(0, 8)
      let finalDate = dateAndTimeSeparated.join(' - ')
      return finalDate
    },
    getServiceClass(status){
      let classes = []
      switch(status) {
        case 1:
          classes.push('processing')
          break;
        case 2:
          classes.push('departure')
          break;
        case 3:
        case 8:
        case 9:
          classes.push('started')
          break;
        case 4:
          classes.push('finished')
          break;
        case 5:
          classes.push('departure')
          break;
        case 10:
          classes.push('cancelled')
          break;
      }
      return classes
    },
    getStatusName (status) {
      let name = ''
      switch (status) {
        case 1:
          name = this.$t('footerStatus.report')
          break
        case 2:
          name = this.$t('footerStatus.start')
          break
        case 3:
          name = this.$t('footerStatus.repairing')
          break
        case 4:
          name = this.$t('footerStatus.end')
          break
        case 5:
          name = this.$t('footerStatus.start')
          break
        case 8:
          name = this.$t('footerStatus.paused')
          break
        case 9:
          name = this.$t('footerStatus.resumed')
          break
        case 10:
          name = this.$t('footerStatus.cancel')
          break
      }
      return name;
    },
    cleanFilters(event) {
      for (var a in this.searchFilter) {
        this.searchFilter[a] = ''
      }
      this.generalSearchText = this.mercuriusCaseSearchText = ''
      this.periodFilterSelected = ''
      this.$forceUpdate()
    },
    getOptionsPayload(options) {
      const { page, rowsPerPage, sortBy, descending } = options;
      let orderBy = sortBy 
        ? sortBy === 'mercurius_case' 
          ? 'MercuriusCase' 
          : (sortBy.charAt(0).toUpperCase() + sortBy.slice(1))
        : ''
      if (descending) {
        orderBy = '-' + orderBy
      }
      return {
        PageNumber: page,
        PageSize: rowsPerPage,
        OrderBy: orderBy
      };
    },
    getFiltersPayload(filters) {
      const startDate = filters.startDate ? new Date(filters.startDate) : '';
      const endDate = filters.endDate ? new Date(filters.endDate) : '';
      return {
        DealershipId: filters.dealership,
        FromDate: startDate ? startDate.toISOString() : '',
        MercuriusCase: filters.mercuriusCase,
        Status: filters.status ? [filters.status] : '',
        ToDate: endDate ? endDate.toISOString() : '',
        Query: filters.generalSearch
      }
    },
    async fetchData() {
      if (this.busy) return
      if (this.firstLoadServiceRequest || this.loadServicesByFilterChange) {
        this.$utils.showLoadingIndicator()
      }
      try {
        this.busy = true
        await this.loadServices({ payload: this.getFiltersPayload(this.searchFilter), queryParams: this.getOptionsPayload(this.options) })
        if (this.firstLoadServiceRequest) {
          this.dealerships = await this.loadDealerships()
          if (this.dealerships.length == 1) {
            this.searchFilter.dealership = this.dealerships[0].id
          }
          this.status = [1, 2, 3, 4, 8, 9, 10]
        }
      } catch (error) {
        console.warn(error)
      }
      this.$store.commit('main/SET_ALERT_TEXT', false)
      if (this.firstLoadServiceRequest || this.loadServicesByFilterChange) {
        this.$utils.hideLoadingIndicator()
      } 
      this.firstLoadServiceRequest = false
      this.loadServicesByFilterChange = false
      this.busy = false
      this.refreshData()
    },
    updateGeneralSearch() {
      this.searchFilter.generalSearch = this.generalSearchText
    },
    updateMercuriusCaseSearch() {
      this.searchFilter.mercuriusCase = this.mercuriusCaseSearchText
    },
    updatePeriodFilter(value) {
      if (this.periodFilters.includes(this.periodFilterSelected) && !this.isPeriodBetween) {
        this.searchFilter.startDate = this.searchFilter.endDate = ''
        const multipliers = [24, 48, 72, 24*30]
        const currentTimestamp = Math.round(new Date(new Date().setHours(0, 0, 0, 0)).getTime() / 1000)
        const periodTimestamp = currentTimestamp - (multipliers[this.periodFilters.indexOf(this.periodFilterSelected)] * 3600)
        const periodStartDate = new Date(periodTimestamp * 1000)
        this.searchFilter.startDate = `${periodStartDate.getFullYear()}-${periodStartDate.getMonth() + 1}-${periodStartDate.getDate()}`
      }
    },
  },

  props: {
    source: String
  },
  mounted() {
    let t = this
    let localStorageFilters = localStorage.getItem('atendimentosFilter')
    let localStoragePeriodFilter = localStorage.getItem('atendimentosPeriodFilter')
    if (localStorageFilters) {
      this.searchFilter = JSON.parse(localStorageFilters)
      this.mercuriusCaseSearchText = this.searchFilter.mercuriusCase
      this.generalSearchText = this.searchFilter.generalSearch
      this.$forceUpdate()
    }
    if (localStoragePeriodFilter) {
      this.periodFilterSelected = JSON.parse(localStoragePeriodFilter)
      this.$forceUpdate()
    }
    this.fetchData()
    this.alertText = this.$utils.handleAlertText()
  },
  beforeDestroy() {
    this.refreshData.cancel()
  },

  watch: {
    searchFilter: {
      handler(value) {
          localStorage.setItem('atendimentosFilter', JSON.stringify(this.searchFilter))
          this.loadServicesByFilterChange = true
          this.fetchData()
      },
      deep: true
    },
    periodFilterSelected(value) {
      localStorage.setItem('atendimentosPeriodFilter', JSON.stringify(this.periodFilterSelected))
    },
    options: {
      handler(value) {
        this.loadServicesByFilterChange = true
        this.fetchData()
      },
      deep: true
    }
  }

}
</script>