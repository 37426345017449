<template>
  <router-link v-bind="$attrs" class='btn-primary vv-button vv-adicionar-novo-button'>
    <svg style="width:22px;height:22px" viewBox="0 0 24 24" class='vv-adicionar-novo-plus'>
      <path fill="#0f3299" d="M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M13,7H11V11H7V13H11V17H13V13H17V11H13V7Z" />
    </svg>
    <span v-html="text"> </span>
  </router-link>
</template>

<script>
export default {

  name: 'BaseAdicionarButton',

  data () {
    return {

    }
  },
  props: {
    text: {
      type: String,
      default: 'Adicionar'
    }
  }
}
</script>

<style lang="css" scoped>
</style>