<template>
  <main-layout>
    <v-layout row wrap>
      <v-flex
        md10
        class="dashboard__title-wrapper mb-2"
        :class="{ selectable: isVasManager }"
      >
        <div @click="openEconomicGroupModal">
          <h1>{{ economicGroupName }}</h1>
          <v-icon color="#35343D" v-if="isVasManager">arrow_drop_down</v-icon>
        </div>
      </v-flex>
      <v-flex md2 class="dashboard__btn-update-wrapper">
        <v-btn round class="dashboard__btn dashboard__btn-update" @click="fetchData">
          <v-icon color="#1E3673" class="mr-2">update</v-icon>
          {{ $t('misc.update') }}
        </v-btn>
        <v-btn class="dashboard__btn dashboard__btn-download" @click="downloadSheet">
          <v-icon color="white" class="mr-2">get_app</v-icon>
          {{ $t('misc.export') }}
        </v-btn>
      </v-flex>
    </v-layout>
    <v-layout row wrap>
      <v-flex md4>
        <select
          class="vv-input"
          v-model="searchFilter.dealership"
          :disabled="!(isVasManager || isEconomicGroupManager)"
        >
          <option selected value="" class="vv-disabled" v-if="isVasManager || isEconomicGroupManager">
            {{ $tc('services.dealership', 1) }}
          </option>
          <option v-for="(dealership, key) in filteredDealerships" :key="key" :value="dealership.id">
            {{ dealership.name }}
          </option>
        </select>
      </v-flex>
      <v-flex md7>
        <v-layout row wrap>
          <v-flex md4>
            <select class="vv-input" v-model="periodFilterSelected" @change="updatePeriodFilter">
              <option selected value="" class='vv-disabled'>{{$t('services.period')}}</option>
              <option v-for="(periodFilter, key) in periodFilters" :key="key" :value="periodFilter.days">
                {{ periodFilter.label }}
              </option>
            </select>
          </v-flex>

          <v-flex md3 v-if="periodFilterSelected === 0">
            <v-menu
              lazy
              :close-on-content-click="true"
              transition="scale-transition"
              offset-y
              full-width
              :nudge-right="40"
              max-width="290px"
              min-width="290px"
            >
              <v-text-field
                class="vv-input mt-0 px-1 pt-2"
                slot="activator"
                readonly
                :placeholder="$t('services.start')"
                v-model="filteredDates.startDate"
              ></v-text-field>
              <v-date-picker no-title v-model="searchFilter.startDate" scrollable locale="pt-BR" actions autosave>
              </v-date-picker>
            </v-menu>
          </v-flex>
          <v-flex md3 v-if="periodFilterSelected === 0">
            <v-menu
              lazy
              :close-on-content-click="true"
              transition="scale-transition"
              offset-y
              full-width
              :nudge-right="40"
              max-width="290px"
              min-width="290px"
            >
              <v-text-field
                class="vv-input mt-0 px-1 pt-2"
                slot="activator"
                readonly
                v-model="filteredDates.endDate"
                :placeholder="$t('services.end')"
              ></v-text-field>
              <v-date-picker no-title v-model="searchFilter.endDate" locale="pt-BR" scrollable actions autosave>
              </v-date-picker>
            </v-menu>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex md1>
        <v-subheader class="cleanfilters" @click="cleanFilters()">
          {{ $t('services.reset') }}
        </v-subheader>
      </v-flex>
    </v-layout>
    <v-layout row wrap v-if="!busy">
      <v-flex md12 class="mt-3">
        <v-card>
          <v-card-text class="dashboard__line-chart-header pb-4">
            <v-layout row justify-space-between align-center class="ma-0">
              <v-flex md6>
                <v-layout column class="mx-0">
                  <p class="mb-2">{{ $t('services.numberServices') }}</p>
                  <h2 class="mt-1">{{ totalCases }} {{ $t('services.cases') }}</h2>
                </v-layout>
              </v-flex>
              <v-flex md6>
                <div class="dashboard__line-chart-legend">
                  <div class="legend-item mr-3" v-for="(item, index) in lineChartLegend" :key="index">
                    <div class="legend-dot mr-2" :style="{ 'background-color': item.color }"></div>
                    <span class="legend-text">{{ item.text }}</span>
                  </div>
                </div>
              </v-flex>
            </v-layout>
          </v-card-text>
          <DashboardLineChart v-if="lineChartData" :chartData="lineChartData" />
        </v-card>
      </v-flex>
      <v-flex md12 class="mt-3">
        <v-card>
          <v-card-text class="dashboard__line-chart-header pb-3">
            <v-layout row justify-space-between align-center class="ma-0">
              <v-flex md5>
                <v-layout column class="mx-0">
                  <p class="mb-2">{{ $t('services.numberCancelServices') }}</p>
                  <h2 class="mt-1">
                    {{ totalCanceled }} {{ $t('services.cases') }}
                    <span class="percentage">{{ canceledPercentage }}{{ $t('services.percentageCancelServices') }}</span>
                  </h2>
                </v-layout>
              </v-flex>
              <v-flex md7>
                <div class="dashboard__line-chart-legend dashboard__line-chart-legend--bar">
                  <div class="legend-item mr-3 mt-2" v-for="(item, index) in barChartLegend" :key="index">
                    <div class="legend-dot mr-2" :style="{ 'background-color': item.color }"></div>
                    <span class="legend-text">{{ item.text }}</span>
                  </div>
                </div>
              </v-flex>
            </v-layout>
          </v-card-text>
          <DashboardBarChart v-if="dashboardCanceledData" :chartData="dashboardCanceledData.items" />
        </v-card>
      </v-flex>
    </v-layout>
    <v-dialog v-model="economicGroupModal" v-if="economicGroupModal" max-width="600">
      <SelecionarGrupoEconomicoModal
        :options="economicGroups"
        @cancel="economicGroupModal = false"
        @select="changeEconomicGroup"
      >
      </SelecionarGrupoEconomicoModal>
    </v-dialog>
  </main-layout>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import config from '@/app.settings';
import utils from '@/libs/utils';
import _ from 'lodash';
import DashboardLineChart from '@/components/DashboardLineChart';
import DashboardBarChart from '@/components/DashboardBarChart';
import SelecionarGrupoEconomicoModal from '@/components/SelecionarGrupoEconomicoModal';
import { addDays, format } from 'date-fns';
import { saveAs } from 'file-saver';

export default {
  name: 'Dashboard',
  components: {
    DashboardLineChart,
    DashboardBarChart,
    SelecionarGrupoEconomicoModal,
  },
  data: () => ({
    searchFilter: {
      economicGroup: '',
      dealership: '',
      startDate: '',
      endDate: '',
    },
    periodFilterSelected: 30,
    economicGroups: [],
    dealerships: [],
    totalCases: 0,
    totalCanceled: 0,
    dashboardStatusData: null,
    lineChartData: null,
    dashboardCanceledData: null,
    economicGroupModal: false,
    busy: false,
    config,
  }),
  computed: {
    ...mapState('users', ['myPermissions']),
    filteredDates() {
      const formatDate = (date) => date.split('-').reverse().join('/');
      let startDate = this.searchFilter.startDate ? formatDate(this.searchFilter.startDate) : '';
      let endDate = this.searchFilter.endDate ? formatDate(this.searchFilter.endDate) : '';
      return { startDate, endDate };
    },
    isVasManager() {
      return this.myPermissions.level === this.config.levels.VASManager || this.myPermissions.level === this.config.levels.CountryVasManager;
    },
    isEconomicGroupManager() {
      return this.myPermissions.level === this.config.levels.economicGroupManager;
    },
    isDealerManager() {
      return this.myPermissions.level === this.config.levels.dealerManager;
    },
    isDealerEmployee() {
      return this.myPermissions.level === this.config.levels.dealerEmployee;
    },
    canceledPercentage() {
      return this.totalCases > 0 ? ((this.totalCanceled * 100) / this.totalCases).toFixed(0) : '0';
    },
    economicGroupName() {
      const economicGroup = this.economicGroups.find(
        (economicGroup) => economicGroup.id === this.searchFilter.economicGroup
      ) || { name: this.$t('services.economicGroup') };
      return economicGroup.name;
    },
    filteredDealerships() {
      if (this.searchFilter.economicGroup) {
        return this.dealerships.filter((dealership) => dealership.economicGroupId === this.searchFilter.economicGroup);
      } else return this.dealerships;
    },
    periodFilters() {
      return [
        { label: this.$t('services.last24h'), days: 1 }, 
        { label: this.$t('services.last48h'), days: 2 }, 
        { label: this.$t('services.last72h'), days: 3 },
        { label: this.$t('services.lastMonth'), days: 30 },
        { label: this.$t('services.periodBetween'), days: 0 }
      ];
    },
    lineChartLegend() {
     return [
        { text: this.$t('footerStatus.report'), color: '#EC4B54' },
        { text: this.$t('footerStatus.end'), color: '#2AB69C' },
        { text: this.$t('footerStatus.cancel'), color: '#ABABAC' },
      ];
    },
    barChartLegend() { 
      return [
        { text: this.$t('services.cancelByClient'), color: '#EC4B54' },
        { text: this.$t('services.towedCar'), color: '#2AB69C' },
        { text: this.$t('services.problemsInApp'), color: '#FF95EE' },
        { text: this.$t('services.noAppInService'), color: '#FDD672' },
        { text: this.$t('services.others'), color: '#3A619D' },
      ];
    }
  },
  methods: {
    ...mapActions('dealerships', ['loadDealerships', 'loadDealership']),
    ...mapActions('dashboard', ['getDashboardStatus', 'getDashboardCanceled', 'downloadDashboardSheet']),
    ...mapActions('economicGroups', ['loadEconomicGroups', 'loadEconomicGroup']),
    cleanFilters(event) {
      for (var a in this.searchFilter) {
        this.searchFilter[a] = '';
      }
      this.periodFilterSelected = 30;
      this.updatePeriodFilter();
      this.$forceUpdate();
    },
    getFiltersPayload(filters) {
      const toIsoDateString = (date, defaultValue) => (date ? (new Date(date)).toISOString() : defaultValue);
      return {
        EconomicGroupId: filters.economicGroup,
        DealershipId: filters.dealership,
        InitialDate: toIsoDateString(filters.startDate, ''),
        FinalDate: toIsoDateString(filters.endDate, ''),
      };
    },
    getEconomicGroupPayload() {
      return { id: this.myPermissions.economicGroupId };
    },
    getDealershipPayload() {
      return { id: this.myPermissions.dealershipId };
    },
    updatePeriodFilter(value) {
      if (this.periodFilterSelected != 0) {
        this.searchFilter.startDate = this.searchFilter.endDate = '';
        const today = new Date();
        const startDate = addDays(today, -this.periodFilterSelected);
        this.searchFilter.startDate = format(startDate, 'y-MM-dd');
        this.searchFilter.endDate = format(today, 'y-MM-dd');
      }
    },
    async fetchData() {
      if (this.busy) return;
      this.$utils.showLoadingIndicator();
      try {
        this.busy = true;
        this.dashboardStatusData = await this.getDashboardStatus(this.getFiltersPayload(this.searchFilter));
        this.lineChartData = _.omit(this.dashboardStatusData, ['totalServices']);
        this.dashboardCanceledData = await this.getDashboardCanceled(this.getFiltersPayload(this.searchFilter));
        this.totalCases = this.dashboardStatusData.totalServices || 0;
        this.totalCanceled = this.dashboardCanceledData.items.reduce((acc, { value }) => acc + value, 0);
      } catch (error) {
        console.warn(error);
      }
      this.$utils.hideLoadingIndicator();
      this.busy = false;
    },
    async fetchFilterData() {
      if (this.busy) return;
      this.$utils.showLoadingIndicator();
      try {
        this.busy = true;
        if (this.isVasManager) {
          this.economicGroups = await this.loadEconomicGroups();
        } else {
          this.economicGroups = await this.loadEconomicGroup(this.getEconomicGroupPayload());
          this.searchFilter.economicGroup = this.economicGroups[0].id;
        }
        if (this.isVasManager || this.isEconomicGroupManager) {
          this.dealerships = await this.loadDealerships();
        } else {
          this.dealerships = await this.loadDealership(this.getDealershipPayload());
          this.searchFilter.dealership = this.dealerships[0].id;
        }
      } catch (error) {
        console.warn(error);
      }
      this.$utils.hideLoadingIndicator();
      this.busy = false;
    },
    async downloadSheet() {
      this.$utils.showLoadingIndicator();
      try {
        const { filename, blob } = await this.downloadDashboardSheet(this.getFiltersPayload(this.searchFilter));
        saveAs(blob, `${filename}.xlsx`);
      } catch (error) {
        console.warn(error);
      }
      this.$utils.hideLoadingIndicator();
    },
    openEconomicGroupModal() {
      if (!this.isVasManager) return;
      this.economicGroupModal = true;
    },
    changeEconomicGroup(groupId) {
      this.searchFilter.economicGroup = groupId;
      this.searchFilter.dealership = '';
      this.filterDealerships();
      this.economicGroupModal = false;
    },
    filterDealerships() {
      if (this.searchFilter.economicGroup) {
        this.dealerships.filter((dealership) => dealership.economicGroupId === this.searchFilter.economicGroup);
      }
    },
  },

  props: {
    source: String,
  },
  async mounted() {
    let localStorageFilters = localStorage.getItem('dashboardFilter');
    let localStoragePeriodFilter = localStorage.getItem('dashboardPeriodFilter');
    if (localStorageFilters) {
      this.searchFilter = JSON.parse(localStorageFilters);
      this.$forceUpdate();
    }
    if (localStoragePeriodFilter) {
      this.periodFilterSelected = JSON.parse(localStoragePeriodFilter);
      this.$forceUpdate();
    }
    this.updatePeriodFilter();
    await this.fetchFilterData();
    await this.fetchData();
  },

  watch: {
    searchFilter: {
      handler(value) {
        localStorage.setItem('dashboardFilter', JSON.stringify(this.searchFilter));
        this.loadServicesByFilterChange = true;
        this.fetchData();
      },
      deep: true,
    },
    periodFilterSelected(value) {
      localStorage.setItem('dashboardPeriodFilter', JSON.stringify(this.periodFilterSelected));
    },
    options: {
      handler(value) {
        this.loadServicesByFilterChange = true;
        this.fetchData();
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss">
.dashboard {
  &__title-wrapper {
    display: flex;
    &.selectable {
      div {
        display: flex;
        cursor: pointer;
        h1 {
          text-decoration: underline;
        }
      }
    }
  }
  &__btn-update-wrapper {
    display: flex;
    justify-content: flex-end;
  }
  &__btn {
    font-size: 14px;
    text-transform: none !important;
    box-shadow: none !important;
    border: none !important;
    letter-spacing: normal !important;
    padding: 20px 12px !important;
  }
  &__btn-update {
    color: #1e3673 !important;
    background-color: rgba(30, 54, 115, 0.1) !important;
  }
  &__btn-download {
    background-color: #1E3673 !important;
    color: white !important;
    border-radius: 4px !important;
  }
  &__line-chart-header {
    border-bottom: 1px solid rgba(151, 151, 151, 0.2);
    p {
      font-size: 16px;
      color: #7f7f7f;
    }
    h2 {
      font-size: 28px;
      color: #35343d;
      .percentage {
        font-size: 14px;
        color: #d8222b;
        font-weight: normal;
      }
    }
  }
  &__line-chart-legend {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    flex-wrap: wrap;
    &--bar {
      justify-content: flex-start;
    }
    .legend-item {
      display: flex;
      align-items: center;
      .legend-dot {
        width: 12px;
        min-width: 12px;
        height: 12px;
        border-radius: 50%;
      }
      .legend-text {
        font-size: 14px;
        color: #35343d;
      }
    }
  }
}

.cleanfilters {
  justify-content: flex-end;
}
</style>
