import axios from 'axios'

// ESSA FUNÇÃO NÃO É USADA MAIS, mas caso precise, tá aqui
function filterByPermission(data, type = false) {
  let employee = store.state.employee
  if (employee.level == 'DealerManager') {
    data = data.filter(item => item.dealershipId === employee.dealershipId)
  }

  if (employee.level == 'EconomicGroupManager') {
    if (type == 'service') {
      data = data.filter(item => item.dealership.economicGroupId === employee.economicGroupId)
    }
    else{
      data = data.filter(item => item.economicGroupId === employee.economicGroupId)
    }
  }
  return data
}

export const state = {

  menuSidebar: [],
  alertText: '',
  kanbanFilters: false,
  serviceNotifications: [],
  pageName: '',
  loadingIndicatorFlag: false,
  drawer: null
}

export const getters = {
}

export const mutations = {
  SET_DRAWER (state, payload) {
    state.drawer = payload
  },
  SET_ALERT_TEXT (state, payload) {
    state.alertText = payload
  },
  SHOW_LOADING_INDICATOR (state, payload) {
    state.loadingIndicatorFlag = true
  },
  HIDE_LOADING_INDICATOR (state, payload) {
    state.loadingIndicatorFlag = false
  },
  SET_PAGENAME (state, payload) {
    state.pageName = payload
  },
  SET_KANBAN_FILTERS (state, payload) {
    state.kanbanFilters = payload
  },
  SET_SIDEBAR_MENU (state, payload) {
    state.menuSidebar = payload
  }
}

export const actions = {
// ECONOMIC GROUPS

  TEST_PING () {
    return axios.get('https://service-api.azurewebsites.net/api/v1/service/testepingfederate')
    .then(response => {
      console.warn(response)
    })
    .catch(error => {
      console.warn(error.response)
      return error.response
    })
  },
  TRANSLATED_SIDEBAR ({ commit }, payload) {
    let sidebar = [
      {
        title: payload.$t('header.dashboard'),
        templateName: 'dashboard',
        path: '/dashboard',
        permissions: [
          'DealerManager',  'EconomicGroupManager', 'VASManager', 'CountryVasManager'
        ]
      },
      {
        title: payload.$t('header.services'),
        templateName: 'services',
        path: 'atendimentos',
        subItems: [
          {
            title: payload.$t('header.list'),
            templateName: 'list',
            path: '/atendimentos'
          },
          {
            title: payload.$t('header.map'),
            templateName: 'mapa',
            path: '/mapa'
          },
          {
            title: payload.$t('header.kanban'),
            templateName: 'kanban',
            path: '/atendimentos/kanban'
          }
        ]
      },
      {
        title: payload.$t('header.employees'),
        templateName: 'employees',
        path: '/funcionarios',
        permissions: [
          'DealerManager',  'EconomicGroupManager', 'VASManager', 'CountryVasManager'
        ]
      },
      {
        title: payload.$t('header.dealerships'),
        templateName: 'dealerships',
        path: '/concessionarias',
        permissions: [
          'EconomicGroupManager', 'VASManager', 'CountryVasManager'
        ]
      },
      {
        title: payload.$t('services.economicGroup'),
        templateName: 'economicGroups',
        path: '/grupos-economicos',
        permissions: [
          'VASManager', 'CountryVasManager'
        ]
      },
      {
        title: payload.$t('services.reasonsCancellations'),
        templateName: 'reasonsCancellations',
        path: '/motivos-cancelamento',
        permissions: [
           'VASManager', 'CountryVasManager'
        ]
      },
      {
        title: payload.$t('header.logout'),
        templateName: 'logout',
        path: '/logout'
      },
    ];
    commit('SET_SIDEBAR_MENU', sidebar);
  }


}