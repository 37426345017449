var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { staticClass: "card-container", attrs: { row: "", wrap: "" } },
    _vm._l(_vm.kanbanGridData, function(data) {
      return _c(
        "v-flex",
        { key: _vm.kanbanGridData.type, attrs: { xs3: "" } },
        [
          _c("v-subheader", [
            _vm._v(
              "\n      " +
                _vm._s(data.type) +
                " (" +
                _vm._s(
                  _vm.sortedItemsPerStatus[data.status]
                    ? _vm.sortedItemsPerStatus[data.status].length
                    : 0
                ) +
                ")\n    "
            )
          ]),
          _c("hr", { staticClass: "vv-kanban-separator" }),
          _vm._l(_vm.sortedItemsPerStatus[data.status], function(item, key) {
            return _c("kanban-card", {
              key: item.id,
              attrs: {
                item: item,
                notifications: _vm.getNotificationsPerService(item)
              },
              on: {
                forceUpdate: function($event) {
                  return _vm.$emit("forceUpdate")
                }
              }
            })
          })
        ],
        2
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }