var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main-layout",
    [
      _c(
        "v-layout",
        { attrs: { row: "", wrap: "" } },
        [
          _c(
            "v-flex",
            {
              staticClass: "dashboard__title-wrapper mb-2",
              class: { selectable: _vm.isVasManager },
              attrs: { md10: "" }
            },
            [
              _c(
                "div",
                { on: { click: _vm.openEconomicGroupModal } },
                [
                  _c("h1", [_vm._v(_vm._s(_vm.economicGroupName))]),
                  _vm.isVasManager
                    ? _c("v-icon", { attrs: { color: "#35343D" } }, [
                        _vm._v("arrow_drop_down")
                      ])
                    : _vm._e()
                ],
                1
              )
            ]
          ),
          _c(
            "v-flex",
            {
              staticClass: "dashboard__btn-update-wrapper",
              attrs: { md2: "" }
            },
            [
              _c(
                "v-btn",
                {
                  staticClass: "dashboard__btn dashboard__btn-update",
                  attrs: { round: "" },
                  on: { click: _vm.fetchData }
                },
                [
                  _c(
                    "v-icon",
                    { staticClass: "mr-2", attrs: { color: "#1E3673" } },
                    [_vm._v("update")]
                  ),
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("misc.update")) + "\n      "
                  )
                ],
                1
              ),
              _c(
                "v-btn",
                {
                  staticClass: "dashboard__btn dashboard__btn-download",
                  on: { click: _vm.downloadSheet }
                },
                [
                  _c(
                    "v-icon",
                    { staticClass: "mr-2", attrs: { color: "white" } },
                    [_vm._v("get_app")]
                  ),
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("misc.export")) + "\n      "
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-layout",
        { attrs: { row: "", wrap: "" } },
        [
          _c("v-flex", { attrs: { md4: "" } }, [
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.searchFilter.dealership,
                    expression: "searchFilter.dealership"
                  }
                ],
                staticClass: "vv-input",
                attrs: {
                  disabled: !(_vm.isVasManager || _vm.isEconomicGroupManager)
                },
                on: {
                  change: function($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function(o) {
                        return o.selected
                      })
                      .map(function(o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.$set(
                      _vm.searchFilter,
                      "dealership",
                      $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                    )
                  }
                }
              },
              [
                _vm.isVasManager || _vm.isEconomicGroupManager
                  ? _c(
                      "option",
                      {
                        staticClass: "vv-disabled",
                        attrs: { selected: "", value: "" }
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$tc("services.dealership", 1)) +
                            "\n        "
                        )
                      ]
                    )
                  : _vm._e(),
                _vm._l(_vm.filteredDealerships, function(dealership, key) {
                  return _c(
                    "option",
                    { key: key, domProps: { value: dealership.id } },
                    [
                      _vm._v(
                        "\n          " + _vm._s(dealership.name) + "\n        "
                      )
                    ]
                  )
                })
              ],
              2
            )
          ]),
          _c(
            "v-flex",
            { attrs: { md7: "" } },
            [
              _c(
                "v-layout",
                { attrs: { row: "", wrap: "" } },
                [
                  _c("v-flex", { attrs: { md4: "" } }, [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.periodFilterSelected,
                            expression: "periodFilterSelected"
                          }
                        ],
                        staticClass: "vv-input",
                        on: {
                          change: [
                            function($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function(o) {
                                  return o.selected
                                })
                                .map(function(o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.periodFilterSelected = $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            },
                            _vm.updatePeriodFilter
                          ]
                        }
                      },
                      [
                        _c(
                          "option",
                          {
                            staticClass: "vv-disabled",
                            attrs: { selected: "", value: "" }
                          },
                          [_vm._v(_vm._s(_vm.$t("services.period")))]
                        ),
                        _vm._l(_vm.periodFilters, function(periodFilter, key) {
                          return _c(
                            "option",
                            {
                              key: key,
                              domProps: { value: periodFilter.days }
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(periodFilter.label) +
                                  "\n            "
                              )
                            ]
                          )
                        })
                      ],
                      2
                    )
                  ]),
                  _vm.periodFilterSelected === 0
                    ? _c(
                        "v-flex",
                        { attrs: { md3: "" } },
                        [
                          _c(
                            "v-menu",
                            {
                              attrs: {
                                lazy: "",
                                "close-on-content-click": true,
                                transition: "scale-transition",
                                "offset-y": "",
                                "full-width": "",
                                "nudge-right": 40,
                                "max-width": "290px",
                                "min-width": "290px"
                              }
                            },
                            [
                              _c("v-text-field", {
                                staticClass: "vv-input mt-0 px-1 pt-2",
                                attrs: {
                                  slot: "activator",
                                  readonly: "",
                                  placeholder: _vm.$t("services.start")
                                },
                                slot: "activator",
                                model: {
                                  value: _vm.filteredDates.startDate,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.filteredDates,
                                      "startDate",
                                      $$v
                                    )
                                  },
                                  expression: "filteredDates.startDate"
                                }
                              }),
                              _c("v-date-picker", {
                                attrs: {
                                  "no-title": "",
                                  scrollable: "",
                                  locale: "pt-BR",
                                  actions: "",
                                  autosave: ""
                                },
                                model: {
                                  value: _vm.searchFilter.startDate,
                                  callback: function($$v) {
                                    _vm.$set(_vm.searchFilter, "startDate", $$v)
                                  },
                                  expression: "searchFilter.startDate"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.periodFilterSelected === 0
                    ? _c(
                        "v-flex",
                        { attrs: { md3: "" } },
                        [
                          _c(
                            "v-menu",
                            {
                              attrs: {
                                lazy: "",
                                "close-on-content-click": true,
                                transition: "scale-transition",
                                "offset-y": "",
                                "full-width": "",
                                "nudge-right": 40,
                                "max-width": "290px",
                                "min-width": "290px"
                              }
                            },
                            [
                              _c("v-text-field", {
                                staticClass: "vv-input mt-0 px-1 pt-2",
                                attrs: {
                                  slot: "activator",
                                  readonly: "",
                                  placeholder: _vm.$t("services.end")
                                },
                                slot: "activator",
                                model: {
                                  value: _vm.filteredDates.endDate,
                                  callback: function($$v) {
                                    _vm.$set(_vm.filteredDates, "endDate", $$v)
                                  },
                                  expression: "filteredDates.endDate"
                                }
                              }),
                              _c("v-date-picker", {
                                attrs: {
                                  "no-title": "",
                                  locale: "pt-BR",
                                  scrollable: "",
                                  actions: "",
                                  autosave: ""
                                },
                                model: {
                                  value: _vm.searchFilter.endDate,
                                  callback: function($$v) {
                                    _vm.$set(_vm.searchFilter, "endDate", $$v)
                                  },
                                  expression: "searchFilter.endDate"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-flex",
            { attrs: { md1: "" } },
            [
              _c(
                "v-subheader",
                {
                  staticClass: "cleanfilters",
                  on: {
                    click: function($event) {
                      return _vm.cleanFilters()
                    }
                  }
                },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("services.reset")) + "\n      "
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      !_vm.busy
        ? _c(
            "v-layout",
            { attrs: { row: "", wrap: "" } },
            [
              _c(
                "v-flex",
                { staticClass: "mt-3", attrs: { md12: "" } },
                [
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-card-text",
                        { staticClass: "dashboard__line-chart-header pb-4" },
                        [
                          _c(
                            "v-layout",
                            {
                              staticClass: "ma-0",
                              attrs: {
                                row: "",
                                "justify-space-between": "",
                                "align-center": ""
                              }
                            },
                            [
                              _c(
                                "v-flex",
                                { attrs: { md6: "" } },
                                [
                                  _c(
                                    "v-layout",
                                    {
                                      staticClass: "mx-0",
                                      attrs: { column: "" }
                                    },
                                    [
                                      _c("p", { staticClass: "mb-2" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("services.numberServices")
                                          )
                                        )
                                      ]),
                                      _c("h2", { staticClass: "mt-1" }, [
                                        _vm._v(
                                          _vm._s(_vm.totalCases) +
                                            " " +
                                            _vm._s(_vm.$t("services.cases"))
                                        )
                                      ])
                                    ]
                                  )
                                ],
                                1
                              ),
                              _c("v-flex", { attrs: { md6: "" } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "dashboard__line-chart-legend"
                                  },
                                  _vm._l(_vm.lineChartLegend, function(
                                    item,
                                    index
                                  ) {
                                    return _c(
                                      "div",
                                      {
                                        key: index,
                                        staticClass: "legend-item mr-3"
                                      },
                                      [
                                        _c("div", {
                                          staticClass: "legend-dot mr-2",
                                          style: {
                                            "background-color": item.color
                                          }
                                        }),
                                        _c(
                                          "span",
                                          { staticClass: "legend-text" },
                                          [_vm._v(_vm._s(item.text))]
                                        )
                                      ]
                                    )
                                  }),
                                  0
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm.lineChartData
                        ? _c("DashboardLineChart", {
                            attrs: { chartData: _vm.lineChartData }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-flex",
                { staticClass: "mt-3", attrs: { md12: "" } },
                [
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-card-text",
                        { staticClass: "dashboard__line-chart-header pb-3" },
                        [
                          _c(
                            "v-layout",
                            {
                              staticClass: "ma-0",
                              attrs: {
                                row: "",
                                "justify-space-between": "",
                                "align-center": ""
                              }
                            },
                            [
                              _c(
                                "v-flex",
                                { attrs: { md5: "" } },
                                [
                                  _c(
                                    "v-layout",
                                    {
                                      staticClass: "mx-0",
                                      attrs: { column: "" }
                                    },
                                    [
                                      _c("p", { staticClass: "mb-2" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "services.numberCancelServices"
                                            )
                                          )
                                        )
                                      ]),
                                      _c("h2", { staticClass: "mt-1" }, [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(_vm.totalCanceled) +
                                            " " +
                                            _vm._s(_vm.$t("services.cases")) +
                                            "\n                  "
                                        ),
                                        _c(
                                          "span",
                                          { staticClass: "percentage" },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.canceledPercentage) +
                                                _vm._s(
                                                  _vm.$t(
                                                    "services.percentageCancelServices"
                                                  )
                                                )
                                            )
                                          ]
                                        )
                                      ])
                                    ]
                                  )
                                ],
                                1
                              ),
                              _c("v-flex", { attrs: { md7: "" } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "dashboard__line-chart-legend dashboard__line-chart-legend--bar"
                                  },
                                  _vm._l(_vm.barChartLegend, function(
                                    item,
                                    index
                                  ) {
                                    return _c(
                                      "div",
                                      {
                                        key: index,
                                        staticClass: "legend-item mr-3 mt-2"
                                      },
                                      [
                                        _c("div", {
                                          staticClass: "legend-dot mr-2",
                                          style: {
                                            "background-color": item.color
                                          }
                                        }),
                                        _c(
                                          "span",
                                          { staticClass: "legend-text" },
                                          [_vm._v(_vm._s(item.text))]
                                        )
                                      ]
                                    )
                                  }),
                                  0
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm.dashboardCanceledData
                        ? _c("DashboardBarChart", {
                            attrs: {
                              chartData: _vm.dashboardCanceledData.items
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.economicGroupModal
        ? _c(
            "v-dialog",
            {
              attrs: { "max-width": "600" },
              model: {
                value: _vm.economicGroupModal,
                callback: function($$v) {
                  _vm.economicGroupModal = $$v
                },
                expression: "economicGroupModal"
              }
            },
            [
              _c("SelecionarGrupoEconomicoModal", {
                attrs: { options: _vm.economicGroups },
                on: {
                  cancel: function($event) {
                    _vm.economicGroupModal = false
                  },
                  select: _vm.changeEconomicGroup
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }