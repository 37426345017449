<template>
  <v-badge color="red" class='unread-services-badge ' v-if="unreadServices">
    <template v-slot:badge>
      <span v-html="unreadServices"></span>
    </template>
  </v-badge>
</template>
<script>
import { mapActions, mapState } from 'vuex'
export default {

  name: 'KanbanSidebarUnreadBadge',
  computed: {
  	unreadServices () {
  		return this.$store.state.services.unreadServices
  	}
  },
  data () {
    return {

    }
  }
}
</script>

<style lang="css">
	.unread-services-badge .v-badge__badge{
    margin-top: -4px;
    margin-left: 8px;
	}
</style>