<template>
  <td class='invisible-element'></td>
</template>

<script>
export default {

  name: 'BaseInvisibleTdElement',

  data () {
    return {

    }
  }
}
</script>

<style lang="css" scoped>
  .invisible-element{
    display: none;
  }
</style>