<template>
  <div>
	<v-card
		:class="getKanbanCardClasses()"
		class='kanban-card'
		:key="item.id"
		:id="`card-${item.id}`"
	>
		<v-card-title class='mb-0 pb-0 pt-1 mt-0 pl-2'>
			<h3 class="mb-0">
      	<router-link class='mb-0' :to="{path: '/atendimentos/' + item.id}">
      		{{ item.mercuriusCase }}
      	</router-link>
      </h3>
      <v-spacer></v-spacer>
	  <button
	  	class="justify-end d-flex align-end archive-service__button"
		:id="`btn--${item.id}`"
		@click="updateCardArchiveService"
		v-if="isStatusEnd"
	>
		<v-icon color="#35343D" v-if="permissionArchive"> more_horiz </v-icon>
	    <v-card v-if="showArchiveService" class="archive-service__card" :id="`item-${item.id}`">
		  <button style="color: #1e3673" class="px-2 pt-3" @click="archiveSelectedService">
	  	  	<img src="@/assets/ic_archive_file.png" width="20%" :id="`img--${item.id}`" /> Arquivar
		  </button>
  	    </v-card>
	  </button>
	  </v-card-title>
	  <v-card-title class='mb-0 pb-0 pt-1 mt-0 px-0'>
    	<v-chip
    		v-if="needsPausedChip()"
    		small
    		label
    		disabled
    		color="red"
    		text-color="white">{{ $t('footerStatus.paused') }}</v-chip>
    	<v-chip
    		small
    		label
				disabled
    		outline
    		v-if="isAvailableETA"
    		>ETA: {{item.estimatedTimeArrival}} </v-chip>
	  </v-card-title>
    <v-card-text >
      <div>

        <div class='vv-kanban-address'>
        	{{item.placeDescription.replace(/BRAZIL/g, '')}}
        </div>
        <div v-for="(notification, index) in sortedNotifications" :key='index' v-if="notification.type !== 7">
        	{{ getNotificationByStatus(notification) }}:
        		<span v-if="notification.type != 1">{{ (notification.date) ? getDateAndTime(notification.date) : '' }}h</span>
        		<span v-else>{{ (item.reportDate) ? getDateAndTime(utils.fixDateWithoutGMT(item.reportDate)) : '' }}h</span>
        </div>
      </div>
    </v-card-text>
    <v-card-actions>
      <span class="left" v-html="(item.gpsDistance) ? `${item.gpsDistance}km` : `${item.distance}km`"></span>
      <v-spacer></v-spacer>
      <span class="right" v-html="item.dealership.name"></span>
    </v-card-actions>
  </v-card>
  <v-dialog
    v-model="requestDialog"
    max-width="450"
  >
    <base-modal
      :title="message"
      :confirm-text="'OK'"
      @confirm="requestDialog = false"
      no-cancel-button>
    </base-modal>
  </v-dialog>
  </div>
</template>
<script>
	import utils from "@/libs/utils"
	import config from "@/app.settings"
	import { mapActions } from "vuex"
	export default {
		name: 'KanbanCard',
		components: {
			utils,
		},
		data: () => {
			return {
				utils,
				sortedNotifications: [],
				timer: false,
				showArchiveService: false,
				permissionArchive: false,
				endStatus: 4,
				requestDialog: false,
				message: "",
				arrayStatusDeparture: [2,5]
			}
		},
		computed: {
			isStatusEnd() {
			  return this.item.status == this.endStatus
			},
			isAvailableETA() {
			  return this.arrayStatusDeparture.includes(this.item.status) && this.item.estimatedTimeArrival
			}
		},
		methods: {
		...mapActions({
      		actionArchiveService: 'services/archiveService'
    	}),
			getDateAndTime (date){
	      let dateAndTimeSeparated = date.split('T')
	      return dateAndTimeSeparated[1].substring(0, 5)
	    },
	    formatISODate (date) {
	      let dateAndTimeSeparated = date.split('T')
	      dateAndTimeSeparated[0] = dateAndTimeSeparated[0].split('-').reverse().join('/')
	      let finalDate = dateAndTimeSeparated.join(' - ')
	      return finalDate
	    },
	    needsPausedChip() {
	    	let pausedNotifications = this.notifications.filter(item => item.type == config.PAUSED_STATUS)
	    	let resumedNotifications = this.notifications.filter(item => item.type == config.RESUMED_STATUS)
	    	let needs =
	    		pausedNotifications.length &&
	    		[3, 8, 9].includes(this.item.status) &&
	    		pausedNotifications.length != resumedNotifications.length
	    	return needs
	    },
	    getKanbanCardClasses() {
	    	let classes = [this.utils.getServiceStatusClass(this.item.status)]
	    	if(this.item.unread && !this.dashboardCard) {
	    		classes.push('unread')
	    	}

	    	return classes
	    },
		getNotificationByStatus(notification) {
		  	return this.utils.getNotificationTypeName(this, notification.type, true)
		},
		updateCardArchiveService() {
			this.showArchiveService = !this.showArchiveService;
		},
		async archiveSelectedService() {
			this.$utils.showLoadingIndicator()
			try {
         		let response = await this.actionArchiveService(this.item.id)
				this.message = this.$t('services.archiveService')
				this.$emit('forceUpdate')
      		} catch(e) {
				this.message = e.response.data.message
      		}
			this.requestDialog = true; 
			this.$utils.hideLoadingIndicator()
		},
		},
		mounted () {
			this.sortedNotifications = this.notifications.sort((a, b) =>
				(a.date < b.date) ? -1 : ((a.date > b.date) ? 1 : 0
			))
			const userLevel = this.$store.state.employees.employee.user.level
			userLevel == 'VASManager' || userLevel == 'CountryVasManager' ? this.permissionArchive = true : this.permissionArchive = false
		},
		props: 	{
			item: {
				default: false
			},
			notifications: {
				default: false
			},
			dashboardCard: {
				type: Boolean,
				default: false
			}
		}
	}
</script>
<style lang="scss">
.archive-service__card {
  position: absolute !important;
  bottom: 2.3rem;
  right: -1.11rem;
  z-index: 99;
  width: 100px !important;
  max-width: 100px !important;
  font-size: 12px;
  font-weight: 800;
  line-height: 16.39px;
  height: 50px !important;
};
.archive-service__button {
  position: relative;
};
.v-tooltip__content {
  background: white;
};
</style>