<template>

</template>

<style lang="scss" scoped>
</style>

<script>

import config from '@/app.settings'
export default {
  name: 'Home',
  components: {

  },
  data: () => ({
    model: {},
    dialog: false
  }),
  methods: {
    auth(){
      this.$router.push('/dashboard')
    },
  },
  mounted () {
    if (localStorage.accessTokenObj) {
      this.$router.push('/dashboard')
    }
    else{
      window.location.replace(config.pingFederateLoginURL);
    }

  }
}
</script>
