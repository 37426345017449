<template>

  <main-layout >

      <v-layout
        justify-center
        row wrap
      >
        <form-alert
          :alert-model="!!alertText"
          :alert-text="alertText"
          @input="closeFormAlert()"
        />
        <v-flex xs8>
           <base-search-input
           v-model="searchFilter.generalSearch"
           :placeholder="$t('dealerships.searchDealership')"
           />
        </v-flex>
        <v-flex xs4>
          <base-adicionar-button
          :to="{path: 'concessionarias/novo'}"
          :text="$t('dealerships.addNew')"
          ></base-adicionar-button>

        </v-flex>
      </v-layout>

      <v-data-table
        v-bind:headers="headers"
        :items="filteredItems"
        no-data-text="Sem dados disponíveis"
        hide-actions
        class="elevation-1 list__table"
        v-if="filteredItems.length"
      >
      <template slot="items" slot-scope="props">
        <router-link
          :to="{path: '/concessionarias/editar/' + props.item.id }"
          tag="tr"
        >
          <td v-html="(economicGroups.length) ? economicGroups.find(item => item.id == props.item.economicGroupId).name : ''"></td>
          <td v-html="props.item.name"></td>
          <td v-html="props.item.address"></td>
        </router-link>
      </template>
    </v-data-table>


  </main-layout>

</template>

<style>


</style>

<script>

import utils from "@/libs/utils"
import { mapState, mapActions } from 'vuex'
import ViewMixin from '@/mixins/ViewMixin'
import config from '@/app.settings'
export default {
  name: 'ConcessionariasListar',
  components: {
  },
  mixins: [ViewMixin],
  data: () => ({
    searchFilter: {
      generalSearch: '',
    },
    dealerships: [],
    economicGroups: []
  }),
  computed: {
    ...mapState('employees', ['employee']),
    filteredItems() {
      let filters = this.searchFilter
      let items = this.dealerships

      if (!items.length) {
        return []
      }
      if (items) {
        items = utils.sortAlphabeticalOrder(items)
      }
      items = items.filter(item => item.id !== config.adminDealership)
      if (!filters.generalSearch )
        return items


      let normalizeString = this.$utils.normalizeString

      let tempItems = items
      .filter(
        item => normalizeString(item.name).indexOf(normalizeString(filters.generalSearch)) !== -1
      )
      if (this.employee.level == 'EconomicGroupManager') {
        tempItems = tempItems
        .filter(
          item => item.economicGroupId === this.employee.economicGroupId
        )
      }

      return tempItems
    },
    headers() {
      return [
        { text: this.$tc('dealerships.economicGroup', 1), align: 'left', sortable: false, value: 'economicGroup' },
        { text: this.$t('dealerships.name'), align: 'left',  value: 'name', sortable: false },
        { text: this.$t('dealerships.localization'), align: 'left', sortable: false, value: 'address' },
      ];
    }
  },
  methods: {

    ...mapActions('economicGroups', ['loadEconomicGroups']),
    ...mapActions('dealerships', ['loadDealerships']),

    async init () {
      this.$utils.showLoadingIndicator()
      try {
        this.dealerships = await this.loadDealerships()
        this.economicGroups = await this.loadEconomicGroups()
      } catch(e) {
        console.warn(e)
      }
      this.$utils.hideLoadingIndicator()
    }
  },

  mounted() {
    this.init()
    this.alertText = this.$utils.handleAlertText()
  }

}
</script>