import axios from 'axios'
import config from '@/app.settings'
import utils from '@/libs/utils'
import i18n from '@/locale/index'
export const state = {
  employee: [],
  employees: [],
  employeeLevels: [],
}
function getEmployeeUserLevelLabel (level) {
  let res = false
  switch (level) {
    case 'VASManager':
      res = i18n.t('employeeLevels.VASManager')
      break
    case 'EconomicGroupManager':
      res = i18n.t('employeeLevels.EconomicGroupManager')
      break
    case 'DealerManager':
      res = i18n.t('employeeLevels.DealerManager')
      break
    case 'DealerEmployee':
      res = i18n.t('employeeLevels.DealerEmployee')
      break
    case 'CountryVasManager':
      res = i18n.t('employeeLevels.CountryVasManager')
      break
  }
  return res

}
function employeeDefaultAdapter(employees) {
  employees.forEach(employee => {
    utils.uncapitalizeAllKeys(employee)
    utils.uncapitalizeAllKeys(employee.user)
    employee.user.levelLabel = getEmployeeUserLevelLabel(employee.user.level)
    // vamos redundar isso aqui pra fazer satisfazer o employee.level e o employee.user.level ao invés de
    // trocar nos views e ter que testar tuuuuuudo de novo
    employee.level = employee.user.level
    employee.user.deleted = (employee.user.hasOwnProperty('deleted')) ? employee.user.deleted : false
  })
  return employees
}

export const getters = {}

export const mutations = {
  SET_EMPLOYEES (state, payload) {
    state.employees = payload
  },
  SET_EMPLOYEE_LEVELS (state, payload) {
    state.employeeLevels = payload
  },
  SET_EMPLOYEE (state, payload) {
    state.employee = payload
  },
}

export const actions = {
  async addEmployee ({commit}, payload) {
    utils.capitalizeAllKeys(payload)
    utils.capitalizeAllKeys(payload.User)
    utils.capitalizeAllKeys(payload.DealershipEmployee)

    let response = await axios.post(config.apiUrl + '/employees', payload)
    return response.data.data
  },
  async editEmployee ({commit}, payload) {
    utils.capitalizeAllKeys(payload)
    utils.capitalizeAllKeys(payload.User)
    utils.capitalizeAllKeys(payload.DealershipEmployee)
    let response = await axios.put(config.apiUrl + '/employees/' + payload.User.EmployeeId, payload)
    return response.data
  },
  async loadEmployees ({commit}) {
    let response = await axios.post(config.apiUrl + '/employees/search', {})
    let data = employeeDefaultAdapter(response.data)
    commit('SET_EMPLOYEES', data)

    return data
  },
  async loadEmployee ({commit}, payload) {
    let response = await axios.post(config.apiUrl + '/employees/search', {
      Id: payload.id
    })
    let data = employeeDefaultAdapter(response.data)
    return data
  },
  async deleteEmployee ({commit}, payload) {
    let response = await axios.delete(config.apiUrl + '/employees/' + payload)
    return response.data.data  || 'delete-success'
  },
  async loadEmployeeLevels ({commit}) {
    let response = await axios.get(config.apiUrl + '/employees-levels')
    commit('SET_EMPLOYEE_LEVELS', response.data.data)
    return response.data.data
  },
}