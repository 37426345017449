export default {
  data: () => ({
    alertModel: false,
    alertText: false,
    menu: [],
    dialog: false
  }),
  created: function () {

  },


  methods: {
    closeFormAlert() {
      this.$store.commit('main/SET_ALERT_TEXT', false)
      this.alertModel = false
      this.$forceUpdate()
    },
  }
}

