<template>
  <main-layout>
        <v-layout justify-center row wrap class='vv-form'>
          <v-flex xs12>
            <v-layout>
              <v-flex xs6>
                <base-voltar-button @click="$router.go(-1)" />
              </v-flex>
              <v-spacer></v-spacer>
            </v-layout>
            <v-layout row wrap>
              <v-flex xs12>
                <div v-if="showMap" id="location-map" class='vv-map full'></div>
                 <v-layout row wrap class='white-background' v-if="!showMap">

                  Houve um problema para renderizar o mapa desse atendimento: por favor, cheque se todos os endereços correspondem à localizações válidas no google maps.
                </v-layout>
              </v-flex>

              <v-flex xs12 v-if="showMap">
                <div>
                  <div class="status-indicator">
                    <div class="status-badge processing"></div>
                    <div class="status-label ">{{$t('footerStatus.report')}}</div>
                  </div>
                  <div class="status-indicator">
                    <div class="status-badge departure"></div>
                    <div class="status-label ">{{$t('footerStatus.start')}}</div>
                  </div>
                  <div class="status-indicator">
                    <div class="status-badge repairing"></div>
                    <div class="status-label">{{$t('footerStatus.repairing')}}</div>
                  </div>
                  <div class="status-indicator">
                    <div class="status-badge mechanic"></div>
                    <div class="status-label">{{$t('footerStatus.mechanic')}}</div>
                  </div>
                  <!--- <div class="status-indicator">
                    <div class="status-badge start"></div>
                    <div class="status-label">{{$t('footerStatus.departure')}}</div>
                  </div> -->
                </div>
              </v-flex>
              <v-flex xs12>
                 <v-layout  row wrap class='margin-top'>
                   <v-flex xs12>
                     <v-data-table
                        v-bind:headers="table2Headers"
                        :items="table2Items"
                        hide-actions
                        class="elevation-1"
                        no-data-text="Sem dados disponíveis"
                      >
                      <template slot="items" slot-scope="props">
                        <td >{{ getNotificationByStatus(props.item) }}</td>
                        <td class="">{{ (props.item.date) ? formatISODate(props.item.date).split('-')[0] : '---' }}</td>
                        <td class="" v-if="props.item.type != 1">{{ (props.item.date) ? formatISODate(props.item.date).split('-')[1].substring(0, 9) : '---' }}
                        </td>

                      <td class="" v-else>
                        {{ (model.reportDate) ? formatISODate(utils.fixDateWithoutGMT(model.reportDate)).split('-')[1].substring(0, 9) : '---' }}
                      </td>
                      </template>
                     </v-data-table>
                    </v-flex>
                  </v-layout>
              </v-flex>

            </v-layout>

          </v-flex>
        </v-layout>
        <div id='kanban-map' style='display: none' v-if="model && model.notifications && kanbanReload" >
          <kanban-card
          :item="model"
          :notifications="model.notifications"
          dashboard-card
          ></kanban-card>
        </div>

  </main-layout>
</template>

<style lang="scss" scoped>

</style>

<script>

import utils from "@/libs/utils"
import { mapState, mapActions } from 'vuex'
import ViewMixin from '@/mixins/ViewMixin'
import config from '@/app.settings'

import KanbanCard from "@/components/KanbanCard"
import mapAssets from "@/libs/map-assets"

export default {

  name: 'Home',
  components: {
    KanbanCard,
  },
  mixins: [ViewMixin],
  data: () => ({
    model: {},
    showMap: true,
    economicGroups: [],
    selectedEconomicGroup: false,
    dealerships: [],
    selectedDealership: false,
    table2Headers: [],
    table2Items: [],
    mapName: 'location-map',
    employeeMarker: false,
    map: false,
    pageName: '',
    kanbanReload: true,
    bounds: false,
    utils
  }),
  methods: {


    ...mapActions('services', [
      'loadService',
      'loadTracking',
      'loadServiceNotifications',
    ]),
    getNotificationByStatus(notification) {
		  return this.$utils.getNotificationTypeName(this, notification.type)
		},
    formatISODate (date) {
      let dateAndTimeSeparated = date.split('T')
      dateAndTimeSeparated[0] = dateAndTimeSeparated[0].split('-').reverse().join('/')
      let finalDate = dateAndTimeSeparated.join(' - ')
      return finalDate
    },

    calculateAndDisplayRoute(directionsService, directionsDisplay, pointA, pointB) {
      // console.log(directionsService, directionsDisplay, pointA, pointB)

      directionsService.route({
        origin: pointA,
        destination: pointB,
        travelMode: google.maps.TravelMode.DRIVING
      }, function(response, status) {
        if (status == google.maps.DirectionsStatus.OK) {
          directionsDisplay.setDirections(response);
        } else {
          //window.alert('Falha ao gerar a rota por motivos de: ' + status);
          console.log('ZERO RESULTS')
        }
      });
    },

    stylizeInfoWindow() {
      // Reference to the DIV which receives the contents of the infowindow using jQuery
       var iwOuter = document.querySelector('.gm-style-iw');
       iwOuter.nextSibling.style.display = 'none'

       /* The DIV we want to change is above the .gm-style-iw DIV.
        * So, we use jQuery and create a iwBackground variable,
        * and took advantage of the existing reference to .gm-style-iw for the previous DIV with .prev().
        */
       var iwBackground = iwOuter.previousSibling;

       // Remove the background shadow DIV

       iwBackground.querySelector(':nth-child(2)').style.display = 'none'
       iwBackground.querySelector(':nth-child(1)').style.display = 'none'
       iwBackground.querySelector(':nth-child(3)').style.display = 'none'
       // Remove the white background DIV
       iwBackground.querySelector(':nth-child(4)').style.display = 'none'
       // iwOuter.style.display = 'block'
    },

    plotMarkers(markerCoordinates, map) {
      let t = this

      markerCoordinates.forEach((coord) => {
        if (!coord.latitude || !coord.longitude || coord.latitude == 'null') return
        const position = new google.maps.LatLng(coord.latitude, coord.longitude)
        const marker = new google.maps.Marker({
          position,
          map,
          icon: coord.icon
        });
        let contentInfoWindow = document.querySelector('#kanban-map').innerHTML
        var infowindow = new google.maps.InfoWindow({
          content: contentInfoWindow
        });
        google.maps.event.addListener(infowindow, 'domready', t.stylizeInfoWindow)

        marker.addListener('mouseover', function() {
          infowindow.open(map, marker);
        });
        marker.addListener('mouseout', function() {
          infowindow.close();
        });

        if (markerCoordinates.length == 2) {
          map.fitBounds(t.bounds.extend(position))
        }
      });
      return markerCoordinates.find(item => item.description == 'serviceMarker').latitude != null
    },

    plotRoute(dealershipMarker, serviceMarker, strokeColor, map) {
      let t = this
      let pointA = new google.maps.LatLng(dealershipMarker.latitude, dealershipMarker.longitude)
      let pointB = new google.maps.LatLng(serviceMarker.latitude, serviceMarker.longitude)

      let directionsService = new google.maps.DirectionsService
      let directionsDisplay = new google.maps.DirectionsRenderer({
        map,
        polylineOptions: { strokeColor },
        suppressMarkers: true
      })
      t.calculateAndDisplayRoute(directionsService, directionsDisplay, pointA, pointB);
    },

    plotEmployee(trackings, map) {
      let t = this
      let employeePosition = new google.maps.LatLng(trackings[trackings.length -1].latitude, trackings[trackings.length -1].longitude)

      t.employeeMarker = new google.maps.Marker({
        position: employeePosition,
        map: map,
        icon: mapAssets.png.blueCar
      });

      let contentInfoWindow = document.querySelector('#kanban-map').innerHTML
      var infowindow = new google.maps.InfoWindow({
        content: contentInfoWindow
      });
      google.maps.event.addListener(infowindow, 'domready', t.stylizeInfoWindow)

      t.employeeMarker.addListener('mouseover', function() {
        infowindow.open(map, t.employeeMarker);
      });
      t.employeeMarker.addListener('mouseout', function() {
        infowindow.close();
      });
      t.employeeMarker.setMap( map );
      map.fitBounds(t.bounds.extend(employeePosition))
    },

    getServiceAssets(status) {
      let serviceAssets = {
        strokeColor: '',
        truckIcon: ''
      }
      switch(status) {
        case 1:
          serviceAssets.strokeColor = '#d8222b'
          serviceAssets.truckIcon = mapAssets.png.redTruck
          break;
        case 2:
        case 5:
          serviceAssets.strokeColor = '#253a71'
          serviceAssets.truckIcon = mapAssets.png.blueTruck
          break;
        case 3:
        case 8:
        case 9:
          serviceAssets.strokeColor = '#632289'
          serviceAssets.truckIcon = mapAssets.png.purpleTruck
          break;

        case 4:
          serviceAssets.strokeColor = '#009949'
          serviceAssets.truckIcon = mapAssets.png.greenTruck
          break;
      }
      return serviceAssets
                },
    moveMarker(latitude, longitude) {
      let t = this
      if (!latitude  || !longitude || latitude == "null") return



      if (!t.map.getBounds().contains(t.employeeMarker.getPosition())) {
        new google.maps.Marker(t.employeeMarker)
      }
      t.employeeMarker.setMap(t.map)

      t.employeeMarker.setPosition( new google.maps.LatLng( latitude, longitude ) );
      t.map.panTo( new google.maps.LatLng( latitude, longitude ) );
      // TODO: checar se isso é relevante


    },

    mountMap() {
      let t = this

      let dealershipAddress = false
      let markerCoordinates = []
      let geocodePromises = []
      let options = {
        zoom: 14,
        mapTypeId: google.maps.MapTypeId.ROADMAP
      }
      let serviceAssets = t.getServiceAssets(t.model.status)

      let serviceAddress = t.model.placeDescription
      t.bounds = new google.maps.LatLngBounds();
      let element = document.getElementById(this.mapName)

      let serviceMarker = {
        latitude: t.model.latitude,
        longitude: t.model.longitude,
        icon: serviceAssets.truckIcon,
        description: 'serviceMarker'
      }

      let dealershipMarker = {
          latitude: t.model.dealership.latitude,
          longitude: t.model.dealership.longitude,
          icon: mapAssets.png.volvoTotem,
          description: 'dealershipMarker'
        }

      markerCoordinates.push(serviceMarker)
      markerCoordinates.push(dealershipMarker)

      t.map = new google.maps.Map(element, options);

      t.$nextTick(() => {
        if(t.plotMarkers(markerCoordinates, t.map)){
          t.plotRoute(dealershipMarker, serviceMarker, serviceAssets.strokeColor, t.map)
        }
        if (((t.model.status > 1 && t.model.status < 4) || [5, 8, 9].includes(t.model.status))
        && (t.model.trackings[(t.model.trackings.length-1)].latitude !== "null" && t.model.trackings[(t.model.trackings.length-1)].longitude !== "null")) {
          t.plotEmployee(t.model.trackings, t.map)
        }
      })
    },
    reloadKanban (){
      return new Promise((resolve, reject) => {

        let t = this
        t.kanbanReload = false
        t.$nextTick(() => {
          t.kanbanReload = true
          t.$forceUpdate()
          resolve()
        })
      })
    },
    init() {
      let t = this
      return new Promise((resolve, reject) => {
        let promises = [
          t.loadService({ Id: t.$route.params.id }),
          t.loadTracking({id: t.$route.params.id}),
          t.loadServiceNotifications({id: t.$route.params.id}),
        ]
        this.$utils.showLoadingIndicator()
        Promise.all(promises).then(response => {
          t.model = response[0]
          t.model.trackings = response[1]
          t.model.notifications = response[2]
          t.selectedDealership = t.model.dealershipId
          t.table1Items = [ t.model ]
          t.table2Items = t.model.notifications.sort((a, b) => (a.date < b.date) ? -1 : ((a.date > b.date) ? 1 : 0))
          t.$store.commit('main/SET_PAGENAME', t.$store.state.main.pageName + ' - ' + t.model.mercuriusCase)
          document.title += " - " + t.model.mercuriusCase

          t.reloadKanban().then(() => {
            t.mountMap()
            t.$socket.emit('join', '123456')
            resolve()
          })
          this.$utils.hideLoadingIndicator()
        }).catch(() => {
          this.$utils.hideLoadingIndicator()
        })
      })
    }
  },
  sockets:{
    connect: function(){
      console.warn('[connect] socket connected')
    },
    update(message) {
      console.warn('[update]', message)
    },
    message(message) {
      let t = this
      console.warn('[message]', message)
      let data = JSON.parse(message)
      let serviceId = t.$route.params.id
      if (serviceId == data.serviceId) {
        if (t.model.status !== data.status) {

          t.init().then(response => {
            if (t.employeeMarker && ([2, 3, 5, 8, 9].includes(data.status) )) {
              t.moveMarker(data.latitude, data.longitude)
            }
          })
        }
        else {
          if (t.employeeMarker) {
            t.moveMarker(data.latitude, data.longitude)
          }
        }
      }
    },
  },
  mounted () {

    let t = this
    t.pageName = t.$store.state.pageName
    t.table2Headers = [
      { text: t.$t('services.history'), align: 'left',  value: 'mercurius_case'  , sortable: false},
      { text: t.$t('misc.date'), align: 'left', value: 'dealership' , sortable: false},
      { text: t.$t('misc.time'), align: 'left', value: 'startDate' , sortable: false},
    ]
    t.init()
    // t.$store.commit('main/SET_PAGENAME', t.pageName + ' - ' + t.model.mercuriusCase)
  }
}
</script>