var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        fill: "#000000",
        height: "24",
        viewBox: "0 0 24 24",
        width: "24",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink"
      }
    },
    [
      _c("defs", [_c("path", { attrs: { d: "M0 0h24v24H0z", id: "a" } })]),
      _c("clipPath", { attrs: { id: "b" } }, [
        _c("use", { attrs: { overflow: "visible", "xlink:href": "#a" } })
      ]),
      _c("path", {
        attrs: {
          id: "setinhas",
          "clip-path": "url(#b)",
          d:
            "M15 3l2.3 2.3-2.89 2.87 1.42 1.42L18.7 6.7 21 9V3zM3 9l2.3-2.3 2.87 2.89 1.42-1.42L6.7 5.3 9 3H3zm6 12l-2.3-2.3 2.89-2.87-1.42-1.42L5.3 17.3 3 15v6zm12-6l-2.3 2.3-2.87-2.89-1.42 1.42 2.89 2.87L15 21h6z"
        }
      }),
      _c("path", {
        attrs: { "clip-path": "url(#b)", d: "M0 0h24v24H0z", fill: "none" }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }