<script>
  import axios from 'axios'
  export default {
    name: 'TestePing',
    methods: {
      parseJwt (token) {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace('-', '+').replace('_', '/');
        return JSON.parse(window.atob(base64));
      }
    },
    async mounted (){
      let accessToken = ''
      let t = this
      let pingResponse = await t.$store.dispatch('TEST_PING')
      console.log(pingResponse.headers['www-authenticate'])
    },
}
</script>