<template>
  <div class='fill-height'>
  	<the-wrapper
	  v-if="$route.name != 'KanbanExpandido'
	  "></the-wrapper>
  	<v-content class='fill-height'>
  		<v-container
	  		fluid
	  		grid-list-md
	  		v-bind="$attrs"
  		>
  			<slot />
			</v-container>
  	</v-content>


	</div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
export default {
  name: 'LayoutMain',
}
</script>

<style lang="scss">
	.fill-height{
		height: 100%;
	}
</style>
