<template>
    <main-layout>
      <v-container fluid grid-list-xl id='kanban-container' >
        <v-layout
          justify-center
          row
          wrap
        >
          <v-flex xs4>
            <base-search-input
              v-model="mercuriusCaseSearchText"
              :placeholder="$t('services.filterService')"
              @update="debounceMercuriusCaseSearch()"
            />

          </v-flex>
          <v-flex xs7>
            <v-layout row wrap :style="{ border: 'none' }">
              <v-flex sm3>
                <select  class='vv-input' v-model="periodFilterSelected" @change="updatePeriodFilter">
                  <option selected value="" class='vv-disabled'>{{$t('services.period')}}</option>
                  <option v-for="(periodFilter, key) in periodFilters" :key="key" :value="periodFilter">{{periodFilter}}</option>
                </select>
              </v-flex>

                <v-flex sm3 xs6 v-if='isPeriodBetween' >
                  <v-menu
                    lazy
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    v-model="menu"
                    full-width
                    :nudge-right="40"
                    max-width="290px"
                    min-width="290px"
                  >
                   <v-text-field class='vv-input mt-0 px-1 pt-2'
                      slot="activator"
                      readonly
                      placeholder="Inicio"
                      v-model="filteredDates.startDate"
                    ></v-text-field>
                    <v-date-picker no-title v-model="searchFilter.startDate" scrollable locale="pt-BR" actions autosave>
                      <!-- <template slot-scope="{ save, cancel }">
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn flat color="primary" @click="cancel">{{$t('misc.cancel')}}</v-btn>
                          <v-btn flat color="primary" @click="save">{{$t('misc.ok')}}</v-btn>
                        </v-card-actions>
                      </template> -->
                    </v-date-picker>
                  </v-menu>
                </v-flex>

                <v-flex sm3 xs6 v-if='isPeriodBetween' >
                  <v-menu
                    lazy
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    full-width
                    :nudge-right="40"
                    max-width="290px"
                    min-width="290px"
                  >
                   <v-text-field class='vv-input mt-0 px-1 pt-2'
                      slot="activator"
                      readonly
                      v-model="filteredDates.endDate"
                      placeholder="Fim"
                    ></v-text-field>
                    <v-date-picker no-title v-model="searchFilter.endDate" scrollable locale="pt-BR" actions autosave>
                      <!-- <template slot-scope="{ save, cancel }">
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn flat color="primary" @click="cancel">{{$t('misc.cancel')}}</v-btn>
                          <v-btn flat color="primary" @click="save">{{$t('misc.ok')}}</v-btn>
                        </v-card-actions>
                      </template> -->
                    </v-date-picker>
                  </v-menu>
                </v-flex>
                <v-flex sm3>
                  <button @click="goToExpandir()" class='btn btn-expandir' tag="button">
                    <kanban-expandir-icon></kanban-expandir-icon>
                    {{$t('misc.expand')}}
                  </button>
                </v-flex>
            </v-layout>
          </v-flex>
          <v-flex md1>
            <v-subheader class='cleanfilters' @click='cleanFilters()'>{{$t('services.reset')}}</v-subheader>
          </v-flex>
        </v-layout>
        <kanban-kanban
          :kanban-grid-data="kanbanGridData"
          :items="items"
          :service-notifications="serviceNotifications"
          :key="kanbanComponentKey"
          @forceUpdate="forceUpdate"
        />
      </v-container>
      <kanban-footer />
    </main-layout>
</template>

<style>


</style>

<script>
import utils from "@/libs/utils"
import { mapState, mapActions } from 'vuex'
import ViewMixin from '@/mixins/ViewMixin'
import config from '@/app.settings'
import _ from 'lodash'

export default {
  name: 'Home',
  mixins: [ViewMixin],
  data: () => ({
    drawer: null,
    periodFilterSelected: '',
    mercuriusCaseSearchText: '',
    searchFilter: {
      generalSearch: '',
      dealership: '',
      status: '',
      mercuriusCase: '',
      startDate: null,
      endDate: '',
      lastHours: ''
    },
    serviceNotifications: [],
    dealerships: [],
    status: [],
    leftPage: false,
    items: [],
    timer: false,
    busy: false,
    kanbanComponentKey: 0,
    refreshData: _.debounce(function() { this.fetchData(true) }, 30000),
    debounceMercuriusCaseSearch: _.debounce(function() { this.updateMercuriusCaseSearch() }, 600),
  }),
  methods: {
    ...mapActions('services', [
      'getServicesKanban',
      'loadServiceListNotifications',
      'checkNewServices',
    ]),
    ...mapActions({
      actionReadService: 'services/readService'
    }),
    getNotificationsPerService(service) {
      let t = this

      return this.serviceNotifications.filter(notification => {
        return ( notification.serviceId == service.id) ? notification : false
      })
    },
    formatISODate (date) {
      let dateAndTimeSeparated = date.split('T')
      dateAndTimeSeparated[0] = dateAndTimeSeparated[0].split('-').reverse().join('/')
      let finalDate = dateAndTimeSeparated.join(' - ')
      return finalDate
    },
    generateFilters (items) {
      let t = this
      for(var a in items) {
        let item = items[a]
        if (!this.dealerships.includes(item.dealership)) this.dealerships.push(item.dealership)
      }
      this.status = [1, 2, 3, 4]
    },
    getDateAndTime (date){
      let dateAndTimeSeparated = date.split('T')
      return dateAndTimeSeparated[1].substring(0, 5)
    },
    getTrackingsByService(id) {
      return this.trackings.filter(tracking => tracking.serviceId == id)
    },
    cleanFilters(event) {
      let t = this
      for (var a in this.searchFilter) {
        this.searchFilter[a] = ''
      }
      this.mercuriusCaseSearchText = ''
      this.periodFilterSelected = ''
      this.$forceUpdate()

    },
    goToExpandir(){
      let t = this
      let obj = {}
      obj.searchFilter = this.searchFilter
      obj.periodFilterSelected = this.periodFilterSelected
      this.$store.commit('main/SET_KANBAN_FILTERS', obj)
      this.$router.push({path: '/atendimentos/kanban/expandido'})
    },
    onMouseOver (e) {
      this.timer = setTimeout(() => this.readService(e), 500)
    },
    onMouseOut () {
      clearTimeout(this.timer)
    },
    async readService (e) {
      let item = null
      try {
        let id = e.target.offsetParent.id.substring(5) || e.target.id.substring(5)
        item = this.items.find(item => item.id == id)
        item.unread = false
        let response = await this.actionReadService({ id })
      } catch(e) {
        console.warn(e)
        if (item)
          item.unread = true
      }
    },
    prepareUnreadListeners () {

      let cards = document.querySelectorAll('.kanban-card')
      cards.forEach(card => {
        // card.addEventListener('mouseover', this.onMouseOver)
        // card.addEventListener('mouseout', this.onMouseOut)
        card.addEventListener('click', this.readService)
      })
    },
    async fetchData(isAutoUpdate = false) {
      if (this.busy) return
      if (!isAutoUpdate) {
        this.$utils.showLoadingIndicator()
      } else {
        this.checkForNewServices();
      }
      try {
        this.busy = true
        this.items = await this.getServicesKanban(this.getParamsPayload(this.searchFilter))
        this.status = [1, 2, 3, 4]
        let serviceIds = []
        this.items.forEach(item => serviceIds.push(item.id))
        this.serviceNotifications = serviceIds.length 
          ? await this.loadServiceListNotifications({ serviceIds: serviceIds })
          : []
      } catch(error) {
        console.warn(error)
      }
      this.$utils.hideLoadingIndicator()
      this.busy = false
      this.kanbanComponentKey = this.kanbanComponentKey ? 0 : 1
      this.refreshData()
      this.$nextTick(() => {
        this.prepareUnreadListeners()
      })
    },
    async checkForNewServices() {
      const onlyStatusChanges = true;
      const newOrChangedServices = await this.checkNewServices(onlyStatusChanges);
      newOrChangedServices.length > 0 && this.sendDesktopNotification();
    },
    getParamsPayload(filters) {
      return {
        mercuriusCase: filters.mercuriusCase,
        initialDate: filters.startDate,
        finalDate: filters.endDate,
        lastHours: filters.lastHours
      }
    },
    updatePeriodFilter(value) {
      if (this.periodFilters.includes(this.periodFilterSelected) && !this.isPeriodBetween) {
        this.searchFilter.startDate = this.searchFilter.endDate = ''
        const hours = [24, 48, 72, 720]
        this.searchFilter.lastHours = hours[this.periodFilters.indexOf(this.periodFilterSelected)];
      } else {
        this.searchFilter.lastHours = ''
      }
    },
    updateMercuriusCaseSearch() {
      this.searchFilter.mercuriusCase = this.mercuriusCaseSearchText
    },
    sendDesktopNotification() {
      const notificationTitle = this.$t('services.newServicesNotificationTitle');
      const notificationBody = this.$t('services.newServicesNotificationBody');
      this.$notification.show(notificationTitle, { body: notificationBody }, {});
    },
    forceUpdate() {
      this.fetchData(true)
    }
  },
  computed: {
    ...mapState('services', [
      'trackings'
    ]),

    filteredDates() {
      let t = this
      let startDate = (this.searchFilter.startDate ) ? this.searchFilter.startDate.split('-').reverse().join('/') : ''
      let endDate = (this.searchFilter.endDate ) ? this.searchFilter.endDate.split('-').reverse().join('/') : ''

      return {
        startDate, endDate
      }

    },
    periodFilters() {
      return [
        this.$t('services.last24h'), 
        this.$t('services.last48h'), 
        this.$t('services.last72h'),
        this.$t('services.lastMonth'),
        this.$t('services.periodBetween')
      ];
    },
    isPeriodBetween() {
      return this.periodFilterSelected == _.last(this.periodFilters);
    },
    kanbanGridData() {
      return [
        { type: this.$t('trackings.sentService'), status: 'report' },
        { type: this.$t('trackings.departure'), status: 'departure' },
        { type: this.$t('trackings.startRepair'), status: 'start' },
        { type: this.$t('trackings.finished'), status: 'end' },
      ];
    }
  },
  mounted (){
    let localStorageFilters = localStorage.getItem('kanbanFilters')
    let localStoragePeriodFilter = localStorage.getItem('kanbanPeriodFilter')
    if (localStorageFilters) {
      this.searchFilter = JSON.parse(localStorageFilters)
      this.mercuriusCaseSearchText = this.searchFilter.mercuriusCase
      this.$forceUpdate()
    }
    if (localStoragePeriodFilter) {
      this.periodFilterSelected = JSON.parse(localStoragePeriodFilter)
      this.updatePeriodFilter()
      this.$forceUpdate()
    }
    this.fetchData()
  },
  beforeDestroy() {
    this.leftPage = true
    this.refreshData.cancel()
  },
  watch: {
    searchFilter: {
      handler(value) {
        localStorage.setItem('kanbanFilters', JSON.stringify(this.searchFilter))
        this.fetchData()
      },
      deep: true
    },
    periodFilterSelected(value) {
      localStorage.setItem('kanbanPeriodFilter', JSON.stringify(this.periodFilterSelected))
    }

  }

}
</script>