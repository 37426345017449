var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main-layout",
    [
      _c(
        "v-layout",
        {
          staticClass: "vv-form",
          attrs: { "justify-center": "", row: "", wrap: "" }
        },
        [
          _c(
            "v-flex",
            { attrs: { xs12: "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c("form-alert", {
                    attrs: {
                      "alert-model": !!_vm.alertText,
                      "alert-text": _vm.alertText
                    },
                    on: {
                      input: function($event) {
                        return _vm.closeFormAlert()
                      }
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "vv-form-header" },
                [
                  _c("base-voltar-button", {
                    attrs: { to: { path: "/concessionarias" } }
                  })
                ],
                1
              ),
              _c(
                "v-layout",
                {
                  staticClass: "white-background",
                  attrs: { row: "", wrap: "" }
                },
                [
                  _c(
                    "v-form",
                    { ref: "form" },
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs6: "" } },
                        [
                          _c(
                            "v-layout",
                            { attrs: { row: "", wrap: "" } },
                            [
                              _c(
                                "v-flex",
                                { attrs: { xs12: "" } },
                                [
                                  _c("v-text-field", {
                                    ref: "inputName",
                                    attrs: {
                                      label: _vm.$t(
                                        "dealerships.dealershipName"
                                      ),
                                      rules: [
                                        function(value) {
                                          return (
                                            !!value ||
                                            _vm.$t("misc.validateInput")
                                          )
                                        }
                                      ],
                                      required: ""
                                    },
                                    model: {
                                      value: _vm.model.name,
                                      callback: function($$v) {
                                        _vm.$set(_vm.model, "name", $$v)
                                      },
                                      expression: "model.name"
                                    }
                                  })
                                ],
                                1
                              ),
                              ["VASManager", "CountryVasManager"].includes(
                                _vm.employee.level
                              )
                                ? _c("v-flex", { attrs: { xs12: "" } }, [
                                    _c(
                                      "select",
                                      {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.selectedEconomicGroup,
                                            expression: "selectedEconomicGroup"
                                          }
                                        ],
                                        staticClass: "vv-input form-select",
                                        attrs: {
                                          label: _vm.$tc(
                                            "dealerships.economicGroup",
                                            1
                                          )
                                        },
                                        on: {
                                          change: function($event) {
                                            var $$selectedVal = Array.prototype.filter
                                              .call(
                                                $event.target.options,
                                                function(o) {
                                                  return o.selected
                                                }
                                              )
                                              .map(function(o) {
                                                var val =
                                                  "_value" in o
                                                    ? o._value
                                                    : o.value
                                                return val
                                              })
                                            _vm.selectedEconomicGroup = $event
                                              .target.multiple
                                              ? $$selectedVal
                                              : $$selectedVal[0]
                                          }
                                        }
                                      },
                                      [
                                        _c(
                                          "option",
                                          {
                                            staticClass: "vv-disabled",
                                            attrs: { selected: "", value: "" }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$tc(
                                                  "dealerships.economicGroup",
                                                  1
                                                )
                                              )
                                            )
                                          ]
                                        ),
                                        _vm._l(_vm.economicGroups, function(
                                          economicGroup,
                                          key
                                        ) {
                                          return _c(
                                            "option",
                                            {
                                              key: key,
                                              domProps: {
                                                value: economicGroup.id
                                              }
                                            },
                                            [_vm._v(_vm._s(economicGroup.name))]
                                          )
                                        })
                                      ],
                                      2
                                    )
                                  ])
                                : _vm._e(),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "" } },
                                [
                                  _c("vuetify-google-autocomplete", {
                                    ref: "address",
                                    attrs: {
                                      id: "map",
                                      required: true,
                                      classname: "input-group",
                                      label: _vm.$t("dealerships.localization"),
                                      country: _vm.searchCountries,
                                      "load-google-api": false,
                                      "google-api-key": "",
                                      types: "address",
                                      language: _vm.$root.$i18n.locale
                                    },
                                    on: { placechanged: _vm.getAddressData },
                                    model: {
                                      value: _vm.model.address,
                                      callback: function($$v) {
                                        _vm.$set(_vm.model, "address", $$v)
                                      },
                                      expression: "model.address"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "" } },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      items: _vm.countryList,
                                      "return-object": "",
                                      color: "rgba(0,0,0,.87)",
                                      label: _vm.$t("misc.country"),
                                      rules: [
                                        function(value) {
                                          return (
                                            !!value ||
                                            _vm.$t("misc.validateInput")
                                          )
                                        }
                                      ],
                                      required: ""
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "selection",
                                        fn: function(data) {
                                          return [
                                            _vm._v(
                                              "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                _vm._s(data.item.name) +
                                                "\n\t\t\t\t\t\t\t\t\t\t"
                                            )
                                          ]
                                        }
                                      },
                                      {
                                        key: "item",
                                        fn: function(data) {
                                          return [
                                            _vm._v(
                                              "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                _vm._s(data.item.name) +
                                                "\n\t\t\t\t\t\t\t\t\t\t"
                                            )
                                          ]
                                        }
                                      }
                                    ]),
                                    model: {
                                      value: _vm.model.country,
                                      callback: function($$v) {
                                        _vm.$set(_vm.model, "country", $$v)
                                      },
                                      expression: "model.country"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs6: "" } },
                                [
                                  _c("v-text-field", {
                                    ref: "latitude",
                                    attrs: {
                                      label: _vm.$t("misc.latitude"),
                                      rules: [_vm.testLatLng]
                                    },
                                    on: {
                                      keyup: function($event) {
                                        return _vm.checkIfLatLng(
                                          $event.target.value
                                        )
                                      }
                                    },
                                    model: {
                                      value: _vm.model.latitude,
                                      callback: function($$v) {
                                        _vm.$set(_vm.model, "latitude", $$v)
                                      },
                                      expression: "model.latitude"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs6: "" } },
                                [
                                  _c("v-text-field", {
                                    ref: "longitude",
                                    attrs: {
                                      label: _vm.$t("misc.longitude"),
                                      rules: [_vm.testLatLng]
                                    },
                                    on: {
                                      keyup: function($event) {
                                        return _vm.checkIfLatLng(
                                          $event.target.value
                                        )
                                      }
                                    },
                                    model: {
                                      value: _vm.model.longitude,
                                      callback: function($$v) {
                                        _vm.$set(_vm.model, "longitude", $$v)
                                      },
                                      expression: "model.longitude"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-layout",
        { staticClass: "vv-form-button", attrs: { row: "", wrap: "" } },
        [
          _c("v-spacer"),
          _vm.$route.params.id
            ? _c("v-flex", { attrs: { xs4: "" } }, [
                _c(
                  "button",
                  {
                    staticClass:
                      "btn-primary vv-button vv-button vv-adicionar-novo-button",
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        _vm.dialog = true
                      }
                    }
                  },
                  [_c("span", [_vm._v(_vm._s(_vm.$t("misc.delete")))])]
                )
              ])
            : _vm._e(),
          _c("v-flex", { attrs: { xs4: "" } }, [
            _c(
              "button",
              {
                ref: "buttonSave",
                staticClass:
                  "btn-primary vv-button vv-button-inverted vv-adicionar-novo-button",
                on: {
                  click: function($event) {
                    return _vm.save()
                  }
                }
              },
              [
                _c("span", [
                  _vm._v(
                    _vm._s(
                      !_vm.$route.params.id
                        ? _vm.$t("misc.add")
                        : _vm.$t("misc.edit")
                    ) +
                      " " +
                      _vm._s(_vm.$tc("dealerships.dealership", 1)) +
                      " "
                  )
                ])
              ]
            )
          ])
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "290" },
          model: {
            value: _vm.dialog,
            callback: function($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "headline" }, [
                _vm._v(_vm._s(_vm.$t("misc.areYouSure")))
              ]),
              _c("v-card-text", [
                _vm._v(
                  _vm._s(
                    _vm.$t("misc.askForDelete", {
                      theEntity: _vm.$t("dealerships.theDealership")
                    })
                  )
                )
              ]),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "green darken-1", flat: "flat" },
                      nativeOn: {
                        click: function($event) {
                          _vm.dialog = false
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("misc.cancel")))]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "green darken-1", flat: "flat" },
                      nativeOn: {
                        click: function($event) {
                          _vm.dialog = false
                          _vm.remove()
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("misc.confirm")))]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }