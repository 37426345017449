<template>
  <v-layout row wrap class='card-container'>
    <v-flex
    xs3
    v-for="data in kanbanGridData"
    :key="kanbanGridData.type">
      <v-subheader>
        {{ data.type }} ({{ (sortedItemsPerStatus[data.status]) ? sortedItemsPerStatus[data.status].length : 0 }})
      </v-subheader>
      <hr class='vv-kanban-separator'>
       <kanban-card
       v-for="(item, key) in sortedItemsPerStatus[data.status]"
       :item="item"
       :notifications="getNotificationsPerService(item)"
       @forceUpdate="$emit('forceUpdate')"
       :key="item.id" ></kanban-card>

    </v-flex>
  </v-layout>
</template>

<script>
export default {

  name: 'KanbanKanban',

  data () {
    return {

    }
  },
  props: ['kanbanGridData', 'items', 'serviceNotifications'],
  computed: {
    sortedItemsPerStatus () {
      let t = this
      let items = t.items

      if (items.length == 0) {
        return []
      }
      let report = items.filter(item => item.status == 1 )
      let departure = items.filter(item => item.status == 2  ||  item.status == 5)
      let start = items.filter(item => item.status == 3 || item.status == 9 || item.status == 8)
      let end = items.filter(item => item.status == 4)
      let sortByMercuriusCase = (a, b) => b.unread - a.unread || a.mercuriusCase - b.mercuriusCase
      report.sort(sortByMercuriusCase)
      departure.sort(sortByMercuriusCase)
      start.sort(sortByMercuriusCase)
      end.sort(sortByMercuriusCase)

      return {report, departure, start, end}
    },

  },
  methods: {
    getNotificationsPerService(service) {
      let t = this

      return t.serviceNotifications.filter(notification => {
        return ( notification.serviceId == service.id) ? notification : false
      })
    },
  }
}
</script>

<style lang="css" scoped>
</style>