var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "d-flex page-not-found__container pa-5" }, [
    _c("div", { staticClass: "page-not-found__row mt-5" }, [
      _c("img", {
        staticClass: "page-not-found__logo logo",
        attrs: { src: require("@/assets/voar_logo.png"), alt: "" }
      }),
      _c("p", { staticClass: "pt-10 mb-6 page-not-found__title" }, [
        _vm._v("\n      " + _vm._s(_vm.$t("pageNotFound.title")) + "\n    ")
      ]),
      _c("p", { staticClass: "mb-3 page-not-found__text" }, [
        _vm._v("\n      " + _vm._s(_vm.$t("pageNotFound.text")) + "\n    ")
      ]),
      _c(
        "button",
        {
          staticClass:
            "page-not-found__container page-not-found__button btn-primary vv-button vv-button-inverted vv-adicionar-novo-button",
          on: {
            click: function($event) {
              return _vm.goToLogin()
            }
          }
        },
        [_c("span", [_vm._v(_vm._s(_vm.$t("pageNotFound.button")))])]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }