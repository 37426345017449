import axios from 'axios'
import config from '@/app.settings'
import utils from '@/libs/utils'

function economicGroupDefaultAdapter(economicGroups) {
  economicGroups.forEach(economicGroup => {
    utils.uncapitalizeAllKeys(economicGroup)
  })
  return economicGroups
}

export const state = {
  economicGroups: [],
}
export const getters = {}

export const mutations = {
  SET_ECONOMIC_GROUPS (state, payload) {
    state.economicGroups = payload
  },
}

export const actions = {
  async addEconomicGroup ({commit}, payload) {
    utils.capitalizeAllKeys(payload)
    let response = await axios.post(config.apiUrl + '/economicgroups', payload)
    return response.data
  },
  async editEconomicGroup ({commit}, payload) {
    utils.capitalizeAllKeys(payload)
    let response = await axios.put(`${config.apiUrl}/economicgroups/${payload.Id}`, payload)
    return response.data
  },
  async loadEconomicGroups ({commit}) {
    let response = await axios.post(config.apiUrl + '/economicgroups/search', {})

    return economicGroupDefaultAdapter(response.data)
  },
  async loadEconomicGroup ({commit}, payload) {
    let response = await axios.post(config.apiUrl + '/economicgroups/search', { Id: payload.id })
    return economicGroupDefaultAdapter(response.data)
  },
  async deleteEconomicGroup ({commit}, payload) {
    let response = await axios.delete(config.apiUrl + '/economicgroups/' + payload.Id)
    return response.data
  },
}