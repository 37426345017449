var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-flex",
    { attrs: { xs12: "" } },
    [
      _c(
        "v-alert",
        {
          attrs: {
            color: _vm.alertText.type,
            outline: "",
            icon: "check_circle",
            dismissible: "",
            transition: "scale-transition"
          },
          on: {
            input: function($event) {
              return _vm.$emit("input")
            }
          },
          model: {
            value: _vm.alertModel,
            callback: function($$v) {
              _vm.alertModel = $$v
            },
            expression: "alertModel"
          }
        },
        [_vm._v("\n    " + _vm._s(_vm.alertText.message) + "\n  ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }