import store from '@/store'
import config from '@/app.settings'
import _ from 'lodash'
import { utcToZonedTime, format } from 'date-fns-tz'

const IMAGE_COUNTRY = {
  brazil: {
    sidebar: require("@/assets/voar_logo.png"),
    status: require("@/assets/volvo_logo_whitesvg.svg"),
  },
  peru: {
    sidebar: require("@/assets/volar_logo.png"),
    status: require("@/assets/volar_logo_white.png"),
  },
  argentina: {
    sidebar: require("@/assets/vas_logo.png"),
    status: require("@/assets/vas_logo_white.png"),
  },
  chile: {
    sidebar: require("@/assets/vas_logo.png"),
    status: require("@/assets/vas_logo_white.png"),
  },
}

export default {
  formatDate(date) {

    return date
  },
  geocodeAddress(address, returnFullResults = false) {
    return new Promise((resolve, reject) => {
      let geocoder = new google.maps.Geocoder();
      geocoder.geocode({ address }, function(results, status) {
        // console.log(results, status)
        if (status == google.maps.GeocoderStatus.OK) {

          let latitude = results[0].geometry.location.lat();
          let longitude = results[0].geometry.location.lng();
          if (returnFullResults) {
            resolve(results)
          }
          resolve({latitude, longitude})
        }

        reject([results, status])

      })
    })
  },
  getTypeOfTracking(trackings) {
    // vai checar as trackings e retornar 3 se achar um do tipo 3,
    // 2 se achar um do tipo 2, 1 se não achar nenhum outro mas achar um do tipo 1
    if (trackings) {
      let i = 3
      while(i) {
        if(trackings.find(item => item.type == i)) {
          return i
        }
        i--
      }
      return false
    }
  },
  getServiceStatusClass(status){
    switch(status) {
      case 1: return 'report'
      case 2: return 'departure'
      case 3: return 'start'
      case 8: return 'start'
      case 9: return 'start'
      case 4: return 'end'
      case 5: return 'arrived'

    }
  },
  getNotificationTypeName (vm, status, kanban = false) {
    if (kanban && status == 7) {
      status = 2
    }
    switch (status) {
      case 1: return vm.$t('trackings.firstContactClient')
      case 2: return vm.$t('footerStatus.start')
      case 3: return vm.$t('footerStatus.repairing')
      case 4: return vm.$t('trackings.finished')
      case 5: return vm.$t('trackings.delayService')
      case 6: return vm.$t('trackings.quitService')
      case 7: return vm.$t('trackings.closer')
      case config.PAUSED_STATUS: return vm.$t('footerStatus.paused')
      case config.RESUMED_STATUS: return vm.$t('footerStatus.resumed')
      case config.CANCELLED_STATUS: return vm.$t('footerStatus.cancel')
      default: return vm.$t('trackings.undefinedStatus') + status

    }
  },
  getTimezoneOffsetMiliseconds() {
    return (new Date()).getTimezoneOffset() * 60000
  },
  fixDateWithoutGMT(date){
    if (!date) return date
    const timeZoneAccordingBrowser = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const zonedDate = utcToZonedTime(new Date(date), timeZoneAccordingBrowser)
    return format(zonedDate, "yyyy-MM-dd'T'HH:mm:ss", { timeZone: timeZoneAccordingBrowser })
  },
  sortAlphabeticalOrder(listToBeSorted){
    let sorted = listToBeSorted.sort((a, b) => {
      if (a.name < b.name) return -1
      if (a.name > b.name) return 1
      return 0
    })
    return sorted
  },
  showLoadingIndicator () {
    store.commit('main/SHOW_LOADING_INDICATOR')
  },
  hideLoadingIndicator () {
    store.commit('main/HIDE_LOADING_INDICATOR')
  },
  handleAlertText() {
    let alertText = store.state.main.alertText
    store.commit('main/SET_ALERT_TEXT', false)
    return alertText
  },

  parseJwt (token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
  },
  capitalize(s)  {
    return s.charAt(0).toUpperCase() + s.slice(1)
  },
  uncapitalize(s){
    return s.charAt(0).toLowerCase() + s.slice(1);
  },
  uncapitalizeAllKeys(data) {
    Object.entries(data).forEach(item => {
      delete data[item[0]]
      data[this.uncapitalize(item[0])] = item[1]
      if (_.isPlainObject(item[1])) {
        this.uncapitalizeAllKeys(item[1]);
      } else if (_.isArray(item[1])) {
        item[1].forEach(element => _.isPlainObject(element) && this.uncapitalizeAllKeys(element));
      }
    })
  },
  capitalizeAllKeys(data) {
    Object.entries(data).forEach(item => {
      delete data[item[0]]
      data[this.capitalize(item[0])] = item[1]
      if (_.isPlainObject(item[1])) {
        this.capitalizeAllKeys(item[1]);
      } else if (_.isArray(item[1])) {
        item[1].forEach(element => _.isPlainObject(element) && this.capitalizeAllKeys(element));
      }
    })
  },
  normalizeString(str) {
    if(!str) return ''
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase()
  },
  getImageAccordingCountry(item) {
    return IMAGE_COUNTRY[item.name][item.type] || IMAGE_COUNTRY["brazil"][item.type];
  },

formatDateApi(date){
  if(date == undefined || date == null || date == ''){
    return ''
  }
  let data = new Date(date);
  let dataFormatada = ((data.getDate() )) + "/" + ((data.getMonth() + 1)) + "/" + data.getFullYear(); 
  return dataFormatada
}

}
