var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main-layout",
    [
      _c(
        "v-layout",
        {
          staticClass: "vv-form",
          attrs: { "justify-center": "", row: "", wrap: "" }
        },
        [
          _c(
            "v-flex",
            { attrs: { xs12: "" } },
            [
              _c(
                "v-layout",
                [
                  _c(
                    "v-flex",
                    { attrs: { xs6: "" } },
                    [
                      _c("base-voltar-button", {
                        on: {
                          click: function($event) {
                            return _vm.$router.go(-1)
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _c("v-spacer")
                ],
                1
              ),
              _c(
                "v-layout",
                { attrs: { row: "", wrap: "" } },
                [
                  _c(
                    "v-flex",
                    { attrs: { xs12: "" } },
                    [
                      _vm.showMap
                        ? _c("div", {
                            staticClass: "vv-map full",
                            attrs: { id: "location-map" }
                          })
                        : _vm._e(),
                      !_vm.showMap
                        ? _c(
                            "v-layout",
                            {
                              staticClass: "white-background",
                              attrs: { row: "", wrap: "" }
                            },
                            [
                              _vm._v(
                                "\n\n                Houve um problema para renderizar o mapa desse atendimento: por favor, cheque se todos os endereços correspondem à localizações válidas no google maps.\n              "
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm.showMap
                    ? _c("v-flex", { attrs: { xs12: "" } }, [
                        _c("div", [
                          _c("div", { staticClass: "status-indicator" }, [
                            _c("div", {
                              staticClass: "status-badge processing"
                            }),
                            _c("div", { staticClass: "status-label " }, [
                              _vm._v(_vm._s(_vm.$t("footerStatus.report")))
                            ])
                          ]),
                          _c("div", { staticClass: "status-indicator" }, [
                            _c("div", {
                              staticClass: "status-badge departure"
                            }),
                            _c("div", { staticClass: "status-label " }, [
                              _vm._v(_vm._s(_vm.$t("footerStatus.start")))
                            ])
                          ]),
                          _c("div", { staticClass: "status-indicator" }, [
                            _c("div", {
                              staticClass: "status-badge repairing"
                            }),
                            _c("div", { staticClass: "status-label" }, [
                              _vm._v(_vm._s(_vm.$t("footerStatus.repairing")))
                            ])
                          ]),
                          _c("div", { staticClass: "status-indicator" }, [
                            _c("div", { staticClass: "status-badge mechanic" }),
                            _c("div", { staticClass: "status-label" }, [
                              _vm._v(_vm._s(_vm.$t("footerStatus.mechanic")))
                            ])
                          ])
                        ])
                      ])
                    : _vm._e(),
                  _c(
                    "v-flex",
                    { attrs: { xs12: "" } },
                    [
                      _c(
                        "v-layout",
                        {
                          staticClass: "margin-top",
                          attrs: { row: "", wrap: "" }
                        },
                        [
                          _c(
                            "v-flex",
                            { attrs: { xs12: "" } },
                            [
                              _c("v-data-table", {
                                staticClass: "elevation-1",
                                attrs: {
                                  headers: _vm.table2Headers,
                                  items: _vm.table2Items,
                                  "hide-actions": "",
                                  "no-data-text": "Sem dados disponíveis"
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "items",
                                    fn: function(props) {
                                      return [
                                        _c("td", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.getNotificationByStatus(
                                                props.item
                                              )
                                            )
                                          )
                                        ]),
                                        _c("td", {}, [
                                          _vm._v(
                                            _vm._s(
                                              props.item.date
                                                ? _vm
                                                    .formatISODate(
                                                      props.item.date
                                                    )
                                                    .split("-")[0]
                                                : "---"
                                            )
                                          )
                                        ]),
                                        props.item.type != 1
                                          ? _c("td", {}, [
                                              _vm._v(
                                                _vm._s(
                                                  props.item.date
                                                    ? _vm
                                                        .formatISODate(
                                                          props.item.date
                                                        )
                                                        .split("-")[1]
                                                        .substring(0, 9)
                                                    : "---"
                                                ) + "\n                      "
                                              )
                                            ])
                                          : _c("td", {}, [
                                              _vm._v(
                                                "\n                      " +
                                                  _vm._s(
                                                    _vm.model.reportDate
                                                      ? _vm
                                                          .formatISODate(
                                                            _vm.utils.fixDateWithoutGMT(
                                                              _vm.model
                                                                .reportDate
                                                            )
                                                          )
                                                          .split("-")[1]
                                                          .substring(0, 9)
                                                      : "---"
                                                  ) +
                                                  "\n                    "
                                              )
                                            ])
                                      ]
                                    }
                                  }
                                ])
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.model && _vm.model.notifications && _vm.kanbanReload
        ? _c(
            "div",
            { staticStyle: { display: "none" }, attrs: { id: "kanban-map" } },
            [
              _c("kanban-card", {
                attrs: {
                  item: _vm.model,
                  notifications: _vm.model.notifications,
                  "dashboard-card": ""
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }