<template>
  <main-layout id='kanban-container' class='kanban-expandido'>

        <v-layout>

          <v-flex xs12 class='logo-container'>
            <router-link :to="{path: '/atendimentos'}">
              <img class='logo kanban-expandido' src="../../../assets/voar_logo.png" alt="">
            </router-link>
          </v-flex>
        </v-layout>
        <v-layout row wrap>
          <v-flex xs4>
             <base-search-input
              v-model="mercuriusCaseSearchText"
              placeholder="Procurar Por Atendimento"
              @update="debounceMercuriusCaseSearch()"
            />

          </v-flex>
          <v-flex xs7>
            <v-layout row wrap class='borderless'>
              <v-flex sm3>
                <select  class='vv-input' v-model="periodFilterSelected" @change="updatePeriodFilter">
                  <option selected value="" class='vv-disabled'>{{$t('services.period')}}</option>
                  <option v-for="(periodFilter, key) in periodFilters" :key="key" :value="periodFilter">{{periodFilter}}</option>
                </select>
              </v-flex>

                <v-flex sm3 xs4 v-if='isPeriodBetween' >
                  <v-menu
                    lazy
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    v-model="menu"
                    full-width
                    :nudge-right="40"
                    max-width="290px"
                    min-width="290px"
                  >
                   <v-text-field class='vv-input mt-0 px-1 pt-2'
                      slot="activator"
                      readonly
                      placeholder="Inicio"
                      v-model="filteredDates.startDate"
                    ></v-text-field>
                    <v-date-picker no-title v-model="searchFilter.startDate" scrollable locale="pt-BR" actions autosave>
                      <!-- <template slot-scope="{ save, cancel }">
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn flat color="primary" @click="cancel">{{$t('misc.cancel')}}</v-btn>
                          <v-btn flat color="primary" @click="save">{{$t('misc.ok')}}</v-btn>
                        </v-card-actions>
                      </template> -->
                    </v-date-picker>
                  </v-menu>
                </v-flex>

                <v-flex sm3 xs6 v-if='isPeriodBetween' >
                  <v-menu
                    lazy
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    full-width
                    :nudge-right="40"
                    max-width="290px"
                    min-width="290px"
                  >
                   <v-text-field class='vv-input mt-0 px-1 pt-2'
                      slot="activator"
                      readonly
                      v-model="filteredDates.endDate"
                      placeholder="Fim"
                    ></v-text-field>
                    <v-date-picker no-title v-model="searchFilter.endDate" scrollable locale="pt-BR" actions autosave>
                      <!-- <template slot-scope="{ save, cancel }">
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn flat color="primary" @click="cancel">{{$t('misc.cancel')}}</v-btn>
                          <v-btn flat color="primary" @click="save">{{$t('misc.ok')}}</v-btn>
                        </v-card-actions>
                      </template> -->
                    </v-date-picker>
                  </v-menu>
                </v-flex>
                <v-flex sm3>

                </v-flex>
            </v-layout>
          </v-flex>
          <v-flex md1>
            <v-subheader class='cleanfilters' @click='cleanFilters()'>{{$t('services.reset')}}</v-subheader>
          </v-flex>

        </v-layout>
        <v-layout row wrap class='card-container'>
          <v-flex xs3 v-for="data in kanbanGridData" :key="kanbanGridData.type">
            <v-subheader>{{ data.type }} ({{ (sortedItemsPerStatus[data.status]) ? sortedItemsPerStatus[data.status].length : 0 }})</v-subheader>
            <hr class='vv-kanban-separator'>
             <kanban-card :item="item" :notifications="getNotificationsPerService(item)" v-for="(item, key) in sortedItemsPerStatus[data.status]" :key="item.id" @forceUpdate="forceUpdate"></kanban-card>
          </v-flex>
        </v-layout>
      </v-container>
      <v-footer fixed class="pa-5 kanban-expandido-footer">
        <v-flex xs2 >
          <kanban-voltar-button @click="goToOriginal()"/>
        </v-flex>
        <v-spacer></v-spacer>
        <div>
          <div class="status-indicator">
            <div class="status-badge processing"></div>
            <div class="status-label ">{{ $t('footerStatus.report') }}</div>
          </div>
          <div class="status-indicator">
            <div class="status-badge departure"></div>
            <div class="status-label ">{{ $t('footerStatus.progress') }}</div>
          </div>
          <div class="status-indicator">
            <div class="status-badge finished"></div>
            <div class="status-label">{{ $t('footerStatus.end') }}</div>
          </div>
        </div>
      </v-footer>
  </main-layout>
</template>

<style>


</style>

<script>
import KanbanCard from '@/components/KanbanCard'

import utils from "@/libs/utils"
import { mapState, mapActions } from 'vuex'
import ViewMixin from '@/mixins/ViewMixin'
import config from '@/app.settings'
import _ from 'lodash'

export default {
  name: 'Home',
  components: {
    KanbanCard
  },

  mixins: [ViewMixin],
  data: () => ({
    drawer: null,
    periodFilterSelected: '',
    mercuriusCaseSearchText: '',
    searchFilter: {
      generalSearch: '',
      dealership: '',
      status: '',
      mercuriusCase: '',
      startDate: null,
      endDate: '',
      lastHours: ''
    },
    leftPage: false,
    serviceNotifications: [],
    items: [],
    dealerships: [],
    status: [],
    menu: null,
    firstLoadServiceRequest: true,
    busy: false,
    refreshData: _.debounce(function() { this.fetchData(true) }, 30000),
    debounceMercuriusCaseSearch: _.debounce(function() { this.updateMercuriusCaseSearch() }, 600),
  }),
  methods: {

    ...mapActions('services', [
      'getServicesKanban',
      'loadServiceListNotifications'
    ]),
    getNotificationsPerService(service) {

      return this.serviceNotifications.filter(notification => {
        return ( notification.serviceId == service.id) ? notification : false
      })
    },
    formatISODate (date) {
      let dateAndTimeSeparated = date.split('T')
      dateAndTimeSeparated[0] = dateAndTimeSeparated[0].split('-').reverse().join('/')
      let finalDate = dateAndTimeSeparated.join(' - ')
      return finalDate
    },
    generateFilters (items) {
      for(var a in items) {
        let item = items[a]
        if (!this.dealerships.includes(item.dealership)) this.dealerships.push(item.dealership)
      }
      this.status = [1, 2, 3, 4]
    },
    getDateAndTime (date){
      let dateAndTimeSeparated = date.split('T')
      return dateAndTimeSeparated[1].substring(0, 5)

    },
    getTrackingsByService(id) {
      return this.trackings.filter(tracking => tracking.serviceId == id)
    },
    cleanFilters(event) {
      for (var a in this.searchFilter) {
        this.searchFilter[a] = ''
      }
      this.mercuriusCaseSearchText = ''
      this.periodFilterSelected = ''
      this.$forceUpdate()
    },
    goToOriginal() {
      let obj = {}
      obj.searchFilter = this.searchFilter
      obj.periodFilterSelected = this.periodFilterSelected
      this.$store.commit('main/SET_KANBAN_FILTERS', obj)
      this.$router.push({path: '/atendimentos/kanban'})
    },
    async fetchData(isAutoUpdate = false) {
      if (this.busy) return
      if (!isAutoUpdate) {
        this.$utils.showLoadingIndicator()
      }
      try {
        this.busy = true
        this.items = await this.getServicesKanban(this.getParamsPayload(this.searchFilter))
        this.status = [1, 2, 3, 4]
        let serviceIds = []
        this.items.forEach(item => serviceIds.push(item.id))
        this.serviceNotifications = serviceIds.length 
          ? await this.loadServiceListNotifications({ serviceIds: serviceIds })
          : []
      } catch(error) {
        console.warn(error)
      }
      this.$utils.hideLoadingIndicator()
      this.busy = false
      this.refreshData()
    },
    getParamsPayload(filters) {
      return {
        mercuriusCase: filters.mercuriusCase,
        initialDate: filters.startDate,
        finalDate: filters.endDate,
        lastHours: filters.lastHours
      }
    },
    updatePeriodFilter(value) {
      if (this.periodFilters.includes(this.periodFilterSelected) && !this.isPeriodBetween) {
        this.searchFilter.startDate = this.searchFilter.endDate = ''
        const hours = [24, 48, 72, 720]
        this.searchFilter.lastHours = hours[this.periodFilters.indexOf(this.periodFilterSelected)];
      } else {
        this.searchFilter.lastHours = ''
      }
    },
    updateMercuriusCaseSearch() {
      this.searchFilter.mercuriusCase = this.mercuriusCaseSearchText
    },
    forceUpdate() {
      this.fetchData(true)
    }
  },
  computed: {
    ...mapState('trackings', [
      'trackings'
    ]),
    sortedItemsPerStatus () {
      let items = this.items

      if (items.length == 0) {
        return []
      }
      let report = items.filter(item => item.status == 1)
      let departure = items.filter(item => item.status == 2  ||  item.status == 5 )
      let start = items.filter(item => item.status == 3)
      let end = items.filter(item => item.status == 4)

      let sortByMercuriusCase = (a, b) => b.unread - a.unread || a.mercuriusCase - b.mercuriusCase
      report.sort(sortByMercuriusCase)
      departure.sort(sortByMercuriusCase)
      start.sort(sortByMercuriusCase)
      end.sort(sortByMercuriusCase)


      return {report, departure, start, end}
    },
    filteredDates() {
      let startDate = (this.searchFilter.startDate ) ? this.searchFilter.startDate.split('-').reverse().join('/') : ''
      let endDate = (this.searchFilter.endDate ) ? this.searchFilter.endDate.split('-').reverse().join('/') : ''

      return {
        startDate, endDate
      }

    },
    periodFilters() {
      return [
        this.$t('services.last24h'), 
        this.$t('services.last48h'), 
        this.$t('services.last72h'),
        this.$t('services.lastMonth'),
        this.$t('services.periodBetween')
      ];
    },
    isPeriodBetween() {
      return this.periodFilterSelected == _.last(this.periodFilters);
    },
    kanbanGridData() {
      return [
        { type: this.$t('trackings.sentService'), status: 'report' },
        { type: this.$t('trackings.departure'), status: 'departure' },
        { type: this.$t('trackings.startRepair'), status: 'start' },
        { type: this.$t('trackings.finished'), status: 'end' },
      ];
    }
  },
  props: {
    source: String
  },
  mounted (){
    let localStorageFilters = localStorage.getItem('kanbanFilters')
    let localStoragePeriodFilter = localStorage.getItem('kanbanPeriodFilter')
    if (localStorageFilters) {
      this.searchFilter = JSON.parse(localStorageFilters)
      this.$forceUpdate()
    }
    if (localStoragePeriodFilter) {
      this.periodFilterSelected = JSON.parse(localStoragePeriodFilter)
      this.updatePeriodFilter()
      this.$forceUpdate()
    }


    this.fetchData()
    let kanbanFooter = document.querySelector('.kanban-expandido-footer')
    let kanbanExpandidoContainer = document.querySelector('#kanban-expandido')
    let interval = false
    document.addEventListener('mousemove', function(){

      kanbanFooter.style.display = 'flex'
      clearInterval(interval)
      interval = setTimeout(function() { kanbanFooter.style.display = 'none'}, 3000)
    })


  },
  beforeDestroy () {
    this.leftPage = true
    this.refreshData.cancel()
  },
  watch: {
    searchFilter: {
      handler(val) {
        localStorage.setItem('kanbanFilters', JSON.stringify(this.searchFilter))
        this.fetchData()
      },
      deep: true
    },
    periodFilterSelected(val) {
      localStorage.setItem('kanbanPeriodFilter', JSON.stringify(this.periodFilterSelected))
    }

  }

}
</script>