var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main-layout",
    [
      _c(
        "v-container",
        { attrs: { fluid: "", "grid-list-xl": "", id: "kanban-container" } },
        [
          _c(
            "v-layout",
            { attrs: { "justify-center": "", row: "", wrap: "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs4: "" } },
                [
                  _c("base-search-input", {
                    attrs: { placeholder: _vm.$t("services.filterService") },
                    on: {
                      update: function($event) {
                        return _vm.debounceMercuriusCaseSearch()
                      }
                    },
                    model: {
                      value: _vm.mercuriusCaseSearchText,
                      callback: function($$v) {
                        _vm.mercuriusCaseSearchText = $$v
                      },
                      expression: "mercuriusCaseSearchText"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-flex",
                { attrs: { xs7: "" } },
                [
                  _c(
                    "v-layout",
                    { style: { border: "none" }, attrs: { row: "", wrap: "" } },
                    [
                      _c("v-flex", { attrs: { sm3: "" } }, [
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.periodFilterSelected,
                                expression: "periodFilterSelected"
                              }
                            ],
                            staticClass: "vv-input",
                            on: {
                              change: [
                                function($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function(o) {
                                      return o.selected
                                    })
                                    .map(function(o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.periodFilterSelected = $event.target
                                    .multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                },
                                _vm.updatePeriodFilter
                              ]
                            }
                          },
                          [
                            _c(
                              "option",
                              {
                                staticClass: "vv-disabled",
                                attrs: { selected: "", value: "" }
                              },
                              [_vm._v(_vm._s(_vm.$t("services.period")))]
                            ),
                            _vm._l(_vm.periodFilters, function(
                              periodFilter,
                              key
                            ) {
                              return _c(
                                "option",
                                { key: key, domProps: { value: periodFilter } },
                                [_vm._v(_vm._s(periodFilter))]
                              )
                            })
                          ],
                          2
                        )
                      ]),
                      _vm.isPeriodBetween
                        ? _c(
                            "v-flex",
                            { attrs: { sm3: "", xs6: "" } },
                            [
                              _c(
                                "v-menu",
                                {
                                  attrs: {
                                    lazy: "",
                                    "close-on-content-click": false,
                                    transition: "scale-transition",
                                    "offset-y": "",
                                    "full-width": "",
                                    "nudge-right": 40,
                                    "max-width": "290px",
                                    "min-width": "290px"
                                  },
                                  model: {
                                    value: _vm.menu,
                                    callback: function($$v) {
                                      _vm.menu = $$v
                                    },
                                    expression: "menu"
                                  }
                                },
                                [
                                  _c("v-text-field", {
                                    staticClass: "vv-input mt-0 px-1 pt-2",
                                    attrs: {
                                      slot: "activator",
                                      readonly: "",
                                      placeholder: "Inicio"
                                    },
                                    slot: "activator",
                                    model: {
                                      value: _vm.filteredDates.startDate,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.filteredDates,
                                          "startDate",
                                          $$v
                                        )
                                      },
                                      expression: "filteredDates.startDate"
                                    }
                                  }),
                                  _c("v-date-picker", {
                                    attrs: {
                                      "no-title": "",
                                      scrollable: "",
                                      locale: "pt-BR",
                                      actions: "",
                                      autosave: ""
                                    },
                                    model: {
                                      value: _vm.searchFilter.startDate,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.searchFilter,
                                          "startDate",
                                          $$v
                                        )
                                      },
                                      expression: "searchFilter.startDate"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.isPeriodBetween
                        ? _c(
                            "v-flex",
                            { attrs: { sm3: "", xs6: "" } },
                            [
                              _c(
                                "v-menu",
                                {
                                  attrs: {
                                    lazy: "",
                                    "close-on-content-click": false,
                                    transition: "scale-transition",
                                    "offset-y": "",
                                    "full-width": "",
                                    "nudge-right": 40,
                                    "max-width": "290px",
                                    "min-width": "290px"
                                  }
                                },
                                [
                                  _c("v-text-field", {
                                    staticClass: "vv-input mt-0 px-1 pt-2",
                                    attrs: {
                                      slot: "activator",
                                      readonly: "",
                                      placeholder: "Fim"
                                    },
                                    slot: "activator",
                                    model: {
                                      value: _vm.filteredDates.endDate,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.filteredDates,
                                          "endDate",
                                          $$v
                                        )
                                      },
                                      expression: "filteredDates.endDate"
                                    }
                                  }),
                                  _c("v-date-picker", {
                                    attrs: {
                                      "no-title": "",
                                      scrollable: "",
                                      locale: "pt-BR",
                                      actions: "",
                                      autosave: ""
                                    },
                                    model: {
                                      value: _vm.searchFilter.endDate,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.searchFilter,
                                          "endDate",
                                          $$v
                                        )
                                      },
                                      expression: "searchFilter.endDate"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _c("v-flex", { attrs: { sm3: "" } }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-expandir",
                            attrs: { tag: "button" },
                            on: {
                              click: function($event) {
                                return _vm.goToExpandir()
                              }
                            }
                          },
                          [
                            _c("kanban-expandir-icon"),
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.$t("misc.expand")) +
                                "\n              "
                            )
                          ],
                          1
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-flex",
                { attrs: { md1: "" } },
                [
                  _c(
                    "v-subheader",
                    {
                      staticClass: "cleanfilters",
                      on: {
                        click: function($event) {
                          return _vm.cleanFilters()
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("services.reset")))]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("kanban-kanban", {
            key: _vm.kanbanComponentKey,
            attrs: {
              "kanban-grid-data": _vm.kanbanGridData,
              items: _vm.items,
              "service-notifications": _vm.serviceNotifications
            },
            on: { forceUpdate: _vm.forceUpdate }
          })
        ],
        1
      ),
      _c("kanban-footer")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }